import {
  blue as muiBlue,
  green as muiGreen,
  grey as muiGrey,
  yellow as muiYellow,
} from '@mui/material/colors';

export const blue = {
  ...muiBlue,
  25: '#F4F6F9',
  50: '#e6ecf7',
  100: '#b3bed3',
  200: '#90a8d6',
};

export const grey = {
  ...muiGrey,
  200: '#eaeaea',
  700: '#696969',
};

export const yellow = muiYellow;
export const green = muiGreen;

import { FC } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import Alert from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { ButtonProps as ButtonPropsMui5 } from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import LinkMui5 from 'components/atoms/Link';
import { User, UserVerificationStatus } from 'shared/types/models';
import { SUPPORT_EMAIL } from 'shared/utils/constants';
import { sessionSelector } from 'recoil/selectors';
import NextLinkMui5 from '../atoms/NextLink';

type VerificationButtonProps = {
  callbackPath: string;
  user: User;
  restProps?: {
    buttonProps?: ButtonPropsMui5;
    boxProps?: BoxProps;
  };
};

export const VerificationButton: FC<VerificationButtonProps> = ({
  callbackPath,
  restProps,
}) => {
  const session = useRecoilValueLoadable(sessionSelector({ callbackPath }));
  const sessionRefresh = useRecoilRefresher_UNSTABLE(
    sessionSelector({ callbackPath }),
  );

  const startVerification = async () => {
    createVeriffFrame({
      url: session.contents?.sessionUrl,
      onEvent: function (msg) {
        switch (msg) {
          case MESSAGES.CANCELED:
          case MESSAGES.FINISHED:
            sessionRefresh();
            break;
        }
      },
    });
  };

  if (session.state === 'hasError') {
    return (
      <Alert severity='warning' variant='standard'>
        Can&apos;t load your verification session
      </Alert>
    );
  } else if (session.state === 'loading') {
    return <CircularProgress />;
  }

  // Please see the end user flow diagram to learn more about how to handle
  // different statuses https://developers.veriff.com/#end-user-in-the-flow
  switch (session.contents?.status) {
    case UserVerificationStatus.Started:
      return (
        <>
          <Box {...restProps?.boxProps} my={1}>
            <Alert severity='info' variant='standard'>
              Please complete the verification process by submitting your driver
              license.
            </Alert>
          </Box>
          <StyledButtonMui5
            color='primary'
            {...restProps?.buttonProps}
            onClick={startVerification}
          >
            Complete verification
          </StyledButtonMui5>
        </>
      );
    case UserVerificationStatus.ResubmissionRequested:
      return (
        <>
          <Box {...restProps?.boxProps} my={1}>
            <Alert severity='info' variant='standard'>
              Please resubmit proof of driver license to complete the
              verification process
            </Alert>
          </Box>
          <StyledButtonMui5
            color='primary'
            {...restProps?.buttonProps}
            onClick={startVerification}
          >
            Start verification
          </StyledButtonMui5>
        </>
      );
    case UserVerificationStatus.Submitted:
      return (
        <Box {...restProps?.boxProps} my={1}>
          <Alert severity='info' variant='standard'>
            Your driver&apos;s license has been successfully submitted for
            review
          </Alert>
        </Box>
      );
    case UserVerificationStatus.Approved:
      return (
        <Box {...restProps?.boxProps} my={1}>
          <Alert
            severity='success'
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            variant='standard'
          >
            Your driver&apos;s license has been successfully verified
          </Alert>
        </Box>
      );
    case UserVerificationStatus.Declined:
      return (
        <Box {...restProps?.boxProps} my={1}>
          <Alert severity='warning' variant='standard'>
            Unfortunately we could not verify your driver license, please
            contact{' '}
            <LinkMui5
              component={NextLinkMui5}
              href={`mailto: ${SUPPORT_EMAIL}`}
              passHref={true}
            >
              {SUPPORT_EMAIL}
            </LinkMui5>
          </Alert>
        </Box>
      );
    default:
      return (
        <StyledButtonMui5
          variant='contained'
          color='primary'
          {...restProps?.buttonProps}
          onClick={startVerification}
        >
          Start verification
        </StyledButtonMui5>
      );
  }
};

import { useEffect } from 'react';
import { clientStorage } from 'recoil/atoms';
import { v4 as uuid } from 'uuid';

export function useInitUnathorisedUserId(): void {
  useEffect(() => {
    const unathorisedUserId = clientStorage.get('unathorised_user_id');
    if (!unathorisedUserId) {
      clientStorage.set('unathorised_user_id', uuid());
    }
  }, []);
}

import { DefaultValue, Snapshot } from 'recoil';
import { msRebatesCategories, userAtom } from '../atoms';
import { getRebatesCategoriesFromAnswersUsingQuestions } from '../../services/rebates/functions';
import { rebatesCategoriesQuestions } from '../../data/constants';
import { RebateCategoryAnswer } from '../../shared/types/common';
import { MSAPIRequestRebateCategory } from '../../shared/types/marketScan';
import Rest from '../../services/rest';
import { userRebatesNamesSelector } from '../selectors';
import { isConsumerSelector } from 'containers/deal/recoil/selectors';

const rest = new Rest();

export const maybeUpdateUserActiveBidsRebatesCategories = async (
  snapshot: Snapshot,
  categories: MSAPIRequestRebateCategory[],
) => {
  const user = await snapshot.getPromise(userAtom);
  if (!user) {
    return;
  }

  const isConsumer = await snapshot.getPromise(isConsumerSelector);
  if (!isConsumer) {
    return;
  }

  const rebates = await snapshot.getPromise(userRebatesNamesSelector);
  await rest.updateUserActiveBidsRebatesCategories({
    rebatesCategories: categories,
    rebatesNames: rebates,
  });
};

export const getRebatesCategories = async (
  snapshot: Snapshot,
  answers: RebateCategoryAnswer[],
) => {
  const msRebatesCategoriesAtom = await snapshot.getPromise(
    msRebatesCategories,
  );
  // noinspection UnnecessaryLocalVariableJS
  const categories = getRebatesCategoriesFromAnswersUsingQuestions(
    rebatesCategoriesQuestions,
    answers,
    msRebatesCategoriesAtom,
  );
  return categories;
};

export const guardRecoilDefaultValue = (
  candidate: any,
): candidate is DefaultValue => {
  return candidate instanceof DefaultValue;
};

import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { blue } from '../../../theme/colors';

export type SelectOptionType = {
  label: any;
  subLabel?: any;
  value: any;
};

const SelectOption = styled(MenuItem)(
  ({ theme }) => `
  &.MuiMenuItem-root {
    padding: ${theme.spacing(1, 3)};
    &:is(&.Mui-selected, &.Mui-selected:hover) {
      color: ${theme.palette.primary.main};
      background-color: ${blue[50]} !important;
    }
  }`,
);

export default SelectOption;

import React from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import { StandardTextFieldProps } from '@mui/material/TextField';
import StyledTextField from 'components/atoms/TextField';
import { FieldValidator } from 'final-form';

interface FieldTextProps extends StandardTextFieldProps {
  validate?: FieldValidator<never>;
  afterChange?: (any) => void;
  metaCondition?: (meta: FieldMetaState<never>) => boolean;
}

const FieldText = ({
  name,
  children,
  helperText = '',
  afterChange,
  inputProps = {},
  validate,
  metaCondition,
  ...otherProps
}: FieldTextProps): JSX.Element => (
  <Field name={name} validate={validate}>
    {({ meta, input: { name, onChange, value, ...restInput } }) => {
      const isReady = metaCondition ? metaCondition(meta) : meta.touched;
      return (
        <StyledTextField
          {...otherProps}
          size='small'
          fullWidth
          name={name}
          inputProps={{ ...restInput, ...inputProps }}
          onChange={(...e) => {
            onChange(...e);
            afterChange && afterChange(value);
          }}
          value={value}
          error={(meta.error || meta.submitError) && isReady}
          helperText={
            (isReady && (meta.error || meta.submitError)) || helperText
          }
        >
          {children}
        </StyledTextField>
      );
    }}
  </Field>
);

export default FieldText;

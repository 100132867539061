import dynamic from 'next/dynamic';
import { forwardRef } from 'react';
import { RunnerUpDialogProps } from './RunnerUpDialog';

const RunnerUpDialog = dynamic(() => import('./RunnerUpDialog'), {
  ssr: false,
});

export const RunnerUpDialogDynamic = forwardRef(
  (
    props: Omit<RunnerUpDialogProps, 'forwardedRef'>,
    ref: RunnerUpDialogProps['forwardedRef'],
  ) => <RunnerUpDialog {...props} forwardedRef={ref} />,
);
RunnerUpDialogDynamic.displayName = 'RunnerUpDialogDynamic';

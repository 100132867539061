import { MSISRebate } from '../types/marketScan';

const REBATE_PERCENT_VALUE_TYPE = 1;

/*
 * This function returns the value of the rebate passed as an argument.
 */
export const getRebateValue = (rebate: MSISRebate, msrp: number): number => {
  const originalValue = rebate.Value.Values?.[0]?.Value;
  const termsValue = rebate.Terms?.[0]?.Value?.Values?.[0]?.Value;

  const rebateValue = originalValue || termsValue;

  if (!rebateValue) {
    return 0;
  }

  if (rebate.ValueType === REBATE_PERCENT_VALUE_TYPE) {
    // FIXME: for some reason, some rebates with this type (1 "MSISRebateValueTypeEnum.PctMSRPIncludingDFC") have Straight Dollar Amount (similar to rebates with type 0 "MSISRebateValueTypeEnum.Dollar")
    if (rebateValue > 100) {
      return rebateValue;
    }
    return (rebateValue / 100) * msrp;
  }

  return rebateValue;
};

import { FC } from 'react';
import { DialogTitle } from '../../containers/dashboard/components/Dialog/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  importInventoryErrorsAtom,
  openImportInventoryErrorsDialogAtom,
} from '../../recoil/atoms';
import { styled as styledMui5 } from '@mui/material/styles';
import { useScreenSize } from '../../hooks/useScreenSize';
import { AlertColor } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

const StyledDialogTitle = styledMui5(DialogTitle)(
  ({ theme }) => `
  &.MuiDialogTitle-root {
    margin: ${theme.spacing(2, 6, 2, 3)};
    box-shadow: none;
    padding: 0;
  }`,
);

const StyledDialogContent = styledMui5(DialogContent)(
  ({ theme }) => `
  &.MuiDialogContent-root {
    margin: ${theme.spacing(0, 3, 2, 3)};
    padding: 0;
  }`,
);

// temporary fix for the issue with the Alert component not being styled properly
const generateAlertSeverityColor = (severity: AlertColor) => {
  switch (severity) {
    case 'success':
      return `
        color: rgb(30, 70, 32);
        background-color: rgb(237, 247, 237);`;
    case 'error':
      return `
        color: rgb(95, 33, 32);
        background-color: rgb(253, 237, 237);`;
    case 'warning':
      return `
        color: rgb(102, 60, 0);
        background-color: rgb(255, 244, 229);`;
    case 'info':
      return `
        color: rgb(1, 67, 97);
        background-color: rgb(229, 246, 253);`;
  }
};

// temporary fix for the issue with the Typography component not being styled properly
const errorTypographyStyle: SxProps<ThemeMui5> = () => ({
  '&.MuiTypography-root': {
    color: 'inherit',
  },
});

const StyledAlert = styledMui5(Alert)(
  ({ theme, severity }) => `
  &.MuiAlert-root {
    margin-bottom: ${theme.spacing(1)};
    ${generateAlertSeverityColor(severity)}
  }`,
);

const PreWrapTypography = styledMui5(Typography)`
  &.MuiTypography-root {
    white-space: pre-wrap;
  }`;

export const ImportInventoryErrorsDialog: FC = () => {
  const { isExtraSmallScreen } = useScreenSize();

  const [open, setOpen] = useRecoilState(openImportInventoryErrorsDialogAtom);
  const errors = useRecoilValue(importInventoryErrorsAtom) ?? [];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen={isExtraSmallScreen} open={open} onClose={handleClose}>
      <StyledDialogTitle title='' onClose={handleClose}>
        <Typography variant='h6' color='primary'>
          The following errors occurred during the inventory import
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        {errors.map((error, index) => (
          <StyledAlert severity='error' color='error' key={index}>
            {typeof error === 'string' ? (
              <Typography
                variant='body2'
                color='inherit'
                sx={errorTypographyStyle}
              >
                {error}
              </Typography>
            ) : (
              <PreWrapTypography variant='body2' color='inherit'>
                {JSON.stringify(error, null, 3)}
              </PreWrapTypography>
            )}
          </StyledAlert>
        ))}
      </StyledDialogContent>
    </Dialog>
  );
};

import { PaymentIntentResult } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { ConfirmCardPayment } from './types';

class Payment {
  async confirmCardPayment(
    params: ConfirmCardPayment,
  ): Promise<PaymentIntentResult> {
    const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);

    return stripe.confirmCardPayment(params.intentClientSecret, {
      payment_method: params.paymentMethod,
    });
  }
}

export default new Payment();

import { ChangeEvent, FC } from 'react';
import TypographyMui5 from '@mui/material/Typography';
import BoxMui5 from '@mui/material/Box';
import { styled as styledMui5 } from '@mui/material/styles';
import { StyledSwitchMui5 } from '../atoms/Switch';

const TitleWrapperMui5 = styledMui5(BoxMui5)(
  ({ theme }) => `
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(1)};
  gap: ${theme.spacing(1)};`,
);

const SwitchWrapperMui5 = styledMui5(BoxMui5)(
  ({ theme }) => `
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${theme.spacing(0.5)};`,
);

type Props = {
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  scale?: number;
};

export const SwitchItemMui5: FC<Props> = ({
  title,
  scale,
  onChange,
  checked = false,
}) => {
  return (
    <TitleWrapperMui5>
      <TypographyMui5>{title}</TypographyMui5>
      <SwitchWrapperMui5>
        <TypographyMui5>No</TypographyMui5>
        <StyledSwitchMui5 onChange={onChange} checked={checked} scale={scale} />
        <TypographyMui5>Yes</TypographyMui5>
      </SwitchWrapperMui5>
    </TitleWrapperMui5>
  );
};

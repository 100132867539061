import { MouseEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { SelectOption as MenuItem } from 'components/atoms/Select';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';

import AffectedAuctionsDialog from '../bids/components/AffectedAuctionsDialog';
import Divider from '@mui/material/Divider';
import { useScreenSize } from 'hooks/useScreenSize';
import { styled } from '@mui/material/styles';

const ListTitle = styled(ListItemText)(
  () => `
  &.MuiListItemText-root {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }`,
);

const CardBrand = styled(Typography)(
  () => `
  &.MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }`,
);

const CardHiddenDigits = styled('span')(
  ({ theme }) => `
  color: ${theme.palette.grey[400]};
  letter-spacing: 0.1;`,
);

const DefaultCardTitle = styled(Typography)(
  () => `
  &.MuiTypography-root {
    font-style: italic;
  }`,
);

const CardMenuIcon = styled(StyledFontAwesomeIconMui5)(
  ({ theme }) => `
  color: inherit;
  &.fa-ellipsis-v {
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
  }`,
);

const PaymentMethod = ({
  paymentMethod,
  defaultPaymentMethodId,
  onSetDefaultPaymentMethod,
  onDeletePaymentMethod,
}: {
  paymentMethod: {
    id: string;
    card: {
      last4: string;
      brand: string;
    };
  };
  defaultPaymentMethodId: string;
  onSetDefaultPaymentMethod: (string) => void;
  onDeletePaymentMethod: (string) => void;
}): JSX.Element => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [deleteMethodDialog, setDeleteMethodDialog] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(anchorEl);

  const { isExtraSmallScreen } = useScreenSize();

  return (
    <>
      <ListItem dense key={paymentMethod.id}>
        <ListTitle disableTypography>
          <CardBrand variant='body2' color='textPrimary'>
            {paymentMethod.card.brand}
          </CardBrand>
          <Typography variant='body2' color='textPrimary'>
            {!isExtraSmallScreen && (
              <CardHiddenDigits>• • • • &nbsp;&nbsp;&nbsp;</CardHiddenDigits>
            )}
            {paymentMethod.card.last4}
          </Typography>
          {defaultPaymentMethodId === paymentMethod.id && (
            <DefaultCardTitle variant='body2' color='textPrimary'>
              Default card
            </DefaultCardTitle>
          )}
        </ListTitle>
        <ListItemSecondaryAction>
          <IconButton
            edge='end'
            size='small'
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={(event: MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <CardMenuIcon icon={faEllipsisV} color='inherit' />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={menuOpened}
            onClose={() => setAnchorEl(null)}
            slotProps={{
              paper: { style: { maxHeight: 48 * 4.5 } },
            }}
          >
            <MenuItem
              onClick={async () => {
                setSelectedPaymentMethod(paymentMethod.id);
                setAnchorEl(null);
              }}
            >
              Remove Card
            </MenuItem>
            <MenuItem
              onClick={() => {
                onSetDefaultPaymentMethod(paymentMethod.id);
                setAnchorEl(null);
              }}
            >
              Make Card Default
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {selectedPaymentMethod && (
        <AffectedAuctionsDialog
          paymentMethod={selectedPaymentMethod}
          onSubmit={() => {
            setDeleteMethodDialog(paymentMethod.id);
          }}
          onClose={() => {
            setSelectedPaymentMethod('');
            setDeleteMethodDialog('');
          }}
        />
      )}
      <Dialog
        open={Boolean(deleteMethodDialog)}
        onClose={() => {
          setSelectedPaymentMethod('');
          setDeleteMethodDialog('');
        }}
        fullScreen={isExtraSmallScreen}
      >
        <DialogContent>
          <DialogTitle>
            Are you sure you want to delete a Payment Card?
          </DialogTitle>
          <DialogActions>
            <StyledButtonMui5
              onClick={() => setDeleteMethodDialog('')}
              variant='outlined'
            >
              Cancel
            </StyledButtonMui5>
            <StyledButtonMui5
              onClick={() => {
                setDeleteMethodDialog('');
                onDeletePaymentMethod(paymentMethod.id);
                setSelectedPaymentMethod('');
              }}
              color='primary'
              // #TODO: autoFocus crashes the page
              // autoFocus={true}
            >
              Confirm
            </StyledButtonMui5>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PaymentMethod;

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { VerificationButton } from '../../components/molecules/VerificationButton';
import { User } from 'shared/types/models';
import { FC, useEffect, useState } from 'react';
import { TaskGroup } from './TaskGroup';
import NextLinkMui5 from '../../components/atoms/NextLink';
import {
  ADD_COSIGNER_TASK_ID,
  ADD_CREDIT_CARD_TASK_ID,
  ADD_GUARANTEE_DEPOSIT_TASK_ID,
  ADD_VEHICLES_TASK_ID,
  CREDIT_SCORE_VALIDITY_TASK_ID,
  EXPLORE_INCENTIVES_TASK_ID,
  INCOME_CHECK_TASK_ID,
  SOFT_CREDIT_CHECK_TASK_ID,
  SOFT_CREDIT_CHECK_WIN_TASK_ID,
  TASKS_VERIFICATION_CALLBACK_PATH,
  VERIFICATION_TASK_ID,
} from '../../shared/utils/constants';
import { IncomeCheckDialog } from './IncomeCheckDialog';
import { useTasks } from 'services/user/hooks/useTasks';
import { isProfileCompleted } from 'shared/utils/tasks';
import { getQueryString } from '../../utils/functions';
import FormAlert from '../../components/atoms/FormAlert';
import { NextRouter, useRouter } from 'next/router';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { SoftCreditCheckDialog } from './SoftCreditCheckDialog';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  userPaymentCapturableSelector,
  userWinningBids,
} from 'services/user/recoil';
import { userAtom } from 'recoil/atoms';
import Snackbar from '@mui/material/Snackbar';
import { GuaranteeDepositDialog } from './GuaranteeDepositDialog';
import { styled, ThemeProvider } from '@mui/material/styles';
import { greyButtonLightTheme } from '../../theme';

const Container = styled(Paper)(
  ({ theme }) => `
  &.MuiPaper-root {
    max-width: 650px;
    ${theme.breakpoints.down('sm')} {
      padding-left: ${theme.spacing(2)};
    }
  }`,
);

type TasksActionsType = {
  taskId: number;
  action: JSX.Element;
};

const GUARANTEE_DEPOSIT_MESSAGE_SUCCESS = 'Deposit was added successfully';

type AddGuaranteeDepositButtonProps = {
  onClick: () => void;
};

const AddGuaranteeDepositButton: FC<AddGuaranteeDepositButtonProps> = ({
  onClick,
}) => {
  const userPaymentCapturable = useRecoilValueLoadable(
    userPaymentCapturableSelector,
  );
  const winningBids = useRecoilValueLoadable(userWinningBids);
  const isDepositRequired = winningBids.valueMaybe()?.length > 0;
  const isPaymentCapturable = userPaymentCapturable.valueMaybe() ?? true;
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
  });
  return (
    <>
      <ThemeProvider theme={greyButtonLightTheme}>
        <StyledButtonMui5
          fullWidth
          variant='contained'
          color='grey'
          onClick={onClick}
          disabled={Boolean(isPaymentCapturable || !isDepositRequired)}
          className='js-add-guarantee-deposit-button'
        >
          Add guarantee deposit
        </StyledButtonMui5>
      </ThemeProvider>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, isOpen: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <FormAlert
          onClose={() => setSnackbar((prev) => ({ ...prev, isOpen: false }))}
          severity={
            snackbar.message === GUARANTEE_DEPOSIT_MESSAGE_SUCCESS
              ? 'success'
              : 'error'
          }
        >
          {snackbar.message}
        </FormAlert>
      </Snackbar>
    </>
  );
};

interface ActionHandlers {
  onSoftIncomeCheck(): void;

  onSoftCreditCheck(): void;

  onGuaranteeDeposit(): void;
}

export const getTaskIdActionMap = ({
  user,
  handlers,
  pathnameQueryString,
}: {
  user: User;
  handlers?: ActionHandlers;
  pathnameQueryString?: string;
}): Array<TasksActionsType> => {
  // noinspection UnnecessaryLocalVariableJS
  const tasksActions: Array<TasksActionsType> = [
    {
      taskId: SOFT_CREDIT_CHECK_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            component={NextLinkMui5}
            href={
              isProfileCompleted(user)
                ? '/profile/get-pre-qualified'
                : '/profile/personal-information'
            }
          >
            Get Pre-qualified
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: SOFT_CREDIT_CHECK_WIN_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            onClick={handlers?.onSoftCreditCheck}
          >
            Soft credit check
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: CREDIT_SCORE_VALIDITY_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            component={NextLinkMui5}
            href={
              isProfileCompleted(user)
                ? '/profile/get-pre-qualified'
                : '/profile/personal-information'
            }
          >
            Get Pre-qualified
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: ADD_CREDIT_CARD_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            component={NextLinkMui5}
            href={'/profile/payment-information'}
          >
            Add credit card
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: ADD_VEHICLES_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            component={NextLinkMui5}
            color='grey'
            href={'/profile/vehicle-information'}
          >
            Add vehicles
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: EXPLORE_INCENTIVES_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            component={NextLinkMui5}
            href={'/profile/incentives'}
          >
            Explore incentives
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: VERIFICATION_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <VerificationButton
            user={user}
            callbackPath={TASKS_VERIFICATION_CALLBACK_PATH}
            restProps={{
              buttonProps: {
                fullWidth: true,
                color: 'grey',
              },
              boxProps: { display: 'flex', flexGrow: 1 },
            }}
          />
        </ThemeProvider>
      ),
    },
    {
      taskId: ADD_COSIGNER_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            component={NextLinkMui5}
            href={`/profile/co-signer${pathnameQueryString}`}
          >
            Add co-signer
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: INCOME_CHECK_TASK_ID,
      action: (
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5
            fullWidth
            variant='contained'
            color='grey'
            onClick={handlers?.onSoftIncomeCheck}
          >
            Income check
          </StyledButtonMui5>
        </ThemeProvider>
      ),
    },
    {
      taskId: ADD_GUARANTEE_DEPOSIT_TASK_ID,
      action: (
        <AddGuaranteeDepositButton onClick={handlers?.onGuaranteeDeposit} />
      ),
    },
  ];
  return tasksActions;
};

const PROFILE_PATHNAME = '/profile/[[...tab]]';
const PROFILE_TAB = 'tasks';

const getProfilePathQuery = (router: NextRouter) => {
  if (
    router.pathname === PROFILE_PATHNAME &&
    router.query.tab?.includes(PROFILE_TAB)
  ) {
    return { ...router.query };
  }
};

export const TasksTab: FC = () => {
  const router = useRouter();

  const user = useRecoilValue(userAtom);
  const winningBids = useRecoilValueLoadable(userWinningBids);
  const hideSoftCreditCheck =
    !winningBids.valueMaybe()?.length || user.is_credit_tier_verified;

  const profileTabQuery = getProfilePathQuery(router);
  delete (profileTabQuery ?? {}).tab;
  const profileTabQueryString = getQueryString(profileTabQuery ?? {});

  const { taskGroups, requiredIncompletedTasksCount } = useTasks({
    pathnameQueryString: profileTabQueryString,
  });

  const returnTo = getProfilePathQuery(router)?.returnTo as string;
  useEffect(() => {
    if (
      returnTo &&
      taskGroups.length > 0 &&
      requiredIncompletedTasksCount === 0
    ) {
      router.push(returnTo);
    }
  }, [requiredIncompletedTasksCount, returnTo, router, taskGroups.length]);

  return (
    <Container variant='elevation' elevation={0}>
      <Box mb={2}>
        <Typography variant='h5'>Tasks</Typography>
      </Box>
      {returnTo && (
        <Box mb={2}>
          <FormAlert severity='info' variant='standard'>
            <NextLinkMui5 href={returnTo}>Back to the auction</NextLinkMui5>
          </FormAlert>
        </Box>
      )}
      {taskGroups.map((task, index) => {
        let tasks = task.tasks;

        if (hideSoftCreditCheck) {
          tasks = tasks.filter(
            (task) => task.id !== SOFT_CREDIT_CHECK_WIN_TASK_ID,
          );
        }

        tasks = tasks.filter(
          (task) => !(task.id === INCOME_CHECK_TASK_ID && !task.required),
        );

        return (
          <Box mt={2} key={index}>
            <TaskGroup title={task.title} tasks={tasks} />
          </Box>
        );
      })}
      <IncomeCheckDialog />
      <SoftCreditCheckDialog />
      <GuaranteeDepositDialog />
    </Container>
  );
};

import { FC, Suspense, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { rebatesDialogAuctionAtom } from '../OfferCard/recoil/atoms';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IncentivesData, {
  IIncentivesData,
  IncentivesDataLoadingPlaceholder,
} from '../../../containers/profile/IncentivesData';
import {
  auctionAtom,
  scanModeInputAtom,
} from '../../../containers/deal/recoil/atoms';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { hideDixaWidget, showDixaWidget } from 'utils/dixaWidgetApi';
import { hideCookiebotWidget, showCookiebotWidget } from 'utils/cookiebot';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MSAPIScanMode } from 'shared/types/marketScan';
import { greyButtonLightTheme } from '../../../theme';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '&& .MuiDialog-paper': {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'row-reverse',
  marginBottom: '-40px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '-60px',
  },
}));

export type SelectAuctionRebatesDialogProps = {
  zipcode?: string;
};

export const SelectAuctionRebatesDialog: FC<
  SelectAuctionRebatesDialogProps
> = ({ zipcode }) => {
  const router = useRouter();
  const incentivesDataRef = useRef<IIncentivesData>(null);
  const [rebatesDialogAuction, openRebateDialogForAuction] = useRecoilState(
    rebatesDialogAuctionAtom,
  );
  const scanMode = useRecoilValue(scanModeInputAtom);
  const isLease = scanMode === MSAPIScanMode.Lease;
  const setAuctionAtom = useSetRecoilState(auctionAtom);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeDialog = () => {
    openRebateDialogForAuction(null);
    showDixaWidget();
    showCookiebotWidget();
  };

  useEffect(() => {
    if (rebatesDialogAuction) {
      setAuctionAtom(rebatesDialogAuction);
      if (fullScreen) {
        hideDixaWidget();
      }
      hideCookiebotWidget();
    }
  }, [rebatesDialogAuction, setAuctionAtom, fullScreen]);

  if (!rebatesDialogAuction) {
    return null;
  }

  if (['/login', '/signup'].includes(router.pathname)) {
    closeDialog();
    return null;
  }

  return (
    <StyledDialog
      open={Boolean(rebatesDialogAuction)}
      onClose={closeDialog}
      keepMounted={false}
      maxWidth={'lg'}
      fullScreen={fullScreen}
    >
      <>
        <StyledToolbar>
          <IconButton color='inherit' onClick={closeDialog} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </StyledToolbar>
        {fullScreen && (
          <DialogTitle>
            {isLease ? 'Lease ' : 'Finance '}Incentives and Discounts
          </DialogTitle>
        )}
      </>
      <DialogContent>
        <Suspense fallback={<IncentivesDataLoadingPlaceholder />}>
          <IncentivesData
            zipcode={zipcode}
            ref={incentivesDataRef}
            divider
            onCancel={closeDialog}
            onSave={closeDialog}
            hideButtons
          />
        </Suspense>
      </DialogContent>
      <Divider />
      <DialogActions>
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5 color='grey' onClick={closeDialog}>
            Close
          </StyledButtonMui5>
        </ThemeProvider>
        <StyledButtonMui5
          color='primary'
          onClick={() => {
            incentivesDataRef.current.save();
            closeDialog();
          }}
        >
          Save
        </StyledButtonMui5>
      </DialogActions>
    </StyledDialog>
  );
};

SelectAuctionRebatesDialog.displayName = 'SelectOfferCardRebatesDialog';

import LinkMui5 from '@mui/material/Link';
import NextLinkMui5 from 'components/atoms/NextLink';
import FieldCheckbox from 'containers/profile/FieldCheckBox';
import { FC } from 'react';
import { BRAND_NAME } from 'shared/utils/constants';

type CreditCheckLegalTextProps = {
  buttonText: string;
};

export const CreditCheckLegalText: FC<CreditCheckLegalTextProps> = ({
  buttonText,
}) => {
  return (
    <span>
      By clicking <b>&apos;{buttonText}&apos;</b> you are providing written
      instructions to {BRAND_NAME} under the <b> Fair Credit Reporting Act</b>{' '}
      authorizing {BRAND_NAME} to obtain information from your personal credit
      profile or other information from Experian and/or TransUnion and/or
      Equifax. You authorize {BRAND_NAME} to obtain such information solely to
      conduct a pre-qualification for credit. You agree to the{' '}
      <LinkMui5 href='/privacy-policy' component={NextLinkMui5}>
        Privacy Policy
      </LinkMui5>{' '}
      and{' '}
      <LinkMui5 href='/terms' component={NextLinkMui5}>
        Terms and Conditions
      </LinkMui5>
      .
      <FieldCheckbox name='creditCheckAgreement' label='I Agree' />
    </span>
  );
};

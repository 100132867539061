import { PaymentIntent } from '@stripe/stripe-js';
import payment from 'services/payment';
import Rest from 'services/rest';
import { PaymentStatus } from 'shared/types/models';

const rest = new Rest();

const createPaymentIntent = async (
  auctionId: number,
  paymentMethod: string,
  captureMethod: 'automatic' | 'manual' = 'manual',
): Promise<PaymentIntent> => {
  const paymentIntent = await rest.createPaymentIntent(
    auctionId,
    captureMethod,
  );

  let errorText = '';

  if (paymentIntent.status === PaymentStatus.RequiresPaymentMethod) {
    const { error, paymentIntent: updatedPaymentIntent } =
      await payment.confirmCardPayment({
        intentClientSecret: paymentIntent.client_secret,
        paymentMethod: paymentMethod,
      });

    const status = error
      ? error.payment_intent.status
      : updatedPaymentIntent.status;

    // try to update payment on backend manually (in case of failure it will be updated anyway by webhook on demo and prod)
    await rest.updatePayment(paymentIntent.id, { status });

    if (error) {
      errorText = error.message;
    }
  }

  if (isHandledNextAction(paymentIntent)) {
    const { error, paymentIntent: updatedPaymentIntent } =
      await payment.confirmCardPayment({
        intentClientSecret: paymentIntent.client_secret,
        paymentMethod: String(paymentIntent.payment_method),
      });

    const status = error
      ? error.payment_intent.status
      : updatedPaymentIntent.status;

    // try to update payment on backend manually (in case of failure it will be updated anyway by webhook on demo and prod)
    await rest.updatePayment(paymentIntent.id, { status });

    if (error) {
      errorText = error.message;
    }
  }

  if (errorText) {
    throw new Error(errorText);
  }

  return paymentIntent;
};

function isHandledNextAction(paymentIntent: any): boolean {
  if (paymentIntent?.next_action?.type === 'use_stripe_sdk') {
    return true;
  }

  return false;
}

export default createPaymentIntent;

import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { User } from 'shared/types/models';

const postAuthTriggers = [
  (user: User) => {
    if (process.env.IS_LOGROCKET_ENABLED) {
      LogRocket.identify(String(user.id), {
        email: user.email,
        role: user.permissions.map(({ name }) => name).join(', '),
        name: user.company_name || [user.first_name, user.last_name].join(' '),
        zipcode: user.zipcode,
      });
    }
  },
  (user: User) => {
    if (typeof window?.hj !== 'function') {
      return;
    }

    window.hj('identify', user.id, {
      email: user.email,
      role: user.permissions.map(({ name }) => name).join(', '),
      name: user.company_name || [user.first_name, user.last_name].join(' '),
      zipcode: user.zipcode,
    });
  },
];

export function useInitUser(user: User): void {
  useEffect(() => {
    if (user) {
      postAuthTriggers.forEach((trigger) => trigger(user));
    }
  }, [user]);
}

import { AuctionStatus, Bid, BidStatus } from '../types/models';
import { getActiveBids } from './getActiveBids';

export const getWinningBid = (bids: Bid[]): Bid => {
  return bids ? getActiveBids(bids)[0] : null;
};

export const getSecondWinningBid = (bids: Bid[]): Bid => {
  return getActiveBids(bids)[1];
};

const FINAL_WINNER_BID_STATUSES = Object.freeze([
  BidStatus.DealerApproved,
  BidStatus.UserReceivedVehicle,
  BidStatus.UserNotReceivedVehicle,
]);

export const getFinalWinnerBid = (bids: Bid[]): Bid => {
  const validFinalWinnerBids = bids.filter((bid) =>
    FINAL_WINNER_BID_STATUSES.includes(bid.status),
  );

  if (
    validFinalWinnerBids.length > 0 &&
    validFinalWinnerBids[0]?.min_program?.Price
  ) {
    validFinalWinnerBids.sort((bidA, bidB) => {
      return bidB.min_program.Price - bidA.min_program.Price;
    });
  }
  return validFinalWinnerBids[0];
};

export const checkWinningBidStatusPredicate = (status: BidStatus): boolean => {
  return ![
    BidStatus.DealerRejected,
    BidStatus.UserDeclined,
    // following statuses indicate that the user has
    // already won and this bid is no longer active
    BidStatus.DealerApproved,
    BidStatus.UserReceivedVehicle,
    BidStatus.UserNotReceivedVehicle,
  ].includes(status);
};

export const WINNING_BID_AUCTION_STATUSES = [
  AuctionStatus.Active,
  AuctionStatus.Completed,
];

import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { PoweredByStripeBadge } from 'components/atoms/PoweredByStripeBadge';
// import { CompactTrustPilotBox } from 'components/atoms/TrustPilotBox';
// import { BBBAccreditationBadge } from '../atoms/BBBAccreditationBadge';

type TrustBadgesProps = {
  theme?: 'light' | 'dark';
};

const BadgesContainer = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flexDirection: 'row',
    gap: spacing(3),
    paddingRight: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: spacing(2, 3),
      justifyContent: 'center',
    },
    [breakpoints.down('xs')]: {
      justifyContent: 'start',
      flexDirection: 'column',
    },
  }),
);

export const TrustBadges: FC<TrustBadgesProps> = ({ theme = 'dark' }) => {
  return (
    <BadgesContainer>
      {/* <CompactTrustPilotBox theme={theme} />
      <BBBAccreditationBadge theme={theme} /> */}
      <PoweredByStripeBadge theme={theme} />
    </BadgesContainer>
  );
};

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled as styledMui5 } from '@mui/material/styles';

const Wrapper = styledMui5('div')`
  display: flex;
  position: relative;`;

const Root = styledMui5('div')`
  width: 1em;
  height: 1em;
  position: relative;
  overflow: hidden;
  border-radius: 100%;`;

const Avatar = styledMui5('div')<{ src: string }>(
  ({ src }) => `
  background-image: url("${src}");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;`,
);

type Props = {
  img: string;
};

const UserIcon = ({ img }: Props): JSX.Element => (
  <Wrapper>
    {img ? (
      <Root>
        <Avatar src={img}></Avatar>
      </Root>
    ) : (
      <AccountCircleIcon />
    )}
  </Wrapper>
);

export default UserIcon;

import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StaticPage from 'components/organisms/StaticPage';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';

type Error = {
  name: string;
  massage: string;
};

type Props = {
  error: Error;
  handleTryAgain: () => void;
};

export const AppErrorContainer: FC<Props> = ({ error, handleTryAgain }) => {
  const isSecurityError = error.name === 'SecurityError';
  const title = isSecurityError
    ? `Can't open the requested page!`
    : 'Oops, there is an error!';

  return (
    <StaticPage>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        minHeight={540}
      >
        <Typography variant='h4' mb={isSecurityError ? 0 : 2}>
          {title}
        </Typography>
        {isSecurityError && (
          <Typography color='secondary' variant='h6' mb={2}>
            Change your browser settings to allow sites to save data on your
            device or allow sites to use all cookies.
          </Typography>
        )}
        <StyledButtonMui5 onClick={handleTryAgain} color='primary'>
          Try again?
        </StyledButtonMui5>
      </Box>
    </StaticPage>
  );
};

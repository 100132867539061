import { useRecoilState } from 'recoil';
import { softCreditCheckDialogAtom } from './recoil/atoms';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FieldText from './FieldText';
import { Form } from 'react-final-form';
import { FC, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import ProgressButton from 'components/atoms/ProgressButton';
import InputAdornment from '@mui/material/InputAdornment';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { FORM_ERROR } from 'final-form';
import { GetCreditScoreQuery } from 'shared/types/api/getCreditScore';
import { User } from 'shared/types/models';
import Rest from 'services/rest';
import { UpdateUserProfileRequest } from 'shared/types/api/updateUserProfile';
import head from 'lodash/head';
import { userAtom } from 'recoil/atoms';
import { CreditCheckLegalText } from 'components/atoms/CreditCheckLegalText';
import { useScreenSize } from 'hooks/useScreenSize';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import NextLinkMui5 from 'components/atoms/NextLink';
import { NextRouter, useRouter } from 'next/router';
import LinkMui5 from '../../components/atoms/Link';
import { styled, ThemeProvider } from '@mui/material/styles';
import { greyButtonLightTheme } from '../../theme';

const rest = new Rest();

type Values = {
  ssn: string;
  creditCheckAgreement: boolean;
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
  &.MuiDialogContent-root {
    padding: ${theme.spacing(0, 3, 3)};
  }`,
);

const getProfileUrl = (router: NextRouter) => {
  const profileUrl = '/profile/personal-information';
  const { returnTo } = router.query;
  if (returnTo) {
    return `${profileUrl}?${new URLSearchParams({
      returnTo: returnTo as string,
    })}`;
  }
  return profileUrl;
};

export const SoftCreditCheckDialog: FC = () => {
  const [softCreditCheckDialog, openSoftCreditCheckDialog] = useRecoilState(
    softCreditCheckDialogAtom,
  );
  const [user, setUser] = useRecoilState(userAtom);
  const { isExtraSmallScreen } = useScreenSize();
  const buttonText = 'Soft credit check';
  const router = useRouter();

  const closeDialog = useCallback(
    () => openSoftCreditCheckDialog(false),
    [openSoftCreditCheckDialog],
  );

  const handleSubmit = useCallback(
    async (values: Values) => {
      const updatedProfile: UpdateUserProfileRequest = {
        is_credit_tier_verified: false,
        is_credit_score_updated: false,
      };

      const verifiedScore = await getCreditScore(values.ssn, user);
      if (!verifiedScore) {
        return {
          [FORM_ERROR]: (
            <>
              Unfortunately, we were unable to verify your credit score. Please
              ensure that you have entered your personal information correctly
              in your{' '}
              <LinkMui5 component={NextLinkMui5} href={getProfileUrl(router)}>
                profile
              </LinkMui5>{' '}
              and try running the soft credit check again.
            </>
          ),
        };
      }

      updatedProfile.credit_score = verifiedScore;
      updatedProfile.is_credit_tier_verified = true;
      updatedProfile.is_credit_score_updated = true;

      try {
        const updatedUser = await rest.updateUserProfile(updatedProfile);
        setUser((prevUser) => ({
          ...prevUser,
          ...updatedUser,
        }));
        closeDialog();
      } catch (e) {
        const error: { msg: string } = e.errors && head(e.errors);
        return { [FORM_ERROR]: error.msg };
      }
    },
    [user, closeDialog, setUser],
  );

  const getCreditScore = async (ssn: string, user: User) => {
    const query: GetCreditScoreQuery = {
      zip: user.zipcode,
      address: user.address,
      name: `${user.first_name} ${user.last_name}`,
      ssn,
    };
    let verifiedScore: number;

    try {
      const res = await rest.getCreditScore(query);
      verifiedScore = res.score;
    } catch (e) {
      console.error(e);
    }

    if (!verifiedScore) {
      return undefined;
    }

    return verifiedScore;
  };

  if (!softCreditCheckDialog) {
    return null;
  }

  return (
    <Dialog
      open={softCreditCheckDialog}
      onClose={closeDialog}
      keepMounted={false}
      fullScreen={isExtraSmallScreen}
    >
      <DialogTitle>Soft credit check</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, submitError, form }) => (
          <form autoComplete='off' onSubmit={handleSubmit} noValidate>
            <StyledDialogContent>
              {submitError && (
                <Alert severity='error' variant='standard'>
                  {submitError}
                </Alert>
              )}
              <Box mb={2}>
                <Typography variant='body2'>
                  A soft credit pull that won&apos;t impact your credit score
                  that will give you accurate pricing when you bid.
                </Typography>
              </Box>
              <FieldText
                inputProps={{
                  maxLength: 9,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <StyledFontAwesomeIconMui5
                        icon={faLock}
                        size='sm'
                        color='inherit'
                        sx={{ color: 'inherit' }}
                      />
                    </InputAdornment>
                  ),
                }}
                type='password'
                required
                helperText='Please enter your full Social Security Number'
                name='ssn'
                label='SSN'
              />
              <Typography variant='caption' gutterBottom>
                <CreditCheckLegalText buttonText={buttonText} />
              </Typography>
              <DialogActions>
                <ThemeProvider theme={greyButtonLightTheme}>
                  <StyledButtonMui5 color='grey' onClick={closeDialog}>
                    Close
                  </StyledButtonMui5>
                </ThemeProvider>
                <Tooltip
                  placement='top'
                  title={
                    form.getState().values.creditCheckAgreement
                      ? ''
                      : 'Please agree to the terms and conditions'
                  }
                >
                  <>
                    <ProgressButton
                      disabled={!form.getState().values.creditCheckAgreement}
                      color='primary'
                      type='submit'
                      onClick={handleSubmit}
                      loading={submitting}
                    >
                      {buttonText}
                    </ProgressButton>
                  </>
                </Tooltip>
              </DialogActions>
            </StyledDialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};

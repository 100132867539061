import {
  BRAND_NAME_ENUM,
  CREDIT_TIERS,
  DOMAIN_NAME_ENUM,
  ENVIRONMENT,
} from '../config';
import { Makes } from '../config/makes';
import { DynamicFilterCategory } from '../types/api/getAuctions';
import { TaskGroupType, UserTaskType } from '../types/misc';
import { AuctionStatus } from '../types/models';

export const BRAND_NAME: string =
  BRAND_NAME_ENUM[process.env.DOMAIN] || BRAND_NAME_ENUM.AUTOBANDIT;

export const DOMAIN_NAME: string =
  DOMAIN_NAME_ENUM[process.env.DOMAIN] || DOMAIN_NAME_ENUM.AUTOBANDIT;

export const LEGAL_NAME = 'Lease Bandit LLC Inc.';

export enum LogoType {
  Christmas,
  CyberMonday,
  Regular,
}

// export const SITE_LOGO = '/img/autobandit_logo.svg';
export const SITE_LOGO = '/img/autobandit_christmas_logo.svg';
export const LOGO_TYPE = LogoType.Christmas;

export const MAX_AUCTIONS_PER_PAGE = 100;
export const AUCTIONS_PER_PAGE = 15;
export const BEST_TERM_LENGTH = 'Best (lowest payment)';
export const DEFAULT_TERM_LENGTH = BEST_TERM_LENGTH;
export const DEFAULT_MILES_PER_YEAR = 10000;
export const DEFAULT_CREDIT_SCORE = CREDIT_TIERS[0].score.max;
export const MIN_BID_STEP = 5;
export const MIN_ONE_PAY_BID_STEP = 100;
export const DUTCH_COUNTDOWN_SECONDS: 10 | 20 | 30 | 60 = 10;
export const SORT_BY_NOT_APPROVED = 'not_approved_first';
export const TIME_TO_ACCEPT_DEAL_IN_DAYS = 2;
export const TIME_TO_VEHICLE_DELIVERY_IN_DAYS = 7;
export const TIME_TO_EXPIRE_VEHICLE_DELIVERY_IN_DAYS = 2;
export const TIME_TO_CONFIRM_DEAL_IN_DAYS = 3;
export const LAST_DAY_TO_MAKE_DECISION = 2;
export const CREDIT_CHECK_VALIDITY_IN_DAYS = 90;
export const TIME_TO_PREPARE_LEASE_DOCS_IN_BUSINESS_DAYS = 2;
export const MINIMAL_USER_AGE_IN_YEARS = 18;
export const DAYS_FOR_AUCTION_FINALIZATION = 2;
export const DAYS_FOR_AUCTION_START = 1;
export const EXCLUDED_OPTION_CATEGORIES = [DynamicFilterCategory.BodyStyle];
export const ZIP_CODE_MAX_LENGTH = 5;

export const E2E_CONSUMER_EMAIL_REGEX = /^e2e\+\d+@leasebandit\.com$/;
export const QA_CONSUMER_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))\+QA_(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MAKES_ARRAY = Object.values(Makes);

export const PHONE_MAPPINGS = {
  '+13333333388': '+380666674477',
  '+13333333389': '+380666674477',
  '+13333333390': '+380666674477',
};
export const CONSUMER_PAYMENT_AMOUNT_CENTS = 29900;
export const STRIPE_3DS_REQUIRED_CODE = '3ds authorization required';
export const PRODUCTION_SUPPORT_EMAIL = `support@autobandit.com`;
export const SANDBOX_SUPPORT_EMAIL = `helpdesk@autobandit.com`;
export const SUPPORT_EMAIL =
  ENVIRONMENT.PRODUCTION === process.env.NODE_ENV
    ? PRODUCTION_SUPPORT_EMAIL
    : SANDBOX_SUPPORT_EMAIL;

export const D_THORNICROFT_EMAIL = `dthornicroft@leasebandit.com`;
export const R_CHAKU_EMAIL = `rchaku@leasebandit.com`;
export const P_ROSARIO_EMAIL = `pedro.rosario@leasebandit.com`;
export const H_RODRIGUEZ_EMAIL = `huascar.rodriguez@leasebandit.com`;
export const R_JOUDEH_EMAIL = `rjoudeh@leasebandit.com`;
export const L_SAADEH_EMAIL = `lsaadeh@leasebandit.com`;

// All time constants is in milliseconds [1 second === 1000 milliseconds]
export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60000;
export const ONE_HOUR = 3600000;
export const ONE_DAY = 86400000;

export type SearchTerm =
  | typeof ALL_SELECTABLE_NUMERIC_TERMS[number]
  | typeof BEST_TERM_LENGTH
  | 'Best';

const ALL_NUMERIC_LEASE_TERMS = [
  12, 15, 18, 21, 24, 25, 26, 27, 28, 30, 31, 32, 33, 34, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 48, 49, 50, 51, 52, 54, 57, 60, 66, 72,
];
const ALL_NUMERIC_FINANCE_TERMS = [
  12, 18, 24, 30, 36, 42, 48, 54, 60, 63, 66, 72, 75, 78, 84, 88, 90, 96,
];
export const MAX_48_NUMERIC_LEASE_TERMS = ALL_NUMERIC_LEASE_TERMS.filter(
  (term) => term <= 48,
);
export const MAX_72_NUMERIC_FINANCE_TERMS = ALL_NUMERIC_FINANCE_TERMS.filter(
  (term) => term <= 72,
);
export const SELECTABLE_LEASE_NUMERIC_TERMS = [18, 24, 30, 36, 39, 42, 48];
export const SELECTABLE_FINANCE_NUMERIC_TERMS = [24, 36, 48, 60, 72];
export const ALL_SELECTABLE_NUMERIC_TERMS = [
  ...new Set([
    ...SELECTABLE_LEASE_NUMERIC_TERMS,
    ...SELECTABLE_FINANCE_NUMERIC_TERMS,
  ]),
];
export const SELECTABLE_TERMS = [
  BEST_TERM_LENGTH,
  ...ALL_SELECTABLE_NUMERIC_TERMS,
];
export const SELECTABLE_LEASE_TERMS = [
  BEST_TERM_LENGTH,
  ...SELECTABLE_LEASE_NUMERIC_TERMS,
];
export const SELECTABLE_FINANCE_TERMS = [
  BEST_TERM_LENGTH,
  ...SELECTABLE_FINANCE_NUMERIC_TERMS,
];

export const AVAILABLE_MILEAGES = [7500, 10000, 12000, 15000];

export const AVAILABLE_MONTHLY_PAYMENTS = [
  { max: 300, label: 'Under $300' },
  { min: 301, max: 500, label: '$301 - $500' },
  { min: 501, max: 750, label: '$501 - $750' },
  { min: 751, max: 1000, label: '$751 - $1,000' },
  { min: 1000, label: 'Above $1,000' },
];

export const MIN_CREDIT_SCORE = 0;
export const MAX_CREDIT_SCORE = 1000;

// User Tasks
export const SOFT_CREDIT_CHECK_TASK_ID = 1;
export const ADD_CREDIT_CARD_TASK_ID = 2;
export const EXPLORE_INCENTIVES_TASK_ID = 3;
export const ADD_VEHICLES_TASK_ID = 4;
export const VERIFICATION_TASK_ID = 5;
export const ADD_COSIGNER_TASK_ID = 6;
export const CREDIT_SCORE_VALIDITY_TASK_ID = 7;
export const INCOME_CHECK_TASK_ID = 8;
export const SOFT_CREDIT_CHECK_WIN_TASK_ID = 9;
export const ADD_GUARANTEE_DEPOSIT_TASK_ID = 10;

export const TASKS_VERIFICATION_CALLBACK_PATH = '/profile';

const IS_E2E_FOR_BACKEND = process.env.NODE_ENV === ('e2e' as any);
const IS_E2E_FOR_CI_FRONTEND =
  process.env.ENVIRONMENT === ENVIRONMENT.SANDBOX ||
  process.env.ENVIRONMENT === ENVIRONMENT.E2E;
export const IS_E2E = IS_E2E_FOR_BACKEND || IS_E2E_FOR_CI_FRONTEND;

export const IS_NOT_PRODUCTION =
  process.env.NODE_ENV !== 'production' &&
  process.env.ENVIRONMENT !== ENVIRONMENT.PRODUCTION;

export const COMPLETED_AUCTION_STATUSES = [
  AuctionStatus.Completed,
  AuctionStatus.Expired,
  AuctionStatus.Canceled,
];

export const getConsumerPaymentString = () => {
  return `$${(CONSUMER_PAYMENT_AMOUNT_CENTS / 100).toFixed(0)}`;
};

export const DISPLAY_CONSUMER_INCENTIVES_TAB = false;

export const USER_TASKS_GROUPS_MAP: Array<TaskGroupType> = [
  {
    id: 1,
    name: 'Before you can bid',
    tasksIds: [SOFT_CREDIT_CHECK_TASK_ID, CREDIT_SCORE_VALIDITY_TASK_ID],
  },
  {
    id: 2,
    name: 'Guarantee your deal',
    tasksIds: [ADD_GUARANTEE_DEPOSIT_TASK_ID],
  },
  {
    id: 3,
    name: 'Save money on your next lease',
    tasksIds: [
      DISPLAY_CONSUMER_INCENTIVES_TAB && EXPLORE_INCENTIVES_TASK_ID,
    ].filter(Boolean) as unknown as number[],
  },
  {
    id: 4,
    name: 'Save time and get verified',
    tasksIds: [
      SOFT_CREDIT_CHECK_WIN_TASK_ID,
      VERIFICATION_TASK_ID,
      INCOME_CHECK_TASK_ID,
    ],
  },
  {
    id: 5,
    name: 'Challenged credit or income?',
    tasksIds: [ADD_COSIGNER_TASK_ID],
  },
];

export const SOFT_CREDIT_CHECK_TASK: UserTaskType = {
  id: SOFT_CREDIT_CHECK_TASK_ID,
  title: 'Get Pre-qualified',
  content:
    "A soft credit pull won't impact your credit score and will give you accurate pricing when you bid.",
};

export const ADD_CREDIT_CARD_TASK: UserTaskType = {
  id: ADD_CREDIT_CARD_TASK_ID,
  title: 'Add credit card',
  content:
    `We require a ${getConsumerPaymentString()} hold to place a bid. ` +
    'Your card will only be charged if you win the auction.',
};

export const ADD_GUARANTEE_DEPOSIT_TASK: UserTaskType = {
  id: ADD_GUARANTEE_DEPOSIT_TASK_ID,
  title: 'Add guarantee deposit',
  content: `We will authorize a non-refundable ${getConsumerPaymentString()} deposit on your card to secure the reservation for the car. If the deal does not proceed, we will refund the full deposit amount.`,
};

export const EXPLORE_INCENTIVES_TASK: UserTaskType = {
  id: EXPLORE_INCENTIVES_TASK_ID,
  title: 'Check your rebate and incentive eligibility',
  content:
    'Apply rebates and incentives for additional discounts to your lease - documentation of eligibility required.',
};

export const ADD_VEHICLES_TASK: UserTaskType = {
  id: ADD_VEHICLES_TASK_ID,
  title: 'Add current vehicle information',
  content: 'Tell us about the current vehicles in your household.',
};

export const VERIFICATION_TASK: UserTaskType = {
  id: VERIFICATION_TASK_ID,
  title: 'Verify your drivers license',
  content:
    'We need to verify your ID once you win an auction before we can schedule vehicle delivery.',
};
export const ADD_A_COSIGNER_TASK: UserTaskType = {
  id: ADD_COSIGNER_TASK_ID,
  title: 'Add a co-signer',
  content:
    'Adding a co-signer could strengthen your financial profile and result in a lower lease payment.',
};
export const CREDIT_SCORE_VALIDITY_TASK: UserTaskType = {
  id: CREDIT_SCORE_VALIDITY_TASK_ID,
  title: `Refresh your credit score (Don't worry, this won't hurt your credit score)`,
  content:
    'It`s been a while since your last credit check. We need to run a soft credit check before you can bid again.',
};

export const INCOME_CHECK_TASK: UserTaskType = {
  id: INCOME_CHECK_TASK_ID,
  title: `Income check`,
  content: "An income pull won't impact your credit score.",
};

export const SOFT_CREDIT_CHECK_WIN_TASK: UserTaskType = {
  id: SOFT_CREDIT_CHECK_WIN_TASK_ID,
  title: 'Soft credit check',
  content:
    "A soft credit pull won't impact your credit score and will give you accurate pricing when you bid.",
};

export const INVALID_ZIP_CODE_ERROR_MSG = 'Invalid ZIP code';

export const PAYMENT_BY_MSRP_LEASE_BEST_RATE = 0.01;
export const PAYMENT_BY_MSRP_FINANCE_BEST_RATE = 0.0125;

export const BRIDGETON_MS_ACCOUNT_NUMBERS = ['310004', '310008', '310009'];
export const BRIDGETON_REGISTRATION_FEE = 450;

export const CRESTMONT_TOYOTA_MS_ACCOUNT_NUMBERS = ['310005', '310006'];
export const CRESTMONT_TOYOTA_REGISTRATION_FEE = 325;

export const MS_REBATE_CATEGORY_ID_EMPLOYEE_PROGRAM = 12;

export enum FEATURE_FLAG {
  SkipCreditCard = 'skip-credit-card',
  SkipPhoneConfirmation = 'skip-phone-confirmation',
  SignUpPopup = 'sign-up-popup',
  AISearch = 'ai-search',
}

export const SKIP_EDGE_DATES_VALIDATION = true;

export const MAX_FEATURED_AUCTIONS = 10;

export const BODY_STYLES = ['SUV', 'Truck', 'Sedan', 'Coupe', 'Minivan'];

export const MAX_DUPLICATE_TRIM_PER_YEAR = 1;

export const MUNICH_PUBLIC_BUCKET = 'https://static.autobandit.com';

export const EMAIL_LOGO_URL = `${MUNICH_PUBLIC_BUCKET}/email/${DOMAIN_NAME.replace(
  '.com',
  '',
)}_logo.png`;

export const LEASEBANDIT_IMAGE_BUCKET =
  'https://leasebandit-image.s3.us-west-2.amazonaws.com';

export const LEASEBANDIT_DATA_BUCKET =
  'https://leasebandit-data.s3.us-west-2.amazonaws.com';

// Social
export const trustPilotLink =
  'https://www.trustpilot.com/review/leasebandit.com';
export const facebookUrl = 'https://www.facebook.com/LeaseBandits';
export const instagramUrl = 'https://www.instagram.com/autobandit_official';
export const twitterUrl = 'https://twitter.com/LeaseBandit';
export const linkedinUrl = 'https://www.linkedin.com/in/leasebandit/';
export const googleUrl =
  'https://www.google.com/search?q=lease+bandit+&sca_esv=06eaa6fe2699486c&sxsrf=ACQVn0_36WpGFSlPI8sdidLTlUzEaHOwGw%3A1707393445146&ei=pcHEZfmeCJaX9u8P0-yB0AQ&ved=0ahUKEwi5haie2JuEAxWWi_0HHVN2AEoQ4dUDCBA&uact=5&oq=lease+bandit+&gs_lp=Egxnd3Mtd2l6LXNlcnAiDWxlYXNlIGJhbmRpdCAyBBAjGCcyBhAAGBYYHjIGEAAYFhgeMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgNItQtQlwZYlwZwAXgAkAEAmAGEAaABhAGqAQMwLjG4AQPIAQD4AQHiAwQYASBBiAYB&sclient=gws-wiz-serp';
export const tiktokUrl = 'https://www.tiktok.com/@autobandit_official';

export const CALENDLY_WIDGET_DATA = {
  url: 'https://calendly.com/lsaadeh-leasebandit/30min?hide_gdpr_banner=1',
  text: 'Schedule time with me',
  color: '#0069ff',
  textColor: '#ffffff',
  branding: undefined,
};

export const POPULAR_MAKES = [
  {
    name: 'Toyota',
    src: '/img/brands/card/Toyota.png',
    seo: {
      keyAttributes: 'innovation, reliability, and performance',
      model1: 'Camry',
      model2: 'RAV4',
      perfectFor:
        'those seeking a fuel-efficient and smooth ride in a midsize sedan',
      offering: 'versatility and adventure-ready capabilities as a compact SUV',
      knownFor: 'fuel efficiency, reliability, and advanced safety features',
    },
  },
  {
    name: 'Honda',
    src: '/img/brands/card/Honda.png',
    seo: {
      keyAttributes: 'reliability, efficiency, and innovative technology',
      model1: 'Accord',
      model2: 'CR-V',
      perfectFor:
        'those seeking a blend of fuel efficiency and premium comfort in a midsize sedan',
      offering:
        'practicality and cutting-edge safety features as a compact SUV',
      knownFor:
        'fuel efficiency, advanced safety features, and lasting reliability',
    },
  },
  {
    name: 'Hyundai',
    src: '/img/brands/card/Hyundai.png',
    seo: {
      keyAttributes: 'innovation, reliability, and cutting-edge technology',
      model1: 'Sonata',
      model2: 'Tucson',
      perfectFor: 'those seeking a stylish and fuel-efficient midsize sedan',
      offering: 'versatility and advanced safety features as a compact SUV',
      knownFor: 'fuel efficiency, advanced technology, and stylish designs',
    },
  },
  {
    name: 'Ford',
    src: '/img/brands/card/Ford.png',
    seo: {
      keyAttributes: 'innovation, performance, and dependability',
      model1: 'F-150',
      model2: 'Explorer',
      perfectFor:
        'those seeking unmatched towing capacity and rugged off-road performance in a full-size pickup truck',
      offering:
        'spaciousness and advanced technology as a versatile midsize SUV',
      knownFor: 'durability, performance, and advanced safety features',
    },
  },
  {
    name: 'Kia',
    src: '/img/brands/card/KIA.png',
    seo: {
      keyAttributes: 'innovation, reliability, and affordability',
      model1: 'Sorento',
      model2: 'Forte',
      perfectFor:
        'those seeking a spacious and family-friendly midsize SUV with modern features',
      offering: 'fuel efficiency and stylish design in a compact sedan',
      knownFor:
        'advanced technology, impressive fuel efficiency, and stylish designs',
    },
  },
  {
    name: 'Jeep',
    src: '/img/brands/card/Jeep.png',
    seo: {
      keyAttributes:
        'rugged performance, off-road capability, and iconic design',
      model1: 'Wrangler',
      model2: 'Grand Cherokee',
      perfectFor:
        'those seeking unmatched off-road prowess and adventure-ready feature',
      offering:
        'a blend of luxury, space, and advanced technology in a versatile SUV',
      knownFor:
        'legendary off-road capabilities, powerful performance, and advanced safety features',
    },
  },
  {
    name: 'Acura',
    src: '/img/brands/card/Acura.png',
    seo: {
      keyAttributes: 'luxury, performance, and innovative technology',
      model1: 'RDX',
      model2: 'TLX',
      perfectFor:
        'those seeking a stylish and versatile compact SUV with advanced safety features and a spacious interior',
      offering:
        'a thrilling driving experience with its powerful engine and precise handling',
      knownFor:
        'luxurious features, exceptional reliability, and cutting-edge technology',
    },
  },
  {
    name: 'Audi',
    src: '/img/brands/card/Audi.png',
    seo: {
      keyAttributes: 'innovation, luxury, and high-performance engineering',
      model1: 'A4',
      model2: 'Q5',
      perfectFor:
        'those seeking a premium sedan with advanced technology and agile handling',
      offering:
        'a blend of sophisticated style and versatile performance for families or outdoor enthusiasts',
      knownFor:
        'cutting-edge technology, exceptional craftsmanship, and unmatched driving experience',
    },
  },
  {
    name: 'Buick',
    src: '/img/brands/card/Buick.png',
    seo: {
      keyAttributes: '',
      model1: 'Enclave',
      model2: 'Encore',
      perfectFor: 'a perfect blend of luxury, comfort, and reliability',
      offering:
        'a compact size with impressive fuel efficiency and modern technology for urban drivers',
      knownFor: 'elegant design, premium materials, and smooth ride quality',
    },
  },
  {
    name: 'Cadillac',
    src: '/img/brands/card/Cadillac.png',
    seo: {
      keyAttributes: 'luxury, performance, and cutting-edge technology',
      model1: 'Escalade',
      model2: 'CT5',
      perfectFor:
        'families and individuals seeking spaciousness and premium features with its commanding presence and advanced safety systems',
      offering:
        'a blend of dynamic performance and sophisticated design for those who appreciate a thrilling driving experience',
      knownFor:
        'exceptional comfort, powerful engines, and luxurious interiors',
    },
  },
  {
    name: 'Chevrolet',
    src: '/img/brands/card/Chevrolet.png',
    seo: {
      keyAttributes:
        'a perfect blend of innovation, reliability, and performance',
      model1: 'Silverado',
      model2: 'Equinox',
      perfectFor:
        'those seeking a rugged and capable pickup truck that excels in towing and hauling',
      offering:
        'a spacious interior and excellent fuel efficiency for families and commuters alike',
      knownFor:
        'outstanding safety ratings, advanced technology features, and impressive performance',
    },
  },
  {
    name: 'Chrysler',
    src: '/img/brands/card/Chrysler.png',
    seo: {
      keyAttributes:
        'a perfect blend of luxury, comfort, and innovative technology',
      model1: '300',
      model2: 'Pacifica',
      perfectFor:
        'those who desire a spacious sedan with a powerful engine and a refined interior',
      offering:
        'versatility and family-friendly features such as ample cargo space and advanced safety technologies',
      knownFor: 'stylish designs, comfort, and high-quality materials',
    },
  },
  {
    name: 'Dodge',
    src: '/img/brands/card/Dodge.png',
    seo: {
      keyAttributes:
        'powerful performance, bold design, and innovative technology',
      model1: 'Charger',
      model2: 'Durango',
      perfectFor:
        'those seeking a thrilling driving experience with its muscle car heritage and impressive horsepower',
      offering:
        'spacious seating and towing capabilities for families and adventure seekers alike',
      knownFor:
        'performance, distinctive styling, and advanced safety features',
    },
  },
  {
    name: 'GMC',
    src: '/img/brands/card/GMC.png',
    seo: {
      keyAttributes:
        'rugged capability, reliability, and innovative technology',
      model1: 'Sierra 1500',
      model2: 'Terrain',
      perfectFor:
        'those seeking a powerful truck with excellent towing capacity and advanced safety features',
      offering:
        'a spacious and comfortable interior ideal for families and everyday commuting',
      knownFor: 'durability, advanced technology, and versatile performance',
    },
  },
  {
    name: 'Lincoln',
    src: '/img/brands/card/Lincoln.png',
    seo: {
      keyAttributes: 'luxury, comfort, and advanced technology',
      model1: 'Navigator',
      model2: 'Aviator',
      perfectFor:
        'those who seek a spacious and opulent SUV with powerful performance and advanced safety features',
      offering: 'a blend of dynamic performance and high-end interior design',
      knownFor:
        'elegant design, innovative technology, and exceptional comfort',
    },
  },
  {
    name: 'Mazda',
    src: '/img/brands/card/Mazda.png',
    seo: {
      keyAttributes: 'innovation, reliability, and exceptional performance',
      model1: 'CX-5',
      model2: '3',
      perfectFor:
        'those seeking a versatile SUV with a refined interior and superior driving dynamics',
      offering: 'sporty handling, sleek design, and impressive fuel efficiency',
      knownFor:
        'engaging driving experience, modern technology, and sleek design',
    },
  },
  {
    name: 'Mercedes-Benz',
    src: '/img/brands/card/Mercedes.png',
    seo: {
      keyAttributes: 'luxury, innovation, and world-class performance',
      model1: 'C-Class',
      model2: 'GLE',
      perfectFor:
        'those seeking a refined driving experience with cutting-edge technology and elegant design',
      offering:
        'versatile performance and spacious comfort for families or adventure seekers',
      knownFor:
        'superior craftsmanship, advanced safety features, and remarkable engineering',
    },
  },
  {
    name: 'Mitsubishi',
    src: '/img/brands/card/Mitsubishi.png',
    seo: {
      keyAttributes: 'reliability, innovation, and versatility',
      model1: 'Outlander',
      model2: 'Eclipse Cross',
      perfectFor: 'families seeking spaciousness and advanced safety features',
      offering:
        'a stylish design and impressive fuel efficiency for urban adventurers',
      knownFor: 'durability, advanced technology, and great value',
    },
  },
  {
    name: 'Nissan',
    src: '/img/brands/card/Nissan.png',
    seo: {
      keyAttributes: 'innovation, reliability, and fuel efficiency',
      model1: 'Altima',
      model2: 'Rogue',
      perfectFor:
        'those seeking a comfortable midsize sedan with advanced technology and impressive fuel economy',
      offering:
        'spacious interiors, modern safety features, and all-weather capability',
      knownFor:
        'fuel efficiency, advanced safety features, and cutting-edge technology',
    },
  },
  {
    name: 'Ram',
    src: '/img/brands/card/RAM.png',
    seo: {
      keyAttributes:
        'outstanding durability, powerful performance, and innovative technology',
      model1: '1500',
      model2: '2500',
      perfectFor:
        'those seeking a versatile pickup that excels in both work and play with its impressive towing capacity and spacious interior',
      offering: 'enhanced capability and ruggedness for heavier-duty tasks',
      knownFor:
        'exceptional towing and hauling capabilities, comfortable interiors, and advanced technology features',
    },
  },
  {
    name: 'Volkswagen',
    src: '/img/brands/card/Volkswagen.png',
    seo: {
      keyAttributes: 'innovation, reliability, and exceptional performance',
      model1: 'Golf',
      model2: 'Tiguan',
      perfectFor:
        'those seeking a versatile hatchback with spirited driving dynamics and practical features',
      offering: 'spaciousness and advanced safety technologies',
      knownFor:
        'impressive fuel efficiency, cutting-edge technology, and engaging driving experience',
    },
  },
  {
    name: 'Volvo',
    src: '/img/brands/card/Volvo.png',
    seo: {
      keyAttributes: 'safety, reliability, and Scandinavian design',
      model1: 'XC60',
      model2: 'S60',
      perfectFor:
        'families seeking a spacious and luxurious SUV with top-notch safety features',
      offering:
        'a sleek and dynamic driving experience with its efficient performance and advanced technology',
      knownFor: 'commitment to safety, comfort, and eco-friendly technology',
    },
  },
];

export enum EnumStateName {
  NewYork = 'New York',
  NewJersey = 'New Jersey',
  Pennsylvania = 'Pennsylvania',
  Connecticut = 'Connecticut',
  Maryland = 'Maryland',
  California = 'California',
}

export const ARE_GOOGLE_REVIEWS_ENABLED = false;

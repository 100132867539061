import { useEffect, useState } from 'react';
import { hotjarTrackMobileExitIntent } from 'services/hotjar';
import { useRouter } from 'next/router';
import { CLIENT_STORAGE_TYPE, getClientStorage } from 'utils/ClientStorage';
import { getDeviceType, UserAgentDeviceType } from 'utils/functions';

const LOCAL_STORAGE_KEY = 'hotjar_mobile_exit_intent_shown';

export const clientStorage = getClientStorage(CLIENT_STORAGE_TYPE);

function wasShownBefore() {
  return clientStorage.get(LOCAL_STORAGE_KEY) === 'true';
}

function setWasShownBefore(value: 'true' | 'false') {
  clientStorage.set(LOCAL_STORAGE_KEY, value);
}

export function useHotjarMobileExitIntent(): void {
  const router = useRouter();
  const [historyUpdated, setHistoryUpdated] = useState(false);

  useEffect(() => {
    const deviceType = getDeviceType(navigator.userAgent);
    const isMobile = deviceType === UserAgentDeviceType.MOBILE;
    if (!isMobile || wasShownBefore() || historyUpdated) {
      return;
    }

    const cleanState = history.state;
    // Mark the entry route using isBouncing = true
    history.replaceState(
      {
        ...history.state,
        isBouncing: true,
      },
      '', // unused,
    );
    history.pushState(cleanState, /* unused = */ '');
    router.beforePopState(() => {
      if (history.state && history.state.isBouncing && !wasShownBefore()) {
        hotjarTrackMobileExitIntent();
        setWasShownBefore('true');
      }
      // Let Next.js handle the navigation
      return true;
    });
    setHistoryUpdated(true);
  }, [router, historyUpdated]);
}

import { UserTaskCardProps, UserTaskCard } from './UserTaskCard';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TaskGroupType } from 'shared/types/misc';
import { FC } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled(Paper)(
  ({ theme }) => `
  &.MuiPaper-root {
    padding: ${theme.spacing(2)};
  }`,
);

const BoldPreWrap = styled('b')(
  () => `
  white-space: pre-wrap;`,
);

export type TaskGroupProps = {
  title: TaskGroupType['name'];
  tasks: Array<UserTaskCardProps>;
};

export const TaskGroup: FC<TaskGroupProps> = ({ title, tasks }) => {
  return (
    <Container variant='outlined'>
      <Typography variant='h6' color='textPrimary'>
        <BoldPreWrap>{title}</BoldPreWrap>
      </Typography>
      {tasks.map((task, index) => (
        <Box mt={2} key={index}>
          <UserTaskCard {...task} />
        </Box>
      ))}
    </Container>
  );
};

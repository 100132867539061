import { FC } from 'react';
import {
  SELECTED_REBATE_NAME_FIELD,
  UIRebate,
  UISelectedRebate,
} from '../../../services/rebates/functions';
import {
  MultipleCheckboxSelect,
  OnChangeRebates,
  OnSelectRebate,
} from './MultipleCheckboxSelect';

type Props = {
  onChange?: (selectedRebates: UISelectedRebate[]) => void;
  onSelect?: OnSelectRebate;
  rebates: Array<UIRebate>;
  selectedRebates: Array<UISelectedRebate>;
};

export const SelectRebates: FC<Props> = ({
  selectedRebates,
  rebates,
  onSelect,
  onChange,
}) => {
  const handleChange: OnChangeRebates = (ids) => {
    onChange &&
      onChange(ids.map((id) => ({ [SELECTED_REBATE_NAME_FIELD]: id })));
  };

  return (
    <MultipleCheckboxSelect
      items={rebates}
      selectedItemsIds={selectedRebates.map((i) => i.id)}
      onChange={handleChange}
      onSelect={onSelect}
    />
  );
};

import { useEffect } from 'react';
import { fbTrackPageView } from 'services/fbtag';

export function useFBTrackPageView(): void {
  useEffect(() => {
    if (process.env.USE_FBTAG) {
      fbTrackPageView();
    }
  }, []);
}

import { Field } from 'react-final-form';
import FormHelperText from '@mui/material/FormHelperText';
import {
  SelectControl,
  SelectLabel,
  SelectField,
} from 'components/atoms/Select';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { CSSProperties, ReactNode } from 'react';

export type FieldSelectProps = {
  name: string;
  label: any;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
  select?: boolean;
  displayEmpty?: boolean;
  InputProps?: any;
  children?: ReactNode;
  onValueChange?: SelectInputProps['onChange'];
  className?: string;
  style?: CSSProperties;
};

const FieldSelect = ({
  name,
  label,
  disabled,
  children,
  displayEmpty,
  onValueChange,
  placeholder,
  ...otherProps
}: FieldSelectProps): JSX.Element => (
  <Field name={name}>
    {({ meta, input: { name, onChange, value, ...restInput } }) => (
      <SelectControl
        error={(meta.error || meta.submitError) && meta.touched}
        disabled={disabled}
        fullWidth
        {...otherProps}
      >
        <SelectLabel>{label}</SelectLabel>
        <SelectField
          variant='outlined'
          placeholder={placeholder}
          displayEmpty={displayEmpty}
          label={label}
          onChange={(...args) => {
            onChange(args[0]);
            onValueChange && onValueChange(...args);
          }}
          value={value}
          name={name}
          error={meta.error && meta.touched}
          inputProps={restInput}
        >
          {children}
        </SelectField>
        <FormHelperText error={true}>
          {meta.touched ? meta.error || meta.submitError : undefined}
        </FormHelperText>
      </SelectControl>
    )}
  </Field>
);

export default FieldSelect;

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledButtonMui5 } from './atoms/StyledButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SwitchItemMui5 } from './molecules/SwitchItem';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { UserVehicle } from 'shared/types/models';
import { UserVehicleForm } from './organisms/UserVehicleForm/UserVehicleForm';
import { clientStorageEffect } from 'recoil/effects';
import { rebatesCategoriesQuestions } from '../shared/utils/functions';
import Rest from '../services/rest';
import {
  userRebatesCategoriesAnswersSelector,
  userVehicleOwnSelector,
} from '../recoil/selectors';
import { FC, useEffect, useState } from 'react';
import { rebatesCategoriesDialogAtom, userAtom } from '../recoil/atoms';
import isEmpty from 'lodash/isEmpty';
import { logger } from '@sentry/utils';
import { StyledFontAwesomeIconMui5 } from './atoms/FontAwesomeIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import Alert from './atoms/FormAlert';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

const rest = new Rest();

const ActionsWrapper = styled(DialogActions)(
  ({ theme }) => `
  &.MuiDialogActions-root {
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1, 3)};
  }`,
);

const textSecondaryFix: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiTypography-body2': {
    color: theme.palette.text.secondary,
  },
});

export type RebatesCategoriesDialogState = {
  hideCheckbox?: boolean;
  title: string;
  isOpen: boolean;
  answers: RebateCategoryAnswer[];
  vehicleOwn: UserVehicle[];
};

type RebateCategoryQuestion = {
  title: string;
  categories: {
    categoryName: string;
    subcategoriesNames?: string[];
  }[];
};

type RebateCategoryAnswer = Pick<RebateCategoryQuestion, 'title'> & {
  isApplied: boolean;
};

export const userClientRebatesCategoriesAnswersAtom = atom<
  RebateCategoryAnswer[]
>({
  key: 'userClientIncentiveEligibilityAnswers',
  default: undefined,
  effects: [
    clientStorageEffect({
      key: 'IncentiveQuestionDialog',
      validate: (value) => value,
    }),
  ],
});

export const userClientVehicleOwnAtom = atom<UserVehicle[]>({
  key: 'userClientVehicleOwn',
  default: undefined,
  effects: [
    clientStorageEffect({
      key: 'VehicleOwn',
      validate: (value) => value,
    }),
  ],
});

export const useRebatesCategoriesDialog = ({
  title = 'Unlock additional savings by answering a few questions',
}) => {
  const setRebatesCategoriesDialog = useSetRecoilState(
    rebatesCategoriesDialogAtom,
  );

  const openRebatesCategoriesDialog = () => {
    setRebatesCategoriesDialog((prevState) => ({
      ...prevState,
      isOpen: true,
      title,
    }));
  };

  return {
    openRebatesCategoriesDialog,
  };
};

enum ESubmitState {
  SKIP,
  SAVE,
}

export const RebatesCategoriesDialog = (): JSX.Element => {
  const state = useRecoilValue(rebatesCategoriesDialogAtom);
  const savedRebatesCategoriesAnswers = useRecoilValueLoadable(
    userRebatesCategoriesAnswersSelector,
  );
  const savedVehiclesOwn = useRecoilValueLoadable(userVehicleOwnSelector);

  const childState = {
    ...state,
    ...(savedRebatesCategoriesAnswers.valueMaybe()
      ? { answers: savedRebatesCategoriesAnswers.valueMaybe() }
      : {}),
    ...(savedVehiclesOwn.valueMaybe()
      ? { vehicleOwn: savedVehiclesOwn.valueMaybe() }
      : {}),
  };

  if (
    [savedRebatesCategoriesAnswers.state, savedVehiclesOwn.state].some(
      (state) => state === 'loading',
    )
  ) {
    return null;
  }
  return <RebatesCategoriesDialogView {...childState} />;
};

const RebatesCategoriesDialogView: FC<RebatesCategoriesDialogState> = (
  initState,
) => {
  const setDialogAtom = useSetRecoilState(rebatesCategoriesDialogAtom);

  const { isExtraSmallScreen } = useScreenSize();

  const [user, setUser] = useRecoilState(userAtom);
  const setUserClientRebatesCategoriesAnswersAtom = useSetRecoilState(
    userClientRebatesCategoriesAnswersAtom,
  );
  const setUserClientVehicleOwnAtom = useSetRecoilState(
    userClientVehicleOwnAtom,
  );
  const [state, setState] = useState<RebatesCategoriesDialogState>({
    ...initState,
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setState(() => ({ ...initState }));
  }, [initState]);

  const handleSubmit = async (submitState: ESubmitState) => {
    setError('');
    setLoading(true);

    const updateAnswers =
      submitState === ESubmitState.SKIP ? initState.answers : state.answers;
    const updateVehiclesOwn =
      submitState === ESubmitState.SKIP
        ? initState.vehicleOwn
        : state.vehicleOwn;

    if (isEmpty(user)) {
      setUserClientRebatesCategoriesAnswersAtom(updateAnswers);
      setUserClientVehicleOwnAtom(updateVehiclesOwn);
      setDialogAtom((prevState) => ({
        ...prevState,
        isOpen: false,
        answers: updateAnswers,
        vehicleOwn: updateVehiclesOwn,
      }));
      setLoading(false);
      return;
    }

    try {
      await Promise.all([
        rest.updateUserProfile({
          rebates_categories_answers: updateAnswers,
        }),
        rest.updateUserVehicles({
          vehicles: updateVehiclesOwn,
        }),
      ]);
    } catch (error) {
      setError(error);
      logger.error({
        location: 'RebatesCategoriesDialog',
        error,
      });
    } finally {
      setUser((prevUser) => ({
        ...prevUser,
        rebates_categories_answers: updateAnswers,
        vehicles: updateVehiclesOwn,
      }));
      setDialogAtom((prevState) => ({
        ...prevState,
        isOpen: false,
        answers: updateAnswers,
        vehicleOwn: updateVehiclesOwn,
      }));
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={state.isOpen}
      onClose={() => {
        setDialogAtom((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      }}
      fullScreen={isExtraSmallScreen}
    >
      <DialogTitle>{state.title}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert variant='filled' severity='error'>
            {error}
          </Alert>
        )}
        {rebatesCategoriesQuestions.map((question) => {
          const checked = state.answers.find(
            (answer) => answer.title === question.title,
          );
          return (
            <SwitchItemMui5
              key={question.title}
              title={question.title}
              scale={isExtraSmallScreen ? 1.4 : 1}
              onChange={(event) => {
                const title = question.title;
                const isApplied = event.target.checked;
                setState((prevState) => ({
                  ...prevState,
                  answers: prevState.answers.map((answer) => {
                    if (answer.title === title) {
                      return { ...answer, isApplied };
                    }
                    return answer;
                  }),
                }));
              }}
              checked={checked?.isApplied}
            />
          );
        })}
        <Typography variant='body1'>
          What vehicles do you have in your household?
        </Typography>
        <UserVehicleForm
          vehicles={state.vehicleOwn || []}
          onChange={(vehicleOwn) => {
            setState((prevState) => ({
              ...prevState,
              vehicleOwn,
            }));
          }}
        />
        <Typography variant='body2' color='textSecondary' sx={textSecondaryFix}>
          * Please confirm your eligibility for each incentive applied. Auction
          prices will only be honored with proof of incentive eligibility
          provided to the dealership upon winning an auction.
        </Typography>
      </DialogContent>
      <ActionsWrapper>
        <StyledButtonMui5
          className='js-skip-incentive-question'
          color='primary'
          variant='text'
          onClick={async () => {
            await handleSubmit(ESubmitState.SKIP);
          }}
        >
          Skip
        </StyledButtonMui5>
        <StyledButtonMui5
          color='primary'
          disabled={isLoading}
          onClick={async () => {
            await handleSubmit(ESubmitState.SAVE);
          }}
          startIcon={
            isLoading ? (
              <StyledFontAwesomeIconMui5 icon={faSpinner} spin />
            ) : null
          }
        >
          Save
        </StyledButtonMui5>
      </ActionsWrapper>
    </Dialog>
  );
};

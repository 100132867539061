import Select from 'components/molecules/Select';
import { IsNewModel } from 'shared/types/api/getMarketScanModelYears';
import { useRecoilValueLoadable } from 'recoil';
import { msModelYearsAtomFamily } from 'recoil/atoms';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

type YearSelectProps = {
  makeId: number;
  modelId: number;
  selectedYear?: string;
  disabled?: boolean;
  onChange(e: SelectChangeEvent): void;
};

const vehicleSelectStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiBox-root': {
    minWidth: 180,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(-1),
      minWidth: 300,
    },
  },
});

export const YearSelect: FC<YearSelectProps> = ({
  makeId,
  modelId,
  disabled,
  selectedYear,
  onChange,
}) => {
  const years = useRecoilValueLoadable(
    msModelYearsAtomFamily({
      makeId: String(makeId),
      modelId: String(modelId),
      isNew: IsNewModel.FALSE,
    }),
  ).valueMaybe();

  return (
    <Select
      variant='outlined'
      containerStyles={vehicleSelectStyles}
      selectControlProps={{
        fullWidth: true,
      }}
      placeholder={years ? 'Select year' : 'Loading...'}
      disabled={disabled}
      value={years ? selectedYear || 'Select year' : 'Loading...'}
      name='year'
      options={
        years?.map((y) => ({
          label: y.YearDisplay,
          value: y.YearDisplay,
        })) || []
      }
      onChange={onChange}
    />
  );
};

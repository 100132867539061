import { DefaultValue } from 'recoil';
import { clientStorage, localStorage } from './atoms';

type PersistenceOptions<T> = {
  key: string;
  validate: (any) => T | DefaultValue; // for backward compatibility on changes
  isLocalStorage?: boolean;
};

function jsonParseOrValue(value: any) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

export const clientStorageEffect = <T>(options: PersistenceOptions<T>) => {
  if (typeof window === 'undefined' && options.isLocalStorage) {
    return () => {};
  }
  return ({ setSelf, onSet }) => {
    const storage = options.isLocalStorage ? localStorage : clientStorage;
    const savedValue = storage.get(options.key);
    if (savedValue != null) {
      setSelf(options.validate(jsonParseOrValue(savedValue)));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? storage.remove(options.key)
        : storage.set(options.key, JSON.stringify(newValue));
    });
  };
};

import { IncentiveStatus } from 'shared/incentives';
import { MSISRebate } from 'shared/types/marketScan';

export const getRebateStatus = (
  rebate: MSISRebate,
  appliedRebates: MSISRebate[] = [],
  selectedRebates: MSISRebate[] = [],
  autoSelectedRebates: MSISRebate[] = [],
): IncentiveStatus => {
  const isApplied = appliedRebates.some(
    (appliedRebate) => appliedRebate.ID === rebate.ID,
  );

  const isSelected = selectedRebates.some(
    (applicableRebate) => applicableRebate.ID === rebate.ID,
  );

  const isAutoSelected = autoSelectedRebates.some(
    (autoSelectedRebate) => autoSelectedRebate.ID === rebate.ID,
  );

  if (isAutoSelected) {
    return isApplied
      ? IncentiveStatus.autoSelectedApplied
      : IncentiveStatus.autoSelectedNonStackable;
  }

  if (isApplied && !isSelected) {
    return IncentiveStatus.autoApplied;
  }

  if (isApplied && isSelected) {
    return IncentiveStatus.applied;
  }

  if (!isApplied && isSelected) {
    return IncentiveStatus.nonStackable;
  }

  return IncentiveStatus.notApplied;
};

import { MSAPIYear } from '../marketScan';

export enum IsNewModel {
  TRUE = 'true',
  FALSE = 'false',
}

export type GetMarketScanModelYearsResponse = MSAPIYear[];

export type GetMarketScanModelYearsRequest = {
  makeId: string;
  modelId: string;
  isNew: IsNewModel;
};

export type GetMarketScanModelYearsParams = void;

import Head from 'next/head';
import { Router } from 'next/router';
import { FC } from 'react';

function removeReferralFromPath(path: string): string {
  const [pathname, search] = path.split('?');

  if (!search) {
    return path;
  }

  const params = new URLSearchParams(search);

  if (params.get('ref')) {
    params.delete('ref');
  }

  const newParams = params.toString();
  return newParams ? `${pathname}?${newParams}` : pathname;
}

type FixMetaCanonicalForRefLeasebanditProps = {
  router: Router;
};

export const FixRefLeasebanditHeadMetaCanonical: FC<
  FixMetaCanonicalForRefLeasebanditProps
> = ({ router }) => {
  const isLeaseBanditReferral = router.query.ref === 'leasebandit';
  const isDealsPathname = router.pathname === '/deals';

  if (!isLeaseBanditReferral || isDealsPathname) {
    return null;
  }

  const canonicalWithoutReferral = removeReferralFromPath(router.asPath);

  return (
    <Head>
      <link
        rel='canonical'
        href={process.env.PUBLIC_SITE_URL + canonicalWithoutReferral}
      />
    </Head>
  );
};

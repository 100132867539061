import { GetProgramRequest, GetProgramResponse } from '../types/api/getProgram';
import {
  MSAPIProgramTransactionTypeEnum,
  MSAPIScanMode,
} from '../types/marketScan';

export const getProgramRequestFromProgram = ({
  program,
  auctionId,
  params,
}: {
  program: GetProgramResponse;
  auctionId: number;
  params?: {
    // programs do not contain original credit score.
    // program.MaxCreditScore will be used if you dont pass it manually
    creditScore: number;
    zipcode?: string;
  };
}): GetProgramRequest => {
  if (!program) {
    return {} as GetProgramRequest;
  }

  const useOnlyLenderCode = [program.FullDetails.Lender].filter(Boolean);
  return {
    programId: program.ID,
    creditScore: params?.creditScore || program.MaxCreditScore,
    demoMileage: program.FullDetails.CurrentMileage,
    downPayment: program.FullDetails.DownpaymentFromCash,
    isOwnersChoice:
      program.FullDetails.TransactionType ===
      MSAPIProgramTransactionTypeEnum.Balloon,
    miles: program.FullDetails.Lease?.AnnualMileage,
    mo: program.FullDetails.Term,
    msd: program.SecurityDeposit,
    msrp: program.FullDetails.MSRP,
    invoicePrice: program.InvoicePrice,
    onePayLease: program.FullDetails.Lease?.IsOnePay,
    upfrontAcquisitionFee: !program.FullDetails.Lease?.AcqusitionFeeInCap,
    zeroDriveOff: program.ZeroDriveOff,
    monthlyPayment: Number(program.Payment),
    salesPrice: program.Price,
    zipcode: params?.zipcode || program.ZIP, // program.ZIP is not reliable
    dealerZipcode: program.DealerZIP,
    vehicleId: program.FullDetails.VehicleID,
    waiveTheAcquisitionFee: false,
    rebates: program.AppliedRebate,
    auctionId,
    dueAtSigningType: program.DueAtSigningType,
    ...(useOnlyLenderCode.length > 0 ? { useOnlyLenderCode } : {}),
    scanMode: program.FullDetails.Lease
      ? MSAPIScanMode.Lease
      : MSAPIScanMode.Retail,
  };
};

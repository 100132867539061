import { SearchTerm } from '../../utils/constants';
import { FiltersState } from '../common';
import { DealTypeEnum } from '../endpoints';
import { Auction, AuctionStatus } from '../models';

export enum DynamicFilterCategory {
  Transmission = 'transmission',
  WheelType = 'wheel_type',
  InteriorColor = 'interior_color',
  ExteriorColor = 'exterior_color',
  Premium = 'premium',
  Drivetrain = 'drivetrain',
  BodyStyle = 'body_style',
  FuelEconomy = 'fuel_economy',
  FuelType = 'fuel_type',
}

export type DynamicFilterCategories = {
  [DynamicFilterCategory.Transmission]: string | string[];
  [DynamicFilterCategory.WheelType]: string | string[];
  [DynamicFilterCategory.InteriorColor]: string | string[];
  [DynamicFilterCategory.ExteriorColor]: string | string[];
  [DynamicFilterCategory.Drivetrain]: string | string[];
  [DynamicFilterCategory.BodyStyle]: string | string[];
  [DynamicFilterCategory.FuelEconomy]: string | string[];
  [DynamicFilterCategory.FuelType]: string | string[];
  [DynamicFilterCategory.Premium]: string | string[];
};

export type Option = {
  name: string;
  count: number;
  selected?: boolean;
};

export type OptionsMap = {
  [category in keyof DynamicFilterCategories]?: Option[];
};

export type DynamicAuctionFilters = {
  msrp?: number[];
  cityEco?: number[];
  hwyEco?: number[];
  options?: OptionsMap;
};

export type AuctionFromSearch = Auction & {
  distance: string;
  free_delivery: boolean;
  current_selling_price: number;
  current_discount: number;
  offer: Auction['offer'] & { image: string };
  payment?: number;
  visited_at?: Date;
  is_featured?: boolean;
};

export type GetAuctionsParams = void;
export type GetAuctionsQuery = void;
export type GetAuctionsRequest = Omit<FiltersState, 'term'> & {
  id_list?: number[];
  term?: SearchTerm;
  watching?: boolean;
  statuses?: AuctionStatus[];
  is_featured?: boolean;
  except_ids?: number[];
  dealType?: DealTypeEnum;
  miles?: number;
  only_make_model_list?: SearchFilterModel[];
  one_per_make_model?: boolean;
};

export type SearchFilterModel = {
  modelName: string;
  makeName: string;
};

export type SearchFilterTrim = {
  trimName: string;
  modelName: string;
  makeName: string;
};

export type SearchFilters = {
  years: number[];
  makes: string[];
  models: SearchFilterModel[];
  trims: SearchFilterTrim[];
};

export type GetAuctionsResponse = {
  perPage: number;
  auctions: Array<AuctionFromSearch>;
  count: number;
  page: number;
  dynamicFilters: DynamicAuctionFilters;
  searchFilters: SearchFilters;
};

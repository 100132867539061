import FormControl, {
  FormControlProps as MUIFormControlProps,
} from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { lightThemeMui5 } from 'theme';
import { FC } from 'react';

export type SelectControlProps = MUIFormControlProps & {
  size?: 'small' | 'medium';
};

const StyledFormControl = styled(FormControl)(
  ({ theme }) => `
  &.MuiFormControl-root {
    margin-top: ${theme.spacing(3)};
    & legend {
      max-width: 0;
    }
    & .MuiFormHelperText-root {
      margin: ${theme.spacing(0.5, 0)};
    }
    & .MuiInputBase-root::before, 
    & .MuiInputBase-root::after {
      display: none;
    }
    &.small .MuiSelect-select.MuiSelect-select {
      font-size: 14px;
      padding: ${theme.spacing(2, 4, 2, 2)};
    }
    &.small .MuiSelect-icon {
      right: 8px;
    }
    &.small:hover .MuiSelect-select, 
    &.small .Mui-focused .MuiSelect-select {
      background: ${lightThemeMui5.palette.grey[100]};
    }
  }`,
);

const SelectControl: FC<SelectControlProps> = ({
  children,
  variant = 'outlined',
  size = 'medium',
  className, // always pass className to be able to style component properly
  ...restProps
}): JSX.Element => (
  <StyledFormControl
    variant={variant}
    {...restProps}
    className={`${className} ${size === 'small' ? 'small' : ''}`}
  >
    {children}
  </StyledFormControl>
);

export default SelectControl;

import useWidth from './useWidth';
import { Breakpoint } from '@mui/material/styles';

const isExtraSmallScreen = (width: Breakpoint) => width === 'xs';
const isSmallScreen = (width: Breakpoint) => width === 'sm';
const isMediumScreen = (width: Breakpoint) => width === 'md';
const isLargeScreen = (width: Breakpoint) => width === 'lg';
const isExtraLargeScreen = (width: Breakpoint) => width === 'xl';

export const useScreenSize = () => {
  const screenSize = useWidth();
  return {
    screenSize,
    isExtraSmallScreen: isExtraSmallScreen(screenSize),
    isSmallScreen: isSmallScreen(screenSize),
    isMediumScreen: isMediumScreen(screenSize),
    isLargeScreen: isLargeScreen(screenSize),
    isExtraLargeScreen: isExtraLargeScreen(screenSize),
  };
};

import { alpha, Direction } from '@mui/material/styles';
import { createTheme as createThemeMui5 } from '@mui/material/styles';
import { ThemeOptions as ThemeOptionsMui5 } from '@mui/material/styles';
import {
  Futura,
  FuturaItalic,
  FuturaBold,
  nextFutura,
  nextRoboto,
} from './fonts';
import { grey } from './colors';

const commonPalette = {
  primary: {
    light: '#bcc2cd',
    main: '#223559',
    dark: '#0b162d',
  },
  secondary: {
    light: '#fbc0c0',
    main: '#f22f2f',
    dark: '#c01919',
  },
  grey,
  action: {
    hoverOpacity: 0.08,
  },
};

const commonThemeMui5: ThemeOptionsMui5 = {
  typography: {
    fontFamily: [
      nextFutura.style.fontFamily,
      'Verdana',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      nextRoboto.style.fontFamily,
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [Futura, FuturaItalic, FuturaBold],
        html: { margin: 0 },
        body: { margin: 0 },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      // increase default sm breakpoint by 60px to avoid edge cases
      sm: 660,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export const lightThemeDataMui5: ThemeOptionsMui5 = {
  palette: {
    ...commonPalette,
    mode: 'light',
    text: {
      secondary: grey[700],
    },
  },
  ...commonThemeMui5,
  typography: {
    ...commonThemeMui5.typography,
    h1: {
      color: commonPalette.primary.main,
    },
    h2: {
      color: commonPalette.primary.main,
    },
    h3: {
      color: commonPalette.primary.main,
    },
    h4: {
      color: commonPalette.primary.main,
    },
    h5: {
      color: commonPalette.primary.main,
      fontWeight: 'bold',
    },
    h6: {
      color: commonPalette.primary.main,
    },
    body1: {
      color: commonPalette.primary.main,
    },
    body2: {
      color: commonPalette.primary.main,
    },
  },
};

// Hack to make slider work Right-To-Left
const sliderLightThemeDataMui5 = {
  ...lightThemeDataMui5,
  direction: 'rtl' as Direction, // Update the type of the direction property
};

const darkThemeDataMui5: ThemeOptionsMui5 = {
  palette: {
    ...commonPalette,
    mode: 'dark',
    background: {
      default: '#1A202B',
    },
    ...commonThemeMui5,
  },
};

export const lightThemeMui5 = createThemeMui5(lightThemeDataMui5);
export const darkThemeMui5 = createThemeMui5(darkThemeDataMui5);
export const rtlLightThemeMui5 = createThemeMui5(sliderLightThemeDataMui5);

// different theme for Mui-4 like default button colors
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

const greyButtonBaseLightTheme = createThemeMui5(lightThemeDataMui5, {
  palette: {
    // mode: "dark",
    mode: 'light',
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
});

export const greyButtonLightTheme = createThemeMui5(greyButtonBaseLightTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: greyButtonBaseLightTheme.palette.getContrastText(
              greyButtonBaseLightTheme.palette.grey[300],
            ),
          },
        },
        {
          props: { variant: 'outlined', color: 'grey' },
          style: {
            color: greyButtonBaseLightTheme.palette.text.primary,
            borderColor:
              greyButtonBaseLightTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${greyButtonBaseLightTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                greyButtonBaseLightTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                greyButtonBaseLightTheme.palette.text.primary,
                greyButtonBaseLightTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
        {
          props: { color: 'grey', variant: 'text' },
          style: {
            color: greyButtonBaseLightTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                greyButtonBaseLightTheme.palette.text.primary,
                greyButtonBaseLightTheme.palette.action.hoverOpacity,
              ),
            },
          },
        },
      ],
    },
  },
});

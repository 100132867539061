import Avatar from '../Avatar';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../recoil/atoms';
import { hasPermission } from '../../../shared/utils/functions';
import { Permission } from '../../../shared/types/models';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const UserHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(6),
  width: theme.spacing(6),
  marginRight: theme.spacing(2),
}));

const UserName = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: theme.palette.grey[600],
    wordBreak: 'break-word',
  },
}));

export const UserInformation = () => {
  const user = useRecoilValue(userAtom);
  const isDealer = hasPermission(user, Permission.dealer);

  return (
    <UserHeader>
      <StyledAvatar src={user.img} />
      <Box flex='1' minHeight='0'>
        <UserName variant='body1'>
          {isDealer
            ? user.company_name
            : user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.email}
        </UserName>
      </Box>
    </UserHeader>
  );
};

import { FC, useCallback, useState } from 'react';
import Select, { SelectProps as MUISelectProps } from '@mui/material/Select';
import { MenuProps } from '@mui/material/Menu';
import { SelectArrowMui5 } from './SelectArrow';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import merge from 'lodash/merge';

export const standardSelectFix: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiSelect-root': {
    marginTop: 0,
    '& svg.MuiSelect-icon': {
      right: `${theme.spacing(1)} !important`,
    },
  },
});

const BaseSelect = styled(Select, { skipSx: false })(
  ({ theme }) => `
  &.MuiSelect-root {
    & .MuiSelect-select {
      padding: ${theme.spacing(2, 3)};
      background: ${theme.palette.common.white};
      &:focus {
        background: ${theme.palette.common.white};
      }
      line-height: ${theme.typography.pxToRem(18)};
      min-height: ${theme.typography.pxToRem(18)};
    }
  }`,
);

const StyledSelect: FC<MUISelectProps> = styled(
  // @ts-expect-error "className" and "MenuProps" does not exists on JSXElementConstructor
  ({ className, MenuProps, ...props }) => {
    const menuProps = merge(
      { slotProps: { paper: { className } } },
      MenuProps || {},
    );
    return <BaseSelect {...props} MenuProps={menuProps} />;
  },
  { skipSx: false },
)(
  ({ theme }) => `
  &&.MuiPaper-root {
     box-shadow: none;
     border: 2px solid ${theme.palette.grey[200]};
     width: min-content;
     max-height: 300px;
     & .MuiList-root {
       padding: 0;
       min-width: 150px;
     }
     & .MuiListItem-placeholder {
       display: none;
     }
  }`,
);

export const defaultSelectMenuProps = {
  disableAutoFocusItem: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
} as Partial<MenuProps>;

type AdditionalSelectProps = {
  dropdownSx?: SxProps<ThemeMui5>;
};

const SelectField: FC<MUISelectProps & AdditionalSelectProps> = ({
  children,
  onOpen = () => {},
  onClose = () => {},
  dropdownSx,
  MenuProps = {},
  ...restSelectProps
}) => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const handleSelectOpen = useCallback(
    (event) => {
      setOpened(true);
      onOpen(event);
    },
    [onOpen],
  );

  const handleSelectClose = useCallback(
    (event) => {
      setOpened(false);
      onClose(event);
    },
    [onClose],
  );

  return (
    <StyledSelect
      open={isOpened}
      onOpen={handleSelectOpen}
      onClose={handleSelectClose}
      MenuProps={{
        slotProps: {
          paper: {
            sx: dropdownSx,
          },
        },
        PopoverClasses: {
          ...MenuProps.PopoverClasses,
        },
        ...defaultSelectMenuProps,
        ...MenuProps,
      }}
      slotProps={{
        root: {
          className: 'MuiSelect-root',
          sx: restSelectProps.sx,
        },
      }}
      IconComponent={SelectArrowMui5}
      {...restSelectProps}
    >
      {children}
    </StyledSelect>
  );
};

export default SelectField;

import Link from 'next/link';
import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { styled as styledMui5 } from '@mui/material/styles';

type NextLinkMui5Props = {
  sx?: SxProps;
  href?: string;
  children: ReactNode;
  prefetch?: boolean;
  passHref?: boolean;
};

const StyledLink = styledMui5(Link, { skipSx: false })`
  &:hover { cursor: pointer; }
`;

export const NextLinkMui5 = forwardRef(
  (
    {
      href,
      children,
      prefetch = false,
      passHref = false,
      sx,
      ...rest
    }: NextLinkMui5Props,
    ref: ForwardedRef<HTMLAnchorElement>,
  ): JSX.Element => (
    <StyledLink
      href={href}
      prefetch={prefetch}
      passHref={passHref}
      ref={ref}
      sx={sx}
      {...rest}
    >
      {children}
    </StyledLink>
  ),
);
NextLinkMui5.displayName = 'NextLinkMui5';

type NextLinkProps = {
  className?: string;
  href?: string;
  children: React.ReactNode;
  prefetch?: boolean;
};

export const NextLink = forwardRef(
  (
    { href, children, prefetch = false, className, ...rest }: NextLinkProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ): JSX.Element => (
    <Link
      href={href}
      prefetch={prefetch}
      className={className}
      ref={ref}
      {...rest}
    >
      {children}
    </Link>
  ),
);
NextLink.displayName = 'NextLink';

export default NextLinkMui5;

import { forwardRef, ForwardRefRenderFunction } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { lightThemeMui5 } from '../../theme';

const StyledMuiTextField = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    marginTop: theme.spacing(3),
    '& legend': {
      maxWidth: 0,
    },
  },
}));

const buildInputProps = ({ InputProps, theme }) => ({
  ...InputProps,
  sx: {
    background: theme.palette.common.white,
    '& input': {
      padding: theme.spacing(2, 1, 2, 2),
      '&::placeholder': {
        color: theme.palette.common.black,
        opacity: 0.87,
      },
      '&:disabled': {
        cursor: 'not-allowed',
        '&::placeholder': {
          opacity: 0.38,
        },
      },
    },
  },
});

const buildInputLabelProps = ({
  InputLabelProps,
  theme: { palette, spacing },
}) => ({
  ...InputLabelProps,
  shrink: true,
  sx: {
    color: palette.primary.main,
    transform: `translate(${spacing(0)}, ${spacing(-3)}) !important`,
    overflow: 'hidden',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
});

const buildFormHelperTextProps = ({ FormHelperTextProps, theme }) => ({
  ...FormHelperTextProps,
  sx: { margin: theme.spacing(0.5, 0) },
});

const StyledTextField: ForwardRefRenderFunction<unknown, TextFieldProps> = (
  {
    variant = 'outlined',
    InputProps = {},
    InputLabelProps = {},
    FormHelperTextProps = {},
    size = 'medium',
    ...restTextFieldProps
  },
  ref,
): JSX.Element => {
  return (
    <StyledMuiTextField
      inputRef={ref}
      size={size}
      variant={variant}
      InputProps={buildInputProps({ InputProps, theme: lightThemeMui5 })}
      InputLabelProps={buildInputLabelProps({
        InputLabelProps,
        theme: lightThemeMui5,
      })}
      FormHelperTextProps={buildFormHelperTextProps({
        FormHelperTextProps,
        theme: lightThemeMui5,
      })}
      {...restTextFieldProps}
    />
  );
};

export default forwardRef(StyledTextField);

import { ReactNode } from 'react';
import Container, { ContainerProps } from '@mui/material/Container';
import Layout from 'containers/Layout';
import Page from 'components/Page';
import { styled } from '@mui/material/styles';
import Breadcrumbs, { BreadcrumbItem } from '../molecules/Breadcrumbs';
import Head from 'next/head';
import { SEO } from 'utils/seo';

type Props = {
  children?: ReactNode;
  links?: BreadcrumbItem[];
  maxWidth?: ContainerProps['maxWidth'];
  seo?: SEO;
};

const BreadcrumbsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 3),
}));

const StaticPageSeoHead = ({ seo }: Props): JSX.Element => {
  if (!seo) return null;

  return (
    <Head>
      <title>{seo.title()}</title>
      <meta name='description' content={seo.description()} />
      <meta name='keywords' content={seo.keywords()} />
    </Head>
  );
};

const StaticPage = ({ children, links, maxWidth, seo }: Props): JSX.Element => {
  return (
    <>
      <StaticPageSeoHead seo={seo} />
      <Layout>
        <Container maxWidth={maxWidth || 'lg'} disableGutters>
          {links?.length > 0 && (
            <BreadcrumbsContainer>
              <Breadcrumbs aria-label='breadcrumb' items={links} />
            </BreadcrumbsContainer>
          )}
          <Page>{children}</Page>
        </Container>
      </Layout>
    </>
  );
};

export default StaticPage;

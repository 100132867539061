import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useCallback } from 'react';
import { StyledButtonMui5 } from '../atoms/StyledButton';
import { useRecoilState } from 'recoil';
import { forwardToDialogAtom } from '../../recoil/atoms';
import { useRouter } from 'next/router';
import { useScreenSize } from 'hooks/useScreenSize';
import { greyButtonLightTheme } from '../../theme';
import { ThemeProvider } from '@mui/material/styles';

export const ForwardToDialog: FC = (): JSX.Element => {
  const { isExtraSmallScreen } = useScreenSize();
  const router = useRouter();

  const [
    {
      open,
      title,
      content,
      redirectTo,
      submitText,
      optionText,
      optionRedirectTo,
    },
    setValues,
  ] = useRecoilState(forwardToDialogAtom);

  const closeDialog = () => {
    setValues((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleClick = useCallback(
    (url: string) => {
      if (url) {
        router.push(url);
      }
      closeDialog();
    },
    [submitText, redirectTo, optionText, optionRedirectTo],
  );

  if (!open) {
    return null;
  }

  const optionButton = optionText && (
    <StyledButtonMui5
      color='secondary'
      onClick={() => {
        handleClick(optionRedirectTo);
      }}
    >
      {optionText}
    </StyledButtonMui5>
  );

  return (
    <Dialog
      className='js-forward-to-dialog'
      maxWidth='xs'
      fullScreen={isExtraSmallScreen}
      open={open}
    >
      <DialogTitle id='form-dialog-title'>
        <b id='js-login-dialog-title'>{title}</b>
      </DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={greyButtonLightTheme}>
          <StyledButtonMui5 color='grey' onClick={closeDialog}>
            Close
          </StyledButtonMui5>
        </ThemeProvider>
        {optionButton}
        <StyledButtonMui5
          color='primary'
          onClick={() => {
            handleClick(redirectTo);
          }}
        >
          {submitText}
        </StyledButtonMui5>
      </DialogActions>
    </Dialog>
  );
};

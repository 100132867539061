import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TypographyMui5 from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Rest from 'services/rest';
import { moneyFormat } from '../../../utils/functions';
import {
  Auction,
  AuctionStatus,
  BidStatus,
  User,
} from '../../../shared/types/models';
import { StyledFontAwesomeIconMui5 } from '../../../components/atoms/FontAwesomeIcon';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import FormAlert from '../../../components/atoms/FormAlert';
import { green, grey } from '../../../theme/colors';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'recoil/atoms';
import { getDueAtSignin } from 'shared/utils/program';
import { useScreenSize } from 'hooks/useScreenSize';
import LinkMui5 from '../../../components/atoms/Link';
import { styled } from '@mui/material/styles';
import NextLinkMui5 from '../../../components/atoms/NextLink';

const StyledContainer = styled(Container)(
  ({ theme }) => `
  &.MuiContainer-root {
    width: max-content;
    margin: ${theme.spacing(1)};
    margin-left: auto;
  }`,
);

const StyledTable = styled(Table)(
  () => `
  &.MuiTable-root {
    border-collapse: collapse;
    width: 100%;
  }`,
);

const StyledTableRow = styled(TableRow)(
  () => `
  &.MuiTableRow-root {
    border-bottom: 1px solid ${grey[200]};
  }`,
);

const StyledTableCell = styled(TableCell)(
  ({ theme }) => `
  &.MuiTableCell-head {
    padding: ${theme.spacing(2)};
    text-align: center;
  }`,
);

const StyledImage = styled('img')(
  ({ theme }) => `
  height: ${theme.spacing(8.75)};
  width: ${theme.spacing(11.5)};
  object-position: center;
  object-fit: contain;`,
);

const StyledContainerAlignCenter = styled(StyledContainer)(
  ({ theme }) => `
  &.MuiContainer-root {
    margin: ${theme.spacing(1)};
    margin-left: auto;
  }`,
);

const StyledContainerAlignRight = styled(StyledContainer)(
  ({ theme }) => `
  &.MuiContainer-root {
    margin: ${theme.spacing(1)};
    margin-left: auto;
  }`,
);

type DialogProps = {
  paymentMethod?: string;
  onSubmit?: () => void;
  onClose?: () => void;
};

type TableProps = {
  rows: RowData[];
};

type RowData = {
  id: number;
  msrp: number;
  year: number;
  make: string;
  model: string;
  style: string;
  stockImage: string;
  isWinning: boolean;
  dueAtSigning?: number;
  monthlyPayment?: string;
};

const rest = new Rest();

const getAffectedAuctions = async (
  paymentMethod: string,
): Promise<Auction[]> => {
  const bids = await rest.getUserBids({
    paymentMethod,
    auctionStatus: AuctionStatus.Active,
    bidStatus: BidStatus.Active,
  });
  return bids
    .filter((bid) => Boolean(bid.auction))
    .map((bid) => {
      bid.auction.bids.sort((a, b) => {
        return b.id - a.id;
      });
      return bid.auction;
    });
};

const getTableRowsData = async (
  affectedAuctions: Auction[],
  userProfile: User,
): Promise<RowData[]> => {
  return affectedAuctions.map((auction) => {
    const currentBid = auction.bids.filter((bid) => {
      return bid.user_id == userProfile.id;
    })[0];

    return {
      id: auction.id,
      msrp: auction.msrp,
      year: auction.offer.year,
      make: auction.offer.make.name,
      model: auction.offer.model.name,
      style: auction.offer.style_display_name,
      stockImage: auction.offer.images[0]['@href'],
      isWinning: auction.bids[0].user_id === userProfile.id,
      monthlyPayment: currentBid.min_program.Payment,
      dueAtSigning: getDueAtSignin(currentBid.min_program),
    };
  });
};

const AffectedAuctionsTable = ({ rows = [] }: TableProps) => {
  return (
    <StyledTable>
      <thead>
        <StyledTableRow>
          <StyledTableCell colSpan={2} variant='head'>
            <Typography>Car</Typography>
          </StyledTableCell>
          <StyledTableCell variant='head'>
            <Typography>Your bid</Typography>
          </StyledTableCell>
        </StyledTableRow>
      </thead>
      <tbody>
        {rows.map((item) => (
          <StyledTableRow key={item.id}>
            <StyledTableCell>
              <StyledImage src={item.stockImage} />
            </StyledTableCell>

            <StyledTableCell>
              <LinkMui5
                component={NextLinkMui5}
                variant='body1'
                underline='hover'
                color='primary'
                href={'/deals/' + item.id}
              >
                {item.make} {item.model} {item.style}
              </LinkMui5>
              <TypographyMui5 variant='body2' color='textSecondary'>
                {item.year} <br />
                MSRP - {moneyFormat(item.msrp)}
              </TypographyMui5>
            </StyledTableCell>

            <StyledTableCell>
              <StyledContainerAlignCenter disableGutters={true}>
                {item.isWinning ? (
                  <>
                    <Typography
                      variant='body2'
                      align='center'
                      color='textSecondary'
                    >
                      <Typography display='inline' variant='body1'>
                        <b>{moneyFormat(Number(item.monthlyPayment))}</b>
                      </Typography>{' '}
                      per month
                    </Typography>

                    <Typography
                      variant='body2'
                      align='center'
                      color='textSecondary'
                    >
                      (<b>+{moneyFormat(item.dueAtSigning)}</b> due at signing)
                    </Typography>

                    <Typography variant='body2' align='center'>
                      <StyledFontAwesomeIconMui5
                        icon={faCheckCircle}
                        color={green[500]}
                        sx={{ color: green[500] }}
                      />
                      <br />
                      Winning
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant='body2' align='center' color='error'>
                      <StyledFontAwesomeIconMui5
                        icon={faExclamationCircle}
                        color='inherit'
                        sx={{ color: 'inherit' }}
                      />
                      <br />
                      You were outbid
                    </Typography>

                    <Typography variant='body2' align='center'>
                      Last bid:{' '}
                      <Typography variant='body1' display='inline'>
                        <b>{moneyFormat(Number(item.monthlyPayment))}</b>
                      </Typography>{' '}
                    </Typography>
                  </>
                )}
              </StyledContainerAlignCenter>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

const AffectedAuctionsDialog = ({
  paymentMethod,
  onSubmit = null,
  onClose = null,
}: DialogProps): JSX.Element => {
  const { isExtraSmallScreen } = useScreenSize();
  const [open, setOpen] = useState(true);

  const [auctions, setAuctions] = useState<RowData[]>([]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleSubmit = () => {
    setOpen(false);
    onSubmit();
  };

  const user = useRecoilValue(userAtom);

  useEffect(() => {
    (async () => {
      const affectedAuctions = await getAffectedAuctions(paymentMethod);
      const tableRows = await getTableRowsData(affectedAuctions, user);
      setAuctions(tableRows);
    })();
  }, []);

  const hasAuctions = auctions.length > 0;
  const isWinningSomewhere = auctions?.some((auction) => {
    return auction.isWinning;
  });

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullScreen={isExtraSmallScreen}
      aria-labelledby='dialog-title'
      onClose={handleClose}
    >
      <DialogTitle id='dialog-title'>Removal of payment method</DialogTitle>

      <DialogContent>
        {hasAuctions ? (
          isWinningSomewhere ? (
            <FormAlert severity='error'>
              We are unable to unlink this card because one or more of your bids
              is currently winning.
            </FormAlert>
          ) : (
            <Typography variant='body2' color='textSecondary' gutterBottom>
              Please note that once you unlink this payment method, you will
              need to authorize another payment method to continue bidding on
              the following auctions.
            </Typography>
          )
        ) : (
          <Typography variant='body2' color='textSecondary' gutterBottom>
            You are free to remove this payment method.
          </Typography>
        )}

        {hasAuctions && <AffectedAuctionsTable rows={auctions} />}
      </DialogContent>

      <StyledContainerAlignRight disableGutters={true}>
        <Button onClick={handleClose}>cancel</Button>
        <Button
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          disabled={isWinningSomewhere}
        >
          proceed
        </Button>
      </StyledContainerAlignRight>
    </Dialog>
  );
};

export default AffectedAuctionsDialog;

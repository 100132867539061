import { ReactNode } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import { useScreenSize } from 'hooks/useScreenSize';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

type Props = {
  children?: ReactNode;
};

export default function Page({
  children,
  ...rest
}: Props & PaperProps): JSX.Element {
  const { isExtraSmallScreen } = useScreenSize();
  return (
    <StyledPaper square={isExtraSmallScreen} {...rest}>
      {children}
    </StyledPaper>
  );
}

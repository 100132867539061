import Head from 'next/head';
import { FC } from 'react';
import { lightThemeMui5 } from 'theme';
import { appName } from 'utils/seo';

export const DefaultAppHead: FC = () => {
  return (
    <Head>
      <meta
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width'
      />
      <meta name='theme-color' content={lightThemeMui5.palette.primary.main} />
      <title>{appName}</title>
    </Head>
  );
};

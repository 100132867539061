import { ReactElement } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { usePrevious } from 'hooks/usePrevious';
import { useScreenSize } from 'hooks/useScreenSize';
import Popover from 'components/molecules/Popover';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { styled as styledMui5 } from '@mui/material/styles';

const IconMui5 = styledMui5(StyledFontAwesomeIconMui5, { skipSx: false })(
  ({ theme }) => `
    margin-right: ${theme.spacing(0.5)};`,
);

const StatusWrapperMui5 = styledMui5('div')`
  display: flex;
  white-space: nowrap;`;

type Props = {
  text: ReactElement;
  loading: boolean;
  icon: IconDefinition;
  color: string;
  ariaDisabled: boolean;
};

export const StatusField = ({
  text,
  loading,
  icon,
  color,
  ariaDisabled,
}: Props): JSX.Element => {
  const { isExtraSmallScreen } = useScreenSize();

  const prevText = usePrevious(text);
  const prevIcon = usePrevious(icon) || faCircle;
  const prevColor = usePrevious(color);

  return !isExtraSmallScreen ? (
    <StatusWrapperMui5>
      <IconMui5
        icon={loading ? prevIcon : icon}
        color={loading ? prevColor : color}
        size='lg'
        aria-disabled={ariaDisabled}
      />
      {loading ? prevText : text}
    </StatusWrapperMui5>
  ) : (
    <Popover
      icon={
        <IconMui5
          icon={loading ? prevIcon : icon}
          color={loading ? prevColor : color}
          size='1x'
          aria-disabled={ariaDisabled}
        />
      }
    >
      {loading ? prevText : text}
    </Popover>
  );
};

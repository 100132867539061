import { FC, useMemo, useState } from 'react';
import Select from 'components/molecules/Select';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { StyledFontAwesomeIconMui5 } from '../../atoms/FontAwesomeIcon';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserVehicle } from 'shared/types/models';
import { MSAPIModel } from 'shared/types/marketScan';
import { useRecoilValueLoadable } from 'recoil';
import { msMakesAtom, msModelsAtom } from 'recoil/atoms';
import { YearSelect } from './YearSelect';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import Divider from '@mui/material/Divider';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

type UserVehicleFormProps = {
  vehicles: UserVehicle[];
  onChange(vehicles: UserVehicle[]): void;
};

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: theme.spacing(-2),
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

const vehicleSelectStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiBox-root': {
    minWidth: 180,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(-1),
      minWidth: 300,
    },
  },
});

const getEmptyVehicle = (): UserVehicle => {
  return {
    // temp unique ID that will be used only on the UI side
    id: -new Date().getTime(),
    market_scan_make_id: undefined,
    market_scan_model_id: undefined,
    year: undefined,
  };
};

export const UserVehicleForm: FC<UserVehicleFormProps> = ({
  vehicles,
  onChange,
}) => {
  const [showEmptySelectsOnMobile, setShowEmptySelectsOnMobile] =
    useState(false);

  const [userVehicles, setUserVehicles] = useState<UserVehicle[]>([
    ...vehicles.map((vehicle, index) => ({
      ...vehicle,
      // Make sure each vehicle has an id. For example when stored locally
      // vehicles don't get an id.
      id: vehicle.id ?? -index,
    })),
    getEmptyVehicle(),
  ]);

  const models = useRecoilValueLoadable(msModelsAtom).valueMaybe();

  const modelsByMakes = useMemo(() => {
    const modelsByMakesMap = new Map<number, MSAPIModel[]>();
    if (!models) {
      return modelsByMakesMap;
    }
    for (const model of models) {
      if (!modelsByMakesMap.has(model.MakeID)) {
        modelsByMakesMap.set(model.MakeID, []);
      }
      modelsByMakesMap.get(model.MakeID).push(model);
    }
    return modelsByMakesMap;
  }, [models]);

  const makes = useRecoilValueLoadable(msMakesAtom).valueMaybe();

  const removeUiId = (userVehicles: UserVehicle[]) => {
    return userVehicles.map((vehicle) => ({
      ...vehicle,
      id: vehicle.id < 0 ? undefined : vehicle.id,
    }));
  };

  const filterNonEmptyVehicles = (userVehicles: UserVehicle[]) => {
    return userVehicles.filter((vehicle) => isVehicleNonEmpty(vehicle));
  };

  const triggerOnChange = (userVehicles: UserVehicle[]) => {
    const nonEmptyVehicles = userVehicles.filter((vehicle) =>
      isVehicleFulfilled(vehicle),
    );
    onChange(removeUiId(nonEmptyVehicles));
  };

  const handleSelection = async (
    e,
    field: keyof UserVehicle,
    userVehicle: UserVehicle,
  ) => {
    const value = Number(e.target.value as string);
    const modifiedIndex = userVehicles.findIndex(
      (vehicle) => userVehicle.id === vehicle.id,
    );
    const modifiedVehicle = Object.assign({}, userVehicles[modifiedIndex]);

    switch (field) {
      case 'market_scan_model_id':
        modifiedVehicle.market_scan_model_id = value;
        modifiedVehicle.year = undefined;
        break;
      case 'market_scan_make_id':
        modifiedVehicle.market_scan_model_id = undefined;
        modifiedVehicle.year = undefined;
        modifiedVehicle.market_scan_make_id = value;
        break;
      case 'year':
        modifiedVehicle.year = value;
        break;
    }

    const clonedVehicles = [...userVehicles];
    clonedVehicles.splice(modifiedIndex, 1, modifiedVehicle);

    const allNonEmptyVehiclesFulfilled = filterNonEmptyVehicles(
      clonedVehicles,
    ).every((vehicle) => isVehicleFulfilled(vehicle));
    const allVehiclesFulfilled = clonedVehicles.every((vehicle) =>
      isVehicleFulfilled(vehicle),
    );
    if (allNonEmptyVehiclesFulfilled) {
      triggerOnChange(clonedVehicles);
    }
    if (allVehiclesFulfilled) {
      clonedVehicles.push(getEmptyVehicle());
      setShowEmptySelectsOnMobile(false);
    }

    setUserVehicles(clonedVehicles);
  };

  const isVehicleFulfilled = (vehicle: UserVehicle) => {
    return Boolean(
      vehicle.market_scan_make_id &&
        vehicle.market_scan_model_id &&
        vehicle.year,
    );
  };

  const isVehicleNonEmpty = (vehicle: UserVehicle) => {
    return Boolean(
      vehicle.market_scan_make_id ||
        vehicle.market_scan_model_id ||
        vehicle.year,
    );
  };

  const removeVehicle = (id: number) => {
    const updatedUserVehicles = userVehicles.filter(
      (vehicle) => vehicle.id !== id,
    );
    triggerOnChange(updatedUserVehicles);
    setUserVehicles(updatedUserVehicles);
  };

  const loaded = Boolean(makes) && Boolean(models);

  return (
    <Box mt={-2} mb={3}>
      <div className='vehicles'>
        {userVehicles &&
          userVehicles.map(
            (vehicle) =>
              (showEmptySelectsOnMobile || isVehicleFulfilled(vehicle)) && (
                <Row key={vehicle.id}>
                  {!loaded && (
                    <Box mt={3}>
                      <CircularProgress />
                    </Box>
                  )}
                  {loaded && (
                    <>
                      <Select
                        containerStyles={vehicleSelectStyles}
                        variant='outlined'
                        selectControlProps={{
                          fullWidth: true,
                        }}
                        placeholder='Select make'
                        value={vehicle.market_scan_make_id || 'Select make'}
                        name='make'
                        options={makes.map((m) => ({
                          label: m.Name.replace(/_/g, ' '),
                          value: m.ID,
                        }))}
                        onChange={(e) =>
                          handleSelection(e, 'market_scan_make_id', vehicle)
                        }
                      />
                      <Select
                        containerStyles={vehicleSelectStyles}
                        variant='outlined'
                        selectControlProps={{
                          fullWidth: true,
                        }}
                        placeholder='Select model'
                        disabled={!vehicle.market_scan_make_id}
                        value={vehicle.market_scan_model_id || 'Select model'}
                        name='model'
                        options={modelsByMakes
                          .get(vehicle.market_scan_make_id)
                          ?.map((m) => ({
                            label: m.Name.replace(/_/g, ' '),
                            value: m.ID,
                          }))}
                        onChange={(e) =>
                          handleSelection(e, 'market_scan_model_id', vehicle)
                        }
                      />
                      <YearSelect
                        makeId={vehicle.market_scan_make_id}
                        modelId={vehicle.market_scan_model_id}
                        disabled={!vehicle.market_scan_model_id}
                        selectedYear={
                          vehicle.year ? String(vehicle.year) : undefined
                        }
                        // containerClassName={classes.selectContainer}
                        onChange={(e) => handleSelection(e, 'year', vehicle)}
                      />
                      <Hidden smDown>
                        <Box
                          mt={3}
                          display='flex'
                          alignItems='center'
                          width={180}
                        >
                          {isVehicleFulfilled(vehicle) && (
                            <IconButton
                              onClick={() => removeVehicle(vehicle.id)}
                            >
                              <StyledFontAwesomeIconMui5
                                icon={faTrash}
                                size='xs'
                                color='inherit'
                                sx={{ color: 'inherit' }}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </Hidden>
                      <Hidden smUp>
                        <Box mt={3} mb={1} width='100%'>
                          {isVehicleFulfilled(vehicle) && (
                            <Box mb={2}>
                              <StyledButtonMui5
                                fullWidth
                                variant='contained'
                                onClick={() => removeVehicle(vehicle.id)}
                              >
                                Remove
                              </StyledButtonMui5>
                            </Box>
                          )}
                          <Divider />
                        </Box>
                      </Hidden>
                    </>
                  )}
                </Row>
              ),
          )}
        <Box mt={3}>
          <StyledButtonMui5
            fullWidth
            disabled={showEmptySelectsOnMobile}
            variant='contained'
            color='primary'
            onClick={() => setShowEmptySelectsOnMobile(true)}
          >
            Add vehicle
          </StyledButtonMui5>
        </Box>
      </div>
    </Box>
  );
};

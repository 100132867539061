import { FC } from 'react';
import Image from 'next/image';

type PoweredByStripeBadgeProps = {
  theme?: 'light' | 'dark';
};

export const PoweredByStripeBadge: FC<PoweredByStripeBadgeProps> = ({
  theme = 'light',
}) => {
  return (
    <div>
      <Image
        height='30'
        width='140'
        alt='Powered by Stripe'
        src={
          theme === 'dark'
            ? '/img/powered-by-stripe-light.svg'
            : '/img/powered-by-stripe-dark.svg'
        }
      />
    </div>
  );
};

import { useCallback, useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { userAtom } from 'recoil/atoms';
import { TaskGroupType, UserTaskType } from 'shared/types/misc';
import {
  ADD_COSIGNER_TASK_ID,
  ADD_CREDIT_CARD_TASK_ID,
  CREDIT_SCORE_VALIDITY_TASK_ID,
  SOFT_CREDIT_CHECK_TASK_ID,
} from 'shared/utils/constants';
import { userTaskGroupsSelectorFamily, userTasksSelector } from '../recoil';
import { isProfileCompleted } from 'shared/utils/tasks';

export const useTasks = ({
  pathnameQueryString = '',
}: {
  pathnameQueryString?: string;
}) => {
  const user = useRecoilValue(userAtom);
  const tasks = useRecoilValueLoadable(userTasksSelector);

  const taskGroups = useRecoilValueLoadable(
    userTaskGroupsSelectorFamily({
      pathnameQueryString,
    }),
  );

  const requiredIncompletedTasks = useMemo(
    () =>
      (tasks.state === 'hasValue' &&
        tasks.contents.filter((task) => task.required && !task.completed)) ||
      [],
    [tasks],
  );

  const requiredIncompletedTasksCount = requiredIncompletedTasks.length;

  const loading = tasks.state === 'loading' || taskGroups.state === 'loading';

  const getTaskPagePathname = useCallback(
    (task: UserTaskType, returnTo?: string) => {
      if (!task) {
        return '/profile';
      }

      let pathname: string;

      switch (task.id) {
        case SOFT_CREDIT_CHECK_TASK_ID:
          pathname = isProfileCompleted(user)
            ? '/profile/get-pre-qualified'
            : '/profile/personal-information';
          break;
        case CREDIT_SCORE_VALIDITY_TASK_ID:
          pathname = isProfileCompleted(user)
            ? '/profile/get-pre-qualified'
            : '/profile/personal-information';
          break;
        case ADD_CREDIT_CARD_TASK_ID:
          pathname = '/profile/payment-information';
          break;
        case ADD_COSIGNER_TASK_ID:
          pathname = '/profile/co-signer';
          break;
        default:
          pathname = '/profile';
      }

      return pathname + (returnTo ? `?returnTo=${returnTo}` : '');
    },
    [user],
  );

  const getRequiredIncompletedTasksByGroupTitle = useCallback(
    (groupTitle: TaskGroupType['name']) => {
      const group = (taskGroups.valueMaybe() || []).find(
        (group) => group.title === groupTitle,
      );

      if (!group) {
        return [];
      }

      return requiredIncompletedTasks.filter((task) => {
        return group.tasks.find((groupTask) => groupTask.id === task.id);
      });
    },
    [requiredIncompletedTasks, taskGroups],
  );

  const getTasksToUnlockBidding = useCallback(() => {
    const beforeBidTasks =
      getRequiredIncompletedTasksByGroupTitle('Before you can bid');

    const addCosignerTasks = getRequiredIncompletedTasksByGroupTitle(
      'Challenged credit or income?',
    );

    return [...beforeBidTasks, ...addCosignerTasks];
  }, [getRequiredIncompletedTasksByGroupTitle]);

  return {
    getTaskPagePathname,
    getRequiredIncompletedTasksByGroupTitle,
    getTasksToUnlockBidding,
    tasks: tasks.valueMaybe() || [],
    taskGroups: taskGroups.valueMaybe() || [],
    requiredIncompletedTasks,
    requiredIncompletedTasksCount,
    loading,
  };
};

import { useRecoilState } from 'recoil';
import { incomeCheckDialogAtom } from './recoil/atoms';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FieldText from './FieldText';
import { Form } from 'react-final-form';
import { FC, useCallback, useState } from 'react';
import Alert from '@mui/material/Alert';
import ProgressButton from 'components/atoms/ProgressButton';
import InputAdornment from '@mui/material/InputAdornment';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { FORM_ERROR } from 'final-form';
import { GetCreditScoreQuery } from 'shared/types/api/getCreditScore';
import { User } from 'shared/types/models';
import Rest from 'services/rest';
import userIncomes from 'shared/config/userIncomes';
import FieldIncome from './FieldIncome';
import { UpdateUserProfileRequest } from 'shared/types/api/updateUserProfile';
import head from 'lodash/head';
import { userAtom } from 'recoil/atoms';
import { CreditCheckLegalText } from 'components/atoms/CreditCheckLegalText';
import { useScreenSize } from 'hooks/useScreenSize';
import { styled } from '@mui/material/styles';

const rest = new Rest();

type Values = {
  ssn: string;
  income: number;
  creditCheckAgreement: boolean;
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
  &.MuiDialogContent-root {
    padding: ${theme.spacing(0, 3, 3)};
  }`,
);

export const IncomeCheckDialog: FC = () => {
  const [incomeCheckDialog, openIncomeCheckDialog] = useRecoilState(
    incomeCheckDialogAtom,
  );
  const [user, setUser] = useRecoilState(userAtom);
  const [showIncomeDropdown, setShowIncomeDropdown] = useState(false);
  const { isExtraSmallScreen } = useScreenSize();

  const closeDialog = useCallback(
    () => openIncomeCheckDialog(false),
    [openIncomeCheckDialog],
  );

  const handleSubmit = useCallback(
    async (values: Values) => {
      const updatedProfile: UpdateUserProfileRequest = {
        is_income_verified: !showIncomeDropdown,
      };

      if (!showIncomeDropdown) {
        const verifiedIncome = await getIncome(values.ssn, user);
        if (!verifiedIncome) {
          return;
        }
        updatedProfile.income = verifiedIncome.value;
      } else {
        updatedProfile.income = values.income;
      }
      try {
        const updatedUser = await rest.updateUserProfile(updatedProfile);
        setUser((prevUser) => ({
          ...prevUser,
          ...updatedUser,
        }));
        closeDialog();
      } catch (e) {
        const error: { msg: string } = e.errors && head(e.errors);
        return { [FORM_ERROR]: error.msg };
      }
    },
    [showIncomeDropdown, user, closeDialog, setUser],
  );

  const getIncome = async (ssn: string, user: User) => {
    const query: GetCreditScoreQuery = {
      zip: user.zipcode,
      address: user.address,
      name: `${user.first_name} ${user.last_name}`,
      ssn,
    };
    let verifiedIncome: number;

    try {
      const res = await rest.getCreditScore(query);
      verifiedIncome = res.income;
    } catch {
      setShowIncomeDropdown(true);
    }

    if (!verifiedIncome) {
      setShowIncomeDropdown(true);
      return undefined;
    }

    return userIncomes.find((income) => income.value > verifiedIncome);
  };

  if (!incomeCheckDialog) {
    return null;
  }

  return (
    <Dialog
      open={incomeCheckDialog}
      onClose={closeDialog}
      keepMounted={false}
      fullScreen={isExtraSmallScreen}
    >
      <DialogTitle>Income check</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, submitError, form }) => (
          <form autoComplete='off' onSubmit={handleSubmit} noValidate>
            <StyledDialogContent>
              {submitError && (
                <Alert severity='error' variant='standard'>
                  {submitError}
                </Alert>
              )}
              <Box mb={2}>
                <Typography variant='body2'>
                  An income pull won&apos;t impact your credit score.
                </Typography>
              </Box>
              {!showIncomeDropdown && (
                <>
                  <FieldText
                    inputProps={{
                      maxLength: 9,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <StyledFontAwesomeIconMui5
                            icon={faLock}
                            size='sm'
                            color='inherit'
                            sx={{ color: 'inherit' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    type='password'
                    required
                    helperText='Please enter your full Social Security Number'
                    name='ssn'
                    label='SSN'
                  />
                  <Typography variant='caption' gutterBottom>
                    <CreditCheckLegalText buttonText='Income check' />
                  </Typography>
                </>
              )}
              {showIncomeDropdown && (
                <FieldIncome name='income' label='Income' />
              )}
              <DialogActions>
                <Tooltip
                  placement='top'
                  title={
                    form.getState().values.creditCheckAgreement
                      ? ''
                      : 'Please agree to the terms and conditions'
                  }
                >
                  <div>
                    <ProgressButton
                      disabled={!form.getState().values.creditCheckAgreement}
                      color='primary'
                      type='submit'
                      onClick={handleSubmit}
                      loading={submitting}
                    >
                      {showIncomeDropdown ? 'Save' : 'Income check'}
                    </ProgressButton>
                  </div>
                </Tooltip>
              </DialogActions>
            </StyledDialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};

import { GetProgramResponse } from '../types/api/getProgram';
import { MSISRebate, MSISRecipientType } from '../types/marketScan';

type Props = {
  auctionRebates: MSISRebate[];
  program: GetProgramResponse;
};

/*
 * Returns the list of rebates that are applied to the auction including custom rebates.
 *
 * @param {Auction} auction - The auction to get applied rebates for.
 * @param {GetProgramResponse} program - The program to get applied rebates for.
 * @returns {MSISRebate[]} - The list of applied rebates.
 */
export const getAuctionAppliedRebates = ({
  auctionRebates,
  program,
}: Props): MSISRebate[] => {
  if (!auctionRebates || !program || !program.AppliedRebate) {
    return [];
  }

  return program.AppliedRebate.filter(
    (appliedRebate) => appliedRebate.Recipient !== MSISRecipientType.Dealer,
  )
    .map((appliedRebate) => {
      const auctionRebate = auctionRebates.find(
        (rebate) => rebate.ID === appliedRebate.ID,
      );

      if (!auctionRebate) {
        return null;
      }

      return {
        ...auctionRebate,
        Value: {
          ...auctionRebate.Value,
          Values: [
            {
              Value: appliedRebate.Value,
              Type: auctionRebate.ValueType,
            },
          ],
        },
      };
    })
    .filter(Boolean);
};

import { bookACallOpenEvent } from '../services/gtag';
import { useCallback } from 'react';

export const useGTagTrackBookACallPopup = () => {
  const gtagTrackBookACallOpen = useCallback(() => {
    if (process.env.USE_GTAG) {
      bookACallOpenEvent();
    }
  }, []);

  return { gtagTrackBookACallOpen };
};

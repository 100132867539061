import { styled } from '@mui/material/styles';
import { lightThemeMui5 } from 'theme';

export const UList = styled('table')(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  borderCollapse: 'collapse',
}));

export const UListBody = styled('tbody')(() => ({}));

export const UListItemMain = styled('tr')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: lightThemeMui5.palette.primary.main,
}));

export const UListItem = styled('tr')(({ theme }) => ({
  '& td': {
    fontSize: theme.typography.caption.fontSize,
  },
  '& td:first-of-type::before': {
    paddingRight: theme.spacing(1),
  },
  '& td:first-of-type': {
    paddingLeft: theme.spacing(1),
  },
  '& td:last-of-type': {
    color: theme.palette.grey[900],
  },
}));

export const UListKey = styled('td')(({ theme }) => ({
  width: 168,
  padding: theme.spacing(0.5, 0.5, 0.5, 0),
  color: theme.palette.grey[700],
}));

export const UListValue = styled('td')(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'right',
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.grey[900],
}));

import { FC, Suspense } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableHead from '@mui/material/TableHead';
import AuctionSummary from '../AuctionSummary';
import Box from '@mui/material/Box';
import { RecoilLoadable, useRecoilState, useRecoilValue } from 'recoil';
import { Bid as CurrentBid } from 'containers/bids/components/Table/Bid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { preventMultipleWinningBidDialogAtom } from '../../recoil/atoms';
import { StyledButtonMui5 } from '../atoms/StyledButton';
import LinkMui5 from '../atoms/Link';
import NextLinkMui5 from '../atoms/NextLink';
import { StyledFontAwesomeIconMui5 } from '../atoms/FontAwesomeIcon';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { getUserWinningBid } from '../../recoil/selectors';
import { useScreenSize } from 'hooks/useScreenSize';
import { Theme as ThemeMui5 } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useRouter } from 'next/router';
import Grid from '@mui/material/Grid';
import { SUPPORT_EMAIL } from 'shared/utils/constants';

type Props = {
  onAuctionLinkClick?: () => void;
};

const contactSupportButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    marginRight: theme.spacing(1),
    height: '100%',
  },
});

const WinningBidTable: FC<Props> = ({ onAuctionLinkClick }) => {
  const winningBid = useRecoilValue(getUserWinningBid);
  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();

  if (!winningBid) {
    return null;
  }

  const program = RecoilLoadable.of(winningBid?.min_program);

  if (isSmallScreen || isExtraSmallScreen) {
    return (
      <Grid container spacing={0.5} pt={2} pb={2}>
        <Grid item xs={12} textAlign={'center'}>
          <Typography>AUCTION</Typography>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <AuctionSummary
            onAuctionLinkClick={onAuctionLinkClick}
            image={winningBid.auction.offer.images[0]['@href']}
            auctionId={winningBid.auction.id}
            make={winningBid.auction.offer.make.name}
            model={winningBid.auction.offer.model.name}
            trim={winningBid.auction.offer.trim.name}
            year={winningBid.auction.offer.year}
            msrp={winningBid.auction.msrp}
          />
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <Typography>CURRENT BID</Typography>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          {program.valueMaybe() && <CurrentBid program={program} item={null} />}
        </Grid>
      </Grid>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align='center' variant='head'>
            AUCTION
          </TableCell>
          <TableCell align='center' variant='head'>
            CURRENT BID
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell align='left'>
            <AuctionSummary
              onAuctionLinkClick={onAuctionLinkClick}
              image={winningBid.auction.offer.images[0]['@href']}
              auctionId={winningBid.auction.id}
              make={winningBid.auction.offer.make.name}
              model={winningBid.auction.offer.model.name}
              trim={winningBid.auction.offer.trim.name}
              year={winningBid.auction.offer.year}
              msrp={winningBid.auction.msrp}
            />
          </TableCell>
          {program.valueMaybe() && (
            <TableCell align='center'>
              <CurrentBid program={program} item={null} />
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const Loader: FC = () => {
  return (
    <Box>
      <StyledFontAwesomeIconMui5
        icon={faSpinner}
        className={'fa-spin'}
        size='3x'
        color='inherit'
        sx={{ color: 'inherit' }}
      />
    </Box>
  );
};

export const PreventMultipleWinningBidDialog: FC = () => {
  const { isExtraSmallScreen } = useScreenSize();
  const router = useRouter();
  const [{ open }, setValues] = useRecoilState(
    preventMultipleWinningBidDialogAtom,
  );

  const handleClose = () => {
    setValues(() => ({
      open: false,
    }));
  };

  if (!open || ['/terms'].includes(router.pathname)) {
    if (open) handleClose();
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isExtraSmallScreen}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='dialog-title'>
        <Typography variant='h5' display='inline' component='span'>
          You cannot bid on this auction at this time as you are currently
          winning another auction.
        </Typography>
        <DialogContent>
          <Box mt={-1} ml={-3} mr={-3}>
            <Typography variant='body1' color='textPrimary'>
              <LinkMui5 component={NextLinkMui5} href='/terms'>
                See Terms & Conditions
              </LinkMui5>
            </Typography>
            <Suspense fallback={<Loader />}>
              <WinningBidTable onAuctionLinkClick={handleClose} />
            </Suspense>
          </Box>
        </DialogContent>
        <DialogActions disableSpacing>
          <Box m={-1}>
            <StyledButtonMui5
              sx={contactSupportButtonStyles}
              variant='text'
              color='primary'
              component={NextLinkMui5}
              href={`mailto:${SUPPORT_EMAIL}`}
            >
              Contact Support
            </StyledButtonMui5>
            <StyledButtonMui5
              className='js-close-one-bid-at-once-dialog'
              onClick={handleClose}
              variant='contained'
              color='primary'
              autoFocus
            >
              OK
            </StyledButtonMui5>
          </Box>
        </DialogActions>
      </DialogTitle>
    </Dialog>
  );
};

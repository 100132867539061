import { atom } from 'recoil';

export const incomeCheckDialogAtom = atom({
  key: 'INCOME_CHECK_DIALOG',
  default: false,
});

export const softCreditCheckDialogAtom = atom({
  key: 'SOFT_CREDIT_CHECK_DIALOG',
  default: false,
});

export const guaranteeDepositDialogAtom = atom({
  key: 'GUARANTEE_DEPOSIT_DIALOG',
  default: false,
});

import { ChangeEvent, FC } from 'react';
import { SelectProps as MUISelectProps } from '@mui/material/Select';
import { InputLabelProps as MUIInputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import {
  SelectControl,
  SelectField,
  SelectLabel,
  SelectOption,
} from '../atoms/Select';
import { SelectOptionType } from 'components/atoms/Select/SelectOption';
import { SelectControlProps } from 'components/atoms/Select/SelectControl';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

type CustomSelectProps = {
  selectControlProps?: SelectControlProps;
  selectLabelProps?: MUIInputLabelProps;
  options: SelectOptionType[];
  placeholder: string;
  onValueChange?: (event: ChangeEvent) => void;
  containerStyles?: SxProps<ThemeMui5>;
  error?: string;
};

export type SelectProps = CustomSelectProps & MUISelectProps;

const Select: FC<SelectProps> = ({
  selectControlProps = {},
  selectLabelProps = {},
  options = [],
  placeholder = '',
  label,
  error = '',
  containerStyles = () => ({}),
  ...restSelectProps
}): JSX.Element => {
  return (
    <Box mt={label ? 3 : 0} sx={containerStyles}>
      <SelectControl {...selectControlProps}>
        <SelectLabel {...selectLabelProps} error={Boolean(error)}>
          {label}
        </SelectLabel>
        <SelectField {...restSelectProps} error={Boolean(error)}>
          {placeholder && (
            <SelectOption
              value={placeholder}
              disabled
              className='MuiListItem-placeholder'
            >
              {placeholder}
            </SelectOption>
          )}
          {options.map(({ value, label, subLabel, ...restOptionProps }) => (
            <SelectOption key={value} value={value} {...restOptionProps}>
              {label}
              <br />
              {subLabel}
            </SelectOption>
          ))}
        </SelectField>
        {error && (
          <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
        )}
      </SelectControl>
    </Box>
  );
};

export default Select;

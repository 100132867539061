import useInterval from '@use-it/interval';
import { FC, useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { DEFAULT_TIME_TICK } from '../../data/constants';
import { timeAtom } from '../../recoil/atoms';

/**
 * `TimeTick` component updates `timeAtom` each `DEFAULT_TIMER_TICK` period and for each `window.FOCUS` event.
 *
 * Usage ⚠️WARNING⚠️: use this component only ONCE for all APP. Do not use inside multiple components.
 *
 */
export const TimeTick: FC = () => {
  const setTime = useSetRecoilState(timeAtom);

  useInterval(
    () => setTime(new Date().getTime()),
    // Guard time from very low update interval
    DEFAULT_TIME_TICK,
  );

  const forceUpdateTime = useCallback(
    () => setTime(new Date().getTime()),
    [setTime],
  );

  //Force update Time on window FOCUS event
  useEffect(() => {
    window.addEventListener('focus', forceUpdateTime);
    return () => {
      window.removeEventListener('focus', forceUpdateTime);
    };
  });

  return null;
};

import { Breakpoint, useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

function useWidth(): Breakpoint {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();

  return keys.reduce<Breakpoint>((output, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(theme.breakpoints.up(key));
    return !output && matches ? key : output;
  }, null);
}

export default useWidth;

import { Redirect } from 'next';
import { GetAuctionsRequest } from 'shared/types/api/getAuctions';
import { DueAtSigningType } from '../shared/types/api/getProgram';
import { SortType } from '../shared/types/endpoints';
import { AuctionStatus } from '../shared/types/models';
import { DISPLAY_CONSUMER_INCENTIVES_TAB } from '../shared/utils/constants';

export type {
  RebateCategoryAnswer,
  RebateCategoryQuestion,
} from '../shared/types/common';
export {
  defaultRebatesCategoriesAnswers,
  rebatesCategoriesQuestions,
} from '../shared/utils/functions';

export const NO_INCENTIVES_DIALOG_KEY = 'dont-show-no-incentives-dialog';
export const MIN_AUCTION_DISCOUNT = -25;
export const MAX_AUCTION_DISCOUNT = 25;
export const DEFAULT_DOWN_PAYMENT = 5000;

export const DEALER_PROFILE_TABS = ['Dealer Information', 'Security'];

export const getConsumerProfileTabs = () => {
  return [
    'Personal Information',
    'Get Pre-qualified',
    // 'Payment Information',
    'Tasks',
    'Security',
    'Co-signer',
    DISPLAY_CONSUMER_INCENTIVES_TAB && 'Incentives',
    // 'LB Referral Promo',
  ].filter(Boolean) as unknown as string[];
};

export const ADMIN_PROFILE_TABS = ['Personal Information', 'Security'];

export const GENERIC_CAR_OPTIONS = [
  'GENERIC TRANSMISSION TYPE',
  'INTERIOR COLOR',
  'GENERIC EXTERIOR COLOR',
];

export const DEFAULT_TIME_TICK = 1000;

export const getRedirectTo404 = (returnTo: string): { redirect: Redirect } => ({
  redirect: {
    destination: `/404?returnTo=${encodeURIComponent(returnTo)}`,
    permanent: false,
  },
});

export const getRedirectToLogin = (
  returnTo: string,
): { redirect: Redirect } => {
  return {
    redirect: {
      destination: `/login?returnTo=${encodeURIComponent(returnTo)}`,
      permanent: false,
    },
  };
};

export enum PageTransitionEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export const NAVBAR_Z_INDEX = 1300;
export const BASE_STICKY_Z_INDEX = 12;
export const BASE_TABLE_HEADER_AFTER_STYLES = `
pointer-events: none;
content: "";
display: block;
position: absolute;
top: 2px;
left: 0;
width: 100%;
height: 100%;
border-bottom: 2px solid transparent;
border-image: linear-gradient(
    90deg, 
    transparent 0%, 
    transparent 2px, 
    #223559 2px, 
    #223559 calc(100% - 2px), 
    transparent calc(100% - 2px),
    transparent 100%
  ) 1 stretch;`;

export const getBestDealsRequestParams = (
  userZipcode?: string,
  perPage = 6,
): GetAuctionsRequest => ({
  statuses: [AuctionStatus.Active],
  perPage,
  offset: 0,
  sortType: SortType.BestDeal,
  ...(userZipcode ? { zipcode: userZipcode } : {}),
});

export const PLACEHOLDER_BACKGROUND = '#dbdbdb';
export const PLACEHOLDER_FOREGROUND = '#ecebeb';

export const DueAtSigningTypeLabels: Record<DueAtSigningType, string> = {
  [DueAtSigningType.ZERO_SIGNING]: 'Zero Signing',
  [DueAtSigningType.ONLY_FIRST_MONTHLY_PAYMENT]: 'First monthly payment only',
  [DueAtSigningType.FIRST_MONTHLY_PLUS_ALL_FEES]:
    'First monthly payment + All fees (excluding Sales Tax)',
  [DueAtSigningType.FIRST_MONTHLY_ALL_FEES_SALES_TAX]:
    'First monthly payment + All fees + Sales Tax',
  [DueAtSigningType.CUSTOM_INPUT]: 'Custom Due At Signing amount:',
  [DueAtSigningType.ZERO_SIGNING_DOWNPAYMENT_ONLY]: 'Downpayment only',
};

export const EMAIL_CONFIRMATION_TITLE = 'Confirm email to login';
export const EMAIL_CONFIRMATION_NOTE =
  'Please enter the code that was sent to your email address';
export const EMAIL_CONFIRMATION_SPAN_NOTICE =
  'Please check your Spam folder if you don’t receive your code right away.';

declare global {
  interface Window {
    _dixa;
  }
}

export const openDixaConversation = () => {
  window?._dixa('api.setView', 'conversation');
};

export const showDixaWidget = () => {
  window?._dixa &&
    window._dixa(
      'api.showWidget',
      JSON.stringify({ show_widget: true, show_contact_form: false }),
    );
};

export const hideDixaWidget = () => {
  window?._dixa &&
    window._dixa(
      'api.showWidget',
      JSON.stringify({ show_widget: false, show_contact_form: false }),
    );
};

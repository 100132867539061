// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: true,
  environment: process.env.ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://72c4b6e34fa740579fbc8f944b0598ee@o458036.ingest.sentry.io/5454909',
  tracesSampleRate: 1.0,
});

import { Bid, BidStatus } from '../types/models';

const ACTIVE_BID_STATUSES = Object.freeze([
  BidStatus.Active,
  BidStatus.UserAccepted,
  BidStatus.CosignerNeeded,
  BidStatus.UserConfirmationNeeded,
  BidStatus.DealerApproved,
]);

export const getActiveBids = (bids: Bid[]): Bid[] => {
  const activeBids = bids.filter((bid) =>
    ACTIVE_BID_STATUSES.includes(bid.status),
  );

  if (activeBids.length > 0 && activeBids[0]?.min_program?.Price) {
    activeBids.sort((bidA, bidB) => {
      return bidB.min_program.Price - bidA.min_program.Price;
    });
  }

  return activeBids;
};

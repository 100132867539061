import { v4 as uuid } from 'uuid';
import { API_HOST, LOCAL_STORAGE_KEY } from './const';

import { GrowthBook } from '@growthbook/growthbook-react';
import { experimentViewedEvent } from 'services/gtag';
import { IncomingMessage, ServerResponse } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

export const growthbook = new GrowthBook({
  apiHost: API_HOST,
  clientKey: process.env.GROWTHBOOK_KEY,
  enableDevMode: process.env.GROWTHBOOK_DEV_MODE,
  trackingCallback: (experiment, result) => {
    if (process.env.USE_GTAG && typeof window !== 'undefined') {
      experimentViewedEvent(experiment.key, result.variationId);
    }
  },
});

export const getGrowthBookContext = (
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  },
  res: ServerResponse,
  userId?: number,
) => {
  let deviceId = req.cookies[LOCAL_STORAGE_KEY];

  if (!deviceId) {
    deviceId = uuid();
    res.setHeader('Set-Cookie', `${LOCAL_STORAGE_KEY}=${deviceId}; Path=/`);
  }

  return {
    apiHost: API_HOST,
    clientKey: process.env.GROWTHBOOK_KEY,
    attributes: {
      id: userId ?? null,
      deviceId,
    },
  };
};

import { Fragment } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { ButtonProps as ButtonPropsMui5 } from '@mui/material/Button';
import { styled as styledMui5 } from '@mui/material/styles';
import { SxProps } from '@mui/system';

const ButtonProgressMui5 = styledMui5(CircularProgress)(
  ({ theme }) => `
  &.MuiCircularProgress-root {
    height: fit-content!important;
    color: ${theme.palette.common.white};
    position: absolute;
    margin-top: -12px;
    margin-bottom: -12px;
  }
`,
);

interface Props extends ButtonPropsMui5 {
  loading: boolean;
  wrapperSx?: SxProps;
  wrapperClassName?: string;
  children: React.ReactNode;
  disabled?: boolean;
}

const WrapperDiv = styledMui5('div', { skipSx: false })<{
  isFullWidth: boolean;
}>(
  ({ isFullWidth }) => `
  position: relative;
  display: inline-block;
  width: ${isFullWidth ? '100%' : 'auto'}
`,
);

export default function ProgressButton({
  loading,
  disabled,
  wrapperSx,
  wrapperClassName,
  fullWidth,
  ...rest
}: Props): JSX.Element {
  return (
    <WrapperDiv
      isFullWidth={fullWidth}
      className={`${wrapperClassName}`}
      sx={wrapperSx}
    >
      <StyledButtonMui5
        disabled={loading || disabled}
        fullWidth={fullWidth}
        {...rest}
      >
        <Fragment>
          {rest.children}
          {loading && <ButtonProgressMui5 size={24} />}
        </Fragment>
      </StyledButtonMui5>
    </WrapperDiv>
  );
}

declare global {
  interface Window {
    hj: any;
  }
}

export const hotjarTrackMobileExitIntent = (): void => {
  if (window.hj) {
    window.hj('event', 'MobileExitIntent');
  }
};

import { MSISRebate } from '../types/marketScan';
import { Auction } from '../types/models';
import { getCustomRebates } from './custom/getCustomRebates';
import { rebatesFilter } from './rebatesFilter';

type GetAuctionCustomRebatesOverwriteSettings = {
  zipcode: string;
};

export function getAuctionCustomRebates(
  auction: Auction,
  overwrite?: GetAuctionCustomRebatesOverwriteSettings,
): MSISRebate[] {
  return getCustomRebates({
    date: new Date().toISOString(),
    make: auction.offer.make.name,
    settings: {
      condition: auction.offer.condition,
      mileage: auction.offer.mileage,
      model: auction.offer.model.name,
      style: auction.offer.style?.name,
      trim: auction.offer.trim?.name,
      state: auction.offer.state,
      year: auction.offer.year,
      zipcode: overwrite?.zipcode || auction.offer.zip[0],
    },
  });
}

/*
 * This function returns all rebates for the auction.
 * It also includes custom rebates that is not part of the auction rebates we are getting from MarketScan.
 * Custom rebates are calculated based on the auction settings.
 *
 * @param {Auction} auction - The auction to get rebates for.
 * @returns {MSISRebate[]} - The list of rebates.
 *
 */
export const getAuctionRebates = (auction: Auction): MSISRebate[] => {
  if (!auction) {
    return [];
  }

  const rebates = auction.rebates_data.Rebates?.filter(rebatesFilter) || [];

  const customRebates = getAuctionCustomRebates(auction);

  return rebates.concat(customRebates);
};

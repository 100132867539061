declare global {
  interface Window {
    Cookiebot;
  }
}

const COOKIEBOT_ELEMENT_ID = 'CookiebotWidget';

export const hideCookiebotWidget = (): void => {
  const CookiebotWidget = document.getElementById(COOKIEBOT_ELEMENT_ID);
  if (CookiebotWidget) {
    CookiebotWidget.style.display = 'none';
  }
};

export const showCookiebotWidget = (): void => {
  const CookiebotWidget = document.getElementById(COOKIEBOT_ELEMENT_ID);
  if (CookiebotWidget) {
    CookiebotWidget.style.display = 'block';
  }
};

import { ElementType, FC, ReactElement } from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import NextLinkMui5 from '../atoms/NextLink';
import { useScreenSize } from 'hooks/useScreenSize';
import { styled as styledMui5 } from '@mui/material/styles';
import LinkMui5 from '../atoms/Link';
import TypographyMui5 from '@mui/material/Typography';
import BreadcrumbsMui5 from '@mui/material/Breadcrumbs';
import { StyledFontAwesomeIconMui5 } from '../atoms/FontAwesomeIcon';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/system/createTheme';

export type BreadcrumbItem = {
  component?: ElementType;
  href?: string;
  name: string;
};

type BreadcrumbsProps = {
  items: BreadcrumbItem[];
  className?: string;
  sxProps?: SxProps<Theme>;
};

const StyledBreadcrumbs = styledMui5(BreadcrumbsMui5, {
  skipSx: false,
  shouldForwardProp(propName) {
    return propName !== 'isCompact';
  },
})<{
  isCompact: boolean;
}>(
  ({ theme, isCompact }) => `
  &.MuiBreadcrumbs-root {
    text-transform: uppercase;
    margin: ${isCompact ? theme.spacing(2, 3) : theme.spacing(2, 0)};
  }
  & .MuiBreadcrumbs-li {
    cursor: pointer;
    &:last-of-type {
      cursor: initial;
      & * {
        text-decoration: none;
        color: ${theme.palette.primary.main};
        font-size: 14px;
      }
    }
  }
  & .MuiBreadcrumbs-separator {
    margin: ${theme.spacing(0, 1)};
  }
`,
);

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  className,
  sxProps,
}): ReactElement => {
  const { isExtraSmallScreen } = useScreenSize();

  return (
    <StyledBreadcrumbs
      isCompact={isExtraSmallScreen}
      className={className}
      sx={sxProps}
      separator={<StyledFontAwesomeIconMui5 icon={faChevronRight} size='xs' />}
    >
      {items.map(({ href, name, component = NextLinkMui5 }, index) => {
        return index !== items.length - 1 ? (
          <LinkMui5
            key={name}
            component={component}
            href={href}
            sx={(theme) => ({
              color: theme.palette.grey[700],
              fontSize: theme.typography.fontSize,
            })}
          >
            {name}
          </LinkMui5>
        ) : (
          <TypographyMui5 key={name}>{name}</TypographyMui5>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;

import { PaymentIntent, SetupIntent } from '@stripe/stripe-js';
import { LogRocketRecordingURL } from 'hooks/useLogRocket';
import { ENVIRONMENT } from 'shared/config';
import {
  AddUserRebateRequest,
  AddUserRebateResponse,
} from 'shared/types/api/addUserRebate';
import {
  CacheAuctionProgramPaymentRequest,
  CacheAuctionProgramPaymentResponse,
} from 'shared/types/api/cacheAuctionProgramPayment';
import { CancelPaymentIntentResponse } from 'shared/types/api/cancelPaymentIntent';
import { CapturePaymentIntentResponse } from 'shared/types/api/capturePaymentIntent';
import {
  CreateBidRequestBody,
  CreateBidResponseBody,
} from 'shared/types/api/createBid';
import {
  CreateConsumerRequest,
  CreateConsumerResponse,
} from 'shared/types/api/createConsumer';
import {
  CreateCosignerRequest,
  CreateCosignerResponse,
} from 'shared/types/api/createCosigner';
import { CreateDataDealershipResponse } from 'shared/types/api/createDataDealership';
import { DeleteDataDealershipResponse } from 'shared/types/api/deleteDataDealership';
import { GetAllDataDealershipsResponse } from 'shared/types/api/getAllDataDealerships';
import { GetAuctionIdsUserIsWatchingResponse } from 'shared/types/api/getAuctionIdsUserIsWatching';
import { GetAuctionPaymentAnalyticsRequest } from 'shared/types/api/getAuctionPaymentAnalytics';
import { GetAuctionPresenceResponse } from 'shared/types/api/getAuctionPresence';
import {
  GetAuctionsRequest,
  GetAuctionsResponse,
} from 'shared/types/api/getAuctions';
import { GetAuctionVisitsResponse } from 'shared/types/api/getAuctionVisits';
import { GetAuctionVisitsCountResponse } from 'shared/types/api/getAuctionVisitsCount';
import { GetAuctionWatchersCountResponse } from 'shared/types/api/getAuctionWatchersCount';
import {
  GetCachedAuctionsQuery,
  GetCachedAuctionsResponse,
} from 'shared/types/api/getCachedAuctions';
import { GetCachedPaymentsResponse } from 'shared/types/api/getCachedPayments';
import {
  GetCityByZipcodeParams,
  GetCityByZipcodeResponse,
} from 'shared/types/api/getCityByZipcode';
import {
  GetConsumerClicksReportQuery,
  GetConsumerClicksReportResponse,
} from 'shared/types/api/GetConsumerClicksReport';
import {
  GetConsumerDocumentsParams,
  GetConsumerDocumentsResponse,
} from 'shared/types/api/getConsumerDocuments';
import {
  GetCosignerDocumentsParams,
  GetCosignerDocumentsResponse,
} from 'shared/types/api/getCosignerDocuments';
import {
  GetCreditScoreQuery,
  GetCreditScoreResponse,
} from 'shared/types/api/getCreditScore';
import {
  GetDashboardAuctionsQuery,
  GetDashboardAuctionsResponse,
} from 'shared/types/api/getDashboardAuctions';
import { GetDataDealershipResponse } from 'shared/types/api/getDataDealership';
import {
  GetDealerClicksReportQuery,
  GetDealerClicksReportResponse,
} from 'shared/types/api/GetDealerClicksReport';
import { GetMigrationsResponse } from 'shared/types/api/getMigrations';
import {
  GetModelsByMakeParams,
  GetModelsByMakeResponse,
} from 'shared/types/api/getModelsByMake';
import { GetModelTrimsResponse } from 'shared/types/api/getModelTrims';
import {
  GetNearbyZipcodeParams,
  GetNearbyZipcodeResponse,
} from 'shared/types/api/getNearbyZips';
import {
  GetOrCreateVerificationSessionRequest,
  GetOrCreateVerificationSessionResponse,
} from 'shared/types/api/getOrCreateVerificationSession';
import {
  GetPopularMakes,
  GetPopularMakesResponse,
} from 'shared/types/api/getPopularMakes';
import {
  GetProgramRequest,
  GetProgramResponse,
} from 'shared/types/api/getProgram';
import {
  GetQuestionsRequest,
  GetQuestionsResponse,
} from 'shared/types/api/getQuestions';
import {
  GetRebateBriefDescriptionRequest,
  GetRebateBriefDescriptionResponse,
} from 'shared/types/api/getRebateBriefDescription';
import {
  GetRebatesParamsRequest,
  GetRebatesParamsResponse,
} from 'shared/types/api/getRebatesParams';
import {
  GetRebatesQuestionsRequest,
  GetRebatesQuestionsResponse,
} from 'shared/types/api/getRebatesQuestions';
import { GetUserResponse } from 'shared/types/api/getUser';
import { GetUsersQuery, GetUsersResponse } from 'shared/types/api/getUsers';
import { GetYearsResponse } from 'shared/types/api/getYears';
import { PopulateMSVehiclesResponse } from 'shared/types/api/populateMSVehicles';
import { RefundPaymentIntentResponse } from 'shared/types/api/refundPaymentIntent';
import {
  RemoveUserRebateRequest,
  RemoveUserRebateResponse,
} from 'shared/types/api/removeUserRebate';
import { StreamConsumerDocumentParams } from 'shared/types/api/streamConsumerDocument';
import { StreamCosignerDocumentParams } from 'shared/types/api/streamCosignerDocument';
import {
  UpdateBidRequestBody,
  UpdateBidResponseBody,
} from 'shared/types/api/updateBid';
import {
  UpdateCosignerRequest,
  UpdateCosignerResponse,
} from 'shared/types/api/updateCosigner';
import { UpdateDataDealershipResponse } from 'shared/types/api/updateDataDealership';
import {
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
} from 'shared/types/api/updateUserProfile';
import {
  UpdateUserRebateResponseBody,
  UpdateUserRebatesRequestBody,
} from 'shared/types/api/updateUserRebates';
import { CreateAuctionFormValues } from 'shared/types/createAuctionFormValues';
import {
  BulkVinData,
  CreateUser,
  DealerVehicle,
  GetAuctionInitialValues,
  GetAuctionResponse,
  RequestVehicle,
  Stats,
  UpdateAuction,
  VinData,
} from 'shared/types/endpoints';
import {
  AuctionBid,
  Cosigner,
  DataDealership,
  Make,
  Model,
  Payment,
  Trim,
  User,
  UserBid,
  WinningBid,
} from 'shared/types/models';
import {
  CreateAuctionsRequest,
  CreateAuctionsResponse,
} from '../../shared/types/api/createAuction';
import {
  CreateQuestionResponse,
  CreateQuestionsRequest,
} from '../../shared/types/api/createQuestion';
import {
  DeletePaymentMethodParams,
  DeletePaymentMethodResponse,
} from '../../shared/types/api/deletePaymentMethod';
import {
  GetAuctionByVinParams,
  GetAuctionByVinResponse,
} from '../../shared/types/api/getAuctionByVin';
import { GetAuctionsCountGroupedByMakeResponse } from '../../shared/types/api/getAuctionsCountGroupedByMake';
import { GetAuctionsCountGroupedModelResponse } from '../../shared/types/api/getAuctionsCountGroupedByModel';
import { GetConsumerClicksByBodyTypeResponse } from '../../shared/types/api/GetConsumerClicksByBodyTypeReport';
import { GetConsumerClicksByMakeModelResponse } from '../../shared/types/api/GetConsumerClicksByMakeModelReport';
import { GetFutureByListedAuctionsResponse } from '../../shared/types/api/getFutureByListedAuctions';
import { GetGoogleReviewsResponse } from '../../shared/types/api/getGoogleReviews';
import { GetMarketScanMakesResponse } from '../../shared/types/api/getMarketScanMakes';
import { GetMarketScanModelsResponse } from '../../shared/types/api/getMarketScanModels';
import {
  GetMarketScanModelYearsRequest,
  GetMarketScanModelYearsResponse,
} from '../../shared/types/api/getMarketScanModelYears';
import {
  GetMSMakesRequest,
  GetMSMakesResponse,
} from '../../shared/types/api/getMSMakes';
import { GetMsRebatesCategoriesResponse } from '../../shared/types/api/getMsRebatesCategories';
import {
  GetRebatesRequest,
  GetRebatesResponse,
} from '../../shared/types/api/getRebates';
import { GetSitemapBodyTypesResponse } from '../../shared/types/api/getSitemapBodyTypes';
import { GetSitemapFeaturesResponse } from '../../shared/types/api/getSitemapFeatures';
import { GetSitemapModelsMakesResponse } from '../../shared/types/api/getSitemapModelsMakes';
import { GetUserAttributeRequest } from '../../shared/types/api/getUserAttribute';
import { GetUserBidsResponse } from '../../shared/types/api/getUserBidsResponse';
import { GetUserVehiclesResponse } from '../../shared/types/api/getUserVehicles';
import { GetUserVisitedAuctionsIDsResponse } from '../../shared/types/api/getUserVisitedAuctionsIDs';
import { GetUserWinningBidResponse } from '../../shared/types/api/getUserWinningBid';
import { GetUserWinningBidIdResponse } from '../../shared/types/api/getUserWinningBidId';
import { GetIsCosignerUpdateAllowedResponse } from '../../shared/types/api/isCosignerUpdateAllowedTypes';
import {
  DeleteRebatesZipcodesForScrapeRequest,
  DeleteRebatesZipcodesForScrapeResponse,
  GetRebatesZipcodesForScrapeResponse,
  SetRebatesZipcodesForScrapeRequest,
  SetRebatesZipcodesForScrapeResponse,
} from '../../shared/types/api/rebatesZipcodesForScrape';
import { SendMakeMeAnOfferEmailRequest } from '../../shared/types/api/sendMakeMeAnOfferEmail';
import { SetUserAttributesRequest } from '../../shared/types/api/setUserAttributesRequest';
import {
  UpdateQuestionResponse,
  UpdateQuestionsRequest,
} from '../../shared/types/api/updateQuestion';
import {
  UpdateToggleAuctionFeaturedParams,
  UpdateToggleAuctionFeaturedRequest,
  UpdateToggleAuctionFeaturedResponse,
} from '../../shared/types/api/updateToggleAuctionFeatured';
import {
  UpdateUserActiveBidsRebatesCategoriesRequest,
  UpdateUserActiveBidsRebatesCategoriesResponse,
} from '../../shared/types/api/updateUserActiveBidsRebatesCategories';
import {
  UpdateUserVehiclesRequest,
  UpdateUserVehiclesResponse,
} from '../../shared/types/api/updateUserVehicles';
import {
  ValidateZipcodeParams,
  ValidateZipcodeResponse,
} from '../../shared/types/api/validateZipcode';
import Auth, { auth } from '../auth';
import { fetchInterface, headersInterface } from './types';
import { GetSearchResultsResponse } from 'shared/types/api/getSearchResults';

/** Whether the browser runs in e2e test environment */
export const IS_E2E_ORIGIN =
  (process.browser && window.location.origin === 'http://frontend:3000') ||
  process.env.ENVIRONMENT === ENVIRONMENT.E2E;

const JSON_CONTENT_TYPE = 'application/json';

export const getApiUrl = () => {
  if (IS_E2E_ORIGIN) {
    return process.env.SERVER_API_URL;
  }

  if (!process.browser) {
    return process.env.SERVER_API_URL;
  }

  if (process.env.API_URL) {
    return process.env.API_URL;
  } else {
    return `${window.location.origin}/api`;
  }
};

const API_URL = getApiUrl();
const FETCH_TIMEOUT_30_SEC = 30000;
const UNHANDLED_ERROR_MSG = `We couldn't process your request. Please contact our support team.`;

function getAbortSignal(timeout: number): {
  signal: AbortSignal;
  timeoutId: NodeJS.Timeout;
} {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return { signal: controller.signal, timeoutId };
}

class Rest {
  constructor(_auth?: Auth) {
    this.auth = _auth || auth;
  }

  auth: Auth;

  async _fetch({
    method,
    endpoint,
    params,
    data,
    headers,
    timeout,
    credentials,
  }: fetchInterface): Promise<any> {
    const url = new URL(API_URL + endpoint),
      body = data ? JSON.stringify(data) : undefined,
      defaultHeaders: headersInterface = {
        'Content-Type': JSON_CONTENT_TYPE,
      };

    if (LogRocketRecordingURL) {
      defaultHeaders['X-LogRocket-URL'] = LogRocketRecordingURL;
    }

    if (params) {
      Object.keys(params).forEach((key) => {
        if (![null, undefined].includes(params[key])) {
          if (Array.isArray(params[key])) {
            params[key].forEach((val) =>
              url.searchParams.append(key + '[]', val),
            );
          } else {
            url.searchParams.append(key, params[key]);
          }
        }
      });
    }

    const token = await this.auth.getAccessToken();
    const emulate = this.auth.storage.cookies.get('emulate');
    if (token) {
      defaultHeaders['authorization-token'] = token;
    }
    if (emulate) {
      defaultHeaders['emulate'] = emulate;
    }

    const requestHedaers = { ...defaultHeaders, ...headers };

    const { signal, timeoutId } = getAbortSignal(
      timeout || FETCH_TIMEOUT_30_SEC,
    );

    return fetch(url.href, {
      credentials,
      method,
      mode: 'cors',
      cache: 'no-cache',
      headers: requestHedaers,
      body,
      signal,
    })
      .then(async (res) => {
        const contentType = res.headers.get('content-type') || '';
        let result;

        // Return full response for PNG images
        if (contentType.match('image/png')) {
          result = res;
        } else if (contentType.match('application/json')) {
          result = res.json();
        } else {
          result = res.text();
        }

        if (res.ok) {
          return result;
        }

        if (res.status >= 500) {
          throw new Error(UNHANDLED_ERROR_MSG);
        }

        if (res.status !== 200) {
          throw await result;
        }

        throw new Error(UNHANDLED_ERROR_MSG);
      })
      .catch((err) => {
        if (err?.name === 'AbortError') {
          throw new DOMException(
            'Oops! Looks like our site is taking a break. Give it another shot later.',
          );
        }
        throw err;
      })
      .finally(() => {
        clearTimeout(timeoutId);
      });
  }

  getDashboardAuctions(
    query: GetDashboardAuctionsQuery,
  ): Promise<GetDashboardAuctionsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/dashboard',
      params: query,
      timeout: 60000,
    });
  }

  getAuctionById(auctionId: number): Promise<GetAuctionResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}`,
    });
  }

  getAuctionVisitsCount(
    auctionId: number,
  ): Promise<GetAuctionVisitsCountResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}/visits-count`,
    });
  }

  getAuctionIdsUserIsWatching(): Promise<GetAuctionIdsUserIsWatchingResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/user-is-watching`,
    });
  }

  deanonymizeAuctionVisits(): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: '/auction/deanonymize-visits',
      credentials: 'include',
    });
  }

  getAuctionVisits(auctionId: number): Promise<GetAuctionVisitsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}/visits`,
    });
  }

  getAuctionPresence(auctionId: number): Promise<GetAuctionPresenceResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}/presence`,
    });
  }

  getAuctionWatchersCount(
    auctionId: number,
  ): Promise<GetAuctionWatchersCountResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}/watchers-count`,
    });
  }

  getListingAuctionById(auctionId: number): Promise<GetAuctionResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}`,
      params: {
        listing: '1',
      },
    });
  }

  getSlackNotificationAuctionById(
    auctionId: number,
  ): Promise<GetAuctionResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auctionId}`,
      params: {
        isSlackNotification: true,
      },
    });
  }

  getAuctions(params: GetAuctionsRequest): Promise<GetAuctionsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/auctions/get',
      data: params,
      credentials: 'include',
    });
  }

  getAuctionsFeatured(
    params: GetAuctionsRequest,
  ): Promise<GetAuctionsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/auctions/getFeatured',
      data: params,
      credentials: 'include',
    });
  }

  getAuctionByVin(
    params: GetAuctionByVinParams,
  ): Promise<GetAuctionByVinResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/vin/${params.vin}`,
    });
  }

  populateMSVehicles(): Promise<PopulateMSVehiclesResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/admin/populate-ms-vehicles',
    });
  }

  getAuctionInitialValues(
    vehicleDetails: GetAuctionInitialValues,
  ): Promise<CreateAuctionFormValues> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/initial-values',
      params: vehicleDetails,
    });
  }

  getSitemapAuctions(): Promise<Array<{ id: number; status: string }>> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auctions/sitemap',
    });
  }

  getSitemapFeatures(): Promise<GetSitemapFeaturesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/features/sitemap',
    });
  }

  getSitemapBodyTypes(): Promise<GetSitemapBodyTypesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/body_types/sitemap',
    });
  }

  getSitemapModelsMakes(): Promise<GetSitemapModelsMakesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/models_makes/sitemap',
    });
  }

  cancelAuction(id: number): Promise<number[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/auction/${id}/cancel`,
    });
  }

  watchAuction(id: number): Promise<number[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/auction/${id}/watch`,
    });
  }

  visitAuction(id: number): Promise<number[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/auction/${id}/visit`,
      credentials: 'include',
    });
  }

  unwatchAuction(id: number): Promise<number[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/auction/${id}/unwatch`,
    });
  }

  updateAuction(id: number, auctionData: UpdateAuction): Promise<void> {
    return this._fetch({
      method: 'PUT',
      endpoint: `/auction/${id}`,
      data: auctionData,
    });
  }

  getPopularMakes(params: GetPopularMakes): Promise<GetPopularMakesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/makes/popular-makes`,
      params,
    });
  }

  approveWinner(offerId: number): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/offer/${offerId}/approveWinner`,
    });
  }

  rejectWinner(offerId: number, reason: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/offer/${offerId}/rejectWinner`,
      data: { reason },
    });
  }

  requestCosigner(offerId: number): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/offer/${offerId}/requestCosigner`,
    });
  }

  getYears(): Promise<GetYearsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/vehicle/year',
    });
  }

  getMakes(): Promise<Make[]> {
    return this._fetch({
      method: 'GET',
      endpoint: '/vehicle/make',
    });
  }

  requestVehicle(data: RequestVehicle): Promise<void> {
    return this._fetch({
      method: 'POST',
      data,
      endpoint: '/vehicle/request',
    });
  }

  getModels(makeId: number): Promise<Model[]> {
    return this._fetch({
      method: 'GET',
      endpoint: `/vehicle/make/${makeId}/model`,
    });
  }

  getModelsByName({
    makeName,
  }: GetModelsByMakeParams): Promise<GetModelsByMakeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/vehicle/make/name/${makeName}/model`,
    });
  }

  getNearbyZips({
    zipcode,
  }: GetNearbyZipcodeParams): Promise<GetNearbyZipcodeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/geo/nearby-zips/${zipcode}`,
    });
  }

  getTrims(modelName: string): Promise<Trim[]> {
    return this._fetch({
      method: 'GET',
      endpoint: `/vehicle/model/${modelName}/trim`,
    });
  }

  getModelTrims(modelName: string): Promise<GetModelTrimsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/vehicle/model/${modelName}/trim`,
    });
  }

  createUser(userData: CreateUser): Promise<number> {
    return this._fetch({
      method: 'POST',
      endpoint: '/user',
      data: userData,
    });
  }

  createDataDealership(
    data: DataDealership,
  ): Promise<CreateDataDealershipResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/datadealership',
      data,
    });
  }

  getUserBid({
    auction_id,
    includeMinProgram,
    includeMaxProgram,
  }: {
    auction_id: number;
    includeMinProgram?: boolean;
    includeMaxProgram?: boolean;
  }): Promise<UserBid> {
    if (!auction_id) {
      return;
    }

    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auction_id}/user-bid`,
      params: {
        includeMinProgram: includeMinProgram ? true : '',
        includeMaxProgram: includeMaxProgram ? true : '',
      },
    });
  }

  getAuctionBids({
    auction_id,
  }: {
    auction_id: number;
  }): Promise<AuctionBid[]> {
    if (!auction_id) {
      return;
    }

    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auction_id}/auction-bids`,
    });
  }

  getWinningBid({
    auction_id,
    includeMinProgram,
    includeMaxProgram,
  }: {
    auction_id: number;
    includeMinProgram?: boolean;
    includeMaxProgram?: boolean;
  }): Promise<WinningBid> {
    if (!auction_id) {
      return;
    }

    return this._fetch({
      method: 'GET',
      endpoint: `/auction/${auction_id}/winning-bid`,
      params: {
        includeMinProgram: includeMinProgram ? true : '',
        includeMaxProgram: includeMaxProgram ? true : '',
      },
    });
  }

  getAuctionPaymentAnalytics(
    data: GetAuctionPaymentAnalyticsRequest,
  ): Promise<any> {
    return this._fetch({
      method: 'POST',
      endpoint: '/auction/payment-analytics',
      data,
    });
  }

  createConsumer(
    userData: CreateConsumerRequest,
  ): Promise<CreateConsumerResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/consumer',
      data: userData,
      credentials: 'include',
    });
  }

  getUserBids(params?: {
    auctionStatus?: string;
    paymentMethod?: string;
    bidStatus?: string;
  }): Promise<GetUserBidsResponse[]> {
    return this._fetch({
      method: 'GET',
      endpoint: '/bids',
      params,
    });
  }

  createBid({
    auctionId,
    isBuyItNow,
    maxProgram,
    potentialIncentives,
  }: CreateBidRequestBody): Promise<CreateBidResponseBody> {
    return this._fetch({
      method: 'POST',
      endpoint: '/bid',
      data: {
        auctionId,
        isBuyItNow,
        maxProgram,
        potentialIncentives,
      },
    });
  }

  updateBid(
    bidId: number,
    bid: UpdateBidRequestBody,
  ): Promise<UpdateBidResponseBody> {
    return this._fetch({
      method: 'POST',
      endpoint: `/bid/${bidId}`,
      data: bid,
    });
  }

  getUserWinningBid(id: number): Promise<GetUserWinningBidResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/bids/user-winning-bid/${id}`,
    });
  }

  getUserWinningBidId(user_id: number): Promise<GetUserWinningBidIdResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/bids/user-winning-bid-id/${user_id}`,
    });
  }

  createAuction(data: CreateAuctionsRequest): Promise<CreateAuctionsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/auction',
      data,
      timeout: 60000,
    });
  }

  getUserProfile(): Promise<User> {
    return this._fetch({
      method: 'GET',
      endpoint: '/user/profile',
    });
  }

  getVinInfo(vin: string, zip: string): Promise<VinData> {
    return this._fetch({
      method: 'GET',
      endpoint: `/vin`,
      params: { zip, vin },
    });
  }

  getVinsInfo(vins: string[], zip: string): Promise<BulkVinData[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/vins`,
      data: { zip, vins },
      timeout: 60000,
    });
  }

  getDealerInventory(): Promise<DealerVehicle[]> {
    return this._fetch({
      method: 'GET',
      endpoint: `/inventory/dealer-inventory`,
    });
  }

  getZipcodeByLocation({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }): Promise<{ zipcode: number }> {
    return this._fetch({
      method: 'GET',
      endpoint: `/geo/zipcode-by-location`,
      params: { latitude, longitude },
    });
  }

  validateZipcode(
    zipcode: ValidateZipcodeParams['zipcode'],
  ): Promise<ValidateZipcodeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/geo/validate-zipcode/${zipcode}`,
    });
  }

  updateUserProfile(
    userData: UpdateUserProfileRequest,
  ): Promise<UpdateUserProfileResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/user/profile',
      data: userData,
    });
  }

  updateUser(id: number, data: User): Promise<User> {
    return this._fetch({
      method: 'PUT',
      endpoint: `/admin/users/${id}`,
      data,
    });
  }

  updateDataDealership(
    id: number,
    data: DataDealership,
  ): Promise<UpdateDataDealershipResponse> {
    return this._fetch({
      method: 'PUT',
      endpoint: `/datadealership/${id}`,
      data: {
        ...data,
        id,
      },
    });
  }

  addEmailToMailingList(email: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: '/service/add-to-mailing-list',
      data: { email },
    });
  }

  unsubscribeUser(email: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: '/user/unsubscribe',
      data: { email },
    });
  }

  getUserCosigners(): Promise<Cosigner[]> {
    return this._fetch({
      method: 'GET',
      endpoint: '/cosigners',
    });
  }

  createCosigner(data: CreateCosignerRequest): Promise<CreateCosignerResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/cosigner',
      data,
    });
  }

  updateCosigner(
    id: number,
    data: UpdateCosignerRequest,
  ): Promise<UpdateCosignerResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/cosigner/${id}`,
      data,
    });
  }

  deleteCosigner(id: number): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/cosigner/${id}/delete`,
    });
  }

  getIsCosignerUpdateAllowed(): Promise<GetIsCosignerUpdateAllowedResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/cosigners/is-update-allowed',
    });
  }

  createPaymentSetupIntent(): Promise<SetupIntent> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/create-setup-intent`,
    });
  }

  cancelPaymentIntent(
    paymentIntentId: string,
  ): Promise<CancelPaymentIntentResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/cancel/${paymentIntentId}`,
    });
  }

  refundPaymentIntent(
    paymentIntentId: string,
  ): Promise<RefundPaymentIntentResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/refund/${paymentIntentId}`,
    });
  }

  capturePaymentIntent(
    paymentIntentId: string,
  ): Promise<CapturePaymentIntentResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/capture/${paymentIntentId}`,
    });
  }

  listUserPaymentMethods(): Promise<any> {
    return this._fetch({
      method: 'GET',
      endpoint: `/payments/payment-methods`,
    });
  }

  setDefaultPaymentMethod(payment_method_id: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/payment-method/${payment_method_id}`,
    });
  }

  createPaymentIntent(
    auction_id: number,
    capture_method: 'manual' | 'automatic' = 'manual',
  ): Promise<PaymentIntent> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/create-intent/${auction_id}`,
      params: {
        capture_method,
      },
    });
  }

  updatePayment(
    payment_intent_id: string,
    data: Partial<Payment>,
  ): Promise<PaymentIntent> {
    return this._fetch({
      method: 'POST',
      endpoint: `/payments/${payment_intent_id}`,
      data,
    });
  }

  removePaymentMethod(
    params: DeletePaymentMethodParams,
  ): Promise<DeletePaymentMethodResponse> {
    return this._fetch({
      method: 'DELETE',
      endpoint: `/payments/payment-method/${params.payment_method_id}`,
    });
  }

  deleteDataDealership(id: number): Promise<DeleteDataDealershipResponse> {
    return this._fetch({
      method: 'DELETE',
      endpoint: `/datadealership/${id}`,
    });
  }

  getUser(id: number): Promise<GetUserResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/users/${id}`,
    });
  }

  getDataDealership(id: number): Promise<GetDataDealershipResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/datadealership/${id}`,
    });
  }

  getUsers(params: GetUsersQuery): Promise<GetUsersResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/users`,
      params,
    });
  }

  getAllDataDealerships(): Promise<GetAllDataDealershipsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/datadealership',
    });
  }

  getPublicDataDealerships(): Promise<DataDealership[]> {
    return this._fetch({
      method: 'GET',
      endpoint: '/datadealership/public',
    });
  }

  async getCreditScore(
    params: GetCreditScoreQuery,
  ): Promise<{ score: number; income: number }> {
    const res: GetCreditScoreResponse = await this._fetch({
      method: 'GET',
      params,
      endpoint: `/credit-check/score`,
    });

    return { score: res.score, income: res.income };
  }

  getWebsiteStats(): Promise<Stats> {
    return this._fetch({
      method: 'GET',
      endpoint: `/service/stats`,
    });
  }

  sendCompletedAuctionStatistic({ from, to }): Promise<void> {
    return this._fetch({
      method: 'GET',
      endpoint: `/service/send-completed-auction-statistic`,
      params: {
        from,
        to,
      },
    });
  }

  getMigrations(): Promise<GetMigrationsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/migrations`,
    });
  }

  migrationUp(name: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/migrations/up/${name}`,
    });
  }

  migrationDown(name: string): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: `/migrations/down/${name}`,
    });
  }

  getUserAttributeNonAuthenticated(
    params: GetUserAttributeRequest,
  ): Promise<string> {
    return this._fetch({
      method: 'GET',
      endpoint: '/user/user-attribute',
      params: params,
    });
  }

  setUserAttributesNonAuthenticated(
    params: SetUserAttributesRequest,
  ): Promise<string> {
    return this._fetch({
      method: 'POST',
      endpoint: '/user/user-attributes',
      params: {
        email: params.email,
        password: params.password,
      },
      data: { attributes: params.attributes },
    });
  }

  isPaymentCapturable(auction_id: number): Promise<boolean> {
    return this._fetch({
      method: 'GET',
      endpoint: `/payments/payment-capturable/${auction_id}`,
    });
  }

  getPrograms(params: GetProgramRequest): Promise<GetProgramResponse[]> {
    return this._fetch({
      method: 'POST',
      endpoint: `/calc/get-programs?programsCount=${params.programsCount || 1}`,
      data: {
        ...params,
      },
    });
  }

  getProgram(params: GetProgramRequest): Promise<GetProgramResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/calc/get-program`,
      data: {
        ...params,
      },
    });
  }

  getRebatesParams(
    params: GetRebatesParamsRequest,
  ): Promise<GetRebatesParamsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/calc/get-rebates-params`,
      data: {
        ...params,
      },
    });
  }

  getOrCreateVerificationSession(
    params: GetOrCreateVerificationSessionRequest,
  ): Promise<GetOrCreateVerificationSessionResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/verification/session`,
      params,
    });
  }

  getQuestions(data?: GetQuestionsRequest): Promise<GetQuestionsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: `/questions/get`,
      data,
    });
  }

  addUserRebate(data: AddUserRebateRequest): Promise<AddUserRebateResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/rebates/user-rebates',
      data,
    });
  }

  updateUserRebates(
    data: UpdateUserRebatesRequestBody,
  ): Promise<UpdateUserRebateResponseBody> {
    return this._fetch({
      method: 'PUT',
      endpoint: '/rebates/user-rebates',
      data,
    });
  }

  removeUserRebate(
    data: RemoveUserRebateRequest,
  ): Promise<RemoveUserRebateResponse> {
    return this._fetch({
      method: 'DELETE',
      endpoint: '/rebates/user-rebates',
      data,
    });
  }

  createQuestion(
    data?: CreateQuestionsRequest,
  ): Promise<CreateQuestionResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/questions',
      data,
    });
  }

  updateQuestion(
    data?: UpdateQuestionsRequest,
  ): Promise<UpdateQuestionResponse> {
    return this._fetch({
      method: 'PUT',
      endpoint: '/questions',
      data,
    });
  }

  getRebatesQuestions(
    data?: GetRebatesQuestionsRequest,
  ): Promise<GetRebatesQuestionsResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/questions/rebates-questions/get',
      data,
    });
  }

  getMarketScanMakes(): Promise<GetMarketScanMakesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/ms/makes`,
    });
  }

  getMarketScanModels(): Promise<GetMarketScanModelsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/ms/models`,
    });
  }

  getMarketScanModelYears(
    params: GetMarketScanModelYearsRequest,
  ): Promise<GetMarketScanModelYearsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/ms/years`,
      params,
    });
  }

  updateUserVehicles(
    data: UpdateUserVehiclesRequest,
  ): Promise<UpdateUserVehiclesResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/user/vehicles',
      data,
    });
  }

  getUserVehicles(): Promise<GetUserVehiclesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/user/vehicles',
    });
  }

  getConsumerDocuments({
    auctionId,
    consumerId,
  }: GetConsumerDocumentsParams): Promise<GetConsumerDocumentsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/verification/auctions/${auctionId}/consumers/${consumerId}/documents`,
    });
  }

  streamConsumerDocument({
    auctionId,
    consumerId,
    documentId,
  }: StreamConsumerDocumentParams): Promise<Response> {
    return this._fetch({
      method: 'GET',
      endpoint: `/verification/auctions/${auctionId}/consumers/${consumerId}/documents/${documentId}`,
      headers: {
        'Content-Type': 'image/png',
      },
    });
  }

  getRebates(data?: GetRebatesRequest): Promise<GetRebatesResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/rebates/get',
      data,
    });
  }

  getMSMakes(data?: GetMSMakesRequest): Promise<GetMSMakesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/ms-makes',
      data,
    });
  }

  getCosignerDocuments({
    auctionId,
    cosignerId,
  }: GetCosignerDocumentsParams): Promise<GetCosignerDocumentsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/verification/auctions/${auctionId}/cosigners/${cosignerId}/documents`,
    });
  }

  streamCosignerDocument({
    auctionId,
    cosignerId,
    documentId,
  }: StreamCosignerDocumentParams): Promise<Response> {
    return this._fetch({
      method: 'GET',
      endpoint: `/verification/auctions/${auctionId}/cosigners/${cosignerId}/documents/${documentId}`,
      headers: {
        'Content-Type': 'image/png',
      },
    });
  }

  getCachedPayments(params?: {
    auctionId?: number;
    creditScore?: number;
    annualMileage?: number | '';
    term?: number;
    rebatesNames?: string[];
  }): Promise<GetCachedPaymentsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/cache/payments',
      params,
    });
  }

  cacheAuctionProgramPayment(
    data: CacheAuctionProgramPaymentRequest,
  ): Promise<CacheAuctionProgramPaymentResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/admin/cache/cache-auction-program-payment',
      data,
    });
  }

  bullQMQueues(): Promise<any> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/queues/api/queues',
    });
  }

  getCachedAuctions(
    params: GetCachedAuctionsQuery,
  ): Promise<GetCachedAuctionsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/cache/auctions',
      params,
    });
  }

  getRebatesZipcodesForScrape(): Promise<GetRebatesZipcodesForScrapeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/rebate-zipcodes-for-scrape',
    });
  }

  setRebatesZipcodesForScrape(
    req: SetRebatesZipcodesForScrapeRequest,
  ): Promise<SetRebatesZipcodesForScrapeResponse> {
    return this._fetch({
      method: 'POST',
      endpoint: '/admin/rebate-zipcodes-for-scrape',
      data: req,
    });
  }

  deleteRebatesZipcodesForScrape(
    req: DeleteRebatesZipcodesForScrapeRequest,
  ): Promise<DeleteRebatesZipcodesForScrapeResponse> {
    return this._fetch({
      method: 'DELETE',
      endpoint: '/admin/rebate-zipcodes-for-scrape',
      data: req,
    });
  }

  getAuctionsCountByMake(): Promise<GetAuctionsCountGroupedByMakeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction/count-grouped-by-make',
    });
  }

  getAuctionsCountByMakeGroupedByModel(
    make_id?: number,
  ): Promise<GetAuctionsCountGroupedModelResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/count-grouped-by-model/${make_id}`,
    });
  }

  getMsRebatesCategories(): Promise<GetMsRebatesCategoriesResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/ms/rebates-categories',
    });
  }

  updateUserActiveBidsRebatesCategories(
    req: UpdateUserActiveBidsRebatesCategoriesRequest,
  ): Promise<UpdateUserActiveBidsRebatesCategoriesResponse> {
    return this._fetch({
      method: 'PUT',
      endpoint: '/bids/user-rebates-categories',
      data: req,
    });
  }

  getSavedGoogleReviews(): Promise<GetGoogleReviewsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/reviews`,
    });
  }

  getRebateBriefDescription(
    params: GetRebateBriefDescriptionRequest,
  ): Promise<GetRebateBriefDescriptionResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/rebates/brief-description`,
      params,
    });
  }

  getCityByZipcode({
    zipcode,
  }: GetCityByZipcodeParams): Promise<GetCityByZipcodeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/geo/city/${zipcode}`,
    });
  }

  getFutureByListedAuctions(): Promise<GetFutureByListedAuctionsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/auction/future-by-listed`,
    });
  }

  getUserVisitedAuctionsIDs(): Promise<GetUserVisitedAuctionsIDsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/auction-visit/get-user-auctions-ids',
    });
  }

  updateToggleAuctionFeatured({
    auction_id,
    is_featured,
  }: UpdateToggleAuctionFeaturedParams &
    UpdateToggleAuctionFeaturedRequest): Promise<UpdateToggleAuctionFeaturedResponse> {
    return this._fetch({
      method: 'PUT',
      endpoint: `/auction/toggle-featured/${auction_id}`,
      data: { is_featured },
    });
  }

  getConsumerClicksReport(
    query: GetConsumerClicksReportQuery,
  ): Promise<GetConsumerClicksReportResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/reports/consumer-clicks',
      params: query,
    });
  }

  getConsumerClicksByBodyType(
    query: GetConsumerClicksReportQuery,
  ): Promise<GetConsumerClicksByBodyTypeResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/reports/consumer-clicks-by-body-type',
      params: query,
    });
  }

  getConsumerClicksByMakeModel(
    query: GetConsumerClicksReportQuery,
  ): Promise<GetConsumerClicksByMakeModelResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/reports/consumer-clicks-by-make-model',
      params: query,
    });
  }

  getDealerClicksReport(
    query: GetDealerClicksReportQuery,
  ): Promise<GetDealerClicksReportResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: '/admin/reports/dealer-clicks',
      params: query,
    });
  }

  sendMakeMeAnOfferEmail(body: SendMakeMeAnOfferEmailRequest): Promise<void> {
    return this._fetch({
      method: 'POST',
      endpoint: '/service/send-make-me-an-offer-email',
      data: body,
    });
  }

  getSearchResults(query: string): Promise<GetSearchResultsResponse> {
    return this._fetch({
      method: 'GET',
      endpoint: `/ai/search`,
      params: { query },
      timeout: 60000,
    });
  }
}

export default Rest;

import React from 'react';
import { Field } from 'react-final-form';
import Checkbox, { CheckboxProps } from 'components/molecules/Checkbox';

const FieldCheckbox = ({ name, ...otherProps }: CheckboxProps): JSX.Element => (
  <Field type='checkbox' name={name}>
    {({ meta, input: { name, onChange, checked, ...restInput } }) => (
      <Checkbox
        error={meta.touched && meta.error}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        checked={checked}
        {...otherProps}
      />
    )}
  </Field>
);

export default FieldCheckbox;

interface ZipCode {
  state: string;
  fullState: string;
}

export function getStateByZip(value: string): ZipCode {
  const res = {
    state: '',
    fullState: '',
  };

  if (!value) {
    return res;
  }

  const zipcode = Number(value);

  if (Number.isNaN(zipcode) || zipcode < 1 || zipcode > 99999) {
    return res;
  }

  /* Code cases alphabetized by state */
  if (zipcode >= 1 && zipcode <= 122) {
    res.state = 'AK';
    res.fullState = 'Alaska';
  } else if (zipcode >= 35000 && zipcode <= 36999) {
    res.state = 'AL';
    res.fullState = 'Alabama';
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    res.state = 'AK';
    res.fullState = 'Alaska';
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    res.state = 'AZ';
    res.fullState = 'Arizona';
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    res.state = 'AR';
    res.fullState = 'Arkansas';
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    res.state = 'CA';
    res.fullState = 'California';
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    res.state = 'CO';
    res.fullState = 'Colorado';
  } else if (zipcode >= 6000 && zipcode <= 6999) {
    res.state = 'CT';
    res.fullState = 'Connecticut';
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    res.state = 'DE';
    res.fullState = 'Delaware';
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    res.state = 'FL';
    res.fullState = 'Florida';
  } else if (zipcode >= 30000 && zipcode <= 31999) {
    res.state = 'GA';
    res.fullState = 'Georgia';
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    res.state = 'HI';
    res.fullState = 'Hawaii';
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    res.state = 'ID';
    res.fullState = 'Idaho';
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    res.state = 'IL';
    res.fullState = 'Illinois';
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    res.state = 'IN';
    res.fullState = 'Indiana';
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    res.state = 'IA';
    res.fullState = 'Iowa';
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    res.state = 'KS';
    res.fullState = 'Kansas';
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    res.state = 'KY';
    res.fullState = 'Kentucky';
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    res.state = 'LA';
    res.fullState = 'Louisiana';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    res.state = 'ME';
    res.fullState = 'Maine';
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    res.state = 'MD';
    res.fullState = 'Maryland';
  } else if (zipcode >= 1000 && zipcode <= 2799) {
    res.state = 'MA';
    res.fullState = 'Massachusetts';
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    res.state = 'MI';
    res.fullState = 'Michigan';
  } else if (zipcode >= 55000 && zipcode <= 56999) {
    res.state = 'MN';
    res.fullState = 'Minnesota';
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    res.state = 'MS';
    res.fullState = 'Mississippi';
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    res.state = 'MO';
    res.fullState = 'Missouri';
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    res.state = 'MT';
    res.fullState = 'Montana';
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    res.state = 'NC';
    res.fullState = 'North Carolina';
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    res.state = 'ND';
    res.fullState = 'North Dakota';
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    res.state = 'NE';
    res.fullState = 'Nebraska';
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    res.state = 'NV';
    res.fullState = 'Nevada';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    res.state = 'NH';
    res.fullState = 'New Hampshire';
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    res.state = 'NJ';
    res.fullState = 'New Jersey';
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    res.state = 'NM';
    res.fullState = 'New Mexico';
  } else if (zipcode >= 10000 && zipcode <= 14999) {
    res.state = 'NY';
    res.fullState = 'New York';
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    res.state = 'OH';
    res.fullState = 'Ohio';
  } else if (zipcode >= 73000 && zipcode <= 74999) {
    res.state = 'OK';
    res.fullState = 'Oklahoma';
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    res.state = 'OR';
    res.fullState = 'Oregon';
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    res.state = 'PA';
    res.fullState = 'Pennsylvania';
  } else if (zipcode >= 300 && zipcode <= 999) {
    res.state = 'PR';
    res.fullState = 'Puerto Rico';
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    res.state = 'RI';
    res.fullState = 'Rhode Island';
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    res.state = 'SC';
    res.fullState = 'South Carolina';
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    res.state = 'SD';
    res.fullState = 'South Dakota';
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    res.state = 'TN';
    res.fullState = 'Tennessee';
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    res.state = 'TX';
    res.fullState = 'Texas';
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    res.state = 'UT';
    res.fullState = 'Utah';
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    res.state = 'VT';
    res.fullState = 'Vermont';
  } else if (zipcode >= 22000 && zipcode <= 24699) {
    res.state = 'VA';
    res.fullState = 'Virgina';
  } else if (zipcode >= 20000 && zipcode <= 20599) {
    res.state = 'DC';
    res.fullState = 'Washington DC';
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    res.state = 'WA';
    res.fullState = 'Washington';
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    res.state = 'WV';
    res.fullState = 'West Virginia';
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    res.state = 'WI';
    res.fullState = 'Wisconsin';
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    res.state = 'WY';
    res.fullState = 'Wyoming';
  }

  return res;
}

export const NJ_CITIES_BY_ZIP = new Map([
  ['07102', 'Newark'],
  ['07302', 'Jersey City'],
  ['07501', 'Paterson'],
  ['07201', 'Elizabeth'],
  ['08608', 'Trenton'],
  ['07011', 'Clifton'],
  ['08102', 'Camden'],
  ['07055', 'Passaic'],
  ['07087', 'Union City'],
  ['07018', 'East Orange'],
  ['07002', 'Bayonne'],
  ['08360', 'Vineland'],
  ['08901', 'New Brunswick'],
  ['08861', 'Perth Amboy'],
  ['07093', 'West New York'],
  ['07060', 'Plainfield'],
  ['07601', 'Hackensack'],
  ['08872', 'Sayreville'],
  ['07032', 'Kearny'],
  ['07036', 'Linden'],
]);

export const NY_CITIES_BY_ZIP = new Map([
  ['10001', 'New York'],
  ['14201', 'Buffalo'],
  ['14602', 'Rochester'],
  ['10701', 'Yonkers'],
  ['13202', 'Syracuse'],
  ['12207', 'Albany'],
  ['10801', 'New Rochelle'],
  ['10550', 'Mount Vernon'],
  ['12305', 'Schenectady'],
  ['13501', 'Utica'],
  ['10601', 'White Plains'],
  ['11550', 'Hempstead'],
  ['12180', 'Troy'],
  ['14301', 'Niagara Falls'],
  ['13901', 'Binghamton'],
  ['11520', 'Freeport'],
  ['11580', 'Valley Stream'],
  ['11561', 'Long Beach'],
  ['10977', 'Spring Valley'],
  ['13440', 'Rome'],
]);

export const PA_CITIES_BY_ZIP = new Map([
  ['19102', 'Philadelphia'],
  ['15201', 'Pittsburgh'],
  ['18101', 'Allentown'],
  ['16501', 'Erie'],
  ['19601', 'Reading'],
  ['18503', 'Scranton'],
  ['18015', 'Bethlehem'],
  ['17602', 'Lancaster'],
  ['17101', 'Harrisburg'],
  ['16601', 'Altoona'],
  ['17401', 'York'],
  ['16801', 'State College'],
  ['18701', 'Wilkes-Barre'],
  ['19401', 'Norristown'],
  ['19013', 'Chester'],
  ['18042', 'Easton'],
  ['18201', 'Hazleton'],
  ['17042', 'Lebanon'],
  ['16101', 'New Castle'],
  ['19464', 'Pottstown'],
]);

export const CT_CITIES_BY_ZIP = new Map([
  ['06601', 'Bridgeport'],
  ['06501', 'New Haven'],
  ['06901', 'Stamford'],
  ['06101', 'Hartford'],
]);

export const MD_CITIES_BY_ZIP = new Map([
  ['21201', 'Baltimore'],
  ['21044', 'Columbia'],
  ['20875', 'Germantown'],
  ['20903', 'Silver Spring'],
]);

export const CA_CITIES_BY_ZIP = new Map([
  ['92801', 'Anaheim'],
  ['90802', 'Long Beach'],
  ['90001', 'Los Angeles'],
]);

export const CITIES_BY_ZIP = new Map([
  ...NJ_CITIES_BY_ZIP,
  ...NY_CITIES_BY_ZIP,
  ...PA_CITIES_BY_ZIP,
  ...CT_CITIES_BY_ZIP,
  ...MD_CITIES_BY_ZIP,
  ...CA_CITIES_BY_ZIP,
]);

export const getCityByZip = (zip: string): string => {
  return CITIES_BY_ZIP.get(zip) || '';
};

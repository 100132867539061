import FieldSelect, { FieldSelectProps } from 'containers/profile/FieldSelect';
import { SelectOption } from 'components/atoms/Select';
import userIncomes from 'shared/config/userIncomes';
import { FC } from 'react';

const FieldIncome: FC<FieldSelectProps> = (props): JSX.Element => (
  <FieldSelect {...props}>
    {userIncomes.map(({ value, label }, index) => (
      <SelectOption key={index} value={value}>
        {label}
      </SelectOption>
    ))}
  </FieldSelect>
);

export default FieldIncome;

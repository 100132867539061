import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  default as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Theme as ThemeMui5 } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import ControlLabel from '../atoms/ControlLabel';

type CustomCheckboxProps = {
  label?: ReactNode;
  withLabel?: boolean;
  error?: ReactNode;
  fullWidth?: boolean;
  className?: string;
};

export type CheckboxProps = MUICheckboxProps & CustomCheckboxProps;

const checkboxStyles: (withLabel?: boolean) => SxProps<ThemeMui5> =
  (withLabel) => (t) => ({
    '&.MuiCheckbox-root': {
      // If withLabel === false - consider that custom icon and checkedIcon props
      // will be passed for such a behavior as Like/Dislike
      borderRadius: withLabel ? '5px' : '50%',
      padding: withLabel ? t.spacing(0.5) : '0',
      margin: t.spacing(1),
      color: 'inherit',
    },
  });

const Checkbox: FC<CheckboxProps> = ({
  color = 'primary',
  disableRipple = true,
  label = '',
  withLabel = true,
  fullWidth = true,
  error = '',
  icon = <FontAwesomeIcon icon={faSquare} size='xl' />,
  checkedIcon = <FontAwesomeIcon icon={faCheckSquare} size='xl' />,
  disabled,
  ...restProps
}): JSX.Element => {
  return (
    <FormControl fullWidth={fullWidth}>
      <FormControlLabel
        sx={{ '&.MuiFormControlLabel-root': { margin: 0 } }}
        control={
          <MUICheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            color={color}
            disabled={disabled}
            disableRipple={disableRipple}
            {...restProps}
            sx={checkboxStyles(withLabel)}
          />
        }
        label={withLabel && <ControlLabel>{label}</ControlLabel>}
      />
      {error && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Checkbox;

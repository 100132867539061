import Cookies from 'universal-cookie';
import { addMilliseconds } from 'date-fns';

enum ClientStorageType {
  cookie = 'cookie',
  localStorage = 'localStorage',
}

export const CLIENT_STORAGE_TYPE = ClientStorageType.cookie;
export const LOCAL_STORAGE_TYPE = ClientStorageType.localStorage;
const DEFAULT_COOKIE_TTL = 1000 * 60 * 60 * 24 * 365; // 1 year

interface IClientStorage {
  get<T>(key: string): T;

  set(key: string, value: unknown, ttl?: number): void;

  remove(key: string): void;
}

class CookieClientStorage implements IClientStorage {
  constructor(source) {
    this.cookies = new Cookies(source);
  }

  cookies: Cookies;

  get(key) {
    return this.cookies.get(key);
  }

  set(key, value, ttl) {
    this.cookies.set(
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value,
      {
        path: '/',
        expires: addMilliseconds(new Date(), ttl || DEFAULT_COOKIE_TTL),
      },
    );
  }

  remove(key) {
    this.cookies.remove(key);
  }
}

class LocalStorageClientStorage implements IClientStorage {
  get(key) {
    const value = localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }
}

export const getClientStorage = (
  type: ClientStorageType,
  source?: string,
): IClientStorage => {
  if (type === ClientStorageType.cookie) {
    return new CookieClientStorage(source);
  } else {
    return new LocalStorageClientStorage();
  }
};

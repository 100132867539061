import { GetProgramResponse } from '../types/api/getProgram';

export const getDueAtSignin = (program: GetProgramResponse): number => {
  if (program?.FullDetails?.PartRecap?.InceptionFees) {
    return (
      (program?.FullDetails?.PartRecap?.InceptionFees?.Total || 0) +
      program?.FullDetails?.DownpaymentFromCash
    );
  }

  if (program?.FullDetails?.Retail) {
    return program?.FullDetails?.DownpaymentFromCash;
  }

  return 0;
};

export const getUpfrontFees = (program: GetProgramResponse): number => {
  return program?.FullDetails.Lease.AcqusitionFeeInCap
    ? 0
    : program?.AcquisitionFee;
};

export const getUpfrontTaxes = (program: GetProgramResponse): number => {
  return program?.FullDetails.Lease.TotalTaxInInception;
};

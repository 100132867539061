import { FC, MouseEvent, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import IconButtonMui5 from '@mui/material/IconButton';
import MUIPopover, {
  PopoverProps as MUIPopoverProps,
} from '@mui/material/Popover';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import { ButtonProps as ButtonPropsMui5 } from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { styled as styledMui5, styled } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';

const CloseButton = styled(IconButton)({
  '&.MuiButtonBase-root': {
    position: 'absolute',
    right: 4,
    top: 10,
    height: 32,
    width: 32,
    zIndex: 1,
  },
});

const PopoverContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth: string }>(({ theme, maxWidth }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2, 4, 2, 2),
  minWidth: '100px',
  maxWidth: maxWidth,
}));

const StyledIconButtonMui5 = styledMui5(IconButtonMui5, { skipSx: false })`
  &.MuiIconButton-root {
    vertical-align: baseline;
    &:hover {
      background: transparent;
    }
  }`;

export enum PopoverAnchorType {
  StyledButton = 'button',
  Icon = 'icon',
}

type PopoverProps = {
  anchorType?: PopoverAnchorType;
  icon?: JSX.Element;
  buttonProps?: ButtonPropsMui5;
  popoverProps?: Partial<MUIPopoverProps>;
  openOnClick?: boolean;
  keepOpenOnBlur?: boolean;
  sx?: SxProps<ThemeMui5>;
  title?: string;
  maxWidth?: string;
  onClose?: () => void;
  onOpen?: () => void;
};

const TOGGLE_TIMEOUT = 500;
let openTimeoutId: number;
let closeTimeoutId: number;

const Popover: FC<PopoverProps> = ({
  anchorType = PopoverAnchorType.Icon,
  icon = <StyledFontAwesomeIconMui5 icon={faQuestionCircle} size='sm' />,
  buttonProps,
  popoverProps,
  children,
  sx = {},
  openOnClick,
  keepOpenOnBlur,
  title = '',
  maxWidth = '100%',
  onClose = () => {},
  onOpen = () => {},
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isHoveringContent, setIsHoveringContent] = useState(false);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    if (isOpen) {
      return;
    }
    window.clearTimeout(openTimeoutId);
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    setIsHoveringContent(false);
    closeTimeoutId = null;
    onOpen();
  };

  const handleClose = (
    event: MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
    // Prevents the popover from jumping when closing.
    setTimeout(() => setAnchorEl(null), 0);
    onClose && onClose();
  };

  const handlers = {
    onMouseEnter: (e: MouseEvent<HTMLButtonElement>) => {
      setIsClicked(false);
      setAnchorEl(e.currentTarget);
      setIsHoveringContent(false);
      closeTimeoutId = null;
      openTimeoutId = window.setTimeout(() => {
        setIsOpen(true);
        onOpen();
      }, TOGGLE_TIMEOUT);
    },
    onMouseLeave: () => {
      window.clearTimeout(openTimeoutId);
    },
    onClick: (e: MouseEvent<HTMLButtonElement>) => {
      setIsClicked(true);
      handleOpen(e);
    },
  };

  const openOnClickHandlers = {
    onClick: handleOpen,
  };

  useEffect(() => {
    // unmount cleanup
    return () => {
      window.clearTimeout(closeTimeoutId);
      window.clearTimeout(openTimeoutId);
    };
  }, []);

  return (
    <>
      {anchorType === PopoverAnchorType.Icon && (
        <StyledIconButtonMui5
          sx={sx}
          size='small'
          {...(openOnClick ? openOnClickHandlers : handlers)}
        >
          {icon}
        </StyledIconButtonMui5>
      )}
      {anchorType === PopoverAnchorType.StyledButton && (
        <StyledButtonMui5
          sx={sx}
          size='small'
          variant='text'
          color='primary'
          {...(openOnClick ? openOnClickHandlers : handlers)}
          {...buttonProps}
        />
      )}
      <MUIPopover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableRestoreFocus
        onMouseMove={(e) => {
          if (!isClicked && !isHoveringContent && !closeTimeoutId) {
            closeTimeoutId = window.setTimeout(
              () => handleClose(e),
              TOGGLE_TIMEOUT,
            );
          }
        }}
        {...popoverProps}
      >
        <PopoverContent
          maxWidth={maxWidth}
          onMouseEnter={() => {
            setIsHoveringContent(true);
            window.clearTimeout(closeTimeoutId);
          }}
          onMouseLeave={(e) => {
            !keepOpenOnBlur && !openOnClick && !isClicked && handleClose(e);
          }}
        >
          <Typography>{title}</Typography>
          <CloseButton onClick={handleClose} size='small'>
            <StyledFontAwesomeIconMui5 icon={faTimes} />
          </CloseButton>
          {children}
        </PopoverContent>
      </MUIPopover>
    </>
  );
};

export default Popover;

import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import ChipMui5 from '@mui/material/Chip';
import { red } from '@mui/material/colors';
import GridMui5 from '@mui/material/Grid';
import { styled as styledMui5 } from '@mui/material/styles';
import TypographyMui5 from '@mui/material/Typography';
import { StyledFontAwesomeIconMui5 } from '../../components/atoms/FontAwesomeIcon';
import { UserTaskTypeEx } from '../../shared/types/misc';
import { green } from '../../theme/colors';
import { FC } from 'react';

export type UserTaskCardProps = UserTaskTypeEx & {
  action: JSX.Element;
};

export const TaskChipMui5 = styledMui5(ChipMui5)(
  () => `
  &.MuiChip-colorPrimary {
    background: ${green[50]};
    color: ${green[900]};
  }
  &.MuiChip-colorSecondary {
    background: ${red[50]};
    color: ${red[900]};
  }
  &.MuiChip-colorPrimary .MuiChip-icon {
    color: ${green[400]};
  }
  &.MuiChip-colorSecondary .MuiChip-icon {
    color: ${red[400]};
  }`,
);

export const UserTaskCard: FC<UserTaskCardProps> = ({
  action,
  title,
  content,
  required,
  completed,
}) => {
  return (
    <GridMui5 container spacing={6}>
      <GridMui5 item md={7} sm={12}>
        <TypographyMui5 variant='body1' color='textPrimary'>
          {title}
        </TypographyMui5>
        <TypographyMui5 color='textSecondary' variant='overline'>
          {completed ? (
            <TaskChipMui5
              icon={
                <StyledFontAwesomeIconMui5 icon={faCheckCircle} size='lg' />
              }
              color='primary'
              label='Completed'
            />
          ) : required ? (
            <TaskChipMui5
              icon={
                <StyledFontAwesomeIconMui5
                  icon={faExclamationCircle}
                  size='lg'
                />
              }
              color='secondary'
              label='Required'
            />
          ) : (
            <TaskChipMui5 label='Optional' />
          )}
        </TypographyMui5>
        <TypographyMui5 color='textPrimary' variant='body2'>
          {content}
        </TypographyMui5>
      </GridMui5>
      <GridMui5
        item
        md={5}
        sm={12}
        container
        justifyContent='center'
        alignItems='center'
      >
        {action}
      </GridMui5>
    </GridMui5>
  );
};

import { Component } from 'react';
import { AppErrorContainer } from './app-error';
import { datadogRum } from '@datadog/browser-rum';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.error({ error, errorInfo });
    datadogRum.addError(error);
  }
  render() {
    // Check if the error is thrown
    // @ts-expect-error Property 'hasError' does not exist on type 'Readonly<{}>'
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <AppErrorContainer
          // @ts-expect-error Property 'error' does not exist on type 'Readonly<{}>'
          error={this.state.error}
          handleTryAgain={() => this.setState({ hasError: false })}
        />
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;

import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Auction } from '../../shared/types/models';
import { getOfferImage, moneyFormat } from '../../shared/utils/functions';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  bidFormProgramSelector,
  buyItNowSellingPriceSelector,
  buyItNowSellingPriceWithoutDiscountSelector,
  currentSellingPriceSelector,
  currentSellingPriceWithoutDiscountSelector,
} from '../../containers/deal/recoil/selectors';
import {
  milesInputAtom,
  moInputSelector,
} from '../../containers/deal/recoil/atoms';
import { MSISRebate } from '../../shared/types/marketScan';
import { getAuctionAppliedRebates } from '../../shared/incentives/getAuctionAppliedRebates';
import { auctionRebatesSelector } from 'recoil/selectors';
import { getAllRebatesTotal } from '../../services/rebates/functions';
import Image from 'next/image';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useScreenSize } from 'hooks/useScreenSize';
import { BEST_TERM_LENGTH } from 'shared/utils/constants';
import { usePrevious } from 'hooks/usePrevious';
import { SEO_AUCTION_PAGE } from 'utils/seo';
import { styled } from '@mui/material/styles';

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(-1),
    paddingTop: theme.spacing(0.5),
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.palette.background.paper,
  },
}));

type Props = {
  auction: Auction;
  selectedRebates: MSISRebate[];
  containerClasses?: string;
  selectedRebatesText: string;
};

export const LeaseCarSavings: FC<Props> = ({
  auction,
  selectedRebates,
  selectedRebatesText,
}) => {
  const { isExtraSmallScreen } = useScreenSize();

  const currentSellingPrice = useRecoilValueLoadable(
    currentSellingPriceSelector,
  ).valueMaybe();

  const currentSellingPriceWithoutDiscount = useRecoilValueLoadable(
    currentSellingPriceWithoutDiscountSelector,
  ).valueMaybe();

  const buyItNowSellingPrice = useRecoilValueLoadable(
    buyItNowSellingPriceSelector,
  ).valueMaybe();

  const buyItNowSellingPriceWithoutDiscount = useRecoilValueLoadable(
    buyItNowSellingPriceWithoutDiscountSelector,
  ).valueMaybe();

  const salesPrice = currentSellingPrice || buyItNowSellingPrice;

  const currentProgram = useRecoilValueLoadable(
    bidFormProgramSelector({
      salesPrice,
      selectedRebates: selectedRebates,
    }),
  );

  const salesPriceWithoutDiscount =
    currentSellingPriceWithoutDiscount || buyItNowSellingPriceWithoutDiscount;

  const noRebatesProgram = useRecoilValueLoadable(
    bidFormProgramSelector({
      salesPrice: salesPriceWithoutDiscount,
      selectedRebates: [],
      rebatesCategories: [],
      disableAutoRebates: true,
    }),
  );

  const miles = useRecoilValue(milesInputAtom);
  const mo = useRecoilValue(moInputSelector);

  const auctionRebates = useRecoilValueLoadable(
    auctionRebatesSelector({ auction }),
  ).valueMaybe();

  const monthlyPayment = parseFloat(noRebatesProgram.valueMaybe()?.Payment);
  const monthlyPaymentWithIncentives = parseFloat(
    currentProgram.valueMaybe()?.Payment,
  );
  const savedOnMonthlyPayment = monthlyPayment - monthlyPaymentWithIncentives;
  const prevEffectiveMoPaymentWithIncentives = usePrevious(
    monthlyPaymentWithIncentives,
  );
  const prevSavedOnMonthlyPayment = usePrevious(savedOnMonthlyPayment);

  if (!auction) {
    return null;
  }

  const appliedRebates = getAuctionAppliedRebates({
    auctionRebates,
    program: currentProgram.valueMaybe(),
  });

  const total = getAllRebatesTotal(appliedRebates, auction?.msrp, salesPrice);

  const isBestTermLength =
    (mo === BEST_TERM_LENGTH || mo === currentProgram.valueMaybe()?.BestTerm) &&
    currentProgram.valueMaybe()?.BestTerm;

  return (
    <Container>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography variant={isExtraSmallScreen ? 'h6' : 'h5'} sx={{ mb: 2 }}>
          How much money can be saved with{' '}
          <span style={{ fontStyle: 'italic' }}>{selectedRebatesText}</span>{' '}
          incentives applied to a lease
        </Typography>
        <Box mb={1} mt={2}>
          <Typography component='div'>
            <Typography component='div' variant='h6'>
              <b>{`${auction.offer.make.name} ${auction.offer.model.name}`}</b>
            </Typography>
            <div>{`${auction.offer.style_display_name}`}</div>
          </Typography>
        </Box>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={3} sm={3} md={3}>
            <Typography variant='body1'>Deal terms</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={9}>
            <Typography variant='body1'>
              {isBestTermLength ? currentProgram.valueMaybe()?.BestTerm : mo}{' '}
              months
              {isBestTermLength && (
                <Typography
                  component='span'
                  variant='body2'
                  color='secondary'
                  style={{ color: '#f22f2f' }}
                >
                  {' '}
                  (Best)
                </Typography>
              )}
              , {miles / 1000}k miles per years
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Image
            src={getOfferImage({ offer: auction.offer })}
            width={320}
            height={240}
            alt={SEO_AUCTION_PAGE.title(auction)}
          />
        </Box>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={9} sm={9} md={9}>
            <Typography variant='body1'>Monthly payment</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <Typography variant='body2' align='right'>
              {moneyFormat(monthlyPayment, true)}
              /mo
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={9} sm={8} md={8}>
            <Typography variant='body1'>
              Monthly payment{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                with incentives applied
              </span>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            {!currentProgram.valueMaybe()?.Payment && (
              <CircularProgress size={16} />
            )}
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography variant='body2' align='right'>
              <>
                {currentProgram.valueMaybe()?.Payment
                  ? moneyFormat(monthlyPaymentWithIncentives, true)
                  : moneyFormat(prevEffectiveMoPaymentWithIncentives, true)}
                /mo
              </>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={8} sm={8} md={8}>
          <Typography variant='body1'>Saved on monthly payment</Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          {!currentProgram.valueMaybe()?.Payment && (
            <CircularProgress size={16} />
          )}
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Typography variant='body2' align='right'>
            <>
              {currentProgram.valueMaybe()?.Payment
                ? moneyFormat(savedOnMonthlyPayment, true)
                : moneyFormat(prevSavedOnMonthlyPayment, true)}
              /mo
            </>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center' pt={1}>
        <Grid item xs={8} sm={8} md={8}>
          <Typography variant='h6'>
            {total > 0 ? 'In total you save' : 'Total markup'}
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          {currentProgram.state === 'loading' && <CircularProgress size={18} />}
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <Typography variant='subtitle1' color={'primary'} align='right'>
            <b>{moneyFormat(Math.abs(total))}</b>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

import { MSAPIAppliedRebate, MSISRebate } from '../types/marketScan';

/*
 * This function converts a rebate from the MSISRebate type to the MSAPIAppliedRebate type.
 * MSISRebate type is a generic MarketScan rebate type available by GetRebatesParam MarketScan API method.
 * MSAPIAppliedRebate type is a type we are getting back with lender program and could not be converted to MSISRebate type because it misses some fields.
 *
 * Some time it is much harder to work with MSISRebate type and we need to convert it to MSAPIAppliedRebate type.
 *
 * @param {MSISRebate} msRebate - The rebate to convert.
 * @returns {MSAPIAppliedRebate} - The converted rebate.
 */
export const convertRebateToAppliedRebate = (
  msRebate: MSISRebate,
): MSAPIAppliedRebate => {
  return {
    ID: msRebate.ID,
    IdentCode: msRebate.IdentCode,
    Name: msRebate.Name,
    Number: msRebate.Number,
    RebateType: msRebate.Type,
    Recipient: msRebate.ReceipientType,
    StopDate: msRebate.StopDate,
    Value: msRebate.Value?.Values?.[0]?.Value,
    IsCustom: msRebate.IsCustom,
  };
};

import ButtonMui5, {
  ButtonProps as ButtonPropsMui5,
} from '@mui/material/Button';
import { ElementType } from 'react';
import { styled as styledMui5 } from '@mui/material/styles';

const InnerStyledButtonMui5 = styledMui5(ButtonMui5, { skipSx: false })(
  ({ theme }) => `
    &.MuiButton-root {
      border-radius: 25px;
      padding: ${theme.spacing(1.5, 3)};
      text-transform: uppercase;
    }
    &.MuiButton-sizeSmall {
      padding: ${theme.spacing(1, 1.5)};
      text-transform: uppercase;
    }
    &.MuiButton-sizeLarge {
      padding: ${theme.spacing(2.5, 4)};
      text-transform: capitalize; 
    }
    &.MuiButton-containedPrimary {      
      border: 2px solid ${theme.palette.primary.main};
      &:disabled {
        box-shadow: none;
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.primary.light};
        border-color: ${theme.palette.primary.main};
      }
    }
    &.MuiButton-containedSecondary {
      border: 2px solid ${theme.palette.secondary.main};
      &:disabled {
        box-shadow: none;
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.secondary.light};
        border-color: ${theme.palette.secondary.main};
      }
    }
    &.MuiButton-outlined {
      &:hover svg {
        color: ${theme.palette.common.white};
      }
    }
    &.MuiButton-outlinedPrimary {
      border: 2px solid ${theme.palette.primary.main};
      &:hover {
        border: 2px solid ${theme.palette.primary.main};
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
      }
      &:disabled {
        border-width: 2px;
        border-color: ${theme.palette.primary.light};
        color: ${theme.palette.primary.light};
      }
    }
    &.MuiButton-outlinedSecondary {
      border: 2px solid ${theme.palette.secondary.main};
      &:hover {
        border: 2px solid ${theme.palette.secondary.main};
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.common.white};
      }
      &:disabled {
        border-width: 2px;
        border-color: ${theme.palette.secondary.light};
        color: ${theme.palette.secondary.light};
      }
    }`,
);

export const StyledButtonMui5 = <C extends ElementType>({
  variant = 'contained',
  size = 'small',
  ...restProps
}: ButtonPropsMui5<
  C,
  {
    component?: C;
  }
>): JSX.Element => {
  return <InnerStyledButtonMui5 variant={variant} size={size} {...restProps} />;
};

import {
  auctionAtom,
  auctionBidsAtom,
  milesInputAtom,
  moInputSelector,
  scanModeInputAtom,
  userBidAtom,
  winningBidAtom,
} from 'containers/deal/recoil/atoms';
import { Router } from 'next/router';
import { FC } from 'react';
import { RecoilRoot } from 'recoil';
import { cookieStringAtom, getGoogleReviewsAtom, userAtom } from 'recoil/atoms';
import { filtersAtom } from 'services/filters/recoil';
import { DealTypeEnum } from 'shared/types/endpoints';
import { MSAPIScanMode } from 'shared/types/marketScan';
import { AVAILABLE_MILEAGES, SELECTABLE_TERMS } from 'shared/utils/constants';

type WithRecoilRootProps = {
  router: Router;
  pageProps: any;
};

export const WithRecoilRoot: FC<WithRecoilRootProps> = ({
  router,
  pageProps,
  children,
}) => {
  function initializeState({ set }) {
    const queryMiles = Number(router.query.miles);
    const queryTerm = String(router.query.term);

    if (pageProps.cookie) {
      set(cookieStringAtom, pageProps.cookie);
    }

    if (pageProps.winningBid) {
      set(winningBidAtom, pageProps.winningBid);
    }

    if (pageProps.reviews) {
      set(getGoogleReviewsAtom, pageProps.reviews);
    }

    if (pageProps.userBid) {
      set(userBidAtom, pageProps.userBid);
    }

    if (pageProps.auctionBids) {
      set(auctionBidsAtom, pageProps.auctionBids);
    }

    if (queryMiles && AVAILABLE_MILEAGES.includes(queryMiles)) {
      set(milesInputAtom, queryMiles);
    }

    if (queryTerm && SELECTABLE_TERMS.includes(queryTerm)) {
      set(moInputSelector, queryTerm);
    }

    if (pageProps.filtersAtomData) {
      set(filtersAtom, pageProps.filtersAtomData);
      const dealType = pageProps.filtersAtomData.dealType;
      if (dealType) {
        const scanMode =
          dealType === DealTypeEnum.FINANCE
            ? MSAPIScanMode.Retail
            : MSAPIScanMode.Lease;

        set(scanModeInputAtom, scanMode);
      }
    }

    if (pageProps.user) {
      set(userAtom, pageProps.user);
    }

    if (pageProps.auction) {
      set(auctionAtom, pageProps.auction);
    }
  }

  return <RecoilRoot initializeState={initializeState}>{children}</RecoilRoot>;
};

import { useEffect } from 'react';
import { clientStorage } from 'recoil/atoms';
import { useRouter } from 'next/router';
import { User } from 'shared/types/models';
import { IS_E2E_ORIGIN } from 'services/rest';
import { growthbook } from 'services/growthbook';
import { LOCAL_STORAGE_KEY } from 'services/growthbook/const';

export function useFeatureFlag(user: User): void {
  const router = useRouter();

  useEffect(() => {
    if (!process.env.USE_GROWTHBOOK || IS_E2E_ORIGIN) {
      return;
    }
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    if (!process.env.USE_GROWTHBOOK || IS_E2E_ORIGIN) {
      return;
    }

    growthbook.setAttributes({
      deviceId: clientStorage.get(LOCAL_STORAGE_KEY),
      id: user?.id,
      loggedIn: Boolean(user?.id),
      browser: navigator.userAgent,
      url: router.pathname,
    });
  }, [user, router.pathname]);
}

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { styled as styledMui5 } from '@mui/material/styles';
import { ElementType, FC } from 'react';

const StyledDialogTitle = styledMui5(MuiDialogTitle)<{
  component?: ElementType;
}>(
  ({ theme }) => `
  &.MuiDialogTitle-root {
    margin: 0;
    padding: ${theme.spacing(0, 2, 0, 2)};
    box-shadow: ${theme.shadows[2]};
    z-index: 5;
  }`,
);

const CloseButton = styledMui5(IconButton)(
  ({ theme }) => `
  &.MuiIconButton-root {
    position: absolute;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    color: ${theme.palette.grey[500]};
  }`,
);

export const DialogTitle: FC<
  DialogTitleProps & {
    onClose: () => void;
    title: string;
  }
> = ({ children, title, onClose, component = 'div', ...other }) => {
  return (
    <StyledDialogTitle component={component} {...other}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h6'>{title}</Typography>
        {children && <>{children}</>}
      </Box>
      {onClose ? (
        <CloseButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </StyledDialogTitle>
  );
};

import createCache from '@emotion/cache';

const isBrowser = typeof document !== 'undefined';

export function createEmotionCache() {
  let insertionPoint: HTMLElement;
  if (isBrowser) {
    const emotionInsertPoint = document.querySelector(
      'meta[name="emotion-insertion-point"]',
    ) as HTMLElement;
    insertionPoint = emotionInsertPoint ?? undefined;
  }
  return createCache({ key: 'css', insertionPoint, prepend: true });
}

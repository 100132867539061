import { FC, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { pageTransitionAtom } from 'recoil/atoms';
import { PageTransitionEnum } from 'data/constants';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

const LARGEST_POSSIBLE_Z_INDEX = 2147483647;

const linearProgressStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiLinearProgress-root': {
    zIndex: LARGEST_POSSIBLE_Z_INDEX,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

/**
 * This component is defined to track NextJS pages transitions
 * It should be rendered once and populate pageTransition recoil atom
 */
export const PageTransition: FC<{ showProgress?: boolean }> = ({
  showProgress = true,
}) => {
  const [pageTransition, setPageTransition] =
    useRecoilState(pageTransitionAtom);

  const router = useRouter();

  const handleStart = useCallback(() => {
    setPageTransition(PageTransitionEnum.LOADING);
  }, [setPageTransition]);

  const handleComplete = useCallback(() => {
    setPageTransition(PageTransitionEnum.LOADED);
  }, [setPageTransition]);

  const handleFailed = useCallback(() => {
    setPageTransition(PageTransitionEnum.FAILED);
  }, [setPageTransition]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleFailed);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleFailed);
    };
  }, [router.events, handleStart, handleComplete, handleFailed]);

  if (!showProgress) {
    return null;
  }

  const isPageLoading = pageTransition === PageTransitionEnum.LOADING;

  if (!isPageLoading) {
    return null;
  }

  return isPageLoading ? (
    <Fade
      in={isPageLoading}
      style={{
        transitionDelay: isPageLoading ? '800ms' : '0ms',
      }}
      unmountOnExit
    >
      <LinearProgress variant='indeterminate' sx={linearProgressStyles} />
    </Fade>
  ) : null;
};

import { Makes } from '../../config/makes';
import { MSISRebate, MSISTransactionTypeEnum } from '../../types/marketScan';
import { getCustomRebateDefinitions } from './customRebateDefinition';
import { CustomRebateDefinition, GetCustomRebateParams } from './types';

export type Props = {
  date: string;
  make: Makes;
  settings?: GetCustomRebateParams;
};

export const filterByMake =
  (make: Makes) =>
  (rebateDefinition: CustomRebateDefinition): boolean => {
    return rebateDefinition.make === make;
  };

export const filterByDate =
  (dateString: string) =>
  (rebateDefinition: CustomRebateDefinition): boolean => {
    const date = new Date(dateString);
    const startDate = new Date(rebateDefinition.startDate);
    const endDate = new Date(rebateDefinition.endDate);

    return startDate <= date && date <= endDate;
  };

export const getCustomRebates = ({
  date,
  make,
  settings,
}: Props): MSISRebate[] => {
  return getCustomRebateDefinitions()
    .filter(filterByMake(make))
    .filter(filterByDate(date))
    .map((rebateDefinition) => {
      return {
        IsCustom: true,
        ID: rebateDefinition.id,
        NameDisplay: rebateDefinition.name,
        Name: rebateDefinition.name,
        Description: rebateDefinition.description,
        Question: rebateDefinition.question,
        MaxCreditScore: rebateDefinition.maxCreditScore,
        MinCreditScore: rebateDefinition.minCreditScore,
        StopDate: rebateDefinition.endDate,
        StartDate: rebateDefinition.startDate,
        IdentCode: rebateDefinition.name,
        Value: {
          Values: [
            {
              Value: rebateDefinition.getValue(settings),
              Type: MSISTransactionTypeEnum.Lease,
            },
          ],
        },
      };
    })
    .filter((rebate) => {
      return rebate.Value.Values[0].Value > 0;
    });
};

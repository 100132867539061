import { MSISRebate } from '../types/marketScan';

const INFORMATIONAL_CATEGORY_ID = 1000;
const PROMO_CODE_CATEGORY_ID = 13;

const INVALID_CATEGORY_IDS = [
  INFORMATIONAL_CATEGORY_ID,
  PROMO_CODE_CATEGORY_ID,
];

export const rebatesFilter = (rebate: MSISRebate) => {
  return (
    // filter by transaction type
    // rebate.Type !== MSISRebateTypeEnum.Cash &&
    !INVALID_CATEGORY_IDS.includes(rebate.CategoryID) &&
    (rebate?.Value?.Values?.[0]?.Value > 0 ||
      (rebate?.Terms?.length > 0 &&
        rebate.Terms.some((t) => t?.Value?.Values?.[0]?.Value > 0)))
  );
};

import { useEffect } from 'react';
import LogRocket from 'logrocket';
import { NextRouter, useRouter } from 'next/router';

function isDataDogBot(router: NextRouter) {
  return router.query.datadog === '';
}

export let LogRocketRecordingURL = '';

export function useLogRocket(): void {
  const router = useRouter();

  const isLogRocketEnabled =
    process.env.IS_LOGROCKET_ENABLED && !isDataDogBot(router);

  useEffect(() => {
    if (isLogRocketEnabled) {
      LogRocket.init(process.env.LOGROCKET_KEY);

      LogRocket.getSessionURL(function (sessionURL) {
        LogRocketRecordingURL = sessionURL;
      });
    }
  }, [isLogRocketEnabled]);
}

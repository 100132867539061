import { getRebateStatus } from 'components/organisms/PopoverWithIncentives/getRebateStatus';
import { IncentiveStatus } from 'shared/incentives';
import { getRebateValue } from 'shared/incentives/getRebateValue';
import {
  MSISRebate,
  MSISRecipientType,
  MSISTransactionTypeEnum,
} from 'shared/types/marketScan';
import { MS_REBATE_CATEGORY_ID_EMPLOYEE_PROGRAM } from 'shared/utils/constants';
import {} from '../recoil/selectors';
import {
  SELECTED_REBATE_NAME_FIELD,
  UIRebate,
} from '../services/rebates/functions';

const NO_REBATE_DESCRIPTION = 'Sorry, this rebate has no description yet';

function uniqueNameDisplay(rebates: MSISRebate[], rebate: MSISRebate): string {
  if (
    rebates.filter((r) => r.NameDisplay === rebate.NameDisplay).length === 1
  ) {
    return rebate.NameDisplay;
  }

  return rebate.Name;
}

const LEASE_APPLICABLE_REBATE_TRANSACTION_TYPES = [
  MSISTransactionTypeEnum.Any,
  MSISTransactionTypeEnum.Lease,
  MSISTransactionTypeEnum.SpecialLease,
  MSISTransactionTypeEnum.StandardLease,
];

const FINANCE_APPLICABLE_REBATE_TRANSACTION_TYPES = [
  MSISTransactionTypeEnum.Any,
  MSISTransactionTypeEnum.Retail,
  MSISTransactionTypeEnum.SpecialRetail,
  MSISTransactionTypeEnum.StandardRetail,
];

export function rebatesToUiRebates({
  autoSelectedRebates,
  auctionMsisRebates,
  appliedRebates,
  selectedMsisRebates,
  loading,
  msrp,
  isLease,
}: {
  autoSelectedRebates: MSISRebate[];
  auctionMsisRebates: MSISRebate[];
  appliedRebates: MSISRebate[];
  selectedMsisRebates: MSISRebate[];
  // rebatesQuestions: RebateQuestion[],
  loading: boolean;
  msrp: number;
  isLease: boolean;
}): UIRebate[] {
  const applicableTransactionTypes = isLease
    ? LEASE_APPLICABLE_REBATE_TRANSACTION_TYPES
    : FINANCE_APPLICABLE_REBATE_TRANSACTION_TYPES;

  return auctionMsisRebates
    .filter(
      (rebate) =>
        rebate.CategoryID !== MS_REBATE_CATEGORY_ID_EMPLOYEE_PROGRAM &&
        applicableTransactionTypes.includes(rebate.TransactionType) &&
        rebate.ReceipientType !== MSISRecipientType.Dealer,
    )
    .map((rebate) => {
      // const rebateQuestion = rebatesQuestions.find(
      //   (rebateQuestion) => rebateQuestion.rebate_name === rebate.Name,
      // );
      const appliedRebate = appliedRebates.find(
        (appliedRebate) => appliedRebate.ID === rebate.ID,
      );
      return {
        [SELECTED_REBATE_NAME_FIELD]: rebate.Name,
        msId: rebate.ID,
        // isQuestion: Boolean(rebateQuestion?.question?.text || rebate.Question),
        // text:
        //  rebateQuestion?.question?.text ||
        //  rebate.Question ||
        //  rebate.NameDisplay ||
        //  rebate.Name,
        // popoverText: rebateQuestion?.question?.description || '',
        text: uniqueNameDisplay(auctionMsisRebates, rebate),
        popoverText: rebate.Description,
        defaultPopoverText: NO_REBATE_DESCRIPTION,
        value: getRebateValue(rebate, msrp),
        valueApplied: appliedRebate
          ? getRebateValue(appliedRebate, msrp)
          : null,
        status: loading
          ? IncentiveStatus.loading
          : getRebateStatus(
              rebate,
              appliedRebates,
              selectedMsisRebates,
              autoSelectedRebates,
            ),
      };
    });
}

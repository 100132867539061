// https://developers.google.com/analytics/devguides/collection/gtagjs/pages

// install guide
// https://developers.google.com/analytics/devguides/collection/gtagjs#install_the_global_site_tag

// types added by @types/gtag.js

export const pageview = (url: string): void => {
  gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_path: url,
  });
};

export const signupEvent = (method = 'WEB'): void => {
  gtag('event', 'sign_up_1', {
    method,
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  });
};

export const bidEvent = (bidEventName: string): void => {
  gtag('event', bidEventName, {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  });
};

export const leaseItNowEvent = (leaseItNowEventName: string): void => {
  gtag('event', leaseItNowEventName, {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  });
};

export const experimentViewedEvent = (
  key: string,
  variationId: number,
): void => {
  gtag('event', 'experiment_viewed', {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    event_category: 'experiment',
    experiment_id: key,
    variation_id: variationId,
  });
};

export const offerCardClickEvent = (): void => {
  gtag('event', 'offer_card_click', {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    event_category: 'offer',
  });
};

export const offerCardFeaturesClickEvent = (): void => {
  gtag('event', 'offer_card_features_click', {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    event_category: 'offer',
  });
};
export const bookACallOpenEvent = (): void => {
  gtag('event', 'book_a_call_open', {
    send_to: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    event_category: 'book_a_call',
  });
};

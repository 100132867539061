import { StyledFontAwesomeIconMui5 } from '../FontAwesomeIcon';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { styled as styledMui5 } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';
import { FC } from 'react';

type SelectArrowMui5Props = {
  sx?: SxProps<ThemeMui5>;
  color?: string;
};

const ChevronIcon = styledMui5(StyledFontAwesomeIconMui5, { skipSx: false })(
  ({ theme }) => `
  color: inherit !important;
  right: ${theme.spacing(2)} !important;
  top: inherit !important;`,
);

export const SelectArrowMui5: FC<SelectArrowMui5Props> = ({
  color = 'primary',
  sx = () => ({}),
  ...restProps
}): JSX.Element => (
  <ChevronIcon icon={faChevronDown} color={color} sx={sx} {...restProps} />
);

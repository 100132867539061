import { FC, useCallback, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import Rest from 'services/rest';
import { User } from 'shared/types/models';
import PaymentCardForm from './PaymentCardForm';
import Alert from '@mui/material/Alert';

const rest = new Rest();

type Props = {
  submitText?: string;
  skipText?: string;
  onSubmit: () => void | Promise<void>;
  onSkip?: () => void;
  user: User;
  alignButtons?: 'left' | 'on-sides';
};

const PaymentCard: FC<Props> = ({
  onSubmit,
  onSkip,
  submitText,
  skipText,
  user,
  alignButtons = 'on-sides',
}) => {
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const createPaymentSetupIntent = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await rest.createPaymentSetupIntent();
      setClientSecret(data.client_secret);
    } catch (e) {
      setError(e.message || e.errors?.[0]?.msg);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    createPaymentSetupIntent();
  }, [createPaymentSetupIntent]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return (
      <Alert severity='error' variant='standard'>
        {error}
      </Alert>
    );
  }

  if (!isLoading && !clientSecret) {
    return (
      <Alert severity='error' variant='standard'>
        Unable to setup payment
      </Alert>
    );
  }

  return (
    <Elements
      options={{ clientSecret }}
      stripe={loadStripe(process.env.STRIPE_PUBLIC_KEY)}
    >
      <PaymentCardForm
        onSubmit={onSubmit}
        onSkip={onSkip}
        user={user}
        submitText={submitText}
        skipText={skipText}
        alignButtons={alignButtons}
      />
    </Elements>
  );
};

export default PaymentCard;

import { useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { BRAND_NAME } from 'shared/utils/constants';

declare global {
  interface Window {
    DD_RUM: any;
  }
}

const isDataDogBot = (router: NextRouter) => router.query.datadog === '';

export function useDatadogRum(): void {
  const router = useRouter();

  const isDatadogRumEnabled =
    process.env.IS_DATADOG_RUM_ENABLED && !isDataDogBot(router);

  useEffect(() => {
    if (isDatadogRumEnabled && !window?.DD_RUM?.getInternalContext()) {
      datadogRum.init({
        applicationId: process.env.DATADOG_RUM_APP_ID,
        clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: BRAND_NAME.toLowerCase().replace(/\s/g, ''),
        env: process.env.ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }, [isDatadogRumEnabled]);
}

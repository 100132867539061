import { CallbackInterface } from 'recoil';
import { userRebatesNamesSelector } from 'recoil/selectors';
import { auth } from 'services/auth';
import Rest from 'services/rest';
import {
  CLIENT_STORAGE_TYPE,
  LOCAL_STORAGE_TYPE,
  getClientStorage,
} from 'utils/ClientStorage';
import {
  userAtom,
  userClientRebatesNamesAtom,
  USER_REBATES_STORAGE_KEY,
} from '../atoms';
import { getUniqueStringArray } from '../../shared/utils/functions';

const rest = new Rest();

const clientStorage = getClientStorage(CLIENT_STORAGE_TYPE);
const localStorage = getClientStorage(LOCAL_STORAGE_TYPE);

type UpdateUserRebatesCallback = (
  params: CallbackInterface,
) => UpdateUserRebates;

type UpdateUserRebates = (
  rebatesNamesToRemove: string[],
  rebatesNamesToAdd: string[],
) => Promise<void>;

export const updateUserRebatesCallback: UpdateUserRebatesCallback =
  (params) => async (rebatesNamesToRemove, rebatesNamesToAdd) => {
    const newUserRebatesNames = await updateAtoms(
      params,
      rebatesNamesToRemove,
      rebatesNamesToAdd,
    );
    await updateUserRebates({
      rebateNames: newUserRebatesNames,
      isLocalStorage: true,
    });
  };

const updateAtoms = async (
  { snapshot, set }: CallbackInterface,
  rebatesNamesToRemove: string[],
  rebatesNamesToAdd: string[],
): Promise<string[]> => {
  const release = snapshot.retain();

  const user = await snapshot.getPromise(userAtom);

  const userRebatesNames = await snapshot.getPromise(userRebatesNamesSelector);

  const newUserRebatesNames = userRebatesNames
    .filter((rebateName) => {
      return !rebatesNamesToRemove.includes(rebateName);
    })
    .concat(rebatesNamesToAdd);

  const newUniqueUserRebatesNames = getUniqueStringArray(newUserRebatesNames);

  if (user) {
    set(userAtom, {
      ...user,
      rebates_names: newUniqueUserRebatesNames,
    });
  } else {
    set(userClientRebatesNamesAtom, newUniqueUserRebatesNames);
  }

  release();

  return newUniqueUserRebatesNames;
};

/**
 * This is generic rest call with fallback to client storage for unauthorized users
 */
const updateUserRebates = async ({
  rebateNames,
  isLocalStorage,
}: {
  rebateNames: string[];
  isLocalStorage?: boolean;
}) => {
  if (await auth.isAuthenticated()) {
    return rest.updateUserRebates({ rebateNames });
  } else {
    const storage = isLocalStorage ? localStorage : clientStorage;
    storage.set(USER_REBATES_STORAGE_KEY, rebateNames);
  }
};

import { moneyFormat } from 'shared/utils/functions';
import React from 'react';
import {
  UList,
  UListBody,
  UListItem,
  UListItemMain,
  UListKey,
  UListValue,
} from 'components/molecules/UList';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import Popover from '../../../components/molecules/Popover';
import { Loadable } from 'recoil';
import { GetProgramResponse } from 'shared/types/api/getProgram';
import { getDueAtSignin } from 'shared/utils/program';
import {
  PLACEHOLDER_BACKGROUND,
  PLACEHOLDER_FOREGROUND,
} from '../../../data/constants';
import ContentLoader from 'react-content-loader';

type Props = {
  program: Loadable<GetProgramResponse>;
  collapsible?: boolean;
  retractable?: boolean;
  keyText?: string;
};

function HelpPopover({ children, hidden }) {
  return !hidden && <Popover sx={{ padding: '0 0 0 3px' }}>{children}</Popover>;
}

export default function DueBreakdown({
  program,
  collapsible = false,
  retractable = false,
  keyText = 'Due at signing',
}: Props): JSX.Element {
  const [showBreakdown, setShowBreakdown] = React.useState(false);

  return (
    <UList>
      <UListBody>
        <UListItemMain>
          <UListKey>
            {keyText}
            <HelpPopover hidden={retractable}>
              A summary of your first month&apos;s payment, down payment and
              non-negotiable fees and taxes
            </HelpPopover>
          </UListKey>
          <UListValue>
            {program.valueMaybe() ? (
              moneyFormat(getDueAtSignin(program.valueMaybe()), true)
            ) : (
              <ContentLoader
                speed={2}
                width={100}
                height={15}
                viewBox='0 0 100 25'
                backgroundColor={PLACEHOLDER_BACKGROUND}
                foregroundColor={PLACEHOLDER_FOREGROUND}
              >
                <rect x='40' y='7' rx='2' ry='2' width='100' height='25' />
              </ContentLoader>
            )}
          </UListValue>
          {collapsible && (
            <td>
              <Box ml={0.5} mt={-0.5}>
                <IconButton
                  onClick={() => setShowBreakdown(!showBreakdown)}
                  size='small'
                >
                  <StyledFontAwesomeIconMui5
                    icon={showBreakdown ? faChevronUp : faChevronDown}
                    size='xs'
                  />
                </IconButton>
              </Box>
            </td>
          )}
        </UListItemMain>
        {(showBreakdown || !collapsible) && (
          <>
            {program
              .valueMaybe()
              ?.FullDetails.PartRecap.InceptionFees?.Item.map((item, index) => {
                const itemStringValue = item.StringValue.replace(/( @)/giu, '');
                if (itemStringValue === 'Security Deposit') {
                  return null;
                }
                return (
                  <UListItem key={index}>
                    <UListKey>{itemStringValue}</UListKey>
                    <UListValue>
                      {moneyFormat(item.DecimalValue, true)}
                    </UListValue>
                  </UListItem>
                );
              })}

            {program
              .valueMaybe()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore TODO: AmountFinanced present but missed in docs?
              ?.FullDetails.PartRecap.AmountFinanced?.Item.map(
                (item, index) => {
                  const itemStringValue = item.StringValue.replace(
                    /( @)/giu,
                    '',
                  );
                  if (itemStringValue === 'Security Deposit') {
                    return null;
                  }
                  return (
                    <UListItem key={index}>
                      <UListKey>{itemStringValue}</UListKey>
                      <UListValue>
                        {moneyFormat(item.DecimalValue, true)}
                      </UListValue>
                    </UListItem>
                  );
                },
              )}
            {program.valueMaybe()?.FullDetails.Retail
              ? program.valueMaybe()?.FullDetails.UI.map((item) => {
                  if (item.Name !== 'Fees') {
                    return null;
                  }
                  return item.Panels[0].Items.map((feeItem, index) => {
                    if (feeItem.Description !== 'Downpayment') {
                      return null;
                    }
                    return (
                      <UListItem key={index}>
                        <UListKey>{feeItem.Description}</UListKey>
                        <UListValue>{feeItem.Value}</UListValue>
                      </UListItem>
                    );
                  });
                })
              : null}
            {Boolean(program.valueMaybe()?.FullDetails.DownpaymentFromCash) &&
            program.valueMaybe()?.FullDetails.DownpaymentFromCash !==
              program.valueMaybe()?.FullDetails?.Retail?.TotalTaxesFees ? (
              <UListItem>
                <UListKey>Downpayment</UListKey>
                <UListValue>
                  {moneyFormat(
                    program.valueMaybe()?.FullDetails.DownpaymentFromCash,
                    true,
                  )}
                </UListValue>
              </UListItem>
            ) : null}
          </>
        )}
      </UListBody>
    </UList>
  );
}

import { CarConditions } from '../../types/models';
import { Makes } from '../../config/makes';
import { CustomRebateDefinition, GetCustomRebateParams } from './types';

export const getCustomRebateDefinitions = () => {
  return customRebateDefinitions;
};

const customRebateDefinitions: CustomRebateDefinition[] = [
  {
    id: 10000001,
    name: 'BMW Car Club of America',
    question:
      'Have you been a member of BMW Car Club of America (BMWCCA) for 12 months or greater?',
    description: 'BMW Car Club of America',
    startDate: '2020-11-15T15:41:18.819Z',
    endDate: '2025-11-15T15:41:18.819Z',
    make: Makes.BMW,
    minCreditScore: 0,
    maxCreditScore: 1000,
    // https://www.bmwcca.org/vehicle_rebate
    getValue: (params: GetCustomRebateParams): number => {
      if (params.condition === CarConditions.New) {
        if (
          [
            'X1',
            'X2',
            'M2',
            'X3',
            'M3',
            'X4',
            'M4',
            'Z4',
            '2 Series',
            '3 Series',
            '4 Series',
          ].includes(params.model)
        ) {
          return 500;
        }

        if (
          [
            'X5',
            'M5',
            'X6',
            'M6',
            'X7',
            'M7',
            'M8',
            '5 Series',
            '6 Series',
            '7 Series',
            '8 Series',
          ].includes(params.model)
        ) {
          return 1000;
        }
      }

      if (params.condition === CarConditions.Loaner) {
        if (['M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8'].includes(params.model)) {
          return 500;
        }

        return 250;
      }
    },
  },
  {
    id: 10000003,
    name: 'Driving Event',
    question:
      'Have you completed a recent BMW Driving Event and received an OL Code?',
    description: 'Driving Event',
    startDate: '2020-11-15T15:41:18.819Z',
    endDate: '2025-11-15T15:41:18.819Z',
    make: Makes.BMW,
    minCreditScore: 0,
    maxCreditScore: 1000,
    getValue: (): number => {
      return 500;
    },
  },
  {
    id: 10000002,
    name: 'Volve Custom Rebate #1',
    startDate: '2020-11-15T15:41:18.819Z',
    endDate: '2025-11-15T15:41:18.819Z',
    make: Makes.Volvo,
    minCreditScore: 0,
    maxCreditScore: 1000,
    getValue: (): number => {
      return 1000;
    },
  },
];

const userIncomes = [
  {
    value: 24999,
    label: '$0 to $24,999',
    isCosignerRequired: true,
  },
  {
    value: 49999,
    label: '$25,000 to $49,999',
    isCosignerRequired: true,
  },
  {
    value: 99999,
    label: '$49,999 to $99,999',
    isCosignerRequired: false,
  },
  {
    value: 149999,
    label: '$100,000 to $149,999',
    isCosignerRequired: false,
  },
  {
    value: 999999,
    label: '$150,000+',
    isCosignerRequired: false,
  },
];

export default userIncomes;

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import React from 'react';
import { StyledButtonMui5 } from './StyledButton';
import { ButtonProps as ButtonPropsMui5 } from '@mui/material/Button';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

interface Props extends ButtonPropsMui5 {
  loading: boolean;
  children: React.ReactNode;
}

const loadingIconStyles: SxProps<ThemeMui5> = (theme) => ({
  marginLeft: theme.spacing(1),
  minWidth: 2.5,
});

const LoadingButton = ({
  variant = 'contained',
  size = 'small',
  loading,
  children,
  ...restProps
}: Props): JSX.Element => {
  return (
    <StyledButtonMui5 variant={variant} size={size} {...restProps}>
      {children}
      {loading ? (
        <StyledFontAwesomeIconMui5
          icon={loading ? faCircleNotch : null}
          className={loading ? 'fa-spin' : ''}
          size='lg'
          sx={loadingIconStyles}
        />
      ) : null}
    </StyledButtonMui5>
  );
};

export default LoadingButton;

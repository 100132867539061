import { styled as styledMui5 } from '@mui/material/styles';
import SwitchMui5 from '@mui/material/Switch';

export const StyledSwitchMui5 = styledMui5(SwitchMui5, {
  shouldForwardProp: (prop) => prop !== 'scale',
})<{ scale?: number }>(
  ({ theme, scale = 1 }) => `
  &.MuiSwitch-root {
    width: ${scale * 28}px;
    height: ${scale * 16}px;
    padding: 0;
    display: flex;
    &:active {
      & .MuiSwitch-thumb {
        width: ${scale * 15}px;
      }
      & .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(${scale * 9}px);
      }
    }
    & .MuiSwitch-switchBase {
      position: absolute;
      padding: ${scale * 2}px;
      color: #fafafa;
      &.Mui-checked {
        transform: translateX(${scale * 12}px);
        color: #fff;
        & + .MuiSwitch-track {
          opacity: 1;
          background-color: ${theme.palette.primary.main};
        }
      }
    }
    & .MuiSwitch-thumb {
      box-shadow: 0 ${scale * 2}px ${scale * 4}px 0 rgb(0 35 11 / 20%);
      width: ${scale * 12}px;
      height: ${scale * 12}px;
      border-radius: ${scale * 6}px;
      transition: ${theme.transitions.create(['width'], {
        duration: 200,
      })};
    }
    & .MuiSwitch-track {
      border-radius: ${(scale * 16) / 2}px;
      opacity: 1;
      background-color: rgba(0,0,0,.25);
      box-sizing: border-box;
    }
  }`,
);

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useRecoilValue } from 'recoil';
import { cookieStringAtom } from 'recoil/atoms';
import Cookies from 'universal-cookie';
import { styled as styledMui5 } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { AlertColor } from '@mui/material';
import { StandardLonghandProperties } from 'csstype';

const Wrapper = styledMui5('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;`;

const generateAlertSeverityColor = (
  severity: AlertColor,
): Pick<StandardLonghandProperties, 'backgroundColor' | 'color'> => {
  switch (severity) {
    case 'success':
      return {
        backgroundColor: 'rgb(46, 125, 50)',
        color: 'rgb(255, 255, 255)',
      };
    case 'error':
      return {
        backgroundColor: 'rgb(211, 47, 47)',
        color: 'rgb(255, 255, 255)',
      };
    case 'warning':
      return {
        backgroundColor: 'rgb(237, 108, 2)',
        color: 'rgb(255, 255, 255)',
      };
    case 'info':
      return {
        backgroundColor: 'rgb(2, 136, 209)',
        color: 'rgb(255, 255, 255)',
      };
  }
};

const alertStyles: (severity: AlertColor) => SxProps<ThemeMui5> =
  (severity) => () => ({
    '&.MuiPaper-root.MuiAlert-root': {
      borderRadius: 0,
      // temporary fix for the alert not being colored correcly
      ...generateAlertSeverityColor(severity),
      '& .MuiAlert-action': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      },
    },
  });

const stopButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    fontSize: '0.8125rem',
    padding: theme.spacing(0.5, 0.625),
  },
});

export function useEmulated(): {
  isEmulated: boolean;
  emulatedEmail: string;
  stopEmulation: () => void;
} {
  const cookieString = useRecoilValue(cookieStringAtom);
  const cookies = new Cookies(cookieString);
  const emulate = cookies.get('emulate');

  return {
    isEmulated: Boolean(emulate),
    emulatedEmail: emulate,
    stopEmulation: () => {
      cookies.set('emulate', '', {});
      location.href = '/admin/users';
    },
  };
}

const EmulateBar = (): JSX.Element => {
  const { isEmulated, emulatedEmail, stopEmulation } = useEmulated();

  if (!isEmulated) {
    return null;
  }

  return (
    <Wrapper>
      <Alert
        className='js-emulate-bar'
        sx={alertStyles('error')}
        variant='filled'
        action={
          <Button
            className='js-stop-emulate'
            color='inherit'
            size='small'
            sx={stopButtonStyles}
            onClick={stopEmulation}
          >
            STOP
          </Button>
        }
        severity='error'
      >
        <b>Danger !!!</b> You are emulating
        <b> {emulatedEmail}</b>
      </Alert>
    </Wrapper>
  );
};

export default EmulateBar;

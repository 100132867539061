import LinkMui5, { LinkProps as LinkPropsMui5 } from '@mui/material/Link';
import { styled as styledMui5 } from '@mui/material/styles';
import { ElementType } from 'react';

const StyledLinkMui5 = styledMui5(LinkMui5, { skipSx: false })(
  ({ theme }) => `
  font-family: ${theme.typography.fontFamily};
  vertical-align: baseline;
`,
);

const Mui5Link = <C extends ElementType>(
  props: LinkPropsMui5<C, { component?: C }>,
): JSX.Element => <StyledLinkMui5 {...props} />;

export default Mui5Link;

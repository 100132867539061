import Alert from '@mui/material/Alert';
import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { lightThemeMui5 } from '../../theme';
import { useRecoilState } from 'recoil';
import { redirectBannerOpenStateAtom } from 'recoil/atoms';
import { useRouter } from 'next/router';

const BannerAlert = styled(Alert)(
  ({ theme }) => `
  &.MuiAlert-root {
    padding: ${theme.spacing(0, 2)};
    border-radius: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    & .MuiAlert-icon {
      display: none;
    }
    & .MuiAlert-message {
      font-weight: bold;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }`,
);

type BannerProps = {
  background?: string;
};

export const RedirectBanner: FC<BannerProps> = ({
  background = lightThemeMui5.palette.primary.main,
}) => {
  const [isOpened, setIsOpened] = useRecoilState(redirectBannerOpenStateAtom);
  const [isClosed, setIsClosed] = useState(false);
  const router = useRouter();
  const referer = router.query.ref;

  useEffect(() => {
    if (!isClosed && referer === 'leasebandit') {
      setIsOpened(true);
    }
  }, [isClosed, referer, setIsOpened]);

  return (
    <>
      {isOpened && (
        <BannerAlert
          variant='filled'
          style={{ background }}
          onClose={() => {
            setIsOpened(false);
            setIsClosed(true);
          }}
        >
          Welcome to Auto Bandit! 🚀 Formerly known as Lease Bandit, we’ve
          rebranded to better serve all your automotive needs.
        </BannerAlert>
      )}
    </>
  );
};

import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Checkbox from 'components/molecules/Checkbox';
import Popover from 'components/molecules/Popover';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { IncentiveStatus } from 'shared/incentives';
import { Permission } from 'shared/types/models';
import { UIRebate } from '../../../services/rebates/functions';
import { useScreenSize } from 'hooks/useScreenSize';
import { OnChangeRebates, OnSelectRebate } from './MultipleCheckboxSelect';
import { rebateBriefDescriptionSelector } from 'recoil/selectors';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { auctionAtom } from 'containers/deal/recoil/atoms';
import CircularProgress from '@mui/material/CircularProgress';
import { userAtom } from 'recoil/atoms';
import { SxProps } from '@mui/system';
import LinkMui5 from '@mui/material/Link';
import { styled as styledMui5 } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';

const PopoverOverflow = styledMui5('div')(
  ({ theme }) => `
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: ${theme.spacing(2)};
  margin-bottom: -${theme.spacing(1)};
  max-height: calc(100vh - ${theme.spacing(16 + 3 - 2)});
  margin-right: ${theme.spacing(-4)};
  margin-left: ${theme.spacing(-2)};
  max-width: 480px;
  padding: ${theme.spacing(0, 2, 2, 2)};
`,
);

const PopoverText = styledMui5(Typography)`
  &.MuiTypography-root {
    white-space: pre-wrap;
    max-width: inherit;
  }`;

const moreDetailsLinkStyles: SxProps<ThemeMui5> = (theme) => ({
  display: 'block',
  cursor: 'pointer',
  marginTop: theme.spacing(1),
});

const DISABLED_STATUS_ARRAY = [
  IncentiveStatus.autoApplied,
  IncentiveStatus.autoSelectedApplied,
  IncentiveStatus.autoSelectedNonStackable,
] as const;

const isDisabled = (item: UIRebate) =>
  DISABLED_STATUS_ARRAY.some((status) => status === item.status);

type Props = {
  item: UIRebate;
  selectedItemsIds: Array<string>;
  onChange?: OnChangeRebates;
  onSelect?: OnSelectRebate;
};

const formatCheckboxLabel = ({ item, isCompact }) => {
  if (!isCompact) {
    return item.text;
  }

  if (item.status === IncentiveStatus.autoApplied) {
    return `${item.text} (Auto-Applied)`;
  }

  return item.text;
};

export const CheckboxField = ({
  item,
  onChange,
  onSelect,
  selectedItemsIds = [],
}: Props): JSX.Element => {
  const { isExtraSmallScreen } = useScreenSize();
  const auction = useRecoilValue(auctionAtom);
  const user = useRecoilValue(userAtom);
  const isAdmin = user?.permissions.some((p) => p.name === Permission.admin);
  const popoverText = item.popoverText || item.defaultPopoverText;
  const briefDescriptionLoadable = useRecoilValueLoadable(
    rebateBriefDescriptionSelector({
      rebateId: item?.msId,
      auctionId: auction.id,
    }),
  );

  const [displayDefaultPopoverText, setDisplayDefaultPopoverText] =
    useState<boolean>(false);

  const toggleMoreDetailsClick = () => {
    setDisplayDefaultPopoverText(!displayDefaultPopoverText);
  };

  const handlePopoverClose = () => {
    setDisplayDefaultPopoverText(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    onSelect &&
      onSelect({
        item,
        selected: checked,
      });

    if (checked) {
      onChange && onChange([...selectedItemsIds, item.id]);
    } else {
      onChange &&
        onChange(
          selectedItemsIds.filter((prevItemId) => prevItemId !== item.id),
        );
    }
  };

  return (
    <Checkbox
      label={
        <>
          {formatCheckboxLabel({
            item,
            isCompact: isExtraSmallScreen,
          })}
          <Popover
            key={item.id}
            title={item.text}
            onClose={() => {
              handlePopoverClose();
            }}
            icon={<StyledFontAwesomeIconMui5 icon={faInfoCircle} size='sm' />}
          >
            <PopoverOverflow>
              <>
                {briefDescriptionLoadable?.state === 'loading' ? (
                  <CircularProgress size={20} />
                ) : (
                  <PopoverText color='textPrimary' variant='body2'>
                    {briefDescriptionLoadable.valueMaybe() || popoverText}
                  </PopoverText>
                )}
                {isAdmin &&
                  briefDescriptionLoadable.valueMaybe() &&
                  !displayDefaultPopoverText && (
                    <LinkMui5
                      sx={moreDetailsLinkStyles}
                      onClick={() => {
                        toggleMoreDetailsClick();
                      }}
                    >
                      Get more details
                    </LinkMui5>
                  )}
                {isAdmin && displayDefaultPopoverText && (
                  <>
                    <LinkMui5
                      sx={moreDetailsLinkStyles}
                      onClick={() => {
                        toggleMoreDetailsClick();
                      }}
                    >
                      Hide details
                    </LinkMui5>
                    <PopoverText color='textPrimary' variant='body2'>
                      {popoverText}
                    </PopoverText>
                  </>
                )}
              </>
            </PopoverOverflow>
          </Popover>
        </>
      }
      onChange={handleCheckboxChange}
      checked={
        isDisabled(item) ||
        selectedItemsIds.some((selectedItemId) => selectedItemId === item.id)
      }
      disabled={isDisabled(item)}
    />
  );
};

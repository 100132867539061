export type MSVehicleData = Omit<MSAPIVehicleItem, 'MakeID'>;

export type MSAPIRebateRequestParams = {
  VehicleID: number;
  ZIP: number;
  DealerZIP: number;
  DateTimeStamp: string;
  VehicleOwn?: MSISRebateVehicleOwn;
  OptionCode?: string;
};

export type MSAPIVehicleItem = {
  Bore?: number;
  CategoryID?: number;
  ClassID?: number;
  Cylinders?: number;
  Displacement?: number;
  EPAMPGAvg?: number;
  EPAMPGCity?: number;
  EPAMPGHwy?: number;
  ElectricRange?: number;
  GrossVehicleWeightRating?: number;
  ID?: number;
  Invoice?: number;
  Is2WheelDrive?: boolean;
  IsNew?: boolean;
  IsCar?: boolean;
  IsDomestic?: boolean;
  IsTruck?: boolean;
  IsSUV?: boolean;
  IsVAN?: boolean;
  IsMiniVAN?: boolean;
  MSRP?: number;
  MakeID?: number;
  MaxHP?: number;
  ModelID?: number;
  ModelNumber?: string;
  MsCode?: string;
  Shipping?: number;
  ShortDescription?: string;
  Stroke?: number;
  SubcategoryID?: number;
  Weight?: number;
  Year?: number;
  YearDisplay?: string;
  engineType?: number;
  GasGuzzlerValue?: number;
  EquipmentResidualizable?: MSAPIVehicleEquipmentResidualizable[];
  Option?: MSAPIVehicleOption[];
};

export type MSISCompatibilityData = {
  Compatibilities: MSISCompatibilityMatrix[];
  IncompatibleByCustomerType: number[];
};

export type MSAPIMRAMilesValues = {
  CalcType: number;
  MaxMiles: number;
  MaxOdoMiles: number;
  MinMiles: number;
  MinOdoMiles: number;
  Value: number[];
};

export type MSAPIMRALender = {
  Adjustment: MSAPIMRAMilesValues[];
  LenderCode: string;
  MakeModel: string;
  PolicyCode: string;
  ZIPSet: number;
};

export type MarketScanBmwTiers = string;

export type MSAPILeaseAdvanceCalcTypeEnum = number;

export type MSISVehicleEligibilityEnum = number;

export type MarseConstantsLoanDemoActionEnum = number;

export type MSAPIRetailAdvanceCalcTypeEnum = number;

export type MSISRebateRegion = {
  Name: string;
  RegionID: number;
};

export type MSAPIRDAdvance = {
  Type: MSAPIRetailAdvanceCalcTypeEnum;
  Value: number;
};

export type MSAPIIntFromToDecimal = {
  From: number;
  To: number;
  Value: number;
};

export type LoanDemoProgram = {
  Action: MarseConstantsLoanDemoActionEnum;
  EligibilityDate: string;
  EligibilityTypes: MSISVehicleEligibilityEnum[];
  ExtensionData: any;
};

export type MSAPILDAdvance = {
  Level: number;
  TypeNew: MSAPILeaseAdvanceCalcTypeEnum;
  Value: number;
};

export type MSAPILeaseRateCalcType = number;

export type MSAPIAPRLMFBreakTypeEnum = number;

export type MSAPILimitedProgram = {
  AcquisitionFee: number;
  AcquisitionFeeByTerm: number[];
  AcquisitionFeeWaived: boolean;
  APRLMF: number[];
  APRLMFBreak: number[];
  APRLMFBreakType: MSAPIAPRLMFBreakTypeEnum;
  BulletinCode: string;
  BulletinNo: string;
  CalcType: MSAPILeaseRateCalcType;
  CrvMRM: number;
  EquityAdj: MSAPIIntFromToDecimal;
  ExpirationDate: string;
  FlatFee: number;
  ID: number;
  IsCPO: boolean;
  IsLoanDemo: boolean;
  IsOnePay: boolean;
  IsSpecial: boolean;
  LeaseAdvance: MSAPILDAdvance[];
  LenderCode: string;
  LoanDemoProgramInfo: LoanDemoProgram;
  LTVAdj: MSAPIIntFromToDecimal;
  MaxCreditScore: number;
  MaxFinanced: number;
  MinCreditScore: number;
  MinFinanced: number;
  Name: string;
  PolicyCode: string;
  PreviousCustomer: boolean;
  ProgramCode: string;
  Residual: number[];
  RetailAdvance: MSAPIRDAdvance;
  Tier: Array<MarketScanBmwTiers>;
};

export type MSAPIRebateLimitedProgram = {
  Program: MSAPILimitedProgram[];
  RebateID: number;
};

export type MSISCompatibilityMatrix = {
  CompatibilityList: number[];
  RebateID: number;
};

export type MSISRebateCustomerType = {
  ID: number;
  IsMostCommon: boolean;
  Name: string;
};

export type MSISRebateValueTypeEnum = number;

export enum MSISRebateTypeEnum {
  Unknown = 0,
  CCR = 1,
  CCRInc = 2,
  Cash = 3,
  Certificate = 4,
  APR = 5,
}

export type MSISRebateTier = {
  Name: string;
  TierNo: 0 | 1;
};

export type MSISRecipientValuePair = {
  Type: MSISRecipientType;
  Value: number;
};

export type MSISRecipientValue = {
  Values: MSISRecipientValuePair[];
};

export type MSISRebateTerm = {
  End: number;
  Start: number;
  TransactionType: MSISTransactionTypeEnum;
  Value: MSISRecipientValue;
};

export type MSISRebateProgram = {
  Code: string;
  Excluded: boolean;
  IsMask: 0 | 1;
};

export enum MSISTransactionTypeEnum {
  SpecialLease = 1,
  StandardLease = 2,
  SpecialRetail = 3,
  StandardRetail = 4,
  Lease = 10,
  Retail = 11,
  Any = 1000,
}

export type MSISTransactionTypeValuePair = {
  Type: MSISTransactionTypeEnum;
  Value: number;
};

export type MSISLeaseRetailTypeValue = {
  Values: MSISTransactionTypeValuePair[];
};

export type MSAPIInfoPurposeOnlyValueType = number;

export enum MSISRecipientType {
  Undefined = 0,
  Customer = 1,
  Dealer = 2,
}

export type MSISCustomerType = {
  AutoSelect: boolean;
  ID: number;
  Recipient: MSISRecipientType;
};

export type MSISCertificateCodeQty = {
  Code: string;
  Qty: number;
};

export type MSISRebate = {
  // this is a custom IsCustom field, non market-scan
  IsCustom: boolean;
  // this is a custom description field, non market-scan
  Description: string;
  // this is a custom question field, non market-scan
  Question?: string;
  ID: number;
  MaxCreditScore: number;
  MinCreditScore: number;
  Name: string;
  NameDisplay: string;
  AvailableToAll?: boolean;
  CashCanBeUsedAsCCR?: boolean;
  CategoryID?: number;
  CCRIncrementals?: MSISRebate[];
  CertificateBaseValue?: number;
  CertificateCodeQtys?: Array<{
    Code: string;
    Qty: number;
  }>;
  CustomerTypes?: MSISCustomerType[];
  FinancialInstitution?: string;
  FinancialInstitutionID?: number;
  IdentCode?: string;
  InfoPurposeOnly?: boolean;
  IPOValue?: number;
  IPOValueType?: MSAPIInfoPurposeOnlyValueType;
  IsDealerSpecific?: boolean;
  IsGeneric?: boolean;
  LenderCode?: string;
  ManualValueInputRequired?: boolean;
  MaxTerm?: number;
  MaxValue?: number;
  MaxValues?: MSISLeaseRetailTypeValue;
  Number?: string;
  Option?: string[];
  Programs?: MSISRebateProgram[];
  ReceipientType?: MSISRecipientType;
  RevisionDate?: string;
  Selected?: boolean;
  StartDate?: string;
  StopDate?: string;
  SubcategoryID?: number;
  Terms?: MSISRebateTerm[];
  Tiers?: MSISRebateTier[];
  TransactionType?: MSISTransactionTypeEnum;
  Type?: MSISRebateTypeEnum;
  UpdateTS?: string;
  UseCashAsCCR?: boolean;
  Value: MSISLeaseRetailTypeValue;
  ValueType?: MSISRebateValueTypeEnum;
  VehicleStartDate?: string;
  VehicleStopDate?: string;
  VINSpecific?: boolean;
};

export type MSAPIRebateLimitedLeaseRetailData = {
  CustomerTypeID: number;
  CustomerTypes: MSISRebateCustomerType[];
  DealerZIP: string;
  GeneralCompatibilities: MSISCompatibilityMatrix[];
  InMem: boolean;
  LimitedLeaseRetailData: MSAPIRebateLimitedProgram[];
  Rebates: MSISRebate[];
  RegionID: number;
  Regions: MSISRebateRegion[];
  VehicleID: number;
  ZIP: string;
};

// --------------------------------------- run scan --------------------------------

/**
 * [MSISAPIScanRequest](http://help.mscanapi.com/html/4e8d4cb7-d50f-538e-dcef-bf81fe25a73b.htm)
 */
export type MSAPIPaymentScanAltered = {
  /**
   *  Selling Price Value
   */
  Price: number;
  /**
   *  Desired Payment Tolerance
   */
  DesiredPaymentTolerance: number;
};

/**
 * Option to Run Payment Scan by Selling Price. Currently Payment scans are "reversed" Profit scans.
 * [MSISAPIScanRequest](http://help.mscanapi.com/html/167c39a9-807a-beb4-268e-efd5c494b6a0.htm)
 */
export type MSAPIAltered = {
  /**
   * Additional Property to Pass Selling Price
   *
   * [MSAPIPaymentScanAltered](http://help.mscanapi.com/html/7e209736-45c1-8991-42f4-c837af43139b.htm)
   */
  PaymentScan: MSAPIPaymentScanAltered;
};

/**
 * [MSISAPIScanRequest](http://help.mscanapi.com/html/8c710dab-dcee-08ec-5744-e8ea715df2c0.htm)
 */
export type MSAPIAdditionalParams = {
  /**
   * Option to Run Payment Scan by Selling Price.
   *
   * Currently Payment scans are "reversed" Profit scans
   *
   * [Altered](http://help.mscanapi.com/html/167c39a9-807a-beb4-268e-efd5c494b6a0.htm)
   */
  Altered: MSAPIAltered;
  /**
   * Lowers the selling price when applying a dealer incentive in a Selling Price Scan transaction.
   */
  DealerIncentivesReducePrice: boolean;
  /**
   * Pass bool as true to return a leading CCR/APR rebate even if the rebate’s value is zero.
   *
   * By default we do not return the leading CCR/APR rebate if it's a zero value.
   */
  IncludeCCRAPRZeroValueRebates: boolean;
  /**
   * Lowers the selling price by the reserve amount in a Selling Price Scan transaction.
   */
  PaidReserveReducesPrice: boolean;
  /**
   * Zero's out Flat Fee reserve values.
   */
  ZeroOutFlatFee: boolean;
};

/**
 * Automatically apply Subcategory rebates.
 * Subcategory types - USAA, Farm Bureau, National Association of Realtors, etc.
 */
export type MSAPIRequestRebateCategory = {
  /**
   * Category Identifier.
   */
  ID: number;
  /**
   * Subcategory Identifier.
   */
  Subcategory?: number[];
};

export type MSISRebateVehicleOwnItem = {
  /**
   * String which contains a lender code or null value. Lender code can be found in the result returned by calling. GetRebatesLenders
   */
  FinancedBy: string;
  /**
   * Set bool to true for lease.
   */
  IsLease: boolean;
  /**
   * Set bool to true for Trade-In.
   */
  IsTradeIn: boolean;
  /**
   *  Date the lease ends.
   */
  LeaseEnds: string;
  /**
   * Set bool to true for mailer received coupon/rebate.
   */
  MailerReceived: boolean;
  /**
   * Make of vehicle.
   */
  Make: string;
  /**
   * Model of vehicle.
   */
  Model: string;
  /**
   * If vehicle has been sold, how many days has it been since the sold date?
   */
  SoldDays: number;
  /**
   * Year of vehicle.
   */
  Year: string;
};

export type MSISRebateVehicleOwn = {
  /**
   * Trade-in vehicle.
   */
  Item: MSISRebateVehicleOwnItem[];
};

/**
 * Apply rebates by ZIP Code.
 *
 * Applies to all rebates that are flagged "Available to All", applicable for the specified ZIP and RegionID.
 *
 * Can auto apply rebates by Category, VIN, Trade-In, and Vehicle Option Code.
 *
 * Option to exclude Dealer Cash rebates that are flagged "Available to All"
 *
 * [MSAPIAutoRebateParams](http://help.mscanapi.com/html/0b3408b1-a152-06c6-100e-a42bdc409907.htm)
 */
export type MSAPIAutoRebateParams = {
  /**
   * Apply rebates based on Category and Subcategory.
   *
   * Will only apply and return rebate if the rebate has a value and if the rebate is compatable with the rebates that are flagged as "Available To All".
   *
   * You can get the IDs for Category and Subcategory from GetRebatesCategories Method.
   *
   * [MSAPIRequestRebateCategory](http://help.mscanapi.com/html/7ef6b9ae-4fd1-8d48-8d9c-4e7ee989e9cd.htm)
   */
  Category: MSAPIRequestRebateCategory[];
  /**
   * Dealership zip code. Some manufacturers apply dealer incentives based on their ZIP.
   */
  DealerZIP?: string;
  /**
   * Pass bool as true to apply additional rebates through the through MSISAPIScanRequest.Rebates Property.
   */
  JoinNonAutoRebates: boolean;
  /**
   * Setting bool to true will exclude Dealer Cash rebates that are flagged "Available to All"
   */
  NoAvailableToAllDealerCash: boolean;
  /**
   * Vehicle option code.
   *
   * Some manufacturers have package/engine specific rebates.
   *
   * Send all option codes separated by comma/semiclon/space/pipe.
   *
   * If a specified package is found in a rebate the returned rebate will be automatically selected.
   *
   * This only applies to calculation methods - RunScan or GetProgramInfo.
   */
  OptionCode: string;
  /**
   * RegionID is used to verify the rebate is being applied to the correct geographical location. A zip code can fall into two seperate lender regions.
   */
  RegionID?: number;
  /**
   * Trade-In vehicle - If defined, rebates will be applied based of Trade-In information.
   *
   * Conquest, Loyalty, or any other rebates that requires a Trade-In will automatically be selected.
   *
   * [MSISRebateVehicleOwn](http://help.mscanapi.com/html/4f895705-2493-2ea7-020c-657b04048b43.htm)
   */
  VehicleOwn: MSISRebateVehicleOwn;
  /**
   *
   * VIN specific rebates.
   *
   * If a specified VIN is found in a rebate, the returned rebate will automatically be selected.
   *
   * Not all OEM's/Captive Lenders offer VIN specific rebates. GM always provides VIN specific rebates. All other lenders provide VIN specific rebates on a case by case basis.
   */
  VIN: string;
  /**
   * The customers 5 digit zip code should be used. Rebates are assigned by the customers geographical location.
   */
  ZIP: string;
};

export type MSAPICustomerBankruptcyTypeEnum = number;

/**
 * Type of Customer debt collection.
 *
 * [MSAPICustomerCollectionTypeEnum](http://help.mscanapi.com/html/81888dc0-1a56-6c39-f3f2-32e99bb0649d.htm)
 */
export type MSAPICustomerCollectionTypeEnum = number;

/**
 * Status of the amount owed to a third-party debt collector.
 *
 * [MSAPICustomerCollection](http://help.mscanapi.com/html/14a09ce7-355a-8113-ac50-31d9fd0a6b94.htm)
 */
export type MSAPICustomerCollection = {
  /**
   * Actual value that is owed.
   */
  Amount: number;
  /**
   * Date of when the amount owed went to collections.
   */
  Date: string;
  /**
   * Type of Customer debt collection.
   */
  Type: MSAPICustomerCollectionTypeEnum;
};

/**
 * Define Customer Information - Credit Info, First and Last Name, Total Monthly Income, etc.
 *
 * This data is primarily used for our Retail programs. A lender might have specific criterias that must be met like Time at current job or Short credit history.
 *
 * You will still return lender programs if this information is not passed.
 *
 * [MSAPICustomer](http://help.mscanapi.com/html/da01de01-bd09-efe0-193d-8b29f2e63103.htm)
 */
export type MSAPICustomer = {
  /**
   * Chapter 13
   *
   * [MSAPICustomerBankruptcyTypeEnum](http://help.mscanapi.com/html/0f61c28a-dbaf-5f9b-53f1-e1f23b7e2aab.htm)
   */
  Chapter13: MSAPICustomerBankruptcyTypeEnum;
  /**
   * Chapter 7
   *
   * [MSAPICustomerBankruptcyTypeEnum](http://help.mscanapi.com/html/0f61c28a-dbaf-5f9b-53f1-e1f23b7e2aab.htm)
   */
  Chapter7: MSAPICustomerBankruptcyTypeEnum;
  /**
   * A collection - a debt that has not been paid on time.
   *
   * [MSAPICustomerCollection](http://help.mscanapi.com/html/14a09ce7-355a-8113-ac50-31d9fd0a6b94.htm)
   */
  Collections: MSAPICustomerCollection[];
  /**
   * Number of open credit lines you have available.
   */
  CreditLinesNum: number;
  /**
   * Customer's Date of Birth.
   */
  DateOfBirth: string;
  /**
   * Date you opened your first credit line.
   */
  FirstDateInCreditBureau: string;
  /**
   * If bool is set to true customer is a First Time Buyer
   */
  FirstTimeBuyer: boolean;
  /**
   * Gross Monthly Income Joint
   */
  GrossMonthlyIncomeJoint: number;
  /**
   * If bool is set to true customer is delinquent on child support payments.
   */
  HasDelinquentChildSupport: boolean;
  /**
   * If bool is set to true customer has prior auto credit.
   */
  HasPriorAutoCredit: boolean;
  /**
   * The highest amount of credit the customer has borrowed.
   */
  HighCredit: number;
  /**
   * Original date of when the highest amount of credit was borrowed.
   */
  HighCreditDate: string;
  /**
   * If bool is set to true customer is a homeowner.
   */
  HomeOwner: boolean;
  /**
   * If bool is set to true customer is self-employed.
   */
  IsSelfEmployed: boolean;
  /**
   * If bool is set to true customer is a US citizen.
   */
  IsUSCitizen: boolean;
  /**
   * Customer's Last Name
   */
  LastName: string;
  /**
   * he date the last repossession occurred.
   */
  LastRepossessionAge: number;
  /**
   * If bool is set to true customer does not live in their own residence and instead lives with relatives.
   */
  LivesWithRelatives: boolean;
  /**
   * Military rank.
   */
  MilitaryRank: string;
  /**
   * Customers residential monthly payment.
   */
  MonthlyPayments: number;
  /**
   * How long the customer has been working at his current employment?
   */
  MonthsAtCurrentJob: number;
  /**
   * How long the customer has been living at his current home of residence?
   */
  MonthsAtCurrentResidence: number;
  /**
   * How long the customer has resided in the current geographical area?
   */
  MonthsInArea: number;
  /**
   * Customers part time income.
   */
  PartTimeIncome: number;
  /**
   * Customers totaly montly income.
   */
  TotalMonthlyIncome: number;
  /**
   * How long the customer has been working with the current employer?
   */
  TotalMonthsJobHistory: number;
  /**
   * Total number of bankruptcies.
   */
  TotalNumberOfBankruptcies: number;
  /**
   * Total number of repossessions.
   */
  TotalNumberOfRepossessions: number;
};

/**
 * Option to include XML for sending data to RouteOne eContracting.
 *
 * [MSAPIExtraFeatureRequest](http://help.mscanapi.com/html/e52b89c7-336e-56a7-e411-5a206a264525.htm)
 */
export type MSAPIExtraFeatureRequest = {
  /**
   * Setting bool to true will return XML for sending data to RouteOne eContracting.
   */
  IncludeRouteOneProcessCreditContractXML: boolean;
};

/**
 * [MSAPIValue2](http://help.mscanapi.com/html/abe21cbf-cdaa-3eb8-b9a3-8c36107eb99f.htm)
 */
export type MSAPIValue2 = {
  Val1: number;
  Val2: number;
};

/**
 * Filter Options:
 *
 * Exclude programs that collect a security deposit.
 *
 * Include Multiple Security Deposit, Balloon Note programs, and Waived Acquisition Fee programs that increase the rate.
 *
 * Filter results by terms.
 *
 * [MSAPIFilterLease](http://help.mscanapi.com/html/2e7dd2de-d18f-0359-a628-1c43c3fe8cf5.htm)
 */
export type MSAPIFilterLease = {
  /**
   * Setting bool to true will only return balloon programs and exclude all lease programs.
   */
  BalloonNotesOnly: boolean;
  /**
   * Setting bool to true will exclude programs that waive the acqusition fee by marking up the money factor.
   */
  DontIncludeAcquisitionFeeWaived: boolean;
  /**
   * Setting bool to true will exclude programs that collect a security deposit.
   */
  DontIncludeSecurityDeposits: boolean;
  /**
   * Setting bool to true will include Balloon programs.
   */
  IncludeBalloonNotes: boolean;
  /**
   * Setting bool to true will include First Payment Waived programs.
   */
  IncludeFirstPaymentWaived: boolean;
  /**
   * Setting bool to true will include Multiple Security Deposit programs.
   */
  IncludeMultipleSecurityDeposits: boolean;
  /**
   * [MSAPIFilterLease.MinMaxPayment](http://help.mscanapi.com/html/387f0114-8c7b-9bf0-980c-332b487601f1.htm)
   */
  MinMaxPayment: MSAPIValue2;
  /**
   * Array of Terms - Filter results by terms.
   */
  Terms: number[];
};

/**
 * Filter Options:
 *
 * Filter results by terms.
 *
 * [MSAPIFilterRetail](http://help.mscanapi.com/html/f8e8e64a-a2c5-c44a-13cb-1bb99894501a.htm)
 */
export type MSAPIFilterRetail = {
  MinMaxPayment: MSAPIValue2;
  Terms: number[];
};

/**
 * Filter lender programs:
 *
 * Include XML for sending data to RouteOne eContracting.
 *
 * Exclude programs that collect a security deposit.
 *
 * Include First Payment Waived programs.
 *
 * Include Multiple Security Deposit, Balloon Note programs, and Waived Acquisition Fee programs that increase the rate.
 *
 * Inclue programs that exceeed Past Advance, Down payment and Paid Reserve policies.
 *
 * [MSAPIFilter](http://help.mscanapi.com/html/d64e2143-5d25-b021-ffeb-0c20a5e56f77.htm)
 */
export type MSAPIFilter = {
  /**
   * Option to include XML for sending data to RouteOne eContracting.
   *
   * [MSAPIExtraFeatureRequest](http://help.mscanapi.com/html/e52b89c7-336e-56a7-e411-5a206a264525.htm)
   */
  ExtraFeature: MSAPIExtraFeatureRequest;
  /**
   * Filter out Flat Fee and reserve programs that are based on a percentage of the cap cost.
   */
  IncludeFlatFeeCapCostParticipation: boolean;
  /**
   * Filter for a speific term.  If no term is defined you will receive data for all terms.
   */
  IncludeOnlyTerm: number;
  /**
   * Setting bool to true will include the lender programs that have exceeded the maximum advance policy.
   */
  IncludePastAdvance: boolean;
  /**
   * Setting bool to true will include the lender programs that have exceeded the maximum down payment policy.
   */
  IncludePastDownpayment: boolean;
  /**
   * Setting bool to true will include the lender programs that have exceeded the maximum paid reserve policy.
   */
  IncludePastPaidReserve: boolean;
  /**
   * Setting bool to true will include previous customer lender programs.
   */
  IncludePreviousCustomer: boolean;
  /**
   * Include Multiple Security Deposit, Balloon Note programs, and Waived Acquisition Fee programs that increase the rate.                          Option to Filter results by term.
   *
   * [MSAPIFilter.Lease](http://help.mscanapi.com/html/bbeaede4-e651-7179-b8b3-efdec5441b2a.htm)
   */
  Lease: MSAPIFilterLease;
  /**
   * Only used in calls to GetProgramInfo.
   */
  ProgramID: number;
  /**
   * Alphanumeric String ID.  Only used in calls to GetProgramInfo.
   */
  ProgramXID: string;
  /**
   * Option to Filter results by term.
   *
   * [MSAPIFilter.Retail](http://help.mscanapi.com/html/4609d694-1701-2c3c-d7c2-e1def4d85274.htm)
   */
  Retail: MSAPIFilterRetail;
};

/**
 *
 */
export type MSAPIState = number;

/**
 * Vehicle residency, where the vehicle will be garaged.
 *
 * Used in Ohio for retail scans. The tax base formula changes for taxes if the Garaged state is not Ohio.
 *
 * For TFS - Toyota Financial services, if exporting to RouteOne this property will be used to calculate personal property taxes.
 *
 * [MSAPIGaragedInfo](http://help.mscanapi.com/html/a5c9556c-9e0f-bdc8-44fc-ad419f2907fe.htm)
 */
export type MSAPIGaragedInfo = {
  /**
   * City where the vehicle will be garaged.
   */
  City: string;
  /**
   *
   Sales tax percent where the vehicle will be garaged.
   */
  SalesTaxPct: number;
  /**
   * State where the vehicle will be garaged.
   *
   * [MSAPIGaragedInfo.State](http://help.mscanapi.com/html/9426115d-4364-51d3-04c7-1ac80c05cd2d.htm)
   */
  State: MSAPIState;
};

/**
 * If initialized the response will contain fields which correspond to the properties set to true.
 *
 * [MSAPIIncludeScanResultField](http://help.mscanapi.com/html/b8892848-d821-f93c-5743-22b9c9ac7ebb.htm)
 */
export type MSAPIIncludeScanResultField = {
  /**
   * Lender Residual - used to tell which source a lender is using to calculate residual values.
   */
  AdvanceResidual: boolean;
  /**
   * Lender Advance - used to tell which source a lender is using to calculate advance values.
   */
  AdvanceSource: boolean;
  /**
   * If included and set the bool to true will return the cash in the result properties.
   */
  Cash: boolean;
  /**
   * Excess mileage charge.
   */
  CostPerMile: boolean;
  /**
   * Generic CRV value for used vehicles and MRM value for new vehicles.
   */
  CrvMRM: boolean;
  /**
   * Demo mileage penalty.
   */
  DemoMilesPenaltyMiles: boolean;
  /**
   * Demo mileage charge.
   */
  DemoMilesRate: boolean;
  /**
   * Demo mileage residual adjustment.
   */
  DemoMilesResidualAdjustment: boolean;
  /**
   * Setting bool to true will return the expiration date in the response.
   */
  ExpirationDate: boolean;
  /**
   * If bool is true and the \"MSAPIFilterLease.IncludeFirstPaymentWaived\" property is passed as true, the First Payment Waived Amount will show.
   */
  FirstPaymentWaivedAmount: boolean;
  /**
   * If included and set bool to true will return all GetProgramInfo result properties.
   */
  FullProgramDetails: boolean;
  /**
   * Setting bool to true will return the Lender Name in the response.
   */
  LenderName: boolean;
  LTV: boolean;
  PaidReserve: boolean;
  PVI: boolean;
  /**
   * Used for requests that are using quoting policies from the Dealer Portal.                        Setting bool to true will return the desired selling price that was defined by the quoting policy rules.
   */
  QuotedDesiredValue: boolean;
  /**
   * Used for requests that are using quoting policies from the Dealer Portal.                        Setting bool to true will return the quoting policy rule description that was applied to the request.
   */
  QuotingPolicyUsed: boolean;
  RegistrationFee: boolean;
  /**
   * If included and bool is set to fallse you will not return UI results.
   */
  UIJson: boolean;
};

/**
 * Increase/markup the lender bank fee for additional profit.
 *
 * [MSAPIRequestBankFeeMarkup](http://help.mscanapi.com/html/54586db3-546e-392a-7953-df81c938ef50.htm)
 */
export type MSAPIRequestBankFeeMarkup = {
  /**
   * Lender code to apply bank fee markup value.
   */
  LenderCode: string;
  /**
   * Will increase the bank fee by the defined value.
   */
  Value: number;
};

/**
 * Pass enumerator of 1 to tax the full amount of Cash.
 *
 * If not defined, or passed as 0, the engine will use the Cash to pay inception fees first, any left over money will be used as a down payment and tax on the down payment. No additonal money is asked for taxes.
 *
 * [MSAPITaxCollectionMethod](http://help.mscanapi.com/html/d9d11b1d-2d4b-411c-b351-4ed4b70c0e5a.htm)
 */
export enum MSAPITaxCollectionMethod {
  /**
   * Down Payment and Tax amount will be taken out of the Non-CCR value
   */
  InValue = 0,
  /**
   * The full amount of the Non-CCR value will be used as a downpayment, and then taxed seperately
   */
  OnTop = 1,
}

/**
 * Allows to pass the different type of Fee without having to define the service contract and insurance classes
 * This is usefull when states tax the fee differently
 *
 * THIS IS ONLY FOR THE STATE OF CONNECTICUT
 *
 * If Enumerator of 0 is passed luxury tax will be included in the calculation.
 * If Enumerator of 1 or 2 is passed luxury tax will not be included in the calculation.
 *
 * [CustomFeeTypeEnum](http://help.mscanapi.com/html/65ac394e-2036-f099-ca0c-9e2af768bce0.htm)
 */

export enum CustomFeeTypeEnum {
  /**
   * Define a dealer Fee
   *
   * Luxury tax will be included in the calculation.
   */
  Fee = 0,
  /**
   * Define a Service Contract Fee
   *
   * Luxury tax will not be included in the calculation.
   */
  ServiceContract = 1,
  /**
   * Define a Insurance Fee
   *
   * Luxury tax will not be included in the calculation.
   */
  Insurance = 2,
}

/**
 * Define account custom fees.
 *
 * [MSAPICustomFeeLease](http://help.mscanapi.com/html/8aba7751-7778-2ceb-14b9-0e0c9a003b8c.htm)
 */
export type MSAPICustomFeeLease = {
  /**
   * Cost. (Inherited from MSAPICustomFee.)
   */
  Cost: number;
  /**
   * Name of the custom fee. (Inherited from MSAPICustomFee.)
   */
  Description: string;
  /**
   * Identifier. (Inherited from MSAPICustomFee.)
   */
  ID: number;
  /**
   * Setting bool to true will collect the custom fee in the cap cost.
   */
  InCap: boolean;
  /**
   * Allows to pass the different type of Fee without having to define the service contract and insurance classesThis is usefull when states tax the fee differentlyTHIS IS ONLY FOR THE STATE OF CONNECTICUTIf Enumerator of 0 is passed luxury tax will be included in the calculation.If Enumerator of 1 or 2 is passed luxury tax will not be included in the calculation. (Inherited from MSAPICustomFee.)
   *
   * [StrongType](http://help.mscanapi.com/html/82e0182e-ff4a-0db9-8d52-a124dd2b89b7.htm)
   */
  StrongType?: CustomFeeTypeEnum;
  /**
   * Setting bool to true will tax the custom fee. (Inherited from MSAPICustomFee.)
   */
  Taxable: boolean;
  /**
   * Inherited from MSAPICustomFee.)
   */
  TaxedAsAftermarketProduct?: boolean;
  /**
   * Setting bool to true will collect the tax in the cap cost.
   */
  TaxInCap: boolean;
  /**
   * Define/categorize the custom fee. MSAPICustomFee Class - valid Type values (used for RouteOne eContracting)MSAPIServiceContract Class - valid Type values (used for RouteOne eContracting)MSAPIInsurance Class - valid Type values (used for RouteOne eContracting) (Inherited from MSAPICustomFee.)
   */
  Type: string;
  /**
   * Default custom fee value. If being used in GetProductPayment Method, this would be the Minimum value of the product. (Inherited from MSAPICustomFee.)
   */
  Value: number;
};

/**
 * Equipment adds that reduce the residual value.
 *
 * [MSAPIResidualDeductionEnum](http://help.mscanapi.com/html/b0e32905-a718-fd3e-565d-036978073137.htm)
 */
export enum MSAPIResidualDeductionEnum {
  /**
   * Without Air Conditioning.
   */
  NoAirConditioner = 0,
  /**
   * Manual Transmission.
   */
  ManualTransmission = 1,
  /**
   * Commercial Use - Do not confuse this with commercial rate or residual programs as we do not track them. If a lender allows for commercial use, they will reduce the residual value by the lender reduction value.
   */
  CommercialUse = 2,
}

/**
 *
 */

export type MSAPIIntDecimal = {
  /**
   * Decimal value.
   *
   * When used in MSAPIScanRequestLeaseGeneric.Residual the Decimal Value should be applied as a whole number and not a decimal format. 10 vs 0.10.
   */
  DecimalValue: number;

  /**
   * When used with MSAPIScanRequestLeaseGeneric.Rate, MSAPIScanRequestLeaseGeneric.AcquisitionFee, MSAPIScanRequestLeaseGeneric.Residual properties the IntValue will apply to the Monthly Term.
   *
   * When used with MSAPIMileagePenalty.Data Property the IntValue will be used as the starting point for the mileage penalty charge. If a lender states to charge $0.10 per mileage for any miles driven after 15,000 miles, you would define 15,000 in this property.
   */
  IntValue: number;
};

/**
 *
 */
export type MSAPILeaseAdvance = {
  /**
   * Lease advancee calculation type.
   */
  Type: MSAPILeaseAdvanceCalcTypeEnum;
  /**
   * Lease advance value.
   */
  Value: number;
};

export type MSAPIMileagePenalty = {
  /**
   * Array of excess mileage penalty values and starting point for the mileage penalty charge.
   */
  Data: MSAPIIntDecimal[];

  /**
   * Total allowable miles for the term of the lease.
   */
  MaxMiles: number;

  /**
   * Term that the excess mileage charge will be applied to.
   */
  Term: number;
};

export type MSAPIMileageResidual = {
  /**
   * Array of mileage value residual adjustments and monthly term the value will apply to.
   */
  Data: MSAPIIntDecimal[];
  /**
   * Amount of free miles that will not receive a residual adjustment.
   */
  FreeMiles: number;
  /**
   * Max annual mileage threshold.
   */
  MaxAnnualMiles: number;
  /**
   * Max demo miles threshold.
   */
  MaxDemoMiles: number;
  /**
   * Minimum annual mileage threshold.
   */
  MinAnnualMiles: number;
  /**
   * Minimum demo mileage threshold.
   */
  MinDemoMiles: number;
};

/**
 * Mileage residual adjustments.
 *
 * [MSAPILeaseMileage](http://help.mscanapi.com/html/8c0c22a6-8ca9-1fc6-da61-35275f7e0c18.htm)
 */
export type MSAPILeaseMileage = {
  /**
   * Excess annual mileage charges.
   *
   * [Penalty](http://help.mscanapi.com/html/63ddf623-d8dd-6439-82dc-2c0af37d3a37.htm)
   */
  Penalty: MSAPIMileagePenalty[];
  /**
   * Upfront mileage resdiual adjustments.
   * [ResidualAdjustment](http://help.mscanapi.com/html/6239caff-a31c-4fcb-66f6-5d50e6930066.htm)
   */
  ResidualAdjustment: MSAPIMileageResidual[];
};

/**
 * Participation/spread reserve structure type.
 *
 * [MSAPISpreadParticipationOverrideTypeEnum](https://help.mscanapi.com/html/68716d3c-fc84-d874-78fa-8f711646cc79.htm)
 */
export enum MSAPISpreadParticipationOverrideTypeEnum {
  RateMarkup = 0,
  CapCostParticipation = 1,
  FlatFee = 2,
}

export type MSAPIPaidReserveParameters = {
  /**
   * Amount of Paid Reserve the dealer should receive.
   *
   * For a Generic Lease Scan, this split only applies when you are marking up the rate.
   *
   * For a Generic Retail Scan, this split should be used regardless of structure type.
   *
   * Use a whole number - If dealer should receive full amount then value should be set to 100.
   *
   * If dealer should receives 70% of the paid reserve then value should be set to 70, etc.
   */

  Split: number;
  /**
   * Participation/spread reserve structure type. (RateMarkup = 0, CapCostParticipation = 1, FlatFee = 2)
   *
   * [Type](http://help.mscanapi.com/html/38c843d9-ce1e-ddb0-4cc8-ed8d948b2fa3.htm)
   */
  Type: MSAPISpreadParticipationOverrideTypeEnum;
  /**
   * Flat Fee Dollar Value or Cap Cost Participation Percentage (depending on which structure type you are passing) If wanting to pass RateMarkUp please use the RateMarkUp class instead.
   */
  Value: number;
};

export type MSAPIPPTaxLenderTypeEnum = number;

export type MSAPIGenericRebateTerm = {
  /**
   * Define the end term of the rebate/dealer incentive.
   */
  End: number;
  /**
   * Defines the value of the generic dealer incentive.
   */
  Incentive: number;
  /**
   * Define the start term of the rebate/dealer incentive.
   */
  Start: number;
  /**
   * Setting Bool to true will treat the rebate like cash.
   */
  UseAsCash: boolean;
  /**
   * Defines the value of the generic rebate.
   */
  Value: number;
};

export type MSAPIGenericRebate = {
  /**
   * Sets the override value for a customer rebate.
   */
  Cash: number;
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Rebate incentive code.
   */
  IdentCode: string;
  /**
   * Sets the override value for a dealer incentive.
   */
  Incentive: number;
  /**
   * Name of the rebate.
   */
  Name: string;
  /**
   * Rebate program code.
   */
  Number: string;
  /**
   * Start date of the rebate.
   */
  StartDate: string;
  /**
   * End date of the rebate.
   */
  StopDate: string;
  /**
   * An array for the start date, end date, and term of the generic rebate.
   *
   * [Term](http://help.mscanapi.com/html/da3f9bf3-aa69-9ab4-4564-c19bd17929b9.htm)
   */
  Term: MSAPIGenericRebateTerm[];
};

/**
 * Security Deposit rounding calculation type.
 *
 * [MSAPISecurityDepositCalcTypeEnum](https://help.mscanapi.com/html/3b3e0faf-18d6-d0e6-e3a0-310c5ceb545f.htm)
 */
export enum MSAPISecurityDepositCalcTypeEnum {
  RoundedMinimum = 0,
  FlatAmountBasedOnPrice = 1,
  RoundedMaximum = 2,
  RoundedAdd = 3,
  RoundedMinimumMultiply = 4,
}

/**
 * Parameters on how the security deposit will be applied.
 *
 * [MSAPISecurityDeposit](http://help.mscanapi.com/html/8fc19bb1-8024-debe-180a-7eafe7537985.htm)
 */
export type MSAPISecurityDeposit = {
  /**
   * Value to be added to the Acquisition Fee when the the Security Deposit is waived.
   */
  AcquisitionFeeAdjustment: number;
  /**
   * Define a specific Security Deposit Value.Minimum Selling Price threshold and value.Must be used with Type=1.
   */
  Data: MSAPIValue2[];
  /**
   * Minimum or maximum Security Deposit.
   */
  MinOrMaxValue: number;
  /**
   * Minimum rate the Security Deposit can be adjusted down.
   */
  MinRate: number;
  /**
   * Number of Security Deposits to be applied.
   */
  Number: number;
  /**
   * If bool is set to true, the lender program will be allowed to collect participation(paid reserve).  Some lenders do not allow you to collect participation.
   */
  ParticipationAllowed: boolean;
  /**
   * Rate adjustment value that will be added to the money factor when the Security Deposit is waived.
   */
  RateAdjustment: number;
  /**
   * Dollar amount to be rounded to when collecting a Security Deposit.
   */
  RoundTo: number;
  /**
   * Security Deposit rounding calculation type.
   *
   * [Type](http://help.mscanapi.com/html/d21207d4-8415-2b1b-0aa0-2ccc99beb9ae.htm)
   */
  Type: MSAPISecurityDepositCalcTypeEnum;
};

/**
 * Define generic lease parameters.
 *
 * [MSAPIScanRequestLeaseGeneric](http://help.mscanapi.com/html/d9fdce50-88ad-20cf-5cc4-60fb2df9f115.htm)
 */
export type MSAPIScanRequestLeaseGeneric = {
  /**
   * Generic lease acquisition fee.
   *
   * [AcquisitionFee](http://help.mscanapi.com/html/329ddf57-3c83-6a82-110d-02b42a3aad81.htm)
   */
  AcquisitionFee: MSAPIIntDecimal[];
  /**
   * Generic lease acquisition fee for all terms.
   */
  AcquisitionFeeAllTerms: number;
  /**
   * Generic lease advance values.
   *
   * [Advance](http://help.mscanapi.com/html/c4c55481-9d43-7143-32fe-7c6df70a3950.htm)
   */
  Advance: MSAPILeaseAdvance;
  /**
   * Generic lease rate calculation type.
   */
  CalcType: MSAPILeaseRateCalcType;
  /**
   * Generic CRV value for used vehicles and MRM value for new vehicles.
   */
  CRVMRM: number;
  /**
   * Set bool to true to add EquipmentResidualAdjustment value to the adjusted calculated residual value.                        If bool is true and CRVMRM is defined the EquipmentResidualAdjustment value will not be added to the adjusted calculated residual value.                        If bool is false EquipmentResidualAdjustment value will be added to MSRP.
   */
  EquipmentAdjustResidualNotMSRP: boolean;
  /**
   * Generic Equipment Advance Adjustment - value will be added to the advance calculation.                        You must define Equipment in the MSISAPIScanRequest.VehicleEquipment Property.                        The defined vehicle Equipment must come from the GetEquipmentResidualizable Method.
   */
  EquipmentAdvanceAdjustment: number;
  /**
   * Generic Equipment Residual Adjustment - value will be added to the residual value calculation.                        You must define Equipment in the MSISAPIScanRequest.VehicleEquipment Property.                        The defined vehicle Equipment must come from the GetEquipmentResidualizable Method.
   */
  EquipmentResidualAdjustment: number;
  /**
   * If bool is set to true the first monthly lease payment will be waived.
   */
  FirstPaymentWaived: boolean;
  /**
   * Define Max First Payment Waived Amount
   */
  FirstPaymentWaivedMaxAmount: number;
  /**
   * Generic mileage calculation adjustments.
   *
   * [Mileage](http://help.mscanapi.com/html/e479a68e-c885-2183-9d95-235adfb3f93e.htm)
   */
  Mileage: MSAPILeaseMileage;
  /**
   * Pass bool as true to round monthly calculated payment to two digits when dividing payment by term.
   */
  OnePay2DigitsAfterCommaMonthlyPayment: boolean;
  /**
   * To be used to define Flat Fee and Percentage of Cap Cost Reserve.                        For Rate Markup, continue to use the LeasePart RateMarkUp. or                 and  or LeasePart Generic Participation. If you choose to you can use the Dealer Split from this PaidReserveParameter property instead.
   *
   * [PaidReserveParameters](http://help.mscanapi.com/html/6e35e98a-0b9c-cb88-fc42-4ced5ffcedef.htm)
   */
  PaidReserveParameters: MSAPIPaidReserveParameters;
  /**
   * Generice lease paid reserve.
   */
  Participation: number;
  /**
   * Lenders that collect property tax.
   *
   * [PPTaxLenderType](http://help.mscanapi.com/html/9af157ff-cd61-8540-0b77-18542f7e2375.htm)
   */
  PPTaxLenderType: MSAPIPPTaxLenderTypeEnum;
  /**
   * Generic lease moneyfactor/interest rate.
   *
   * [Rate](http://help.mscanapi.com/html/93c1628b-5c59-c307-ad74-4fb4905951e3.htm)
   */
  Rate: MSAPIIntDecimal[];
  /**
   * Generic rebate value.
   *
   * [Rebate](http://help.mscanapi.com/html/bea28232-1ec3-e731-3800-923e50e6a6a5.htm)
   */
  Rebate: MSAPIGenericRebate;
  /**
   * [RebateEx](http://help.mscanapi.com/html/48b473fd-d575-eb13-4ea9-bb4ca8edd7dc.htm)
   */
  RebateEx: MSAPIGenericRebate[];
  /**
   * Generic residual value.                        For USED vehicles pass the residual value as a dollar amount and not as a percentage.
   *
   * [Residual](http://help.mscanapi.com/html/87318e73-f59f-8ce0-946a-bbd88b7a9840.htm)
   */
  Residual: MSAPIIntDecimal[];
  /**
   * Define how the security deposit will be applied.
   *
   * [SecurityDeposit](http://help.mscanapi.com/html/69116033-d9c2-87a7-8de4-406c09eda7b2.htm)
   */
  SecurityDeposit: MSAPISecurityDeposit[];
  /**
   *
   */
  SecurityDepositWaived: boolean;
  /**
   * Use security deposit index.
   */
  UseSecurityDepositIndex: number;
};

/**
 * Insurance information.
 *
 * [MSAPIInsurance](http://help.mscanapi.com/html/34be6cc1-68d5-1aec-951a-ffdd6aa4397a.htm)
 */
export type MSAPIInsurance = {
  /**
   * Insurance company name.
   */
  CompanyName: string;
  /**
   * Cost. (Inherited from MSAPICustomFee.)
   */
  Cost: number;
  /**
   * Name of the custom fee. (Inherited from MSAPICustomFee.)
   */
  Description: string;
  /**
   * Insurance start date.
   */
  EffectiveDate: string;
  /**
   * Setting bool to true will finance the insurance in a lease transaction.  For a retail transaction, the insurance fee/value will always be financed.
   */
  Financed: boolean;
  /**
   * Identifier. (Inherited from MSAPICustomFee.)
   */
  ID: number;
  /**
   * Allows to pass the different type of Fee without having to define the service contract and insurance classesThis is usefull when states tax the fee differentlyTHIS IS ONLY FOR THE STATE OF CONNECTICUTIf Enumerator of 0 is passed luxury tax will be included in the calculation.If Enumerator of 1 or 2 is passed luxury tax will not be included in the calculation. (Inherited from MSAPICustomFee.)
   */
  StrongType: CustomFeeTypeEnum;
  /**
   * Setting bool to true will tax the custom fee. (Inherited from MSAPICustomFee.)
   */
  Taxable: boolean;
  /**
   * (Inherited from MSAPICustomFee.)
   */
  TaxedAsAftermarketProduct: boolean;
  /**
   * Length of insurance contract. Used for reporting purposes.
   */
  Term: number;
  /**
   * Define/categorize the custom fee. MSAPICustomFee Class - valid Type values (used for RouteOne eContracting)MSAPIServiceContract Class - valid Type values (used for RouteOne eContracting)MSAPIInsurance Class - valid Type values (used for RouteOne eContracting) (Inherited from MSAPICustomFee.)
   */
  Type: string;
  /**
   * Default custom fee value. If being used in GetProductPayment Method, this would be the Minimum value of the product. (Inherited from MSAPICustomFee.)
   */
  Value: number;
};

/**
 * Defines Lender Calc Type and name of Lender.
 *
 * [MSAPILenderCalcType](http://help.mscanapi.com/html/958ba9cc-511f-e3cb-a47b-e5dd105fb080.htm)
 */

export type MSAPILenderCalcType = {
  /**
   * Lender calc type
   */
  CalcType: MSAPILeaseRateCalcType;
  /**
   * Name
   */
  Name: string;
};

/**
 * Define lender tax credits.
 *
 * [MSAPILenderTaxExempt](http://help.mscanapi.com/html/cf578d35-d11b-a867-3565-1e4b3d1a09eb.htm)
 */

export type MSAPILenderTaxExempt = {
  /**
   * Lender is used to apply the tax credit value to the correct lender.
   *
   * Lender is actually the Lender Code. LenderCode can be found in the GetLenders Method response - MSAPILender.Code.
   */
  Lender: string;
  /**
   * Lender tax credits - Defining a value will lower the taxable value.
   */
  Value: number;
};

/**
 * Define a value to overwrite, and the term the overwritten value will be applied to./>.
 */
export type MSAPIOverrideTermValue = {
  /**
   * Monthly Term
   */
  Term: number;
  /**
   * Value to overwrite
   */
  Value: number;
};

/**
 * Override Lease Rate, Lease OnePay Rate, Residual or Max Advance Value.
 *
 * [MSAPILeaseOverrideTypeEnum](https://help.mscanapi.com/html/0c4e1f6b-04d6-1717-02ff-0b25b77019f3.htm)
 */
export enum MSAPILeaseOverrideTypeEnum {
  /**
   * Identifier for residual value to be overwritten.
   */
  Residual = 0,
  /**
   * Identifier for rate value to be overwritten.
   */
  Rate = 1,
  /**
   * Identifier for one pay rate value to be overwritten.
   */
  OnePayRate = 2,
  /**
   * Identifier for max advance value to be overwritten.
   */
  MaxAdvance = 3,
  /**
   * Identifier for max acquisition fee value to be overwritten.
   */
  AcquisitionFee = 4,
  /**
   * Identifier for MSRP adjustment to be overwritten.
   */
  MSRPAdjustment = 5,
}

/**
 * Override Lease Rate, Lease OnePay Rate, Resdiual or Max Advance Value.
 *
 * [MSAPIOverrideLease](http://help.mscanapi.com/html/584a336e-0bb9-ff0d-0425-a6f31ef125d5.htm)
 */
export type MSAPIOverrideLease = {
  /**
   * An array of override values. Define Term and override value.
   *
   * [Data](http://help.mscanapi.com/html/86dd65c8-26f6-f8b4-5055-4b2e620ddee7.htm)
   */
  Data: MSAPIOverrideTermValue[];
  /**
   *  Lender program to apply override value.
   */
  Name: string;
  /**
   * Override Type - Residual, Lease Rate, Lease OnePay Rate, or Max Advance.
   *
   * [Type](http://help.mscanapi.com/html/bb2dbaa4-7566-5889-6973-b3360252cbd1.htm)
   */
  Type: MSAPILeaseOverrideTypeEnum;
};

/**
 * Markup/increase the money factor or APR.
 *
 * [MSAPIRateMarkUpLease](http://help.mscanapi.com/html/e6256aab-c5a9-123e-50db-a44187473249.htm)
 */
export type MSAPIRateMarkUpLease = {
  /**
   * By default if a program has exceeded the max markup lender policy the program will not be returned unless you pass MSAPIFilter.IncludePastPaidReserve Property set to true. The program will then be returned with a rejected flag, the rate itself will not be markedup.
   *
   * Passing AcceptLowerMaxRate as true will not reject programs and will return all programs regardless if they have exceeded the max markup lender policy. If a program has exceeed the max markup lender policy, the rate will be returned with the max markup value.
   *
   * (Inherited from MSAPIRateMarkUpCommon.)
   */
  AcceptLowerMaxRate: boolean;
  /**
   * Desired APR markup value.
   * (Inherited from MSAPIRateMarkUpCommon.)
   */
  APR: number;
  /**
   * Desired money factor markup value.
   */
  Factor: number;
};

/**
 * Define if lease or retail transaction.
 *
 * [MSAPILeaseRetailTypeEnum](http://help.mscanapi.com/html/6b30c53b-536c-d0f7-e441-1e7e95668dc7.htm)
 */
export enum MSAPILeaseRetailTypeEnum {
  Lease = 0,
  Retail = 1,
}

/**
 * Lender Codes.
 */
export type MSAPILenderCode = {
  /**
   * Lender code identifier.
   */
  Code: string;
  /**
   * Bool is true if lender provides One Pay Lease programs.
   */
  HasOnePay: boolean;
  /**
   * Transaction type - lease or retail.
   *
   * [Type](http://help.mscanapi.com/html/61881f0a-3526-d85b-5841-5fbf8de96d13.htm)
   */
  Type: MSAPILeaseRetailTypeEnum;
};

/**
 * Define the lender tier.
 *
 * [MSAPILenderTier](http://help.mscanapi.com/html/bca918db-ff3f-7c58-22ab-429994c62029.htm)
 */
export type MSAPILenderTier = {
  /**
   * Tier identifier.
   */
  Code: string;
  /**
   * Tier name.
   */
  Name: string;
  /**
   * Lease or retail transaction.
   */
  Type: MSAPILeaseRetailTypeEnum;
};

/**
 * Define Lender Codes, Lender, and Lender Tier.
 *
 * [MSAPILender](http://help.mscanapi.com/html/81bb71b3-ab5b-63c7-11c3-8f8351ce2e64.htm)
 */
export type MSAPILender = {
  /**
   * Lease rate calculation type.
   */
  CalcType: MSAPILeaseRateCalcType[];
  /**
   * Lender Code identifier.
   *
   * [MSAPILenderCode](http://help.mscanapi.com/html/bcd967af-24d5-ae0d-367c-38b2458163bb.htm)
   */
  Code: MSAPILenderCode[];
  /**
   * Optional - Lender name that will be used in returning lender programs.
   */
  Name: string;
  /**
   * Lender program identifier.
   */
  ProgramCode: string;
  /**
   * Security Numbe - number of security deposits.
   */
  SecurityNumber: number;
  /**
   * Lender tier can be used to filter for a specific tier.                        Lender tier can also be used to apply a Rate Markup to a specific tier.
   *
   * [MSAPILenderTier](http://help.mscanapi.com/html/0563e383-a4c1-5c2a-5c64-526d1b13064e.htm)
   */
  Tier: MSAPILenderTier[];
};

/**
 * Return Both Special and Standard programs or only Special or Standard programs.
 *
 * [MSAPISpecialStandardEnum](http://help.mscanapi.com/html/c4aa4400-d143-e707-6f91-fb514d527cc2.htm)
 */
export type MSAPISpecialStandardEnum = number;

/**
 * Apply rate markup Ex, will provide more options and flexibility to apply rate markup to a Special and Standard. If you would like to default Rate for Both Special and Standard RateMarkupEx will do that Globally.
 * NOTE This propertu was introduced after Rate MarkUp
 *
 * [MSAPIRateMarkupExTermLease](http://help.mscanapi.com/html/0d5028d2-8ebe-3415-2a45-d51ade3a61f5.htm)
 */
export type MSAPIRateMarkupExTermLease = {
  /**
   * By default if a program has exceeded the max markup lender policy the program will not be returned unless you pass MSAPIFilter.IncludePastPaidReserve Property set to true. The program will then be returned with a rejected flag, the rate itself will not be markedup.
   *
   * Passing AcceptLowerMaxRate as true will not reject programs and will return all programs regardless if they have exceeded the max markup lender policy. If a program has exceeed the max markup lender policy, the rate will be returned with the max markup value.
   */
  AcceptLowerMaxRate: boolean;
  /**
   * Define APR markup. For Programs like Ford, Ally, etc. you would like to Pass this property
   */
  APR: number;
  /**
   * Defines money factor markup. For Programs like Ford, Ally, etc. you would like to Pass this property
   */
  Factor: number;
  /**
   * Optional - Define Term to apply markup. If not defined or passed a null, markup will apply to all terms.
   */
  Term: number;
};

/**
 * Apply paid reserve by marking up/increasing the rate value for a Specific Lender. Options to apply to Standards, Specials or Both, also apply reserve to a specific tier, and for a specific term.
 */
export type MSAPIRateMarkupExLease = {
  /**
   * Define Lender Codes - Apply paid reserve to a specific lender by the Lender Code.
   *
   * (Inherited from MSAPIRateMarkupExCommon.)
   *
   * Public property  SpecialStandard
   *
   * [Lender](http://help.mscanapi.com/html/9cab4328-8696-a1fa-b31a-300fb3c62931.htm)
   */
  Lender: MSAPILender[];
  /**
   * Lender programs, both Special and Standard programs or only Special or only Standard programs.
   *
   *  (Inherited from MSAPIRateMarkupExCommon.)
   *
   * [SpecialStandard](http://help.mscanapi.com/html/7902bb36-bde1-d0d4-f290-d2a133967e15.htm)
   */
  SpecialStandard: MSAPISpecialStandardEnum;
  /**
   * Apply rate markup, have the ability to apply rate markup to a specific Monthly Term.
   *
   * [Term](http://help.mscanapi.com/html/83b1925f-bf4e-f1a1-b05b-b77a4f816030.htm)
   */
  Term: MSAPIRateMarkupExTermLease[];
};

/**
 * Service contract information.
 *
 * [MSAPIServiceContract](http://help.mscanapi.com/html/a5d0cd7d-2e13-e15f-c43d-bd8753143de1.htm)
 */
export type MSAPIServiceContract = {
  /**
   * Service contract amount to be financed.
   */
  AmountFinanced: number;
  /**
   * Service contract name.
   */
  CompanyName: string;
  /**
   * Service contract identifier.
   */
  ContractId: string;
  /**
   * Cost. (Inherited from MSAPICustomFee.)
   */
  Cost: number;
  /**
   * Service contract coverage amount.
   */
  CoverageAmount: number;
  /**
   * Service contract deductible.
   */
  Deductible: number;
  /**
   * Name of the custom fee. (Inherited from MSAPICustomFee.)
   */
  Description: string;
  /**
   * Service contract start date.
   */
  EffectiveDate: string;
  /**
   * Setting bool to true will finance the service contract.
   */
  Financed: boolean;
  /**
   * Identifier. (Inherited from MSAPICustomFee.)
   */
  ID: number;
  /**
   * Service contract mileage.
   */
  Mileage: number;
  /**
   * Service contract plan code/ID.
   */
  PlanCode: string;
  /**
   * Allows to pass the different type of Fee without having to define the service contract and insurance classesThis is usefull when states tax the fee differentlyTHIS IS ONLY FOR THE STATE OF CONNECTICUTIf Enumerator of 0 is passed luxury tax will be included in the calculation.If Enumerator of 1 or 2 is passed luxury tax will not be included in the calculation. (Inherited from MSAPICustomFee.)
   */
  StrongType: CustomFeeTypeEnum;
  /**
   * Setting bool to true will tax the custom fee. (Inherited from MSAPICustomFee.)
   */
  Taxable: boolean;
  /**
   * (Inherited from MSAPICustomFee.)
   */
  TaxedAsAftermarketProduct: boolean;
  /**
   * Length of service contract.
   */
  Term: number;
  /**
   * Define/categorize the custom fee. MSAPICustomFee Class - valid Type values (used for RouteOne eContracting)MSAPIServiceContract Class - valid Type values (used for RouteOne eContracting)MSAPIInsurance Class - valid Type values (used for RouteOne eContracting) (Inherited from MSAPICustomFee.)
   */
  Type: string;
};

/**
 * Overrides the Participation/Spread.
 *
 * [MSAPISpreadParticipationOverride](http://help.mscanapi.com/html/2d9020bf-4627-b6a1-b121-ca3c7e9af97e.htm)
 */
export type MSAPISpreadParticipationOverride = {
  /**
   * Define Lender Codes, Lender, and Lender Tier.
   */
  Lender: MSAPILender[];
  /**
   * Maximum allowed for rate markup.                        For percent of Cap Cost reserve.
   */
  MaxRateMarkup: number;
  /**
   * Participation amount that is used for Paid Reserve.                        For Percentage of Cap Cost programs where the overrride value should be defined in the participation value. Example US Bank.
   */
  Participation: number;
  /**
   * Special or Standard lender program.
   */
  SpecialStandard: MSAPISpecialStandardEnum;
  /**
   * Participation/spread reserve structure type. (RateMarkup = 0, CapCostParticipation = 1, FlatFee = 2)
   */
  Type: MSAPISpreadParticipationOverrideTypeEnum;
};

/**
 * Apply itemized City, County and State Tax
 */
export type MSAPICityCountyStateTaxApplicability = {
  /**
   * Setting bool to true will calculate and itemize city tax.
   */
  ApplyCityTax: boolean;
  /**
   * Setting bool to true will calculate and itemize county tax.
   */
  ApplyCountyTax: boolean;
  /**
   * Setting bool to true will calculate and itemize county tax.
   */
  ApplyStateTax: boolean;
};

/**
 * Auto apply itemized city, county, and state tax.
 *
 * Works for Colorado, Florida, Wisconsin
 *
 * [MSAPIAutoTaxRateComposeRule](http://help.mscanapi.com/html/8c27d3b5-0da5-5a5d-b4aa-972cabbe4f4d.htm)
 */
export type MSAPIAutoTaxRateComposeRule = {
  /**
   * Lease City, County and State Tax
   *
   * [Lease](http://help.mscanapi.com/html/4dc40b5c-8389-7076-b1c2-f22793ac25fe.htm)
   */
  Lease: MSAPICityCountyStateTaxApplicability;
  /**
   * Retail City, County and State Tax
   *
   * [Retail](http://help.mscanapi.com/html/39b44ae3-5f18-1677-bbb4-1d51e3ca8ad9.htm)
   */
  Retail: MSAPICityCountyStateTaxApplicability;
};

/**
 * Customer or dealership zip code.
 *
 * Options to include auto tax rates in scan transactions. Some locations might require a TaxRegionID and TaxInCity properties to be defiend to return the proper tax rate.
 *
 * If TaxRegionID and TaxInCity are not defined then the default rate is used for the ZIP.
 *
 * If using the CalculateRegistrationFee property. MSAPILocation needs to be used along with it. If TaxRegionID and TaxInCity are not defined then the default rate is used for the ZIP.
 *
 * On occasion AHFC - American Honda Finance Corp. offers an enhanced residual adjustment for specific customers in select counties in the New York area. This enhanced residual adjustment is the only piece of lease data that is currently tracked by passing the Customer zip code.
 *
 * [MSAPILocation](http://help.mscanapi.com/html/d24ec2e4-f096-809b-5cf9-31775dcedc09.htm)
 */
export type MSAPILocation = {
  /**
   * If AutoTaxRate is set to true and CustomerZIP is defined, tax rates are automatically applied to the scan.
   */
  AutoTaxRate: boolean;
  /**
   * Auto apply itemized city, county, and state tax.            Works for Colorado, Florida, Wisconsin
   *
   * [AutoTaxRateComposeRule](http://help.mscanapi.com/html/8c27d3b5-0da5-5a5d-b4aa-972cabbe4f4d.htm)
   */
  AutoTaxRateComposeRule: MSAPIAutoTaxRateComposeRule;
  /**
   * Customer Zip Code.
   */
  CustomerZIP: string;
  /**
   * Dealership Sub Region ID                        SubRegionID - On occasion there are multiple tax rates returned for the same zip code, city, county. We provide a subregion id to be able to differentiate between the multiple tax rates.
   */
  DealershipSubRegionID: number;
  /**
   * Dealership - Set bool to true if InCity or false if OutSide of City.
   */
  DealershipTaxInCity: boolean;
  /**
   * Customer TaxRegionID.                         Used for calculating registration fees where counties fall into multiple zip codes.                        If TaxRegionID is not defined then the default rate is used for the ZIP
   */
  DealershipTaxRegionID: number;
  /**
   * Dealership Zip Code.
   */
  DealershipZIP: string;
  /**
   * Customer Sub Region ID                        SubRegionID - On occasion there are multiple tax rates returned for the same zip code, city, county. We provide a subregion id to be able to differentiate between the multiple tax rates.
   */
  SubRegionID: number;
  /**
   * Customer - Set bool to true if InCity or false if OutSide of City.
   */
  TaxInCity: boolean;
  /**
   * 1Customer TaxRegionID.                         Used for calculating registration fees where counties fall into multiple zip codes.                        If TaxRegionID is not defined then the default rate is used for the ZIP
   */
  TaxRegionID: number;
};

/**
 * Must be initialized for lease and mPencil scans. Define lease parameters.
 *
 * [MSAPIScanRequestLeasePart](http://help.mscanapi.com/html/bd2c94e6-e47e-333d-e7ad-bc6acccbb353.htm)
 */
export type MSAPIScanRequestLeasePart = {
  /**
   * Mileage that will be used to caclulate lease transactions.
   *
   * Amount of miles that will be driven per year.
   */
  AnnualMileage: number;
  /**
   * An array of mileage that will be used to caclulate lease transactions.
   *
   * Amount of miles that will be driven per year.
   */
  AnnualMileages: number[];
  /**
   * Boolean option to apply Used car tax calculations and auto calculated registration fees
   *
   * If a new car vehicleID is passed and boolean is set to true, the taxes and auto calculated registraion fees will be treated as used car calculations.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  ApplyUsedVehicleTaxRegistration: boolean;
  /**
   * Increase/markup the bank fee value for additional profit.
   *
   * This only applies to lenders that allow you to markup the bank fee.
   *
   * [MSAPIScanRequestLeasePart.BankFeeMarkup](http://help.mscanapi.com/html/a262b82d-3393-371f-d9bc-b7f650ccf4a2.htm)
   */
  BankFeeMarkup: MSAPIRequestBankFeeMarkup[];
  /**
   * Setting bool to true will collect the bank fee in the inception fees.
   *
   * By default the bank fee is always collected in the cap cost. This option only applies to lenders that allow you to collect the bank fee at inception.
   */
  BankFeeUpfront: boolean;
  /**
   * Setting bool to true will apply excess cash to pay off any negative equity on the trade.
   *
   * Cash will always pay inception fees first and then apply the excess cash to lower the negative equity.
   */
  CashCanPayTrade: boolean;
  /**
   * Setting bool to true will not apply any cash to inception fees.
   *
   * Works in conjunction with MSAPIScanRequestLeasePart.TreatCashAsCCR.
   *
   * MSAPIScanRequestLeasePart.TreatCashAsCCR must be set to true for this property to work.
   */
  CashDoesntCoverInceptionFee: boolean;
  /**
   * Pass enumerator of 1 to tax the full amount of Cash.
   *
   * If not defined, or passed as 0, the engine will use the Cash to pay inception fees first, any left over money will be used as a down payment and tax on the down payment.  No additonal money is asked for taxes.
   *
   * [MSAPIScanRequestLeasePart.CCRCashTaxCollectionMethod](http://help.mscanapi.com/html/c9d179b0-3c26-ada4-6068-2a99c6f7a3ac.htm)
   */
  CCRCashTaxCollectionMethod: MSAPITaxCollectionMethod;
  /**
   * Pass enumerator of 1 to tax the full amount of Non-CCR Rebate Money.
   *
   * If not defined, or passed as 0, the engine will use the Non-CCR Rebate Money to pay inception fees first**, any left over money will be used as a down payment and tax on the down payment. No additonal money is asked for taxes. **
   *
   * Cash will always pay inception fees first before any rebate money.
   *
   * [MSAPIScanRequestLeasePart.CCRRebateTaxCollectionMethod](http://help.mscanapi.com/html/a73587d2-f918-59ae-1a36-5db4da251082.htm)
   */
  CCRRebateTaxCollectionMethod: MSAPITaxCollectionMethod;
  /**
   * Setting bool to true will collect the cap cost reduction(CCR) rebate tax in the cap cost.
   */
  CCRRebateTaxInCap: boolean;
  /**
   * Pass enumerator of 1 to tax the full amount of Trade Equity.
   *
   * If not defined, or passed as 0, the engine will use the Trade Equity to pay inception fees first**, any left over equity will be used as a down payment and tax on the down payment.  No additonal money is asked for taxes.
   *
   * ** Cash will always pay inception fees first before any rebate money. If no Cash, then rebate money will be used to pay inception fees before any trade equity.
   */
  CCRTradeInTaxCollectionMethod: MSAPITaxCollectionMethod;
  /**
   * Customer cash. (Inherited from MSAPIScanRequestCommonPart.)
   */
  CustomerCash: number;
  /**
   * Sets an array of custom defined fees.
   *
   * [CustomFee](http://help.mscanapi.com/html/c462baf2-10a3-84df-fc29-c73cd4dbccf6.htm)
   */
  CustomFee: MSAPICustomFeeLease[];
  /**
   * Sets an array of vehicle adds that will lower the residual value.
   *
   * [MSAPIScanRequestLeasePart.Deduction](http://help.mscanapi.com/html/e3634d5d-3d89-a5ff-6af9-fcbd21f418f3.htm)
   */
  Deduction: MSAPIResidualDeductionEnum[];
  /**
   * Desired paid reserve value that will be calculated in the deal.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  DesiredPaidReserve: number;
  /**
   * Desired paid reserve range/threshold.
   *
   * Desired paid reserve range will include lender programs that fall above or below the desired paid reserve value.                          Desired paid reserve range value cannot exceed the desired paid reserve value.  Used in conjuction with \"DesiredPaidReserve\".             (Inherited from MSAPIScanRequestCommonPart.)
   */
  DesiredPaidReserveRange: number;
  /**
   * Setting bool to true will not apply the first payment waiver to the specific First Payment Waiver programs.
   */
  DontApplyFirstPaymentWaiver: boolean;
  /**
   * Setting bool to true will collect the downpayment tax for Cash, Rebate and Trade in cap cost.
   *
   * This property only works for upfront tax states like New York, New Jersey, Ohio, Illinois, etc.
   */
  DownpaymentTaxInCap: boolean;
  /**
   * Collects first payment in Cap.
   */
  FirstPaymentInCap: boolean;
  /**
   * Define generic lease values that do not come from an actual lease lender program.
   *
   * Defining this Generic property will automatically force your request to use the generic data that you are passing in the request, and will ignore any actual lender data.
   *
   * [MSAPIScanRequestLeasePart.Generic](http://help.mscanapi.com/html/1ccfffac-14d7-a21f-16cd-2e94ad4ffa92.htm)
   */
  Generic: MSAPIScanRequestLeaseGeneric;
  /**
   * Setting bool to true will take the deficiency out of the profit.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  IncludeDeficiencyInProfit: boolean;
  /**
   * Setting bool to true will return expired lease lender programs.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  IncludeExpired: boolean;
  /**
   * Setting bool to true will always display zero due at lease signing programs regardless of the amount of customer cash or rebate that is being applied.
   */
  IncludeZeroDueAtLeaseSigningRegardlessOfCustomerCash: boolean;
  /**
   * Insurance information.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [MSAPIScanRequestCommonPart.Insurance](http://help.mscanapi.com/html/34be6cc1-68d5-1aec-951a-ffdd6aa4397a.htm)
   */
  Insurance: MSAPIInsurance[];
  /**
   * Setting bool to true will calculate the lease payments as a one pay calculation - one payment vs. term monthly payments.
   */
  IsOnePay: boolean;
  /**
   * Used in conjunction with RebateOverride property.
   *
   * This has no affect if the RebateOverride property is not initialized.
   *
   * Setting bool to true will apply the rebates being passed in the RebateOverride property and will also apply any rebates being passed in the MSISAPIScanRequest.Rebates Property. If bool is false, rebates that are passed in the RebateOverride property will be applied and any rebates passed in the MSISAPIScanRequest.Rebates Property will be ignored. If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverride Property and MSAPIScanRequestCommonPart.JoinRebateOverride Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  JoinRebateOverride: boolean;
  /**
   *
   * [MSAPIScanRequestLeasePart.LenderCalcType](http://help.mscanapi.com/html/ea1d004e-9f7d-4d7b-7c24-6fbbe878cb58.htm)
   */
  LenderCalcType: MSAPILenderCalcType[];
  /**
   * Defines Lender tax credit. TX gives a tax credit based on Lender and value being passedShould only pass the lender that are returned.
   *
   * [MSAPIScanRequestLeasePart.LenderTaxExempt](http://help.mscanapi.com/html/94c8fafb-c585-b380-4741-0022becd4181.htm)
   */
  LenderTaxExempt: MSAPILenderTaxExempt[];
  /**
   * Setting bool to true will collect the maximum amount of paid reserve allowed by the deal structure. When set to true this option will ignore the values in \"DesiredPaidReserve\" and \"DesiredPaidReserveRange\"
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  MaximizePaidReserve: boolean;
  /**
   * MSRP Discount value that is part of the vehicle invoice. Some lenders will allow to residualize the MSRP discounts.
   */
  MSRPDiscount: number;
  /**
   * Setting bool to to true will apply trade in equity and rebates after the one pay payment has been calculated.                        By default the one pay calculation will reduce the capitalized cost before calculating the one pay payment.
   */
  OnePayNoCapCostReduction: boolean;
  /**
   * Override Lease Rate, Lease OnePay Rate, Resdiual or Max Advance Value.
   *
   * [MSAPIScanRequestLeasePart.Override](http://help.mscanapi.com/html/02708ea2-2d55-36fd-828b-2c11e1190399.htm)
   */
  Override: MSAPIOverrideLease[];
  /**
   * Apply paid reserve by marking up/increasing the rate value.
   *
   * [MSAPIScanRequestLeasePart.RateMarkUp](http://help.mscanapi.com/html/d00c2480-ba8b-434a-14d2-6f4bda436efd.htm)
   */
  RateMarkUp: MSAPIRateMarkUpLease;
  /**
   * Apply paid reserve by marking up/increasing the rate value.
   *
   * Options to apply to Standards, Specials or Both.  Option to also apply reserve to a specific tier.
   *
   * [MSAPIScanRequestLeasePart.RateMarkUpEx](http://help.mscanapi.com/html/f4d339b0-561c-a2e8-e578-d1158bcb4545.htm)
   */
  RateMarkUpEx: MSAPIRateMarkupExLease[];
  /**
   * Setting bool to true will apply excess rebate money to pay off any negative equity on the trade. Non cap cost rebate cash will always pay inception fees first and then apply the excess rebate money to lower the negative equity. Only applies to non cap cost reduction rebates.
   */
  RebateCanPayTrade: boolean;
  /**
   * Define override rebate values  Ability to send your own rebate description, value, etc. Used in conjunction with MSAPIScanRequestCommonPart.JoinRebateOverride Property. If MSAPIScanRequestCommonPart.JoinRebateOverride Property is not defined rebates passed in the MSISAPIScanRequest.Rebates Property will be ignored and only rebates passed in the RebateOverride Property will be applied.
   *
   * If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverride Property and MSAPIScanRequestCommonPart.JoinRebateOverride Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [MSAPIScanRequestCommonPart.RebateOverride](http://help.mscanapi.com/html/22fe3576-aa1f-0583-2b58-c1753da06b59.htm)
   */
  RebateOverride: MSAPIGenericRebate;
  /**
   * RebaetOverideEx provides the ability to define an Array of rebates and also provides the ability to apply all rebates to a Transaction Type. Ability to send your own rebate description, value, etc. RebateOverrideEx takes precedence over RebateOverride and JoinRebateOverride. There is no joining RebateOverrideEx to MS rebates.                        If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverrideEx Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  RebateOverrideEx: MSAPIGenericRebate[];
  /**
   * If bool is true, registraton fees will be collected in the Cap Cost.
   */
  RegistrationFeeInCap: boolean;
  /**
   * If bool is true, current mileage on vehicle will adjust annual mileage response. Some lenders like GM Financial allow you to lower the annual mileage based on the applicable current mileage on vehicle.
   */
  RemoveDemoMileageFromAnnualMileage: boolean;
  /**
   * Service contract information.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [MSAPIScanRequestCommonPart.ServiceContract](http://help.mscanapi.com/html/abb8f860-b32e-2c1c-36b5-faf5548ef9ef.htm)
   */
  ServiceContract: MSAPIServiceContract[];
  /**
   * Lender programs, both Special and Standard programs or only Special or only Standard programs.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [SpecialStandard](http://help.mscanapi.com/html/d9c60843-1221-7c16-f4de-69a18f627b7b.htm)
   */
  SpecialStandard: MSAPISpecialStandardEnum;
  /**
   * Override Participation/Spread.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [SpreadParticipationOverride](http://help.mscanapi.com/html/d1d39eae-f65b-a67d-d974-2a957c5efaa7.htm)
   */
  SpreadParticipationOverride: MSAPISpreadParticipationOverride[];
  /**
   * (Inherited from MSAPIScanRequestCommonPart.)
   *
   * [SuppressLender](http://help.mscanapi.com/html/3988cdf4-3e23-0f41-d714-46bb8f66d41a.htm)
   */
  SuppressLender: MSAPILender[];
  /**
   * Accommodates the non-taxation of GM Wear products in California.
   *
   * Currently only applies to the state of California, and only applies to GM Financial(Non-Generic) and Generic Scans.
   */
  TaxExemptForUseTax: number;
  /**
   * Setting bool to true will apply the trade in equity to payoff inception fees, and the difference will be returned back to customer.
   *
   * When inception fees are paid off, we use cash first, rebates money second, and trade equity third.
   */
  TradeInCashBack: boolean;
  /**
   * Setting bool to true will use cash, rebate, and trade equity as cap cost reduction values.
   *
   * This property will also collect cash for the inception fees ontop of the cash that is being defined in the CustomerCash property.
   *
   * If bool is false, the calculation default will always pay off the inception fees first.
   */
  TreatCashAsCCR: boolean;
  /**
   * Setting bool to true will apply the full amount of the rebate as cap cost reduction.
   */
  TreatRebateAsCCR: boolean;
  /**
   * Setting bool to true will apply the full amount of the trade allowance as cap cost reduction.
   */
  TreatTradeInAsCCR: boolean;
  /**
   * Setting bool to true will return Captive Lender Data only.
   *
   * This property will supersede the MSAPIScanRequestCommonPart.UseOnlyLender and the MSAPIScanRequestCommonPart.UseOnlyLenderCode Property.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  UseOnlyCaptiveLender: boolean;
  /**
   * Filter lender programs by the Lender code and Lender tier.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  UseOnlyLender: boolean;
};

/**
 * Must be initialized for retail and mPencil scans. Define retail parameters.
 *
 * [MSAPIScanRequestRetailPart](https://help.mscanapi.com/html/a372eedb-50a4-1a33-5f18-654651843166.htm)
 */
export type MSAPIScanRequestRetailPart = {
  /**
   * Setting bool to true will add the negative trade equity to the selling price.
   */
  AdjustSellingPriceWithNegativeEquity: boolean;
  /**
   * Sets an array of retail lender advance values.
   *
   * New car lender advance policies - Only used for lenders that do not have a published advance policy.
   *
   * Applies the lender advance policy based on the lender code.
   */
  Advance: number[];
  /**
   * When set as true:
   *
   * If DaysToFirstPayment is less than days in period (30 for monthly, 7 for weekly, etc.), the interest portion of the payment will be decreased using the updated calculation.
   */
  ApplyInterestSavedEarlyPayment: boolean;
  /**
   * Pass bool as true to apply Odd Days interest calculation on retail transactions.
   */
  ApplyOddDaysInterest: boolean;
  /**
   * Boolean option to apply Used car tax calculations and auto calculated registration fees
   *
   * If a new car vehicleID is passed and boolean is set to true, the taxes and auto calculated registraion fees will be treated as used car calculations.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  ApplyUsedVehicleTaxRegistration: boolean;
  /**
   * Contains customer information.
   */
  Customer: MSAPICustomer;
  /**
   * Customer cash.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  CustomerCash: number;
  /**
   * Contains an array of custom defined fees.
   */
  CustomFee: MSAPICustomFeeRetail[];
  /**
   * Apply a Percentage % Amount or a Specific Dollar amount from the Dealer Incentive to Reduce the Selling Price
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  DealerIncentivesReducePricePortion: number;
  /**
   * Lender advance value.
   */
  DefaultAdvance: MSAPIAdvanceBase;
  /**
   * Define New and Used car advance policies for lenders that do not have a published advance policy.
   *
   * Defined values will apply to all lenders that do not have a published advance policy.
   */
  DefaultAdvanceEx: MSAPIDefaultAdvance;
  /**
   * Desired paid reserve value that will be calculated in the deal.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  DesiredPaidReserve: number;
  /**
   * Desired paid reserve range/threshold.
   *
   * Desired paid reserve range will include lender programs that fall above or below the desired paid reserve value.
   *
   * Desired paid reserve range value cannot exceed the desired paid reserve value. Used in conjuction with "DesiredPaidReserve".
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  DesiredPaidReserveRange: number;
  /**
   * Option to pass your own calculated tax value.
   */
  FlatTax: number;
  /**
   * Generic retail values that do not come from an actual retail lender program.
   *
   * Defining this Generic property will automatically force your request to use the generic data that you are passing in the request, and will ignore any actual lender data.
   */
  Generic: MSAPIScanRequestRetailGeneric;
  /**
   * If boolean set to true, property will allow to exceed the limitation on APR
   */
  IgnoreMaxAPR: boolean;
  /**
   * Setting bool to true will take the deficiency out of the profit.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  IncludeDeficiencyInProfit: boolean;
  /**
   * Setting bool to true will return expired lease lender programs.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  IncludeExpired: boolean;
  /**
   * Insurance information.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  Insurance: MSAPIInsurance[];
  /**
   * Setting bool to true will calculate a cash deal.
   */
  IsCashDeal: boolean;
  /**
   * Used in conjunction with RebateOverride property.
   *
   * This has no affect if the RebateOverride property is not initialized.
   *
   * Setting bool to true will apply the rebates being passed in the RebateOverride property and will also apply any rebates being passed in the MSISAPIScanRequest.Rebates Property.
   *
   * If bool is false, rebates that are passed in the RebateOverride property will be applied and any rebates passed in the MSISAPIScanRequest.Rebates Property will be ignored.
   *
   * If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverride Property and MSAPIScanRequestCommonPart.JoinRebateOverride Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  JoinRebateOverride: boolean;
  /**
   * Setting bool to true will collect the maximum amount of paid reserve allowed by the deal structure.
   *
   * When set to true this option will ignore the values in "DesiredPaidReserve" and "DesiredPaidReserveRange"
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  MaximizePaidReserve: boolean;
  /**
   * Override Retail Rate and Retail CashDeal Rate.
   */
  Override: MSAPIOverrideRetail[];
  /**
   * Calculate paid reserve by marking up/increasing the rate value.
   */
  RateMarkUp: MSAPIRateMarkUpRetail;
  /**
   * Apply paid reserve by marking up/increasing the rate value for a Specific Lender. Options to apply to Standards, Specials or Both. Option to also apply reserve to a specific tier.
   */
  RateMarkUpEx: MSAPIRateMarkupExRetail[];
  /**
   * Rebate Identifier - Must be defined in order to pass rebate values to cash deals.
   *
   * Rebate ID should be be a Special, Std, or Other Bank ID. We recommend using Other Bank ID.
   */
  RebateIDAPRForCashOrGeneric: number;
  /**
   * Define override rebate values  Ability to send your own rebate description, value, etc.
   *
   * Used in conjunction with MSAPIScanRequestCommonPart.JoinRebateOverride Property.
   *
   * If MSAPIScanRequestCommonPart.JoinRebateOverride Property is not defined rebates passed in the MSISAPIScanRequest.Rebates Property will be ignored and only rebates passed in the RebateOverride Property will be applied.
   *
   * If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverride Property and MSAPIScanRequestCommonPart.JoinRebateOverride Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  RebateOverride: MSAPIGenericRebate;
  /**
   * RebaetOverideEx provides the ability to define an Array of rebates and also provides the ability to apply all rebates to a Transaction Type.
   *
   * Ability to send your own rebate description, value, etc.
   *
   * RebateOverrideEx takes precedence over RebateOverride and JoinRebateOverride.
   *
   * There is no joining RebateOverrideEx to MS rebates.
   *
   * If applying override to balloon transactions, ,MSAPIScanRequestCommonPart.RebateOverrideEx Property must be defined under the RetailPart.
   *
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  RebateOverrideEx: MSAPIGenericRebate[];
  /**
   * Pass bool as true to allow rebates applicable to “Other Banks Retail” when either manually defining rebates or auto-applying rebates.
   */
  RebateUseOtherBanksForCashOrGeneric: boolean;
  /**
   * Override registration fees.
   *
   * Add your own custom registration fee or remove a registration fee that you do not want to track.
   *
   * Only works when using auto calculated registration fee option.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  RegistrationFeeOverride: MSAPIRegistrationFeeOverride;
  /**
   * Service contract information.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  ServiceContract: MSAPIServiceContract[];
  /**
   * Lender programs, both Special and Standard programs or only Special or only Standard programs.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  SpecialStandard: MSAPISpecialStandardEnum;
  /**
   * Override Participation/Spread.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  SpreadParticipationOverride: MSAPISpreadParticipationOverride[];
  /**
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  SuppressLender: MSAPILender[];
  /**
   * Setting bool as true will change the paid reserve calcualtion.
   *
   * Calculation will change to - PaidReserve = (SellRate - BuyRate)/SellRate * FinanceCharge * DealerSplit
   */
  UseAPRFinanceChargePaidReserveForRateMarkUp: boolean;
  /**
   * Setting bool to true will return Captive Lender Data only.
   *
   * This property will supersede the MSAPIScanRequestCommonPart.UseOnlyLender and the MSAPIScanRequestCommonPart.UseOnlyLenderCode Property.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  UseOnlyCaptiveLender: boolean;
  /**
   * Filter lender programs by the Lender code and Lender tier.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  UseOnlyLender: MSAPILender[];
  /**
   * Filter Lenders by the Lender code.
   * (Inherited from MSAPIScanRequestCommonPart.)
   */
  UseOnlyLenderCode: string[];
};

/**
 * Apply paid reserve by marking up/increasing the rate value for a Specific Lender. Options to apply to Standards, Specials or Both. Option to also apply reserve to a specific tier.
 *
 * [MSAPIRateMarkupExRetail](https://help.mscanapi.com/html/394c7541-0954-9986-b03b-1bda7c38eda0.htm)
 */
export type MSAPIRateMarkupExRetail = {
  /**
   * Define Lender Codes - Apply paid reserve to a specific lender by the Lender Code.
   * (Inherited from MSAPIRateMarkupExCommon.)
   */
  Lender: MSAPILender[];
  /**
   * Lender programs, both Special and Standard programs or only Special or only Standard programs.
   * (Inherited from MSAPIRateMarkupExCommon.)
   */
  SpecialStandard: MSAPISpecialStandardEnum;
  /**
   * Term
   */
  Term: number;
};

/**
 * Markup/increase the APR.
 *
 * [MSAPIRateMarkUpRetail](https://help.mscanapi.com/html/c2d6db6f-65fd-d0c1-aea4-1b4331a69ff7.htm)
 */
export type MSAPIRateMarkUpRetail = {
  /**
   * By default if a program has exceeded the max markup lender policy the program will not be returned unless you pass MSAPIFilter.
   * IncludePastPaidReserve Property set to true. The program will then be returned with a rejected flag, the rate itself will not be markedup.
   *
   * Passing AcceptLowerMaxRate as true will not reject programs and will return all programs regardless if they have exceeded the max markup lender policy. If a program has exceeed the max markup lender policy, the rate will be returned with the max markup value.
   * (Inherited from MSAPIRateMarkUpCommon.)
   */
  AcceptLowerMaxRate: boolean;
  /**
   * Desired APR markup value.
   * (Inherited from MSAPIRateMarkUpCommon.)
   */
  APR: number;
};

/**
 * Define advance policies for lenders that do not have a published advance policy.
 *
 * [MSAPIDefaultAdvance](https://help.mscanapi.com/html/d70313dc-324c-7b7f-a8ad-8ed35ba7c47c.htm)
 */
export type MSAPIDefaultAdvance = {
  /**
   * New car advance properties.
   */
  New: MSAPIAdvanceNew;
  /**
   * Used car advance properties.
   */
  Used: MSAPIAdvanceUsed;
};

/**
 * Applies to New car advance calculations.
 *
 * [MSAPIAdvanceNew](https://help.mscanapi.com/html/dd3bbaf4-c230-2eb2-e50d-1eb80997f545.htm)
 */
export type MSAPIAdvanceNew = {
  /**
   * Advance value to be used in calculation.
   * (Inherited from MSAPIAdvanceBase.)
   */
  Percent: number;
  /**
   * New car advance has three caclulation types - Undefined, Cost, MSRP.
   */
  Type: MSAPIAdvanceTypeEnum;
};

/**
 * Calculation type for New car advance.
 *
 * [MSAPIAdvanceType](https://help.mscanapi.com/html/1d4f80a7-a310-4fd2-115f-7a0fd06873c9.htm)
 */
export enum MSAPIAdvanceTypeEnum {
  /**
   * Treated like Cost, but avoid using Undefined.
   */
  Undefined = 0,
  /**
   * New car advance calculation based on Cost/Invoice Price of vehicle.
   */
  Cost = 1,
  /**
   * New car advance calculation based on MSRP value of vehicle.
   */
  MSRP = 2,
}

/**
 * Applies to Used car advance calculations.
 *
 * [MSAPIAdvanceUsed](https://help.mscanapi.com/html/d834100d-afe9-f45a-4b45-1af5bc91727b.htm)
 */
export type MSAPIAdvanceUsed = {
  /**
   * Defines automobile industry's Used Car Guide Books - KBB(Kelley Blue Book), NADA(National Automobile Dealers Association) and BBK (Black Book).
   */
  Book: MSAPIUsedVehicleBookTypeEnum;
  /**
   * Advance value to be used in calculation.
   * (Inherited from MSAPIAdvanceBase.)
   */
  Percent: number;
  /**
   * Used car advance has two caclulation types. All Used car guide books have either Wholesale/TradeIn or Retail value.
   */
  Type: MSAPIUsedWholesaleRetailEnum;
};

/**
 * Automobile industry's Used Car Guide Books - KBB(Kelley Blue Book), NADA(National Automobile Dealers Association) and BBK (Black Book).
 *
 * [MSAPIUsedVehicleBookTypeEnum](https://help.mscanapi.com/html/8425542d-afd9-3a33-73ad-5157984525b8.htm)
 */
export enum MSAPIUsedVehicleBookTypeEnum {
  /**
   * Lender does not use the guide books for Used car advance.
   */
  Undefined = 0,
  /**
   * Kelley Blue Book.
   */
  KBB = 2,
  /**
   * National Automobile Dealers Association.
   */
  NADA = 3,
  /**
   * Black Book
   */
  BBK = 4,
}

/**
 * Used car advance has two caclulation types. All Used car guide books have either Wholesale/TradeIn or Retail value.
 *
 * [MSAPIUsedWholesaleRetailEnum](https://help.mscanapi.com/html/660e1ec5-538c-a3a8-c805-877e8e711f41.htm)
 */
export enum MSAPIUsedWholesaleRetailEnum {
  /**
   * Used car advance calculation based on the guide books wholesale/trade-in value.
   */
  WholesaleOrTradeIn = 0,
  /**
   * Used car advanec calculation based on the guide books retail value.
   */
  Retail = 1,
}

/**
 * Lender Advance - How much the lender will allow you to advance(borrow)?
 *
 * [MSAPIAdvanceBase](https://help.mscanapi.com/html/838b7308-915d-a803-0971-219eb7ced6ab.htm)
 */
export type MSAPIAdvanceBase = {
  /**
   * Advance value to be used in calculation.
   */
  Percent: number;
};

/**
 * Generic retail values that do not come from an actual retail lender program.
 *
 * [MSAPIScanRequestRetailGeneric](https://help.mscanapi.com/html/5ee34441-98ae-c355-0cdd-32a7d4f44870.htm)
 */
export type MSAPIScanRequestRetailGeneric = {
  /**
   * Pass bool as true to apply exceeding max advance filter functionality.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  ApplyPastMaxAdvanceCheck: boolean;
  /**
   * Days to add for odd days interest calcualtion.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  DaysPerYearForOddDaysInterest: number;
  /**
   * Amount of paid reserve the dealer should receive.
   *
   * Use a whole number - If dealer should receive full amount then value should be set to 100.
   * If dealer should receives 70% of the paid reserve then value should be set to 70, etc.
   */
  DealerSplit: number;
  /**
   * Pass bool as true to exclude the acquistion fee from percentage of cap cost based reserve calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  ExcludeBankFeeFromCapCostBasedPaidReserve: boolean;
  /**
   * Generic Ford Flex percentage to decrease.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  FordFlexDecreasePct: number;
  /**
   * Defne generic Ford Flex term.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  FordFlexFirstTerm: number;
  /**
   * Pass bool as false to exclude custom fees from the generic advance calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  IsCustomFeeAdvanced: boolean;
  /**
   * Pass bool as false to exclude license/registration fees from the generic advance calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  IsLicenseFeeAdvanced: boolean;
  /**
   * Pass bool as false to exclude the processing fees from the generic advance calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  IsProcessingFeeAdvanced: boolean;
  /**
   * Pass bool as false to exclude taxes from the generic advance calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  IsTaxAdvanced: boolean;
  /**
   * Pass bool as false to exclude warranty from the generic
   * advance calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  IsWarrantyAdvanced: boolean;
  /**
   * Generic retail lender fee.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  LenderFees: number;
  /**
   * Pass bool as true to take into account the number of days in the current month when calculating odd days interest.
   *
   * This property only applies to the month of February, since it is the only month with fewer than 30 days.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  OddDaysBasedOnDaysPerMonth: boolean;
  /**
   * To be used to define Flat Fee and Percentage of Cap Cost Reserve.
   *
   * For Rate Markup, continue to use the RetailPart RateMarkUp property and RetailPart Generic DealerSplit.
   */
  PaidReserveParameters: MSAPIPaidReserveParameters[];
  /**
   * Define an array of generic retail lender rates for specific terms. Only used if UseRatePerTerm is set to true.
   *
   * Define a rate for each element in the array. Each element defined represents a term. If you define elements 1 through 4, you will return programs for Terms 1 through 4.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  RatePerTerm: number[];
  /**
   * Generic rebate value, can only pass one rebate value.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  Rebate: MSAPIGenericRebate;
  /**
   * Generic rebate array, pass multiple rebates and values.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  RebateEx: MSAPIGenericRebate[];
  /**
   * Generic retail rate for all terms.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  SingleRate: number;
  /**
   * Ford Flex Term array
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  TreatTermAsFordFlex: number[];
  /**
   * Pass bool as true to apply KIA's converging calculation method to the Retail calculation.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  UseKIAConvergingCalcMethod: boolean;
  /**
   * Setting bool to true will allow you to use generic rates for specific terms. Used in conjuction with RatePerTerm.
   * (Inherited from MSAPIScanRequestmPencilRetailGeneric.)
   */
  UseRatePerTerm: boolean;
};

/**
 * Override registration fees.
 * Add your own custom registration fee or remove a registration fee that you do not want to track.
 * Only works when using auto calculated registration fee option.
 *
 * [MSAPIRegistrationFeeOverride](https://help.mscanapi.com/html/28e03c05-d0ba-30b6-82a5-b04b76fd33d8.htm)
 */
export type MSAPIRegistrationFeeOverride = {
  /**
   * Custom Registration Fee Array
   */
  Fee: MSAPICustomRegistrationFee[];
  /**
   * If RemoveAllFees is true, all auto calculated registration fees will be excluded, not calculated nor returned in the transaction response.
   */
  RemoveAllFees: boolean;
};

/**
 * Custom Registration Fee Array
 *
 * [MSAPICustomRegistrationFee](https://help.mscanapi.com/html/16b7e05d-55fd-0547-5a85-f19033d20085.htm)
 */
export type MSAPICustomRegistrationFee = {
  /**
   * Registration fee Name.
   */
  Description: string;
  /**
   * If IsPlateFee is true then if there is a regular plate fee registration item in our data the item will not be added, the custom plate fee registration item will be added instead.
   */
  IsPlateFee: boolean;
  /**
   * If RemoveIfDescriptionMatch is true and if a registration fee in our data with the description equal (case insensitive A==a) to "Description" property will be removed from deal.
   * If RemoveIfDescriptionMatch is false then the item will be added to the registration items list.
   */
  RemoveIfDescriptionMatch: boolean;
  /**
   * Registration fee value.
   */
  Value: number;
};

/**
 * Override Retail Rate and Retail CashDeal Rate.
 *
 * [MSAPIOverrideRetail](https://help.mscanapi.com/html/f9fddbda-89b0-6aeb-0e08-3f84e85a43b7.htm)
 */
export type MSAPIOverrideRetail = {
  /**
   * An array of override values. Define Term and override value.
   */
  Data: MSAPIOverrideTermValue[];
  /**
   * By default Finance programs might be setup with APR modifiers. If certain criteria is met, the APR is modified, going up or down.
   *
   * Pass bool as true to ignore retail database APR modifiers.
   * When overriding the APR rate, if you pass this option as true, the override rate will be used without any additional APR modifiers.
   */
  IgnoreAPRModifiers: boolean;
  /**
   * Lender Code. Obtain Lender Code from the RunScan Response MSAPIProgramReference.Code property.
   */
  Name: string;
  /**
   * Rate Type (enumerator=1 for rate).
   */
  Type: MSAPIRetailOverrideTypeEnum;
};

/**
 * Rate override type for Retail.
 *
 * [MSAPIRetailOverrideTypeEnum](https://help.mscanapi.com/html/8c8d8b5e-8b3e-2b8f-1b8e-2c5b9e7e8e8f.htm)
 */
export enum MSAPIRetailOverrideTypeEnum {
  /**
   * Undefined
   */
  Undefined = 0,
  /**
   * Rate
   */
  Rate = 1,
  /**
   * LenderFee
   */
  LenderFee = 2,
}

/**
 * Market/State for tax purposes.
 *
 * [MSAPIMarket](http://help.mscanapi.com/html/b725bf0b-53b3-33c6-3098-8322e01f0ae4.htm)
 */
export type MSAPIMarket = number;

/**
 * Lease mPencil parameters. mDesking mPencil Example PDF
 */
export type MSAPIScanRequestmPencilLease = {
  /**
   * Set an array of cash values, you must pass 4 cash elements.
   *
   * If you only want to return data for one cash element, you can pass all other cash elements as -1.
   * Lets say you only want to return mPencil data for zero down(zero cash deal).
   * You can pass the Cash elements like this: "Cash": [ 0,-1,-1,-1]
   * This will return a payment for the cash element(cash zero) you defined. All other payments will be returned with empty properties since we passed a -1 for the cash.
   * (Inherited from MSAPIScanRequestmPencilCommon.)
   */
  Cash: number[];
  /**
   * Set an array of term values.
   * (Inherited from MSAPIScanRequestmPencilCommon.)
   */
  Term: number[];
  /**
   * If bool is set to true, your last mPencil cash value will be ignored and instead will be used to return a One Pay lease transaction.
   */
  UseLastCashAsOnePay: boolean;
};

/**
 * Generic retail values that do not come from an actual retail lender mDesking mPencil Example PDF.
 *
 * [MSAPIScanRequestmPencilRetailGeneric](http://help.mscanapi.com/html/9397ee6b-0af2-6f84-4fd7-a5fccd4a7acb.htm)
 */

export type MSAPIScanRequestmPencilRetailGeneric = {
  /**
   * Generic retail lender fee.
   */
  LenderFees: number;
  /**
   * Define an array of generic retail lender rates for specific terms.  Only used if UseRatePerTerm is set to true.                        Define a rate for each element in the array.  Each element defined represents a term.  If you define elements 1 through 4, you will return programs for Terms 1 through 4.
   */
  RatePerTerm: number[];
  /**
   * Generic rebate value.
   */
  Rebate: MSAPIGenericRebate;
  RebateEx: MSAPIGenericRebate[];
  /**
   * Generic retail rate for all terms.
   */
  SingleRate: number;
  /**
   * Setting bool to true will allow you to use generic rates for specific terms.  Used in conjuction with RatePerTerm.
   */
  UseRatePerTerm: boolean;
};

/**
 * Retail mPencil parameters.
 *
 * [MSAPIScanRequestmPencilRetail](http://help.mscanapi.com/html/d876e80e-7474-5fdd-df6c-ba919b3180fe.htm)
 */
export type MSAPIScanRequestmPencilRetail = {
  /**
   * Set an array of cash values, you must pass 4 cash elements.
   *
   * If you only want to return data for one cash element, you can pass all other cash elements as -1.
   * Lets say you only want to return mPencil data for zero down(zero cash deal).
   * You can pass the Cash elements like this: "Cash": [ 0,-1,-1,-1]
   * This will return a payment for the cash element(cash zero) you defined. All other payments will be returned with empty properties since we passed a -1 for the cash.
   * (Inherited from MSAPIScanRequestmPencilCommon.)
   */
  Cash: number[];
  /**
   * Generic retail parameters for mPencil mDesking mPencil Example PDF.
   *
   * [MSAPIScanRequestmPencilRetailGeneric](http://help.mscanapi.com/html/707793eb-4510-79ad-1d26-8ab9976b6502.htm)
   */
  Generic: MSAPIScanRequestmPencilRetailGeneric;
  /**
   * Set an array of term values.
   * (Inherited from MSAPIScanRequestmPencilCommon.)
   */
  Term: number[];
};

/**
 * Spread type - Payment Greater than Spread or Payment Closest to Spead.
 *
 * [MSAPIScanRequestmPencilSpreadTypeEnum](http://help.mscanapi.com/html/7f7fb950-ef7a-983b-746f-e05fbeedad86.htm)
 */
export type MSAPIScanRequestmPencilSpreadTypeEnum = number;

/**
 * Defines the payment spread of the program results.
 *
 * [MSAPIScanRequestmPencilSpread](http://help.mscanapi.com/html/07a37772-ab3e-6f66-4696-92354fc3912d.htm)
 */
export type MSAPIScanRequestmPencilSpread = {
  /**
   * Spread type - Payment Greater than Spread or Payment Closest to Spread.
   *
   * [Type](http://help.mscanapi.com/html/654f7386-ad32-3695-1c9e-5831f26da24f.htm)
   */
  Type: MSAPIScanRequestmPencilSpreadTypeEnum;
  /**
   * Desired Payment Spread.
   */
  Value: number;
};

/**
 * Define mPencil parameters.
 *
 * [MSAPIScanRequestmPencil](http://help.mscanapi.com/html/01462dcd-cdf5-afeb-1b31-cfb57b3514b1.htm)
 */
export type MSAPIScanRequestmPencil = {
  /**
   * Define lease mPencil parameters.
   *
   * [LeasePart](http://help.mscanapi.com/html/cc796e19-46a8-899d-b2fa-5097d4afb1e8.htm)
   */
  LeasePart: MSAPIScanRequestmPencilLease;
  /**
   * Define retail mPencil parameters.
   *
   * [RetailPart](http://help.mscanapi.com/html/59446df7-2f2b-0ee4-8e10-658a6c4bebc2.htm)
   */
  RetailPart: MSAPIScanRequestmPencilRetail;
  /**
   * Defines the payment spread of the program results.
   *
   * [Spread](http://help.mscanapi.com/html/625360a8-d239-593f-1290-c869168ebdc3.htm)
   */
  Spread: MSAPIScanRequestmPencilSpread;
};

/**
 * Omit Inventory Quoting.
 *
 * [MSAPIOmitConfigInventory](http://help.mscanapi.com/html/ce4cbb7b-81c2-9f05-c686-57d239bbe3b1.htm)
 */
export type MSAPIOmitConfigInventory = {
  /**
   * Setting bool to true will ignore Inventory Quoting rules that are setup in the Dealer Portal.
   */
  Quoting: boolean;
};

/**
 * Omit Lease specific options.
 */
export type MSAPIOmitConfigLease = {
  /**
   * Setting bool to true will ignore Acquisition Fee MarkUp defaults that are setup in the API Account Manager/Dealer Portal.
   */
  AcquisitionFeeMarkup: boolean;
  /**
   * Setting bool to true will ignore Lender option defaults that are setup in the API Account Manager/Dealer Portal.
   *
   * (Inherited from MSAPIOmitConfigCommon.)
   */
  OnlyAvailableLenders: boolean;
};

/**
 * Omit Retail specific options.
 */
export type MSAPIOmitConfigRetail = {
  /**
   * Setting bool to true will ignore Lender option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  OnlyAvailableLenders: boolean;
};

/**
 * Omit default settings from Account Manager/Dealer Portal.
 *
 * MSISAPIScanRequest.UseConfig Property (MSAPIUseConfig) takes priority over MSISAPIScanRequest.OmitConfig. You cannot omit a property in the MSISAPIScanRequest.OmitConfig if a property is defined in MSISAPIScanRequest.UseConfig Property.
 *
 * [MSAPIOmitConfig](http://help.mscanapi.com/html/099728e8-ce18-9920-219f-bcb21e564e86.htm)
 */
export type MSAPIOmitConfig = {
  /**
   * Setting bool to true will ignore Custom Fee defaults that are setup in the API Account Manager/Dealer Portal.
   */
  CustomFee: boolean;
  /**
   * Setting bool to true will ignore Taxes and Registration fee defaults that are setup in the API Account Manager/Dealer Portal.
   */
  FeeTax: boolean;
  /**
   * Setting bool to true will ignore Filter option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  Filter: boolean;
  /**
   * Omit Inventory Quoting.
   *
   * [Inventory](http://help.mscanapi.com/html/d94a3915-94bf-cf09-78b1-3f9cd2c99381.htm)
   */
  Inventory: MSAPIOmitConfigInventory;
  /**
   * Omit Lease specific options.
   *
   * [Lease](http://help.mscanapi.com/html/b48f7202-3227-6969-7d63-0dbbdd7dd682.htm)
   */
  Lease: MSAPIOmitConfigLease;
  /**
   * Setting bool to true will ignore Non Market Dependent option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  NonMarketDependent: boolean;
  /**
   * Setting bool to true will ignore Rate MarkUp option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  RateMarkup: boolean;
  /**
   * Setting bool to true will ignore RateMarkUpEx option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  RateMarkupEx: boolean;
  /**
   * Omit Retail specific options.
   *
   * [Retail](http://help.mscanapi.com/html/d064e133-4f5a-6ddb-69ce-267f71808e4e.htm)
   */
  Retail: MSAPIOmitConfigRetail;
  /**
   * Setting bool to true will ignore Warranty option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  Warranty: boolean;
  /**
   * Setting bool to true will ignore Wear Care option defaults that are setup in the API Account Manager/Dealer Portal.
   */
  Wearcare: boolean;
};

/**
 *
 */

export type MSAPIOmitScanResultField = {
  /**
   * Acquisition Fee/Bank Fee.
   */
  AcquisitionFee: boolean;
  /**
   * APR or Money Factor.
   */
  AlternitiveRate: boolean;
  /**
   * Amount Financed.
   */
  AmountFinanced: boolean;
  /**
   * Selected/Applied rebate.
   */
  AppliedRebate: boolean;
  /**
   * Buy Rate (interest factor).
   */
  BuyRate: boolean;
  /**
   * Lender Program Identifier.
   */
  Code: boolean;
  /**
   * Omits specific types of programs like Balloon, Percent, Previous Customer, and Expired programs.
   */
  Flags: boolean;
  /**
   *
   */
  ID: boolean;
  /**
   * Total Inception Fees and taxes.
   */
  InceptionFeesTaxes: boolean;
  /**
   * Lender Code.
   */
  Lender: boolean;
  /**
   * Maximum Advance.
   */
  MaxAdvance: boolean;
  /**
   * Maximum Credit Score.
   */
  MaxCreditScore: boolean;
  /**
   * Minimum Credit Score.
   */
  MinCreditScore: boolean;
  /**
   * Out of Pocket Cash.
   */
  OutOfPocketCash: boolean;
  /**
   * Paid Reserve.
   */
  PaidReserve: boolean;
  /**
   * Monthly Payment.
   */
  Payment: boolean;
  /**
   * Selling Price.
   */
  Price: boolean;
  /**
   * Profit.
   */
  Profit: boolean;
  /**
   * Lender program description.
   */
  ProgramDescription: boolean;
  /**
   * Array of the deal structure. Used for design/presentation.
   */
  ProgramInfoUI: boolean;
  /**
   * Program rank.
   */
  Rank: boolean;
  /**
   * Rate of the lender program.                        Marked up value if the rate is being marked up because of reserve/back end profit.
   */
  Rate: boolean;
  /**
   * Rejected programs.
   */
  Rejections: boolean;
  /**
   * Residual Name.
   */
  ResidualName: boolean;
  /**
   * Residual percent.
   */
  ResidualPercent: boolean;
  /**
   * Calculated residual value.
   */
  ResidualValue: boolean;
  /**
   * Security Deposit.
   */
  SecurityDeposit: boolean;
  /**
   * Rate of the lender program.                        Marked up value if the rate is being marked up because of reserve/back end profit.
   */
  SellRate: boolean;
  /**
   * Special or Standard lender programs.
   */
  SpecialStandard: boolean;
  /**
   * Calculated Tax.
   */
  Tax: boolean;
  /**
   * Lender Tier.
   */
  Tiers: boolean;
  /**
   * Total Rebate.
   */
  TotalRebate: boolean;
  /**
   * Alphanumeric String ID.
   */
  XID: boolean;
};

/**
 * Out of State Retail Sales Tax
 *
 * [MSAPIOutOfStateFeeTaxRetail](http://help.mscanapi.com/html/794e98d1-0d05-9ede-e143-723f42266e78.htm)
 */

export type MSAPIOutOfStateFeeTaxRetail = {
  /**
   * Setting bool to true will apply CAT Tax to rebate used as equity.             Oregon Corporate Activity Tax
   */
  ApplyCATTaxToRebate: boolean;
  /**
   * Virginia Business, Professional, and Occupational License Tax
   */
  BPOLTaxPct: number;
  /**
   * Oregon Corporate Activity Tax Rate
   */
  CATTaxPct: number;
  /**
   * Tennessee - Pass as true to exempt after market product from sales tax.
   */
  ExemptAftermarketProductFromSalesTax: boolean;
  /**
   * Pass bool as true to include Out Of State Tax in MSAPIProgramInfo.SalesTax.            By default MSAPIProgramInfo.SalesTax does not include the Out of State Tax.              Out of State Tax is itemized and returned in the MSAPIProgramInfoRetail.TaxBreakdown Property
   */
  IncludeOutOfStateTaxInSalesTax: boolean;
  /**
   * Tennessee local tax for after market products.
   */
  LocalAftermarketTaxPct: number;
  /**
   * Tennessee local business tax for after market products.
   */
  LocalBusinessAftermarketTaxPct: number;
  /**
   * Tennessee local business tax.
   */
  LocalBusinessTaxPct: number;
  /**
   * Alabama - Used for retail sales tax when doing out of state transactions and you want to collect Alamaba retail tax.
   */
  SalesUseTaxPct: number;
  /**
   * Tennessee state business tax.
   */
  StateBusinessTaxPct: number;
  /**
   * Texas Vehicle Inventory tax rate.
   */
  VehicleInventoryTaxPct: number;
};

/**
 * Out of State Lease Sales Tax
 *
 * [MSAPIOutOfStateFeeTaxLease](http://help.mscanapi.com/html/e71d6d1d-16ca-6482-2c44-4b362fb4ddd2.htm)
 */

export type MSAPIOutOfStateFeeTaxLease = {
  /**
   * Setting bool to true will apply CAT Tax to rebate used as equity. Oregon Corporate Activity Tax
   */
  ApplyCATTaxToRebate: boolean;
  /**
   * Option to collect the Virginia BPOL (Business, Professional, and Occupational License) in the cap cost. Setting bool to true will collect the BPOL in the cap cost.
   */
  BPOLTaxInCap: boolean;
  /**
   * Virginia Business, Professional, and Occupational License Tax rate
   */
  BPOLTaxPct: number;
  /**
   * Oregon Corporate Activity Tax Rate
   */
  CATTaxPct: boolean;
  /**
   * Setting bool to true will include the calculated Out of State Tax in the Sales Tax value.
   */
  IncludeOutOfStateTaxInSalesTax: number;
  /**
   * Tennessee local tax for after market products.
   */
  LocalAftermarketTaxPct: number;
  /**
   * Tennessee local business tax for after market products.
   */
  LocalBusinessAftermarketTaxPct: number;
  /**
   * Setting bool to true will collect the Tennessee after market business tax in the cap cost.
   */
  LocalBusinessAftermarketTaxUpfront: boolean;
  /**
   * Tennessee local business tax.
   */
  LocalBusinessTaxPct: number;
  /**
   * Setting bool to true will collect the Tennessee local business tax in the cap cost.
   */
  LocalBusinessTaxUpfront: boolean;
  /**
   * Tennessee state business tax.
   */
  StateBusinessTaxPct: number;
  /**
   * Setting bool to true will collect the Tennessee state business tax in the cap cost.
   */
  StateBusinessTaxUpfront: boolean;
  /**
   * Option to collect the Texas Vehicle Inventory tax in the cap cost.             Setting bool to true will collect the Texas Vehicle Inventory tax in the cap cost.
   */
  VehicleInventoryTaxInCap: boolean;
  /**
   * Texas Vehicle Inventory tax rate.
   */
  VehicleInventoryTaxPct: number;
};

/**
 * Out of State Fee Tax Options
 *
 * [MSAPIOutOFStateFeeTax](http://help.mscanapi.com/html/d260a00c-2faa-2b9e-2202-1790a09de08e.htm)
 */

export type MSAPIOutOFStateFeeTax = {
  /**
   * Lease sales tax
   *
   * [Lease](http://help.mscanapi.com/html/7437d5be-3605-35da-36b8-4b475554898b.htm)
   */
  Lease: MSAPIOutOfStateFeeTaxLease;
  /**
   * Retail sales tax
   *
   * [Retail](http://help.mscanapi.com/html/f140a8a3-58df-300b-881e-089b0a5597fa.htm)
   */
  Retail: MSAPIOutOfStateFeeTaxRetail;
};

/**
 * Option to calculate weekly and bi-weekly payments based on a period payment.
 *
 * [MSAPIPaymentScheduleCalcTypeEnum](http://help.mscanapi.com/html/4b969678-a62d-1c6c-3035-b3dcc9942742.htm)
 */
export type MSAPIPaymentScheduleCalcTypeEnum = number;

/**
 * Type of Payment Schedule (Monthly, BiWeekly, Weekly).
 *
 * [MSAPIPaymentScheduleTypeEnum](http://help.mscanapi.com/html/c3c3ab00-d433-ee2a-5d72-4a7e1fa7433b.htm)
 */
export type MSAPIPaymentScheduleTypeEnum = number;

/**
 * Payment schedule for customer payments. Option to collect monthly, bi-weekly or weekly.
 *
 * Option to calculate weekly and bi-weekly payments based on a period payment.
 *
 * [MSAPIPaymentSchedule](http://help.mscanapi.com/html/9767fa0c-0da2-f2d9-c318-a1bb8fd12ea8.htm)
 */
export type MSAPIPaymentSchedule = {
  /**
   * Option to calculate weekly and bi-weekly payments based on a period payment.
   *
   * [CalcType](http://help.mscanapi.com/html/af557140-1d1b-0a44-6f31-16cc77dbfa1b.htm)
   */
  CalcType: MSAPIPaymentScheduleCalcTypeEnum;
  /**
   * Payment schedule processing fee from thrid-party company. Fee can be different from company to company.
   */
  Fee: number;
  /**
   * Payment schedule payment types.
   *
   * [Type](http://help.mscanapi.com/html/7b42befe-0b6d-6429-ffb8-dbdd094e575d.htm)
   */
  Type: MSAPIPaymentScheduleTypeEnum;
};

/**
 * Define rebate values.
 *
 * [MSISRebateValue](http://help.mscanapi.com/html/8fb09afe-f84a-909f-225b-5a85e9d46ca8.htm)
 */
export type MSISRebateValue = {
  /**
   * Identifier for Certificate rebates.
   */
  CertificateCode: string;
  /**
   * Rebate Identifier.
   */
  RebateID: number;
  /**
   * [Terms](http://help.mscanapi.com/html/b69a5ab5-7773-d6a1-625b-1e948dbf3087.htm)
   */
  Terms: MSISRebateTerm[];
  /**
   * Setting bool to true will apply the rebate as a CCR - Cap Cost Reduction rebate.
   */
  UseCashAsCCR: boolean;
  /**
   * Define the value of the rebate. Only used when rebate has manual entry value set to true.
   */
  Value: number;
};

/**
 * Define rebate customer type, value, region and ZIP code.
 *
 * [MSAPIScanRequestRebateContainer](http://help.mscanapi.com/html/cdf5b0bd-abec-323f-9fb1-1391761cd2c3.htm)
 */
export type MSAPIScanRequestRebateContainer = {
  /**
   * Define  the rebate customer type.                        All lenders have a customer/individual type.                        Some lenders have rebates that apply to different types of customers.                        GM for example has GM Dealership Employee, GM College, GM Military, etc.
   */
  CustomerTypeID: number;
  /**
   * Dealership zip code - Some manufacturers apply dealer incentives based on their Dealership ZIP code.
   */
  DealerZIP: string;
  /**
   * Vehicle option code.                        Some manufacturers have package/engine specific rebates.                                Send all option codes separated by comma/semiclon/space/pipe.                         If a specified package is found in a rebate the returned rebate will be automatically selected.             This only applies to calculation methods - RunScan or GetProgramInfo.
   */
  OptionCode: string;
  /**
   *
   */
  OverrideAPIRebateValues: boolean;
  /**
   * Define an array of rebate values.
   *
   * [RebateValues](http://help.mscanapi.com/html/6aebbdf3-8ab2-a86f-3e37-67ee70340dc2.htm)
   */
  RebateValues: MSISRebateValue[];
  /**
   * RegionID is used to verify the rebate is being applied to the correct geographical location. A zip code can fall into two seperate lender regions.
   */
  RegionID: number;
  /**
   * VIN for specific rebates.                        If a specified VIN is found in a rebate, the returned rebate will automatically be selected.                        Not all OEM's/Captive Lenders offer VIN specific rebates.  GM always provides VIN specific rebates.  All other lenders provide VIN specific rebates on a case by case basis.
   */
  VIN: string;
  /**
   * The 5 digit zip code should be used. Rebates are assigned by geographical location.
   */
  ZIP: string;
};

/**
 * Scan Type - Lease, Retail, or mPencial
 *
 * [MSAPIScanMode](http://help.mscanapi.com/html/743b30ea-fbd9-f259-95eb-ac9b08389113.htm)
 */
export enum MSAPIScanMode {
  'Lease' = 0,
  'Retail' = 1,
  'mPencil' = 2,
}

/**
 * Scan type.
 *
 * [MSAPIScanType](http://help.mscanapi.com/html/b146bbbc-75bf-9ff5-153e-b1f689a24269.htm)
 */
export enum MSAPIScanType {
  /**
   * Calculate the scan by the desired profit.
   */
  'Profit' = 0,
  /**
   * Calculate the scan by the vehicle selling price.
   */
  'SellingPrice' = 1,
  /**
   * Calculate the scan by the desired monthly payment.
   */
  'Payment' = 2,
  /**
   * Calculate the scan by the maximum profit allowed.              The scan will collect maximum upfront profit that can be made - difference between Selling Price and Dealer Cost.  The scan will collect the maximum paid reserve that is allowed by the lender and deal structure.               This scan will maximize all other values that can be marked up for profit.
   */
  'Maxi' = 3,
}

/**
 * [MSAPITradeInDivisionEnum](http://help.mscanapi.com/html/a3bb1728-dffb-fdaf-de77-8edf85c78310.htm)
 */
export type MSAPITradeInDivisionEnum = number;

/**
 * Currently only used for Wisconsin. Leased Type enumerator to differentiate between a Leased Turn In vs. a Leased Trade In
 *
 * [MSAPITradeInTypeEnum](http://help.mscanapi.com/html/582c65f1-2612-c3dd-fb50-5522953017d6.htm)
 */

export type MSAPITradeInTypeEnum = number;

/**
 * Customer trade-in values.
 *
 * [MSAPITradeIn](http://help.mscanapi.com/html/80a6cb46-c1d7-27d4-b8af-c833fabec5e2.htm)
 */
export type MSAPITradeIn = {
  /**
   * Actual Cash Value of the lease trade-in.
   */
  ActualCashValueLeased: number;
  /**
   * Actual Cash Value of the purchase trade-in
   */
  ActualCashValuePurchased: number;
  /**
   * Lease trade-in allowance - The credit given to a buyer or leasse for a vehicle traded in.
   */
  AllowanceLeased: number;
  /**
   * Retail trade-in allowance - The credit given to a buyer or leasse for a vehicle traded in.
   */
  AllowancePurchased: number;
  /**
   * Body Style of trade-in vehicle.
   */
  BodyStyle: string;
  /**
   * At this time this is only used for Kentucky.                        Kentucky lets you apply the Trade-In credit based off a book value rather than basing it off the trade allowance.                        If no book value is passed then the trade-in credit will be based off the trade allowance value being passed.                        If a book value is passed then the book value will be used as the trade credit value and the trade allowance value will be ignored(only for trade credit).
   */
  BookValueLeased: number;
  /**
   * At this time this is only used for Kentucky.                        Kentucky lets you apply the Trade-In credit based off a book value rather than basing it off the trade allowance.                        If no book value is passed then the trade-in credit will be based off the trade allowance value being passed.                        If a book value is passed then the book value will be used as the trade credit value and the trade allowance value will be ignored(only for trade credit).
   */
  BookValuePurchased: number;
  /**
   * The Division property is available for use only in the MSISAPIScanRequest.TradeInEx Property.                        The Division property allows you to apply Trade In Credits to specific Trade Ins.  Some states have additional rules on how they apply the Trade-In Credts.                        Illinois has a Trade In Credit cap on Division 1 vehicles and there is no Trade In Credit cap limit on Division 2 vehicles.                        Rhode Island does not allow for a Trade In Credit to be applied if you are trading in a truck.
   *
   * [Division](http://help.mscanapi.com/html/fad6719e-60a8-37ba-6171-2c04e430bc21.htm)
   */
  Division: MSAPITradeInDivisionEnum;
  /**
   * Currently only used for Wisconsin.            Leased Type enumerator to differentiate between a Leased Turn In vs. a Leased Trade In
   *
   * [LeasedType](http://help.mscanapi.com/html/348d5c18-ef84-ee22-f2eb-770c1eff7518.htm)
   */
  LeasedType: MSAPITradeInTypeEnum;
  /**
   * If bool is set to true, lease pays off is positive equity.
   */
  LeasePayOffIsPositiveEquity: boolean;
  /**
   * Make of trade-in vehicle.
   */
  Make: string;
  /**
   * Current mileage of trade-in vehicle.
   */
  Mileage: number;
  /**
   * Model of trade-in vehicle.
   */
  Model: string;
  /**
   * Amount of cash still owed on the vehicle being traded.
   */
  PayOffLeased: number;
  /**
   * Amount of cash still owed on the vehicle being traded.
   */
  PayOffPurchased: number;
  /**
   * Setting bool to true will use the purchased trade-in values only.
   */
  UseOnlyPurchasedTradeInValues: boolean;
  /**
   * VIN of trade-in vehicle.
   */
  VIN: string;
  /**
   * Year of trade-in vehicle.
   */
  Year: string;
};

/**
 * Lease tax options - Lenders and Members Fee Rate, No Inventory Tax On New Vehicle, Sales Tax In Cap, Tax Cost, Tax Exempt ACV, Vehicle Inventory Tax Rate.
 *
 * [MSAPITXFeeTaxLease](http://help.mscanapi.com/html/98cd08c6-1e52-c556-0aea-2b6c4eff7122.htm)
 */
export type MSAPITXFeeTaxLease = {
  /**
   * Tax lender tax credits.  Used in conjunction with MSAPILenderTaxExempt.              Defining a value will tax the MSAPILenderTaxExempt Value
   */
  LendersAndMembersFeePct: number;
  /**
   * Option to not collect inventory tax on new vehicles.  Setting bool to true will exclude new vehicles from inventory tax.
   */
  NoInventoryTaxOnNewVehicle: boolean;
  /**
   * Option to collect the sales tax in the cap cost. Setting bool to true will collect the sales tax in the cap cost.
   */
  SalesTaxInCap: boolean;
  /**
   * Option to tax Dealer Cost (before adds) instead of Selling Price.  Setting bool to true will tax Dealer Cost (before adds) instead of Selling Price.
   */
  TaxCost: boolean;
  /**
   * Option to collect the Texas Vehicle Inventory tax in the cap cost.              Setting bool to true will collect the Texas Vehicle Inventory tax in the cap cost.
   */
  VehicleInventoryTaxInCap: boolean;
  /**
   * Texas Vehicle Inventory tax rate.
   */
  VehicleInventoryTaxPct: number;

  WasteTireFeeInCap: boolean;
  SupplementalTitleFeeInCap: boolean;
};

/**
 * Texas sales tax and state registration fees.
 *
 * [MSAPITXFeeTax](http://help.mscanapi.com/html/d43b0ea8-1ef8-78d7-b46d-6bc0fa6a4f26.htm)
 */
export type MSAPITXFeeTax = {
  /**
   * If boolean property is set to true, Registration Fees will be collect.
   */
  CalculateRegistrationFee?: boolean;
  /**
   * Lease tax options - Lenders and Members Fee Rate, No Inventory Tax On New Vehicle, Sales Tax In Cap, Tax Cost,             Tax Exempt ACV, Vehicle Inventory Tax Rate.
   *
   * [Lease](http://help.mscanapi.com/html/98cd08c6-1e52-c556-0aea-2b6c4eff7122.htm)
   */
  Lease: MSAPITXFeeTaxLease;
  /**
   * Texas registration fee.
   */
  RegistrationFee: number;
  /**
   * Texas multi year registration.            Used to apply two year registration fees.
   */
  RegistrationYears?: number;
  /**
   * Retail - Vehicle Inventory Tax Rate.
   */
  Retail: any;
  /**
   * Texas sales tax rate for lease and retail transactions.
   */
  SalesUseTaxPct: number;
  /**
   * Option to tax Trade-In ACV instead of Trade Allowance. Setting bool to true will tax Trade-In ACV instead of Trade Allowance.
   */
  TaxExemptACV: boolean;
};

/**
 * [MSAPINYFeeTaxLease](http://help.mscanapi.com/html/448a83db-be31-8916-43df-90dba72ee33e.htm)
 */
export type MSAPINYFeeTaxLease = {
  /**
   * Setting bool to true will not tax cash downpayment value.
   */
  ExemptCashFromCCRTax: boolean;
  /**
   * Setting bool to true will not tax inception fees.
   */
  ExemptInceptionFeesFromTax: boolean;
  /**
   * Negative equity will not be added to the selling price when calclulating lease tax.            Setting bool to true will exempt negative equity from being taxed.                          Only used for Balloon transactions.
   */
  ExemptNegativeEquityFromTax: boolean;
  /**
   * Setting bool to true will not tax rebate downpayment value.
   */
  ExemptRebateFromCCRTax: boolean;
  /**
   * Setting bool to true will not tax trade equity downpayment value.
   */
  ExemptTradeEquityFromCCRTax: boolean;
  /**
   * Option to collect the sales tax in the cap cost. Setting bool to true will collect the sales tax in the cap cost.
   */
  SalesTaxInCap: boolean;
  /**
   * Option to collect surcharge tax.  Setting bool to true will collect additional surcharge tax on the lease tax base and downpayment.
   */
  SurchargeOnCappedDownpaymentTax: boolean;
  /**
   * Option to tax bank fee when collected at inception. Setting bool to true will only tax the bank fee if it is collected in the inception fees.
   */
  TaxBankFeesInInceptionFees: boolean;
  /**
   * Option to collect Interest on Capped Sales Tax.  Setting bool to true will collect interest on the capped sales tax.
   */
  TaxCappedInterestOnSalesTax: boolean;
};

/**
 * New York Plate Transfer Fee
 *
 * [MSAPINYFeeTaxPlateTransfer](http://help.mscanapi.com/html/9ced7a75-dc31-7e5e-f1a2-a9c1f9be19f5.htm)
 */
export type MSAPINYFeeTaxPlateTransfer = {
  /**
   * Pass Bool as true to apply plate transfer to registration fees
   */
  IsPlateTransfer: boolean;
};

/**
 * New York sales tax and state registration fees.
 *
 * [MSAPINYFeeTax](http://help.mscanapi.com/html/c27fb344-1c6a-502f-7dc9-ad9d32eb91f7.htm)
 */
export type MSAPINYFeeTax = {
  /**
   * Calculate registration Fee. Needs to be used with the MSAPILocation Class.
   */
  CalculateRegistrationFee?: boolean;
  /**
   * If boolean is set to true, Lien Fees will be collected.
   */
  CollectLienFees?: boolean;
  /**
   * Lease tax options - Exempt Negative Equity From Tax, Exempt Trade Equity From Tax, Sales Tax In Cap, Surcharge On Capped Down Payment Tax, Collect Interest on Capped Sales Tax .
   *
   * [Lease](http://help.mscanapi.com/html/fdb3518c-6437-334f-dde0-a5dc7f081356.htm)
   */
  Lease: MSAPINYFeeTaxLease;
  /**
   * Plate Transfer - Contains boolean to apply plate transfers for registration fees.
   *
   * [PlateTransfer](http://help.mscanapi.com/html/b3135d4e-8d1e-c1c5-20e3-4b4d70cfab0f.htm)
   */
  PlateTransfer: MSAPINYFeeTaxPlateTransfer;
  /**
   * If bool is set to true, vehicle will be registered as commercial and passenger vehicle.
   */
  RegisterAsPassengerVehicle?: boolean;
  /**
   * New York registration fee.
   */
  RegistrationFee: number;
  /**
   * New York sales tax rate for lease and retail transactions.
   */
  SalesUseTaxPct: number;
};

/**
 * Lease tax options - Apply Trade In Credit Allowance, Down Payment Tax Included In Sales Tax, Exempt Negative Equity From Tax, Sales Tax In Cap Cost, Supplemental Title Fee In Cap, Tax Bank Fee.
 *
 * [MSAPINJFeeTaxLease](http://help.mscanapi.com/html/a22c8105-0352-a468-82cc-8d42957730c1.htm)
 */
export type MSAPINJFeeTaxLease = {
  /**
   * Option to include downpayment tax in the vehicle sales tax.            Setting bool to true will include the downpayment tax in the vehicle sales tax.
   */
  DownPaymentIncludedInSalesTax: boolean;
  /**
   * Negative equity will not be added to the selling price when calclulating lease tax.            Setting bool to true will exempt negative equity from being taxed.
   */
  ExemptNegativeEquityFromTax: boolean;
  /**
   * Setting bool to true will include the acquisition fee value in the Supplemental Title fee calculation.
   */
  IncludeAcquisitionFeeIntoSupplementalTitleFeeCalc: boolean;
  /**
   * Option to collect the sales tax in the cap cost.            Setting bool to true will collect the sales tax in the cap cost.
   */
  SalesTaxInCap: boolean;
  /**
   * Option to collect supplemental title fee in the cap cost.            Setting bool to true will collect the suplemental title fee in the cap cost.
   */
  SupplementalTitleFeeInCap: boolean;
  /**
   * Option to tax bank fee.  Setting bool to true will tax the bank fee.
   */
  TaxBankFees: boolean;
  /**
   * Setting bool to true will tax full rebate amount.
   */
  TaxFullRebateAmount: boolean;
  /**
   *
   */
  WasteTireFeeInCap: boolean;
};

/**
 *
 */
export type MSAPINJFeeTaxPlateTransfer = {
  /**
   *
   */
  ExtensionData: any;
  /**
   *
   */
  IsPlateTransfer: boolean;
  /**
   *
   */
  IsRegistrationCredit: boolean;
  /**
   *
   */
  RegistrationCreditIsTruck: boolean;
  /**
   *
   */
  RegistrationCreditVehicleYear: number;
  /**
   *
   */
  RegistrationCreditWeightOrGWR: number;
};

/**
 * New Jersey sales tax and fees.
 *
 * [MSAPINJFeeTax](http://help.mscanapi.com/html/e9bf7bba-b196-5b7e-fc3c-6b1a21967322.htm)
 */
export type MSAPINJFeeTax = {
  /**
   * Option to collect supplemental title fee.
   *
   * Supplemental tax will always be collected if the selling price is $45,000 or greater regardless if property is set to true.
   *
   * Regardless of the selling price, setting bool to true will always collect a supplemental title fee for both lease and retail transactions.
   */
  ApplySupplementalTitleFee: boolean;
  /**
   * Pass bool as true to lower the taxable price by the trade-in allowance value.
   */
  ApplyTradeInCreditAllowance: boolean;
  /**
   * Setting Bool to true will apply New Jersey Sales Tax Exemption on Zero Emission Vehicle.
   */
  ApplyZeroEmissionTaxExemption: boolean;
  /**
   * If bool is set to true, registration fees with be auto calculated.
   */
  CalculateRegistrationFee: boolean;
  /**
   * Option to collect waste tire fee on used vehicles.
   */
  CollectWasteTireFeeOnUsedVehicle: boolean;
  /**
   * Option to not apply the Supplemental Title Fee.
   */
  DontApplySupplementalTitleFee: boolean;
  /**
   * Lease tax options - Apply Trade In Credit Allowance, Down Payment Tax Included In Sales Tax, Exempt Negative Equity From Tax, Sales Tax In Cap Cost, Supplemental Title Fee In Cap, Tax Bank Fee.
   */
  Lease: MSAPINJFeeTaxLease;
  /**
   * New Jersey plate transfer.
   */
  PlateTransfer: MSAPINJFeeTaxPlateTransfer;
  /**
   * New Jersey registration fee.
   */
  RegistrationFee: number;
  /**
   * Retail sale tax.
   */
  Retail: MSAPINJFeeTaxRetail;
  /**
   * New Jersey sales tax rate for lease and retail transactions.
   */
  SalesUseTaxPct: number;
  /**
   * Option to change supplemental title fee calculation. Setting bool to true will change the supplemental title fee calulcation to be based off of the adjusted selling price. Only applies if ApplySupplementalTitleFee is set to true.
   */
  SuppTitleFeeBasedOnAdjSellingPrice: boolean;
};

/**
 * Retail sale tax.
 *
 * [MSAPINJFeeTaxRetail](https://help.mscanapi.com/html/53fa8175-2490-6c78-452d-1237e09e212d.htm)
 */
export type MSAPINJFeeTaxRetail = {
  /**
   * City Tax Rate.
   */
  CityTaxPct: number;
  /**
   * County Tax Rate.
   */
  CountyTaxPct: number;
  /**
   *
   */
  ExcludeWasteTireFee: boolean;
  /**
   * Specify a custom fee ID or StrongType to include in the supplemental title fee calculation.
   */
  IncludeCustomFeeInSupplementalTitleFeeCalc: MSAPICustomFeeIDOrStrongType[];
  /**
   * State Tax Rate.
   */
  StateTaxPct: number;
};

/**
 * Specify a custom fee ID or StrongType value.
 *
 * [MSAPICustomFeeIDOrStrongType](https://help.mscanapi.com/html/fce20580-6754-f37f-6baf-b2747d7327b4.htm)
 */
export type MSAPICustomFeeIDOrStrongType = {
  /**
   * Custom fee ID or StrongType.
   */
  Type: string;
  /**
   * Value.
   */
  Value: string;
};

/**
 * State tax classes
 *
 * [MSAPIStateFeeTax](http://help.mscanapi.com/html/14cee958-60b8-fb65-94d3-31b7d89ae5e2.htm)
 */
export type MSAPIStateFeeTax = MSAPITXFeeTax | MSAPINYFeeTax | MSAPINJFeeTax;

/**
 * TradeInEx provides the ability to pass multiple trade-ins in an array.
 */
export type MSAPITradeInEx = {
  /**
   * Define multiple Trade-In values
   *
   * [Item](http://help.mscanapi.com/html/dc4adfd1-37f4-12af-9186-eebc323a4f84.htm)
   */
  Item: MSAPITradeIn[];
  /**
   * If bool is set to true the Use Only Purchased Trade In Values
   */
  UseOnlyPurchasedTradeInValues: boolean;
};

/**
 * Inventory Quoting - only applies to accounts that have an inventory feed with Market Scan.
 *
 * [MSAPIUseConfigInventory](http://help.mscanapi.com/html/6117025b-7268-8d05-34f1-78666881a785.htm)
 */
export type MSAPIUseConfigInventory = {
  /**
   * Setting bool to true will allow for inventory quoting from dealer portal.
   */
  Quoting: boolean;
};

/**
 * Taxes and registration fees will be applied from the defined values in the API Account Manager Portal.
 *
 * [MSAPIUseConfigFeeTax](http://help.mscanapi.com/html/b8ee1a65-9b1c-09ad-31ec-19d7e79f4004.htm)
 */
export type MSAPIUseConfigFeeTax = {
  /**
   * Setting bool to true will include registraion fees from the API Account Manager Portal.
   */
  RegistrationFee: boolean;
  /**
   * Setting bool to true will include the tax rate from the API Account Manager Portal.
   */
  TaxPct: boolean;
};

/**
 * Lease lender filter will be applied from the defined values in the API Account Manager Portal.
 *
 * [MSAPIUseConfigLease](http://help.mscanapi.com/html/3db56950-d936-cf77-f5a5-42835b6a7d41.htm)
 */
export type MSAPIUseConfigLease = {
  UseAcquisitionFeeMarkup: boolean;
  /**
   * Setting bool to true will apply lease lender filters from the Account Manager Portal.
   */
  UseOnlyAvailableLenders: boolean;
};

/**
 * Retail lender filter will be applied from the defined values in the API Account Manager Portal.
 *
 * [MSAPIUseConfigRetail](http://help.mscanapi.com/html/94224e98-a7e7-a457-76b5-f0c32a8712af.htm)
 */
export type MSAPIUseConfigRetail = {
  /**
   * Setting bool to true will apply lease lender filters from the Account Manager Portal.
   */
  UseOnlyAvailableLenders: boolean;
};

/**
 * Apply configuration settings from API Account Manager Portal.
 *
 * [MSAPIUseConfig](http://help.mscanapi.com/html/1b5066b7-5bb9-288f-2cbc-8adf1fcfcf5b.htm)
 */
export type MSAPIUseConfig = {
  /**
   * Setting bool to true will use Custom Fee data from API Account Manager Portal.
   */
  CustomFee: boolean;
  /**
   * Taxes and registration fees will be applied from the defined values in the API Account Manager Portal.
   *
   * [FeeTax](http://help.mscanapi.com/html/1c084d03-30ef-f3ed-6c78-a0b8ff0bbfc5.htm)
   */
  FeeTax: MSAPIUseConfigFeeTax;
  /**
   * Filter options will be applied from the defined values in the API Account Manager Portal.
   */
  Filter: boolean;
  /**
   * Inventory Quoting - only applies to accounts that have an inventory feed with Market Scan.
   *
   * [Inventory](http://help.mscanapi.com/html/bad602bf-8cb6-865a-cfac-c5bd93df1d64.htm)
   */
  Inventory: MSAPIUseConfigInventory;
  /**
   * Lease Option for lender filtering.
   *
   * [Lease](http://help.mscanapi.com/html/93c87802-54f2-3df8-4e81-0c1988c78b12.htm)
   */
  Lease: MSAPIUseConfigLease;
  /**
   * Non Market Dependent options will be applied from the defined values in the API Account Manager Portal.
   */
  NonMarketDependent: boolean;
  /**
   * Payment schedule.
   */
  PaymentSchedule: boolean;
  /**
   * Setting bool to true will use Rate MarkUp values from API Account Manager Portal.  These values only apply if you are running a Selling Price scan (MSISAPIScanRequest.ScanType = 1).
   */
  RateMarkup: boolean;
  /**
   * Setting bool to true will use RateMarkUpEx values from API Account Manager Portal.  These values only apply if you are running a Selling Price scan (MSISAPIScanRequest.ScanType = 1).
   */
  RateMarkupEx: boolean;
  /**
   * Retail Option for lender filtering.
   *
   * [Retail](http://help.mscanapi.com/html/76192692-7d8b-0377-70a5-f58bef914341.htm)
   */
  Retail: MSAPIUseConfigRetail;
  /**
   * Setting bool to true will use Warranty values from API Account Manager Portal.  These values only apply if you are running a Selling Price scan (MSISAPIScanRequest.ScanType = 1).
   */
  Warranty: boolean;
  /**
   * Setting bool to true will use Wear care values from API Account Manager Portal.  These values only apply if you are running a Selling Price scan (MSISAPIScanRequest.ScanType = 1).
   */
  Wearcare: boolean;
};

/**
 * Engine Type Enumerators
 * Gas, Electric, Hybrid, etc.
 *
 * [MSAPIVehicleEngineTypeEnum](http://help.mscanapi.com/html/e61b9547-eb5c-4d63-727d-ffb088dc8cda.htm)
 */
export type MSAPIVehicleEngineTypeEnum = number;

/**
 * Vehicle Option Code packages.
 *
 * [MSAPIVehicleOption](http://help.mscanapi.com/html/eb6ced60-e6ec-3587-2ccb-adf77c41f97c.htm)
 */
export type MSAPIVehicleOption = {
  /**
   * Vehicle option code.                        Some manufacturers have package/engine specific rebates.                                Send all option codes separated by comma/semiclon/space/pipe.                         If a specified package is found in a rebate the returned rebate will be automatically selected.             This only applies to calculation methods - RunScan or GetProgramInfo.
   */
  Code: string;
  /**
   * Cost of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Cost: number;
  /**
   * Name of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Name: string;
  /**
   * Selling price of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Price: number;
  /**
   * Setting bool to true will not tax vehicle equipment.  Only works for states that do not collect the tax in the monthly payment.             (Inherited from MSAPIVehicleEquipment.)
   */
  TaxExempt: boolean;
};

/**
 *
 */
export type MSAPIUsedVehicleValueTypeEnum = number;

/**
 * Vehicle guide book and value.
 *
 * [MSAPIUsedVehicleValue](http://help.mscanapi.com/html/cea17069-5722-5692-7188-a53f47546c6d.htm)
 */
export type MSAPIUsedVehicleValue = {
  /**
   * Vehicle guide book type.
   *
   * [Type](http://help.mscanapi.com/html/74402bcd-463f-2008-3209-4491613c8fc9.htm)
   */
  Type: MSAPIUsedVehicleValueTypeEnum;
  /**
   * Vehicle guide book value.
   */
  Value: number;
};

/**
 * Vehicle auction and guide book data.
 *
 * [MSAPIUsedVehicleData](http://help.mscanapi.com/html/47bb1975-092c-6243-536a-7ebb85fb7b99.htm)
 */
export type MSAPIUsedVehicleData = {
  /**
   * Vehicles auction price.
   */
  AuctionBlockPrice: number;
  /**
   * Array of Residualized Equipment IDs.                        Residualized equipment requires a specific ID.  For Used Vehicles, residualized equipment IDs come from the             GetVehicles method.
   */
  EquipmentResidualizable: number[];
  /**
   * Fair Market Value.                        Used in Kentucky to override the default used car tax base.                        By default the used car tax base in Kentucky is NADA Retail.                        Passing a Fair Market Value will change the used car tax base in Kentucky to the Fair Market Value.
   */
  FairMarketValue: number;
  /**
   * Date of the first time the vehicle was registered.
   */
  FirstRegistrationDate?: string;
  /**
   * Set bool to true if vehicle is Certified Pre-Owned.
   */
  IsCertifiedPreowned: boolean;
  /**
   * Current Vehicle Mileage.
   */
  Mileage?: number;
  /**
   * Vehicle Option Code packages.
   *
   * [Option](http://help.mscanapi.com/html/947c9f92-86ae-9842-fa37-7596ac95c86b.htm)
   */
  Option: MSAPIVehicleOption[];
  /**
   * Guide book value of the vehicle.                        Lease and Retail lenders use the third party used car guide books for their advance policy guidelines. Due to third party contract obligations mScanAPI does not provide these third party guide book values. We provide the ability to pass these values in the mScanAPI.                         You will not return any lender programs in your results for leases if the used car guide book values are not passed in the request.
   *
   * [Value](http://help.mscanapi.com/html/4ade0ca4-efcf-88b5-5375-90fd969f8e89.htm)
   */
  Value: MSAPIUsedVehicleValue[];
  /**
   * mScanAPI Vehicle Identifier.
   */
  VehicleID?: number;
  /**
   * VIN
   */
  VIN: string;
};

/**
 * Vehicle values.
 *
 * [MSAPIVehicle](http://help.mscanapi.com/html/3df61508-de15-c97a-de52-3e28df99ed54.htm)
 */
export type MSAPIVehicle = {
  /**
   * Base MSRP value.  The cost of the car without the destination and handling fees.
   */
  BaseMSRPAmount: number;
  /**
   * In a piston engine, the bore (or cylinder bore) is the diameter of each cylinder.
   */
  Bore?: number;
  /**
   * Current mileage on the vehicle.
   */
  CurrentMileage: number;
  /**
   * Vehicle Cylinders - Number of cylinders the vehicle has
   */
  Cylinders?: number;
  /**
   * Engine displacement is the combined swept volume of the pistons inside the cylinders of an engine.                         It is calculated from the bore (diameter of the cylinders), stroke (distance the piston travels), and number of cylinders.
   */
  Displacement?: number;
  /**
   * Electric and Hybrid Vehicle Miles Per Battery Range
   */
  ElectricRange?: number;
  /**
   * Engine Type Enumerators            Gas, Electric, Hybrid, etc.
   *
   * [EngineType](http://help.mscanapi.com/html/394736d2-a622-610f-d505-9f9b2f931b30.htm)
   */
  EngineType?: MSAPIVehicleEngineTypeEnum;
  /**
   * EPA Average Miles Per Gallon
   */
  EPAMPGAvg?: number;
  /**
   * EPA Average Miles Per Gallon in City
   */
  EPAMPGCity?: number;
  /**
   * EPA Average Miles Per Gallon on Highway
   */
  EPAMPGHwy?: number;
  /**
   * Vehicle Fair Market Value            Some states like Kentucky calculate their sales tax using a used car guidebook value.
   */
  FairMarketValue?: number;
  /**
   * Gaz Guzzler value.
   */
  GasGuzzlerValue?: number;
  /**
   * Gross Vehicle Weight Rating.
   */
  GrossVehicleWeightRating?: number;
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Car if bool = true
   */
  IsCar?: boolean;
  /**
   * Domestic Vehicle if bool = true
   */
  IsDomestic?: boolean;
  /**
   * Option to return Loan/Demo/Courtesy specific lender programs.                        Setting bool to True will ONLY return Loan/Demo/Courtesy specific lender programs.
   */
  IsLoanOrDemo?: boolean;
  /**
   * SUV if bool = true
   */
  IsSUV?: boolean;
  /**
   * Truck if bool = true
   */
  IsTruck?: boolean;
  /**
   * VAN if bool = true
   */
  IsVAN?: boolean;
  /**
   * Maximum Horse Power
   */
  MaxHP?: number;
  /**
   * Used to increase MRM lender values that are based on specific option codes, MRM = Maximum Residualized MSRP.                        Only pass Option Codes that meet the lenders qualification to increase the MRM value.                        The MRM value will be adjusted only if needed.  You will see this in a GetProgramInfo detail, the MSRP value should increase.
   */
  Option: string;
  /**
   * Engine Stroke
   */
  Stroke?: number;
  /**
   * Total dealer cost of the vehicle.
   */
  TotalDealerCost: number;
  /**
   * Total MSRP of the vehicle, Total MSRP includes options, destination and handling fees.
   */
  TotalMSRP: number;
  /**
   * Used car vehicle values.  Must be initialized for used car leases or you will not receive any results back.
   *
   * [UsedData](http://help.mscanapi.com/html/2e96b01b-3ad5-2958-51f7-0923c8fa8b68.htm)
   */
  UsedData: MSAPIUsedVehicleData;
  /**
   * Some OEMS have rebates that include or exclude Demo, Company Cars, and Retired vehicles.You can define the vehicle type with VehicleEligibility.Also used for Demo/Loan/Courtesy Vehicles Lease and Finance.
   *
   * [VehicleEligibility](http://help.mscanapi.com/html/88c67027-efa2-6179-bc4e-f1adca4112bb.htm)
   */
  VehicleEligibility?: MSISVehicleEligibilityEnum;
  /**
   * Lenders might apply a restriction date for a vehicle to qualify as a Demo/Loan/Courtesy Vehicle.                        Define the enrolled date of when the vehicle became a Demo/Loan/Courtesy Vehicle.
   */
  VehicleEligibilityEnrollmentDate?: string;
  /**
   * Vehicle identification number.
   */
  VIN: string;
  /**
   * Wearcare charge - At this time only used for Ford, Audi, and Porsche.
   */
  WearcareCharge: number;
  /**
   * Wearcare cost - At this time only used for Ford, Audi, and Porsche.
   */
  WearcareCost: number;
  /**
   * Vehicle Weight (curb weight). If not passed will default to mScanAPI weight.
   */
  Weight?: number;
  /**
   * Year as integer.
   */
  Year: number;
  /**
   * Year as string.
   */
  YearDisplay: string;
};

/**
 * Residualized Equipment/Adds.
 *
 * [MSAPIVehicleEquipmentResidualizable](http://help.mscanapi.com/html/1f557652-6d75-e665-cedf-63281e4ec00c.htm)
 */
export type MSAPIVehicleEquipmentResidualizable = {
  /**
   * Cost of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Cost: number;
  /**
   * Identifier.                        Set ID = 0 for manually defined equipment.                        Residualized equipment requires a specific ID.  Residualized equipment IDs come from the             GetEquipmentResidualizable method
   */
  ID: number;
  /**
   * Factory Part - Did the equipment come from the factory?
   */
  IsFactoryPart: boolean;
  /**
   * Name of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Name: string;
  /**
   * Selling price of the vehicle equipment.             (Inherited from MSAPIVehicleEquipment.)
   */
  Price: number;
  /**
   * Setting bool to true will not tax vehicle equipment.  Only works for states that do not collect the tax in the monthly payment.             (Inherited from MSAPIVehicleEquipment.)
   */
  TaxExempt: boolean;
};

/**
 * Warranty values.
 *
 * [MSAPIWarranty](http://help.mscanapi.com/html/41ec8dd0-a567-bb4f-cf9e-da3a792f931e.htm)
 */
export type MSAPIWarranty = {
  /**
   * Warranty charge.
   */
  Charge: number;
  /**
   * Warranty cost.
   */
  Cost: number;
  /**
   * Apply a warranty to the scan.  Setting bool to true will always apply warranty to the scan.
   */
  ForceSale: boolean;
  /**
   * MSRP of the warranty.
   */
  MSRP: number;
};

export type MultiAnnualMileageLeaseScanRequest = {
  Request: MSISAPIScanRequest;
  AnnualMileage: number[];
};

/**
 * Define the scan parameters.
 *
 * Contained in the following methods:
 * - RunScan Method
 * - RunMultiVehicleScan Method
 * - RunMultiAnnualMileageLeaseScan Method
 * - RunMultiAnnualMileagemPencilScan Method
 *
 * The following minimum parameters are required to return results:
 * - DesiredValue
 * - LeasePart
 *   - AnnualMileage
 * - RetailPart
 * - ScanMode
 * - ScanType
 * - StateFeeTax
 * - Vehicle
 *   - ID
 *   - TotalDealerCost
 *   - TotalMSRP
 *
 * [MSISAPIScanRequest](http://help.mscanapi.com/html/ed481d63-01f7-38fc-e444-c14233114f11.htm)
 */
export type MSISAPIScanRequest = {
  /**
   * Additional options - Zero out Flat Fee reserve values.
   *
   * Lower the selling price when applying a dealer incentive in a Selling Price Scan transaction.
   *
   * Lower the selling price by the reserve amount in a Selling Price Scan transaction.
   *
   * Return a leading CCR/APR rebate even if the rebate’s value is zero.
   *
   * [MSAPIAdditionalParams](http://help.mscanapi.com/html/8c710dab-dcee-08ec-5744-e8ea715df2c0.htm)
   */
  AdditionalParams: MSAPIAdditionalParams;
  /**
   *
   * If initialized, AutoRebateParams will apply all rebates that are flagged "Available to all", applicable for the specified ZIP and RegionID.
   *
   * The rebates are applied based on Zip Code, Rebate Region, and type of Rebate.
   *
   * "Available to all" are rebate programs that have been flagged in our rebate database as available to all customers.
   *
   * Can auto apply rebates by Category, VIN, Trade-In, and Vehicle Option Code.
   *
   * Option to exclude Dealer Cash rebates that are flagged "Available to All"
   *
   * [MSAPIAutoRebateParams](http://help.mscanapi.com/html/0b3408b1-a152-06c6-100e-a42bdc409907.htm)
   */
  AutoRebateParams: MSAPIAutoRebateParams;
  /**
   * An array of credit scores. Defining a credit score will limit the number of lender programs returned.
   *
   * N possible results for each credit score. If you have a credit score of 720 and credit score of 760, will return the best program for each credit score in array.
   *
   * Not intended to work in mPencil Mode, MSISAPIScanRequest.ScanMode Property =2.
   */
  BestForCreditScore: number;
  /**
   * Call Time Stamp Local Time Zone.
   */
  CallTimeStampLocalTimeZone: string;
  /**
   * Defining a credit score will limit the number of lender programs returned.
   *
   * Use "0" if you want to return all lender programs.
   */
  CreditScore: number;
  /**
   * Define Customer Information - Credit Info, First and Last Name, Total Monthly Income, etc.
   *
   * This data is primarily used for our Retail programs. A lender might have specific criterias that must be met like Time at current job or Short credit history.
   *
   * You will still return lender programs if this information is not passed.
   *
   * [MSAPICustomer](http://help.mscanapi.com/html/da01de01-bd09-efe0-193d-8b29f2e63103.htm)
   */
  Customer: MSAPICustomer;
  /**
   * Days to first payment is used for Retail and Balloon transactions.
   *
   * Common terms are 30 and 45 days to first payment.
   *
   * The higher the number the greater the monthly payment will be.
   */
  DaysToFirstPayment: number;
  /**
   * Desired Profit or Selling Price Value or Payment.
   *
   * Desired Profit Value when running a Profit scan (MSISAPIScanRequest.ScanType = 0).
   *
   * Desired Selling Price value when running a Selling Price scan (MSISAPIScanRequest.ScanType = 1).
   *
   * Desired Payment value when running a Payment scan (MSISAPIScanRequest.ScanType = 2).
   */
  DesiredValue: number;
  /**
   * mScan engine name.
   */
  Engine: string;
  /**
   * Filter lender programs:
   *
   * Exclude programs that collect a security deposit.
   *
   * Include Multiple Security Deposit, Balloon Note programs, and Waived Acquisition Fee programs that increase the rate.
   *
   * Inclue programs that exceeed Past Advance, Down payment and Paid Reserve policies.
   *
   * Filter lease and retail programs by term.
   *
   * [MSISAPIScanRequest.Filter](http://help.mscanapi.com/html/4908b78e-ebfd-e2f8-e212-096fcbcd8559.htm)
   */
  Filter: MSAPIFilter;
  /**
   * Vehicle residency, where the vehicle will be garaged.
   *
   * Used in Ohio for retail scans. The tax base formula changes for taxes if the Garaged state is not Ohio.
   *
   * For TFS - Toyota Financial services, if exporting to RouteOne this property will be used to calculate personal property taxes.
   *
   * [MSISAPIScanRequest.GaragedInfo](http://help.mscanapi.com/html/0c403fcc-6e0a-b844-d6d6-df1804a56668.htm)
   */
  GaragedInfo: MSAPIGaragedInfo;
  /**
   * If initialized the response will contain fields which correspond to the properties set to true.
   *
   * Lender Name.
   *
   * Program Expiration date.
   *
   * [MSISAPIScanRequest.IncludeScanResultField](http://help.mscanapi.com/html/75a5f7af-a39c-3a3e-d9cb-f0a73d04f365.htm)
   */
  IncludeScanResultField: MSAPIIncludeScanResultField;
  /**
   * Must be initialized for lease and mPencil scans. Define lease parameters.
   * [MSISAPIScanRequest.LeasePart](http://help.mscanapi.com/html/6c360a19-70e0-7969-6cd5-2de54939fc84.htm)
   */
  LeasePart: MSAPIScanRequestLeasePart;
  /**
   * Customer or dealership zip code.
   *
   * Options to include auto tax rates in scan transactions. Some locations might require a TaxRegionID and TaxInCity properties to be defiend to return the proper tax rate.
   *
   * If TaxRegionID and TaxInCity are not defined then the default rate is used for the ZIP.
   *
   * If using the CalculateRegistrationFee property. MSAPILocation needs to be used along with it. If TaxRegionID and TaxInCity are not defined then the default rate is used for the ZIP.
   *
   * On occasion AHFC - American Honda Finance Corp. offers an enhanced residual adjustment for specific customers in select counties in the New York area. This enhanced residual adjustment is the only piece of lease data that is currently tracked by passing the Customer zip code.
   *
   * [Location](http://help.mscanapi.com/html/18385474-1a54-4ca2-7664-e7504885259b.htm)
   */
  Location: MSAPILocation;
  /**
   * Market/State for tax purposes.
   *
   * [Market](http://help.mscanapi.com/html/22733e59-d502-f152-5a68-acad0a2f0e87.htm)
   */
  Market: MSAPIMarket;
  /**
   * mPencil parameters for Lease and Retail.
   *
   * [mPencil](http://help.mscanapi.com/html/b4ed87b1-1be0-d141-5c53-2154256533e8.htm)
   */
  mPencil: MSAPIScanRequestmPencil;
  /**
   * Omit default settings from Account Manager/Dealer Portal.
   *
   * MSISAPIScanRequest.UseConfig Property (MSAPIUseConfig) takes priority over MSISAPIScanRequest.OmitConfig. You cannot omit a property in the MSISAPIScanRequest.OmitConfig if a property is defined in MSISAPIScanRequest.UseConfig Property.
   *
   * [OmitConfig](http://help.mscanapi.com/html/5077275b-024a-de74-6a5e-c6106615b264.htm)
   */
  OmitConfig: MSAPIOmitConfig;
  /**
   * If initialized the response will not contain fields which correspond to the properties set to true.
   *
   * [OmitScanResultField](http://help.mscanapi.com/html/6baee37a-1661-acaa-94de-f955f5f86203.htm)
   */
  OmitScanResultField: MSAPIOmitScanResultField;
  /**
   * Out of State Fee Tax Options
   * -Texas VIT Tax - Vehicle Inventory Tax. Collect VIT tax outside the state of Texas
   * -Tennessee Local Business Aftermarket Tax. Collect Tennessee Local Business Aftermarket Tax outside the state of Tennessee
   * -Apply retail tax for Alabama out of state transactions
   * -Virginia Business, Professional, and Occupational License Tax rate
   * -Virginia Business, Professional, and Occupational License Tax in the Cap
   * -Option to include Out of State Tax in MSAPIProgramInfo.SalesTax. By default MSAPIProgramInfo.SalesTax does not include the Out of State Tax.
   * -Option to collect Oregon Corporate Activity Tax.
   * -Option to apply Oregon Corporate Activity Tax to rebate used as equity.
   *
   * [OutOfStateFeeTax](http://help.mscanapi.com/html/0fa16ee8-fd8b-76ba-4e15-b506857bff9c.htm)
   */
  OutOfStateFeeTax: MSAPIOutOFStateFeeTax;
  /**
   * Payment schedule for customer payments. Option to collect monthly, bi-weekly or weekly.
   *
   * Option to calculate weekly and bi-weekly payments based on a period payment.
   *
   * [PaymentSchedule](http://help.mscanapi.com/html/02efda9e-d907-cfaa-70b7-9386fc178288.htm)
   */
  PaymentSchedule: MSAPIPaymentSchedule;
  /**
   * Market for rates.
   *
   * Example: If rate market is set to New Jersey, and you run an out of state scan, the rates will always use the rates based out of the New Jersey market.
   *
   * [RateMarket](http://help.mscanapi.com/html/ea8adf4a-5bcb-698a-2769-bf7bc17a9ff2.htm)
   */
  RateMarket: MSAPIMarket;
  /**
   * Rebate customer type, value, region and ZIP code.
   *
   * [Rebates](http://help.mscanapi.com/html/99575c04-f77b-2d5b-d252-e599bd18f54c.htm)
   */
  Rebates: MSAPIScanRequestRebateContainer;
  /**
   * Vehicle Registration Date. Format:(YYYY-MM-DD T hh:mm:ss) By default it will use the current date.
   *
   */
  RegistrationDate: string;
  /**
   * Must be initialized for retail and mPencil scans. Define retail parameters.
   *
   * [RetailPart](http://help.mscanapi.com/html/09490f8b-b5be-92bf-d135-24deaafee0df.htm)
   */
  RetailPart: MSAPIScanRequestRetailPart;
  /**
   * Type of financial transaction you are going to run - Lease, Retail, or mPencil.
   *
   * [ScanMode](http://help.mscanapi.com/html/66d21b53-f5fa-1dd3-d86b-9038e5ea9d91.htm)
   */
  ScanMode: MSAPIScanMode;
  /**
   * Scan type to be used. There are four scan types to select from, Profit, SellingPrice, Payment, and Maxi Scan. Selling Price scan is the most commonly used scan type.
   *
   * [ScanType](http://help.mscanapi.com/html/5ecbe548-0977-a7de-04c6-fb52b18d5e32.htm)
   */
  ScanType: MSAPIScanType;
  /**
   *  Calculate state taxes, returns one of the state tax clases.
   *
   * [StateFeeTax](http://help.mscanapi.com/html/027676b8-1ac4-245e-bc1a-779237fe870e.htm)
   */
  StateFeeTax: MSAPIStateFeeTax;
  /**
   * Customer trade-in values.
   *
   * [TradeIn](http://help.mscanapi.com/html/a7aee1d5-899b-ec29-78d7-aacd7873f74d.htm)
   */
  TradeIn: MSAPITradeIn;
  /**
   *  TradeInEx provides the ability to pass multiple customer trade-ins in an array.
   *
   * [TradeInEx](http://help.mscanapi.com/html/c2f24d96-0a8f-b868-9f33-2a0b8a23852c.htm)
   */
  TradeInEx: MSAPITradeInEx;
  /**
   * Multiple Customer Trade-in values
   *
   * [TradeIns](http://help.mscanapi.com/html/c6ebcd2f-99d3-246f-3d81-9675ff4957ac.htm)
   */
  TradeIns: MSAPITradeIn[];
  /**
   * Apply configuration settings from API Account Manager Portal.
   *
   * As of May 29, 2018, if UseConfig property is not defined or set to null, UseConfig properties will always be enabled to true on the back end.
   *
   * If you do not want the UseConfig properties to be set to true by default you can either pass the UseConfig property as an empty Object or pass all UseConfig properties set to false.
   *
   * [UseConfig](http://help.mscanapi.com/html/93b81cd6-6177-b7e7-7c52-4d9198b4075e.htm)
   */
  UseConfig: MSAPIUseConfig;
  /**
   * Vehicle values, MSRP, DealerCost, Current Mileage, etc. that will be used in the scan. Also used to define used car guidebook values.
   *
   * [Vehicle](http://help.mscanapi.com/html/d4e35e46-4434-b0be-376c-1426df6e5a1c.htm)
   */
  Vehicle: MSAPIVehicle;
  /**
   * Vehicle equipment - Residualized Equipment/manually defined equipment.
   *
   * [VehicleEquipment](http://help.mscanapi.com/html/23a3cef3-caf5-6c9b-89f5-06ee11bc6d91.htm)
   */
  VehicleEquipment: MSAPIVehicleEquipmentResidualizable[];
  /**
   * Warranty Values - Charge, Cost, and MSRP. Also option to always include Warranty in transaction.
   *
   * [Warranty](http://help.mscanapi.com/html/aa30c047-26e7-c959-14ff-6c4f15d007a0.htm)
   */
  Warranty: MSAPIWarranty;
};

/**
 * [MSAPIPaidReserve](https://help.mscanapi.com/html/7a143b5e-8d2b-fd56-b24a-9a5c25693dd9.htm)
 */
export type MSAPIPaidReserve = {
  ExtensionData: any;
  Lender: string;
  PaidReserve: number;
  Payment: number;
};

/**
 * Residual source type.
 *
 * [MSAPIAdvanceResidualBaseTypeEnum](https://help.mscanapi.com/html/a103acfd-2c48-a376-9779-ec94a311c78a.htm)
 */
export enum MSAPIAdvanceResidualBaseTypeEnum {
  Undefined = 0,
  CRVMRMFMV = 1,
  KBB = 2,
  JDPower = 3,
  BBK = 4,
  ABP = 5,
  OriginalMSRP = 6,
  Invoice = 7,
}

/**
 * Vehicle condition enumerators
 *
 * [MSAPIVehicleConditionEnum](https://help.mscanapi.com/html/d270a27a-9d67-f036-57cb-23b75a91536c.htm)
 */
export enum MSAPIVehicleConditionEnum {
  Undefined = 0,
  ExtraClean = 1,
  Clean = 2,
  Average = 3,
  Rough = 4,
}

/**
 * Wholesale/Retail type
 *
 * [MSAPIWholesaleRetailTypeEnum](https://help.mscanapi.com/html/515060e1-4997-f785-a06c-a878182fce7d.htm)
 */
export enum MSAPIWholesaleRetailTypeEnum {
  Undefined = 0,
  Wholesale = 1,
  Retail = 2,
}

/**
 * [MSAPILeaseAdvanceResidualTuple](http://help.mscanapi.com/html/0701abf8-c2ee-2c4b-614a-4a968dc39715.htm)
 */
export type MSAPILeaseAdvanceResidualTuple = {
  /**
   * Vehicle condition enumerators - clean, extra clean, average, rough
   *
   * [Condition](https://help.mscanapi.com/html/8dd48d38-3557-8521-607f-be52ce37373d.htm)
   */
  Condition: MSAPIVehicleConditionEnum;
  /**
   * Residual source description.
   */
  Description: string;
  /**
   *"
   */
  Multiplier: number;
  /**
   * Residual source type.
   */
  Type: MSAPIAdvanceResidualBaseTypeEnum;
  /**
   * Wholesale/Retail type
   *
   * [WholesaleRetail](http://help.mscanapi.com/html/64eaba03-85f2-d171-9229-352075f0c605.htm)
   */
  WholesaleRetail: MSAPIWholesaleRetailTypeEnum;
};

/**
 * Lender Residual - used to tell which source a lender is using to calculate residual values.
 *
 * [MSAPILeaseAdvanceResidual](http://help.mscanapi.com/html/524c66fe-e629-39b6-f018-4c6af7796d2a.htm)
 */
export type MSAPILeaseAdvanceResidual = {
  /**
   * Lease Advance Source
   *
   * [Advance](http://help.mscanapi.com/html/c78022b2-788d-a6b1-09b6-84a1226666fb.htm)
   */
  Advance: MSAPILeaseAdvanceResidualTuple;
  /**
   * Lease Residual Source
   *
   * [Residual](http://help.mscanapi.com/html/61d94441-1214-6cb7-339f-9e5b8def953c.htm)
   */
  Residual: MSAPILeaseAdvanceResidualTuple;
};

/**
 * [MSAPIAlternitiveRate](http://help.mscanapi.com/html/68918578-e8cb-cc6b-af05-27ba88b6271d.htm)
 */
export type MSAPIAlternitiveRate = {
  /**
   * Will return the corresponding interest rate as an APR.
   */
  APR: number;
  /**
   * Will return the corresponding interest rate as a moneyfactor.
   */
  MoneyFactor: number;
};

export type MSAPIAppliedRebate = {
  // Custum leasebandit field
  IsCustom: boolean;
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Rebate incentive code.
   */
  IdentCode: string;
  /**
   * Rebate Name.
   */
  Name: string;
  /**
   * Rebate program code.
   */
  Number: string;
  /**
   * Rebate type - CCR, CCR Incremental, Cash, Certificate, or APR.
   */
  RebateType: MSISRebateTypeEnum;
  /**
   * Rebate type - Customer or Dealer. needs comments
   */
  Recipient: MSISRecipientType;
  /**
   * Rebate expiration date
   */
  StopDate: string;
  /**
   * Rebate value.
   */
  Value: number;
};

/**
 * Lender programs flags.
 *
 * [MSAPIProgramFlag](http://help.mscanapi.com/html/fdf77755-6bc2-6fce-fb1d-3f4755f69a96.htm)
 */
export enum MSAPIProgramFlag {
  Balloon = 0,
  Percent = 1,
  PreviousCustomer = 2,
  Expired = 3,
  ResidualOverriden = 4,
  RateOverriden = 5,
  AdvanceOverriden = 6,
}

/**
 * APR or Money Factor.
 *
 * [MSAPIAPRLMFEnum](http://help.mscanapi.com/html/842950cc-a242-9c48-ed21-8a0eb2c36d5a.htm)
 */
export enum MSAPIAPRLMFEnum {
  APR = 0,
  LeaseMoneyFactor = 1,
}

/**
 * Lease equipment header.

 * [MSAPIEquipmentRecapHeaderLease](http://help.mscanapi.com/html/1f2d1562-46aa-3664-6406-1daa5866dddc.htm)
 */
export type MSAPIEquipmentRecapHeaderLease = {
  /**
   * Max Advance Adjustment.             (Inherited from MSAPIEquipmentRecapHeaderCommon.)
   */
  AdvanceName: string;
  /**
   * Dealer Cost.             (Inherited from MSAPIEquipmentRecapHeaderCommon.)
   */
  CostName: string;
  /**
   *
   */
  ResidualName: string;
  /**
   * MSRP Adjustments Recap.             (Inherited from MSAPIEquipmentRecapHeaderCommon.)
   */
  Title: string;
};

/**
 *
 */
export type MSAPIEquipmentRecapLease = {
  /**
   * Adjust residual value instead of MSRP.
   */
  AdjustResidualNotMSRP: boolean;
  /**
   * Equipment Advance Value.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  Advance: number;
  /**
   * Equipment Cost.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  Cost: number;
  /**
   * Equipment CRV/FMV.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  CrvFmv: number;
  /**
   * Equipment Description.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  Description: string;
  /**
   * Identifier.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  ID: number;
  /**
   * Equipment Price.             (Inherited from MSAPIEquipmentRecapCommon.)
   */
  Price: number;
  /**
   * Equipment residual value.
   */
  Residual: number;
};

/**
 * Vehicle Equipment.
 *
 * [MSAPIEquipmentRecap](http://help.mscanapi.com/html/0c678442-5f8a-dae4-e18e-61186c58a0df.htm)
 */
export type MSAPIEquipmentRecap = {
  /**
   * Header description.
   *
   * [Header](http://help.mscanapi.com/html/c072564b-3f38-a963-4edb-11bac977519a.htm)
   */
  Header: MSAPIEquipmentRecapHeaderLease;
  /**
   * Array of vehicle equipment description, cost, price, etc.
   *
   * [Items](http://help.mscanapi.com/html/acbe77bf-12d3-c27f-2569-321b5167b8c6.htm)
   */
  Items: MSAPIEquipmentRecapLease[];
};

/**
 * Array of Custom Fees that were defined in the request.
 * Provides breakdown of Fee Value, Fee Type, and Tax on Fee.
 *
 * [MSAPICustomFeeTaxRecap](http://help.mscanapi.com/html/3d222230-ffb7-35c3-2849-b1c069772229.htm)
 */
export type MSAPICustomFeeTaxRecap = {
  /**
   * Defined Custom Fee enumerator                        Fee Type - Dealer Defined Fee, Service Contract, and Insurance
   */
  StrongType: CustomFeeTypeEnum;
  /**
   * Tax
   */
  Tax: number;
  /**
   * Fee value that tax was calculated on
   */
  TaxableBase: number;
  /**
   * Use to calculate the tax on Fee
   */
  TaxRate: number;
};

/**
 * Tax Type - Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc.
 *
 * [MSAPITaxAndFeeTypeEnum](http://help.mscanapi.com/html/79176a7c-b349-ed12-eb61-089769aa6987.htm)
 */
export type MSAPITaxAndFeeTypeEnum = number;

/**
 * Array of Tax Type - Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc and calcualted tax value
 *
 * [MSAPITaxAndFee](http://help.mscanapi.com/html/e210db51-ae28-f7f0-3362-3a95e078501c.htm)
 */
export type MSAPITaxAndFee = {
  /**
   * Tax Type - Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc.
   *
   * [Type](http://help.mscanapi.com/html/82924f6c-d3f6-8ef5-e63d-cb86556cffbc.htm)
   */
  Type: MSAPITaxAndFeeTypeEnum;
  /**
   * Calculated tax value
   */
  Value: number;
};

/**
 * Tax Type - CCR, CustomFee, MonthlyUse, SalesTax.
 *
 * [MSAPITaxPartsEnum](http://help.mscanapi.com/html/c8e0c57b-b3a5-d341-a6a7-ff8fcbeedb0a.htm)
 */
export enum MSAPITaxPartsEnum {
  Undefined = 0,
  CCR = 1,
  CustomFee = 2,
  MonthlyUse = 3,
  SalesTax = 4,
  Other = 5,
}

/**
 * Array of taxes - Includes Tax rate, tax type, and calculated tax value.
 * Array contains two types - types for Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc and types for CCR, CustomFee, MonthlyUse, SalesTax.
 *
 * [MSAPITaxPart](http://help.mscanapi.com/html/0229e30e-c802-6fc1-2068-8e1957d1103b.htm)
 */
export type MSAPITaxPart = {
  /**
   * Array of Tax Type - Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc and calcualted tax value
   *
   * [TaxRate](http://help.mscanapi.com/html/71393132-ca5c-aeb4-3ac1-27e6192a420e.htm)
   */
  TaxRate: MSAPITaxAndFee[];
  /**
   * Tax Type - CCR, CustomFee, MonthlyUse, SalesTax.
   *
   * [Type](http://help.mscanapi.com/html/879b9ca9-3edd-0f88-7fb6-cea46c02b751.htm)
   */
  Type: MSAPITaxPartsEnum;
};

/**
 * Lease lender program information.
 *
 * [MSAPIProgramInfoLease](http://help.mscanapi.com/html/14fca3f9-62a5-7e6b-4076-0be878252f4e.htm)
 */
export type MSAPIProgramInfoLease = {
  /**
   * Acquisition Fee (Bank Fee).
   */
  AcqusitionFee: number;
  /**
   * If bool is set to true Acquisition Fee will be collected in the Cap Cost.
   */
  AcqusitionFeeInCap: boolean;
  /**
   * Advance Type and value
   */
  Advance: MSAPILDAdvance;
  /**
   *
   */
  AdvanceResidual: MSAPILeaseAdvanceResidual;
  /**
   * Annual mileage of the lender program.
   */
  AnnualMileage: number;
  /**
   * BPOL Tax value collected in cap cost.
   */
  BPOLTaxInCap: number;
  /**
   * BPOL Tax value collected in the inception fees.
   */
  BPOLTaxInFees: number;
  /**
   * Cap Cost Reduction tax rate.
   */
  CapCostReductionTaxRate: number;
  /**
   * Wearcare charge, primarily used in Audi and Ford transactions.
   */
  CareCharge: number;
  /**
   * CAT tax collected in the capitalized cost.
   */
  CATTaxInCap: number;
  /**
   * CAT tax collected in the inception fees.
   */
  CATTaxInFees: number;
  /**
   * CCR rebate.
   */
  CCRRebate: number;
  /**
   * CCR rebate tax that is being collected in the inception fees.
   */
  CCRRebateTaxInFees: number;
  /**
   * Excess Annual Mileage Charge.
   */
  CostPerMile: number;
  /**
   * Generic CRV value for used vehicles and MRM value for new vehicles.
   */
  CrvMRM: number;
  /**
   * Array of defined Custom Fees.
   */
  CustomFee: MSAPICustomFeeLease[];
  /**
   * Custom fee tax in the cap cost.
   */
  CustomFeeTaxInCap: number;
  /**
   * Custom fee tax in inception fees.
   */
  CustomFeeTaxInFees: number;
  /**
   * Custom Fee Tax collected in Cap Cost
   *
   * [CustomFeeTaxRecapInCap](http://help.mscanapi.com/html/d9e17c83-1416-1fac-e0c1-69b85c733d14.htm)
   */
  CustomFeeTaxRecapInCap: MSAPICustomFeeTaxRecap[];
  /**
   * Custom Fee Tax collected in Inception
   */
  CustomFeeTaxRecapInInception: MSAPICustomFeeTaxRecap[];
  /**
   * Demo mileage penalty.
   */
  DemoMilesPenaltyMiles: number;
  /**
   * Demo mileage charge.
   */
  DemoMilesRate: number;
  /**
   * Demo mileage residual adjustment.
   */
  DemoMilesResidualAdjustment: number;
  /**
   * Downpayment tax.
   */
  DownpaymentTax: number;
  /**
   * If bool is true, downpayment tax is collected in the capitalized cost.                        If bool is false, downpayment tax is collected in the inception fees/upfront/due on delivery.
   */
  DownpaymentTaxInCap: number;
  /**
   * Down payment tax that is being collected in the inception fees.
   */
  DownpaymentTaxInFees: number;
  /**
   * Amount of excess miles that will be driven per year.
   */
  ExcessMilesPerYear: number;
  /**
   *
   */
  ExtraUseTax: number;
  /**
   *
   */
  ExtraUseTaxRate: number;
  /**
   * If bool is set to true it will be a first payment waiver information/program.
   */
  FirstPaymentWaived: boolean;
  /**
   * If bool is true and the \"MSAPIFilterLease.IncludeFirstPaymentWaived\" property is passed as true, the First Payment Waived Amount will show.
   */
  FirstPaymentWaivedAmount: number;
  /**
   * Inception fee values.
   */
  InceptionFee: number;
  /**
   * Amount of Inception Fees that was not paid for at signing(drive off).  This value will be rolled into the Capitalized Cost(Financed).
   */
  InceptionFeeDeficiency: number;
  /**
   * Bool is true if Tax is collected in cap cost.
   */
  InventoryTaxInCap: boolean;
  /**
   * One Pay programs.
   */
  IsOnePay: boolean;
  /**
   * Previous customer programs.
   */
  IsPreviousCustomer: boolean;
  /**
   * Zero due at lease signing programs.
   */
  IsZeroDueAtSigning: boolean;
  /**
   * Collect the lease tax in the monthly payment.
   */
  LeaseTaxInPayment: boolean;
  /**
   * Lender Residual Adjustment                        On occasion, some lenders will enhance a vehicle residual by a residual adder/dollar amount.
   */
  LenderResidualAdjustment: number;
  /**
   *
   */
  LendersAndMembersFee: number;
  /**
   * Max Advance value.
   */
  MaxAdvance: number;
  /**
   * Expected mileage - anticipated mileage to be driven.
   */
  MileageExpected: number;
  /**
   * Mileage limit.
   */
  MileageLimit: number;
  /**
   * Mileage penalty.
   */
  MileagePenaltyAmount: number;
  /**
   * Adjusted MSRP.
   */
  MSRPAdjusted: number;
  /**
   * Base MSRP.
   */
  MSRPBase: number;
  /**
   * Array of taxes - Includes Tax rate, tax type, and calculated tax value.            Array contains two types, types for Custom Fee Tax, State Tax, County Tax, Service Fee Tax, etc and types for CCR, CustomFee, MonthlyUse, SalesTax.
   *
   * [MunicipalityTaxBreakdown](http://help.mscanapi.com/html/2f039e11-ac04-3d79-19e9-7ef19d26e2b4.htm)
   */
  MunicipalityTaxBreakdown: MSAPITaxPart[];
  /**
   * If the lease term is less or equal 36 months and SpecialSalesUseTaxPct is defined in the request  then SalesUseTaxRate will be obtained from SpecialSalesUseTaxPct.
   */
  NumberOfSpecialUseTaxRatePayments: number;
  /**
   * One Pay Payment including tax
   */
  OnePayPayment: number;
  /**
   * Original Annual Miles
   */
  OriginalAnnualMileage: number;
  /**
   * Other fees tax collected in Cap Cost.
   */
  OtherTaxInCap: number;
  /**
   * Other fees collected in Cap Cost tax rate.
   */
  OtherTaxInCapRate: number;
  /**
   * Other fees tax collected in Inception Fees.
   */
  OtherTaxInFees: number;
  /**
   * Other fees collected in Inception Fees tax rate.
   */
  OtherTaxInFeesRate: number;
  /**
   * Participation amount that is used for Paid Reserve.
   */
  Participation: number;
  /**
   * Personal Property Tax.
   */
  PersonalPropertyTax: number;
  /**
   * Some lenders might charge an additional fee, besides the Acquisition Fee, to use a specific program.
   */
  PreparationFee: number;
  /**
   * Residual name.
   */
  ResidualName: string;
  /**
   * Residual percentage of the lender program.
   */
  ResidualPct: number;
  /**
   * Calculated residual value of the lender program.
   */
  ResidualValue: number;
  /**
   * Calculated residual value mileage adjustment.
   */
  ResidualValueMileageAdjustment: number;
  /**
   * Residual value mileage adjustment percentage.
   */
  ResidualValueMileageAdjustmentPct: number;
  /**
   * If bool set to true the sales tax will be collected in the Inception Fees.                        If bool set to false the sales tax will be collected in the Cap Cost.
   */
  SalesTaxUpFront: boolean;
  /**
   * Lender program security deposit.
   */
  SecurityDeposit: number;
  /**
   * Rate adjustment value for waived security adjustment programs.
   */
  SecurityDepositRateAdjustment: number;
  /**
   * If bool is set to true it will be a lender waived security deposit program.
   */
  SecurityDepositWaived: boolean;
  /**
   * Returned in states like Washington.  Only returns if MSAPIWAFeeTaxLease.SpecialSalesUseTaxPct is defined.
   */
  SpecialUseTaxRate: number;
  /**
   * State fee
   */
  StateFee: number;
  /**
   * Itemization of taxes and fees collected in cap cost.
   *
   * [TaxBreakdownInCap](http://help.mscanapi.com/html/abaeb497-5673-7748-a1e9-b56d73928dbd.htm)
   */
  TaxBreakdownInCap: MSAPITaxAndFee[];
  /**
   * Itemization of taxes and fees collected in inception fees.
   *
   * [TaxBreakdownInInception](http://help.mscanapi.com/html/abaeb497-5673-7748-a1e9-b56d73928dbd.htm)
   */
  TaxBreakdownInInception: MSAPITaxAndFee[];
  /**
   * Itemization of Out of State Tax Collected in cap cost.
   *
   * [TaxBreakdownOutOfStateInCap](http://help.mscanapi.com/html/abaeb497-5673-7748-a1e9-b56d73928dbd.htm)
   */
  TaxBreakdownOutOfStateInCap: MSAPITaxAndFee[];
  /**
   * Itemization of Out of State Tax Collected in the inception fees.
   *
   * [TaxBreakdownOutOfStateInInception](http://help.mscanapi.com/html/abaeb497-5673-7748-a1e9-b56d73928dbd.htm)
   */
  TaxBreakdownOutOfStateInInception: MSAPITaxAndFee[];
  /**
   * Total amount due at delivery.
   */
  TotalDueAtDelivery: number;
  /**
   * Total tax collected in cap cost.
   */
  TotalTaxInCap: number;
  /**
   * Total tax collected in inception fees.
   */
  TotalTaxInInception: number;
  /**
   * Trade equtiy cash to be given back to customer.
   */
  TradeEquityCashBack: number;
  /**
   * Trade tax exempt - exclude trade from tax.
   */
  TradeTaxExempt: number;
  /**
   * Use tax.
   */
  UseTax: number;
  /**
   * Use tax exemption.
   */
  UseTaxExemption: number;
  /**
   * Use tax rate.
   */
  UseTaxRate: number;
  /**
   * Bool is true if Waste Tire Fee is collected in cap cost.
   */
  WasteTireFeeInCap: boolean;
};

/**
 * Transaction Fee
 *
 * [MSAPITransactionFee](http://help.mscanapi.com/html/59d1b57d-85cc-d6cb-fa87-68cb817b605a.htm)
 */
export type MSAPITransactionFee = {
  Description: string;
  Value: number;
};

/**
 * Lender programs might be flagged as rejected because you are over advanced, exceeded maxiumum down payment policy, exceeded maximum paid reserve, etc.
 * GetProgramInfo response returns details of why the deal is being rejected. Rejected details are located in the "UI" section of the GetProgramInfo response.
 *
 * [MSAPIRejectFlag](http://help.mscanapi.com/html/e8a2b6d7-4893-e53c-7166-4c6395340f2d.htm)
 */
export enum MSAPIRejectFlag {
  /**
   * Lease program has exceeded lender advance policy.
   */
  Advance = 0,
  /**
   * Lease program has exceeded lender down payment/cap cost reduction policy.
   */
  DownPayment = 1,
  /**
   * Lease program has exceeded lender paid reserve policy.
   */
  PaidReserve = 2,
  /**
   * Lease program has exceeded lender warranty policy.
   */
  Warranty = 3,
  /**
   * Lease program does not meet lender credit score policy.
   */
  CreditScorePolicy = 4,
  /**
   * Program requires customer/lender review.
   */
  CaseByCase = 5,
  /**
   * Rejected retail program. This reject will be applied when you have a pre-qulification status that has not been met.
   */
  RetailReject = 6,
  /**
   * Rejected retail program. This reject will be applied when you have exceeded one of the retail lender policies, can be advance, reserve policy, etc.
   */
  RetailRejectStrong = 7,
}

/**
 * Define account custom fees.
 *
 * [MSAPICustomFeeRetail](http://help.mscanapi.com/html/2e90c7d5-a364-f4ca-fd10-cf6b9de4a2b5.htm)
 */
export type MSAPICustomFeeRetail = {
  /**
   * Cost.             (Inherited from MSAPICustomFee.)
   */
  Cost: number;
  /**
   * Name of the custom fee.             (Inherited from MSAPICustomFee.)
   */
  Description: string;
  /**
   * Identifier.             (Inherited from MSAPICustomFee.)
   */
  ID: number;
  /**
   * Allows to pass the different type of Fee without having to define the service contract and insurance classesThis is usefull when states tax the fee differentlyTHIS IS ONLY FOR THE STATE OF CONNECTICUTIf Enumerator of 0 is passed luxury tax will be included in the calculation.If Enumerator of 1 or 2 is passed luxury tax will not be included in the calculation. (Inherited from MSAPICustomFee.)
   *
   * [StrongType](http://help.mscanapi.com/html/82e0182e-ff4a-0db9-8d52-a124dd2b89b7.htm)
   */
  StrongType?: CustomFeeTypeEnum;
  /**
   * Setting bool to true will tax the custom fee.             (Inherited from MSAPICustomFee.)
   */
  Taxable: boolean;
  /**
   * (Inherited from MSAPICustomFee.)
   */
  TaxedAsAftermarketProduct?: boolean;
  /**
   * Define/categorize the custom fee.            MSAPICustomFee Class - valid Type values (used for RouteOne eContracting)MSAPIServiceContract Class - valid Type values (used for RouteOne eContracting)MSAPIInsurance Class - valid Type values (used for RouteOne eContracting) (Inherited from MSAPICustomFee.)
   */
  Type: string;
  /**
   * Default custom fee value.            If being used in GetProductPayment Method, this would be the Minimum value of the product. (Inherited from MSAPICustomFee.)
   */
  Value: number;
};

/**
 * Retail lender program information
 *
 * [MSAPIProgramInfoRetail](http://help.mscanapi.com/html/de56da27-41b6-86b7-5127-84d75fb809f0.htm)
 */
export type MSAPIProgramInfoRetail = {
  /**
   *
   */
  AdvanceSource: string;
  /**
   * Custom fee.
   *
   * [CustomFee](http://help.mscanapi.com/html/fb76a68f-3d5c-c6a1-2634-6a6a34a4715f.htm)
   */
  CustomFee: MSAPICustomFeeRetail[];
  /**
   * Custom fee taxes.
   */
  CustomFeeTax: number;
  /**
   *
   */
  CustomFeeTaxRecap: MSAPICustomFeeTaxRecap[];
  /**
   * Collects state sales tax for garaged vehicles.
   */
  GaragedStateSalesTax: number;
  /**
   * Bool is set to true if Cash deal.
   */
  IsCashDeal: boolean;
  /**
   * Monthly Payment for every $1000 financed.
   */
  MonthlyPaymentFor1000Financed: number;
  /**
   *
   */
  NonTaxBreakdown: MSAPITaxAndFee[];
  /**
   * Participation amount that is used for Paid Reserve.
   */
  Participation: number;
  /**
   * Rebate tax.
   */
  RebateTax: number;
  /**
   * "Rebate tax rate.
   */
  RebateTaxPct: number;
  /**
   * "TaxBreakDown Array - Tax rate, tax type, and calculated tax value.
   */
  TaxBreakdown: MSAPITaxAndFee[];
  /**
   * "Out of State TaxBreanDown Array - Out of State Tax rate, tax type, and calculated tax value.
   */
  TaxBreakdownOutOfState: MSAPITaxAndFee[];
  /**
   * "Total taxes and fees.
   */
  TotalTaxesFees: number;
};

/**
 * Tax Payment Schedule - Not used in all states, primarily IA, WA, and WI.
 *
 * [MSAPITaxPaymentSchedule](http://help.mscanapi.com/html/ce3b2c35-2c76-d44c-e388-ac97e285eadd.htm)
 */
export type MSAPITaxPaymentSchedule = {
  /**
   * Base Monthly Payment before taxes.
   */
  BasePayment: number;
  /**
   * Full Amount of calcualted tax on Payment.
   */
  FullTaxOnPaymentAmount: number;
  /**
   * Number of Payments with Full Tax.
   */
  NumberPaymentsFullTax: number;
  /**
   * Number of Payments with Prorated Tax.
   */
  NumberPaymentsPartialTax: number;
  /**
   * Number of Tax Exempt Payments.
   */
  NumberPaymentsTaxExempt: number;
  /**
   * Partial Monthly Payment Amount. (Prorated Monthly Payment)
   */
  PartialPayment: number;
  /**
   * Partial Monthly Tax Amount. (Prorated Monthly Tax)
   */
  PartialTaxOnPaymentAmount: number;
  /**
   * Total Number of Payments.
   */
  TotalPayments: number;
  /**
   * Total Tax Exempt Amount.
   */
  TotalTaxExemption: number;
};

/**
 * Transaction type Lease, Retail, or Balloon.
 *
 * [MSAPIProgramTransactionTypeEnum](http://help.mscanapi.com/html/9cbc90c9-0d6a-2e73-942c-92628cfcf5db.htm)
 */
export enum MSAPIProgramTransactionTypeEnum {
  'Lease' = 0,
  'Retail' = 1,
  'Balloon' = 2,
}

/**
 * [MSAPIUIItemKind](http://help.mscanapi.com/html/a0669f42-40f6-a554-5b03-9f0ae2b17b59.htm)
 */
export enum MSAPIUIItemKind {
  Item = 0,
  DescriptionTitle = 1,
  ValueTitle = 2,
  Summary = 3,
  Button = 4,
}

/**
 * [MSAPIUIItemSubkind](http://help.mscanapi.com/html/d050e2ca-5b90-3242-19d7-bfa6e74e5b59.htm)
 */
export enum MSAPIUIItemSubkind {
  Normal = 0,
  Negative = 1,
  Positive = 2,
  NegativeBlink = 3,
  PositiveBlink = 4,
  Banner = 5,
}

export type MSAPIUIItem = {
  Description: string;
  DescriptionValue: string;
  /**
   * [Kind](http://help.mscanapi.com/html/a0669f42-40f6-a554-5b03-9f0ae2b17b59.htm)
   */
  Kind: MSAPIUIItemKind;
  /**
   * [Subkind](http://help.mscanapi.com/html/55c2b63d-4d56-c35e-1602-9135deb8b1b7.htm)
   */
  Subkind: MSAPIUIItemSubkind;
  Symbol: string;
  Tag: string;
  Value: string;
};

/**
 *
 */
export type MSAPIUIPanel = {
  /**
   * Panel Description.
   */
  Description: string;
  /**
   * Array of UI items that contain deal information based on the Child Panel Name.
   *
   * [Items](http://help.mscanapi.com/html/5b3621d1-c165-6c84-ca02-a4baca911a79.htm)
   */
  Items: MSAPIUIItem[];
  /**
   * Panel Name.
   */
  Name: string;
};

/**
 * UI Panel - Deal Information for Presentation Purposes.
 *
 * [MSAPIUIPanelGroup](http://help.mscanapi.com/html/4295fd11-2767-a769-9eab-808353e323bb.htm)
 */
export type MSAPIUIPanelGroup = {
  /**
   * Panel Description.
   */
  Description: string;
  /**
   * Panel Name.
   */
  Name: string;
  /**
   * Array of Panels - Contains deal information based on the parent Panel Name.
   *
   * [Panels](http://help.mscanapi.com/html/4298ec0e-33da-73e7-ef38-21b237e9a7ef.htm)
   */
  Panels: MSAPIUIPanel[];
};

/**
 * Lender program information.
 *
 * [MSAPIProgramInfo](http://help.mscanapi.com/html/5dc6a147-a586-6690-979b-743756362827.htm)
 */
export type MSAPIProgramInfo = {
  /**
   *
   */
  _Feedback: string;
  /**
   * Adjusted selling price of the lender program.
   */
  AdjustedSellingPrice: number;
  /**
   * Amount financed of the lender program.
   */
  AmountFinanced: number;
  /**
   * Interest factor of a lease or retail program.
   */
  APRLMF: number;
  /**
   * APR or Money Factor.
   *
   * [APRLMFType](http://help.mscanapi.com/html/530ed09e-2b51-b077-4b69-dbcb23631c35.htm)
   */
  APRLMFType: MSAPIAPRLMFEnum;
  /**
   * AutoRebateParams will apply all rebates that are flagged \"Available to all\", applicable for the specified ZIP and RegionID.                        The rebates are applied based on Zip Code, Rebate Region, and type of Rebate.                        \"Available to all\" are rebate programs that have been flagged in our rebate database as available to all customers.
   *
   * [AutoRebateParams(http://help.mscanapi.com/html/8c214ba2-336a-2acf-3a81-5e2081334d1d.htm)]
   */
  AutoRebateParams: MSAPIAutoRebateParams;
  /**
   * Program Bulletin Code
   */
  BulletinCode: string;
  /**
   * Program Bulletin Number
   */
  BulletinNo: string;
  /**
   * Original buy rate (interest factor) of the lease or retail program.
   */
  BuyRate: number;
  /**
   * Cash Deficiency will be the the remainder of the total inception fees that were not covered by the upfront cash, rebate or equity.
   */
  CashDeficiency: number;
  /**
   * City tax value only applies to states that contain a defined city tax rate.  At this time only applies to Colorado.
   */
  CityTax?: number;
  /**
   * City tax rate only applies to cities that collect a city tax.  At this time only applies to Colorado.
   */
  CityTaxRate?: number;
  /**
   * Vehicle cost.
   */
  Cost: number;
  /**
   * County tax value only applies to states that contain a defined county tax rate.
   */
  CountyTax?: number;
  /**
   * County tax rate only applies to cities that collect a County tax.
   */
  CountyTaxRate?: number;
  /**
   * Vehicle's current mileage.
   */
  CurrentMileage: number;
  /**
   * Customer cash.
   */
  CustomerCash: number;
  /**
   * Returns the generic pass description
   */
  CustomKey: string;
  /**
   * Days to first payment.  This is primarily used for retail and balloon transactions.            Most dealerships use 30 or 45 days to first payment.
   */
  DaysToFirstPayment: number;
  /**
   * Dealer incentive.            Rebate money paid directly to the dealer for a particular vehicle.
   */
  DealerIncentive: number;
  /**
   * If bool is set to true the deficiency will come out of profit.
   */
  DeficiencyInProfit: boolean;
  /**
   * Lender program description.
   */
  Description: string;
  /**
   * Doc Stamp Tax - Only used for states that collect a Doc Stamp Tax.
   */
  DocStampTax: number;
  /**
   * Doc Stamp Tax Rate is used for states that collect a Doc Stamp Tax.
   */
  DocStampTaxRate: number;
  /**
   * Total downpayment - The total downpayment value can include money from cash, trade equity, or rebate.
   */
  Downpayment: number;
  /**
   * Amount of cash used for down payment.  The API/Market Scan calculation will always pay inception fees first.            Example:  If the customer is putting down $5,000, and total inception fees are $1,000, the amount of cash used for down payment will be $4,000.
   */
  DownpaymentFromCash: number;
  /**
   * Amount of equity used for down payment.  The API/Market Scan calculation will always pay inception fees first.Example 1:  If the customer has zero cash out of pocket (no money down), trade equity of $5,000 and total inception fees are $1,000, the amount of equity used for down payment will be $4,000.Example 2:  If the customer has $500 cash out of pocket, trade equity of $5,000, and total inception fees are $2,000.            The $500 customer cash, and $1,500 from the trade equity will be used to pay the inception fees first.  The remaining equity of $3,500 will be the amount of equity used for downpayment.
   */
  DownpaymentFromEquity: number;
  /**
   * Amount of rebate used for down payment.The API/Market Scan calculation will always pay inception fees off first.Example 1:  If the customer has zero cash out of pocket (no money down), rebate of $3,000 and total inception fees are $1,000, the amount of rebate used for down payment will be $2,000.Example 2:  If the customer has $500 cash out of pocket, rebate of $2,500, and total inception fees are $2,000.             The $500 customer cash, and $1,500 from the rebate will be used to pay the inception fees first.  The remaining $1,500 will be the amount of rebate money used for downpayment.The above scenarios only apply to non cap cost reduction rebates.  If the rebate is a CCR (cap cost reduction) rebate, then the full amount of the rebate will be used as downpayment.
   */
  DownpaymentFromRebate: number;
  /**
   * mScan engine name.
   */
  Engine: string;
  /**
   * mScan engine version.
   */
  EngineVersion: string;
  /**
   * Equiment Recap.
   *
   * [EquipmentRecap](http://help.mscanapi.com/html/9d3ecdb1-8da6-74ee-d5ca-7271be7bf93d.htm)
   */
  EquipmentRecap: MSAPIEquipmentRecap;
  /**
   * Expiration Date.
   */
  ExpirationDate: string;
  /**
   * Finance Charge.
   */
  FinanceCharge: number;
  /**
   * Flags for specific programs -Balloon, previous customer, expired lender program or if the program requires lender review.
   */
  Flags: MSAPIProgramFlag[];
  /**
   * Imbalance Amount if rounding is off by one cent
   */
  ImbalanceAmount: number;
  /**
   * Inventory sales tax.
   */
  InventoryTax: number;
  /**
   * Inventory sales tax rate.
   */
  InventoryTaxRate: number;
  /**
   * Lease lender program information.
   *
   * [Lease](http://help.mscanapi.com/html/14fca3f9-62a5-7e6b-4076-0be878252f4e.htm)
   */
  Lease: MSAPIProgramInfoLease;
  /**
   * Lender code.
   */
  Lender: string;
  /**
   * Lender name.
   */
  LenderName: string;
  /**
   * Load to value ratio.
   */
  LTV: number;
  /**
   * Provides the max markup that is allowed.
   */
  MaxRateMarkup: number;
  /**
   * Monthly payment including taxes.
   */
  MonthlyPayment: number;
  /**
   * Monthly payment before taxes.
   */
  MonthlyPaymentBeforeTax: number;
  /**
   * MSRP.
   */
  MSRP: number;
  /**
   * Base MSRP.
   */
  MSRPBase: number;
  /**
   * Non - CCR rebate. The rebate will pay inception fees first, any leftover rebate money will be used as a Cap Cost Reduction.
   */
  NonCCRRebate: number;
  /**
   * Number of advance payments.
   */
  NumberOfAdvancePayments: number;
  /**
   * Total number of payments.
   */
  NumberOfPayments: number;
  /**
   * Paid reserve.
   */
  PaidReserve: number;
  /**
   * If set to true, the paid reserve is calculated as a percentage of the cap cost.
   */
  PaidReserveIsCapCostParticipation: boolean;
  /**
   * If set to true, the paid reserve is coming from a Flat Fee value.
   */
  PaidReserveIsFlatFee: boolean;
  /**
   * http://help.mscanapi.com/html/1582c7fe-6536-e94c-b3e6-589e6c89a6b1.htm
   */
  PartRecap: MSAPIProgramInfoPartRecap;
  /**
   * Price adjustment to selling price.
   */
  PriceAdjustment: number;
  /**
   * Profit.
   */
  Profit: number;
  /**
   * Quoting policy rule description that was applied to the request.
   */
  QuotingPolicyUsed: string;
  /**
   * Lender rate market.
   */
  RateMarket: MSAPIMarket;
  /**
   * If set to true, the rate markup is an incremental value.  This is for lenders like Ford where they only allow you to markup the rate in increments of 0.25.
   */
  RateMarkupIsIncremental: boolean;
  /**
   * Amount of rebate given back to customer.
   */
  RebateCashBack: number;
  /**
   * Rebate information.
   */
  RebateValues: MSISRebateValue[];
  /**
   * Registration fees.
   */
  RegistrationFee: number;
  /**
   * Registration Item.
   *
   * [RegistrationItem](http://help.mscanapi.com/html/f11ddb05-72ca-7e16-ae68-704eae33adfa.htm)
   */
  RegistrationItem: MSAPITransactionFee[];
  /**
   * Rejections - Why the program is being rejected?
   *
   * [Rejections](http://help.mscanapi.com/html/9bdae0f9-88c9-b13a-dcc0-1f8f2a29dcc4.htm)
   */
  Rejections: MSAPIRejectFlag[];
  /**
   * Retail lender program information.
   *
   * [Retail](http://help.mscanapi.com/html/7d1873a7-ad60-eb78-a817-90c91ec4547d.htm)
   */
  Retail: MSAPIProgramInfoRetail;
  /**
   * XML that can be used for RouteOne eContracting.
   */
  RouteOneProcessCreditContractXML: string;
  /**
   * RTA Tax - For states that collect the RTA Tax.
   */
  RTATax?: number;
  /**
   * RTA Tax Rate is used for states that collect a RTA Tax.
   */
  RTATaxRate?: number;
  /**
   * Sales tax.
   */
  SalesTax: number;
  /**
   * Used for states that collect additonal tax.
   */
  SalesUseAdditionalTax?: number;
  /**
   * Addtional sales tax rate - Used for states that collect additonal tax.
   */
  SalesUseAdditionalTaxRate?: number;
  /**
   * Payment schedule for customer payments.
   */
  ScheduledPayment: number;
  /**
   * Type of Payment Schedule (Monthly, BiWeekly, Weekly).
   */
  ScheduledPaymentType: MSAPIPaymentScheduleTypeEnum;
  /**
   * Selling price.
   */
  SellingPrice: number;
  /**
   * Special or Standard lender programs.
   */
  SpecialStandard: boolean;
  /**
   * State for tax calculations.
   */
  State: string;
  /**
   * State Tax
   */
  StateTax?: number;
  /**
   * State Tax Rate
   */
  StateTaxRate?: number;
  /**
   * Supplemental title fee - New Jersey market only.
   */
  SupplementalTitleFee?: number;
  /**
   * Supplemental title fee tax rate - New Jersey market only.
   */
  SupplementalTitleFeeTaxRate?: number;
  /**
   * Tax Payment Schedule - Not used in all states, primarily IA, WA, and WI.
   *
   * [TaxPaymentSchedule](http://help.mscanapi.com/html/09b45647-470b-9133-a59e-237bce1f4745.htm)
   */
  TaxPaymentSchedule: MSAPITaxPaymentSchedule;
  /**
   * Tax rate.
   */
  TaxRate: number;
  /**
   * Term/length of financial contract.
   */
  Term: number;
  /**
   * Lender program tiers.
   */
  Tiers: string;
  /**
   * ACV (actual cash value) of the trade.
   */
  TradeACV: number;
  /**
   * Trade allowance.
   */
  TradeAllowance: number;
  /**
   * Trade equity.
   */
  TradeEquity: number;
  /**
   * Amount of trade equity given back to customer.
   */
  TradeEquityCashBack: number;
  /**
   * Trade In Tax Credit Tax Savings.
   */
  TradeInTaxSavings: number;
  /**
   * Trade payoff.
   */
  TradePayoff: number;
  /**
   * Trade tax exempt.
   */
  TradeTaxExempt: number;
  /**
   * Transaction type of the lender program.  Lease, Retail, or Balloon
   *
   * [TransactionType](http://help.mscanapi.com/html/211f8454-75fa-8259-cb34-b7506195eed3.htm)
   */
  TransactionType: MSAPIProgramTransactionTypeEnum;
  /**
   * Array of the deal structure.  Used for design/presentation.
   */
  UI: MSAPIUIPanelGroup[];
  /**
   * mScanAPI Vehicle Identifier.
   */
  VehicleID: number;
  /**
   * Warranty Charge.
   */
  WarrantyCharge: number;
  /**
   * Waste Tire Fee.
   */
  WasteTireFee: number;
};

/**
 * Ammount Finance Array and Inception Fees
 * [MSAPIProgramInfoPartRecap](http://help.mscanapi.com/html/c718909c-2464-a59b-3354-56b551cf0f41.htm)
 */
export type MSAPIProgramInfoPartRecap = {
  /**
   * Array Inception Fees
   * http://help.mscanapi.com/html/43e2d3c3-0750-d1ea-270c-21116cec2f90.htm
   */
  InceptionFees: MSAPIInceptionFeesRecap;
};

/**
 * [MSAPIInceptionFeesRecap](http://help.mscanapi.com/html/a518198a-64ca-bf49-c5a0-79a3b0ce14c9.htm)
 */
export type MSAPIInceptionFeesRecap = {
  Item: MSAPIStringDecimal[];
  Total: number;
};

/**
 * [MSAPIStringDecimal](http://help.mscanapi.com/html/cca2951d-34f1-97da-3ca1-e01fbc43ca1e.htm)
 */
export type MSAPIStringDecimal = {
  DecimalValue: number;
  ExtensionData: any;
  StringValue: string;
};

/**
 * Lease lender program information.
 * [MSAPIProgramReference](http://help.mscanapi.com/html/135e573d-9231-437a-5e25-92931341cea7.htm)
 */
export type MSAPIProgramReference = {
  /**
   * Leasebandit custom field for 0 drive off
   */
  IsOwnersChoice: boolean;
  /**
   * Leasebandit custom field for 0 drive off
   */
  ZeroDriveOff: boolean;
  /**
   * Leasebandit custom field for zip
   */
  ZIP: string;
  /**
   * Leasebandit custom field for VIN
   */
  Term: number;
  /**
   * Leasebandit custom field for VIN
   */
  VIN: string;
  /**
   * Lender Acquisition Fee (Bank Fee)."
   */
  AcquisitionFee: number;
  /**
   * Lender Advance and Residual Source - used to tell which source a lender is using to calculate advance and residual values."
   *
   * [AdvanceResidual](http://help.mscanapi.com/html/12ca6167-8394-8e91-0e92-9919c0a2ec8d.htm)
   */
  AdvanceResidual: MSAPILeaseAdvanceResidual;
  /**
   * Advance Source for Used Car Retail transactions."
   */
  AdvanceSource: string;
  /**
   * APR or Money Factor."
   *
   * [AlternitiveRate](http://help.mscanapi.com/html/ebfc9d83-d250-01c5-bdc8-f9b3919df6dc.htm)
   */
  AlternitiveRate: MSAPIAlternitiveRate;
  /**
   * Amount Financed."
   */
  AmountFinanced: number;
  /**
   * Selected/applied rebates."
   *
   * [AppliedRebate](http://help.mscanapi.com/html/54f45602-a9e7-2cb3-6b8e-88a753c0f2b4.htm)
   */
  AppliedRebate: MSAPIAppliedRebate[];
  /**
   * Program Buy Rate (interest factor)."
   */
  BuyRate: number;
  /**
   * If included and set the bool to true will return the cash in the result properties."
   */
  Cash: number;
  /**
   * Lender Program identifier."
   */
  Code: string;
  /**
   * Annual mileage excess mileage cost."
   */
  CostPerMile: number;
  /**
   * Generic CRV value for used vehicles and MRM value for new vehicles."
   */
  CrvMRM: number;
  /**
   * Demo mileage penalty."
   */
  DemoMilesPenaltyMiles: number;
  /**
   * Demo mileage charge."
   */
  DemoMilesRate: number;
  /**
   * Demo mileage residual adjustment."
   */
  DemoMilesResidualAdjustment: number;
  /**
   * Expiration Date."
   */
  ExpirationDate: string;
  /**
   * If bool is true and the \"MSAPIFilterLease.IncludeFirstPaymentWaived\" property is passed as true, the First Payment Waived Amount will show."
   */
  FirstPaymentWaivedAmount: number;
  /**
   * An array of specific lender programs in the results.            Types of programs are Balloon, Percent, Previous Customer, and Expired programs."
   *
   * [Flags](http://help.mscanapi.com/html/89f74ed0-ccf3-2394-075f-0a65a8fa883b.htm)
   */
  Flags: MSAPIProgramFlag[];
  /**
   * If bool is set to true it will return all GetProgramInfo result properties."
   *
   * [FullDetails](http://help.mscanapi.com/html/398cf0a2-9c0f-0fb4-9861-24c8e26981d3.htm)
   */
  FullDetails: MSAPIProgramInfo;
  /**
   * Identifier."
   */
  ID: number;
  /**
   * Total inception fees and taxes."
   */
  InceptionFeesTaxes: number;
  /**
   * Lender code."
   */
  Lender: number;
  /**
   * Lender Name."
   */
  LenderName: string;
  /**
   * LTV - Loan To Value."
   */
  LTV: number;
  /**
   * Maximum advance value for the lender program."
   */
  MaxAdvance: number;
  /**
   * Maximum credit score for the lender program."
   */
  MaxCreditScore: number;
  /**
   * Minimum credit score for the lender program."
   */
  MinCreditScore: number;
  /**
   * Out of Pocket Cash."
   */
  OutOfPocketCash: number;
  /**
   * Paid reserve value."
   */
  PaidReserve: number;
  /**
   * Calculated monthly payment."
   */
  Payment: string;
  /**
   * Selling Price."
   */
  Price: number;
  /**
   * Profit value."
   */
  Profit: string;
  /**
   * Description of the lender program."
   */
  ProgramDescription: string;
  /**
   * PVI - Profit to Value Index.                        The highest PVI (Payment to Value Index) number indicates the best bang for the buck! PVI. (Payment to Value Index.)   i.e. A $20000 vehicle @ $200 per month for 36 months has a higher PVI than a $17000 vehicle @$150 per month times 36.  Basically $3000 more car for $1800 in extra payment."
   */
  PVI: number;
  /**
   * Rank of the lender program."
   */
  Rank: number;
  /**
   * string Rate of the lender program.                        Marked up value if the rate is being marked up because of reserve/back end profit."
   */
  Rate: string;
  /**
   * Registration Fee"
   */
  RegistrationFee: number;
  /**
   * Rejected flags for the programs that do not meet certain criterias.            Programs might be flagged as rejected because you are over advanced, exceeded maxiumum down payment policy, exceeded maximum paid reserve, etc.                        GetProgramInfo response returns details of why the deal is being rejected.  Rejected details are located in the \"UI\" section of the GetProgramInfo response."
   *
   * [Rejections](http://help.mscanapi.com/html/60ae209b-9eb5-6fbc-5cc7-439e0dd1dc68.htm)
   */
  Rejections: MSAPIRejectFlag[];
  /**
   * Residual Name."
   */
  ResidualName: string;
  /**
   * Residual percentage.            For Used car vehicles we will always return a dollar value and not the percentage.  We do not convert the dollar value to a percentage."
   */
  ResidualPercent: number;
  /**
   * Calculated residual value."
   */
  ResidualValue: number;
  /**
   * Security deposit."
   */
  SecurityDeposit: number;
  /**
   * decimal Rate of the lender program.                        Marked up value if the rate is being marked up because of reserve/back end profit."
   */
  SellRate: number;
  /**
   * Special or Standard lender program."
   */
  SpecialStandard: boolean;
  /**
   * Calculated motor vehicle tax value.                        You will only receive a rate and value in this property when the taxes are collected upfront, like NY, NJ, OH, IL, etc.                          In monthly tax states the tax is included in the monthly payment."
   *
   * [Tax](http://help.mscanapi.com/html/9ff74cad-f2f0-2bd5-13a9-e6ed942301ec.htm)
   */
  Tax: MSAPITaxData;
  /**
   * Lender program tier."
   */
  Tiers: string;
  /**
   * Total rebate value."
   */
  TotalRebate: number;
  /**
   * UI index."
   */
  UIIndex: number;
  /**
   * XID - Alphanumeric String ID."
   */
  XID: string;
};

/**
 * [MSAPITaxData](http://help.mscanapi.com/html/c9ed15bc-cfe5-ec96-2477-eb7e38e73312.htm)
 */
export type MSAPITaxData = {
  /**
   * Calculated motor vehicle sales tax. The value might contain property tax, inventory tax, or any other taxes that are included in the transaction.
   *
   * [Sales](http://help.mscanapi.com/html/74b80806-9c60-3051-1a7e-ec071b1ceaeb.htm)
   */
  Sales: MSAPIRateValue;
};

/**
 * Motor Vehicle Sales Tax Rate and Sales Tax Value.
 *
 * [MSAPIRateValue](http://help.mscanapi.com/html/3dca12c7-c98a-79bb-ddd7-6dc857b393b6.htm)
 */
export type MSAPIRateValue = {
  /**
   * Tax rate.
   */
  Rate: number;
  /**
   * Tax value.
   */
  Value: number;
};

/**
 * Lender program information for each defined Term.
 * [MSAPITermReference](http://help.mscanapi.com/html/28d1ab5b-1514-ed03-0cbe-81a768108a8f.htm)
 */
export type MSAPITermReference = {
  /**
   * [PaidReserves](http://help.mscanapi.com/html/7624b8ea-4598-cef6-c60c-196d8b30ad03.htm)
   */
  PaidReserves: MSAPIPaidReserve[];
  /**
   * An array of lender programs.
   * [Programs](http://help.mscanapi.com/html/66896a1d-85dc-e561-22cb-a264d20541c3.htm)
   */
  Programs: MSAPIProgramReference[];
  Term: number;
};

/**
 * Lease scan results.
 *
 * [MSAPIScanResultLease](http://help.mscanapi.com/html/43a1f0f2-bc8f-e0c2-fd98-989aeacd5378.htm)
 */
export type MSAPIScanResultLease = {
  /**
   *  (Inherited from MSAPIScanResultCommon.)
   */
  Hint: string[];
  /**
   * An array of Terms that contains lender program information for each defined Term.
   * [Terms](http://help.mscanapi.com/html/b68f6437-1962-7cab-bb00-2c1d8dba5322.htm)
   */
  Terms: MSAPITermReference[];
};

/**
 * Retail scan results.
 *
 * [MSAPIScanResultRetail](https://help.mscanapi.com/html/cba4746e-064a-47df-a814-75417132d8f5.htm)
 */
export type MSAPIScanResultRetail = {
  /**
   *  (Inherited from MSAPIScanResultCommon.)
   */
  Hint: string[];
  /**
   * An array of Terms that contains lender program information for each defined Term.
   * [Terms](http://help.mscanapi.com/html/b68f6437-1962-7cab-bb00-2c1d8dba5322.htm)
   */
  Terms: MSAPITermReference[];
};

/**
 * mPencil - Cash Purchase Option.
 *
 * [MSAPIScanResultmPencil](http://help.mscanapi.com/html/ff1a18b1-9854-5779-d403-03273b9ee7a6.htm)
 */
export type MSAPIScanResultmPencil = {
  /**
   * [CashPurchaseOption](http://help.mscanapi.com/html/f82575db-6ebc-7453-fdf1-8a89e7c4cb4d.htm)
   */
  CashPurchaseOption: MSAPIScanResultCashPurchaseOption;
};

/**
 * [MSAPIScanResultCashPurchaseOption](http://help.mscanapi.com/html/16b49f7b-c36a-6af2-3e85-99b942251a94.htm)
 */
export type MSAPIScanResultCashPurchaseOption = {
  /**
   *
   */
  AppliedRebate: MSAPIAppliedRebate[];
  /**
   * Custom Fees.
   */
  CustomFees: number;
  /**
   * Other Fees.
   */
  OtherFees: number;
  /**
   * Rebate.
   */
  Rebate: number;
  /**
   * Registration Fee.
   */
  RegistrationFee: number;
  /**
   * Sales Tax.
   */
  SalesTax: number;
  /**
   * Selling Price.
   */
  SellingPrice: number;
  /**
   * Calculated motor vehicle tax value.
   */
  Tax: MSAPITaxData;
  /**
   * Taxes and Fees.
   */
  TaxesAndFees: number;
  /**
   * Tire Fee.
   */
  TireFee: number;
  /**
   * "Total Cash Due.
   */
  TotalCashDue: number;
  /**
   * "Trade In Allowance.
   */
  TradeAllowance: number;
  /**
   * "Trade In Pay Off.
   */
  TradePayOff: number;
  /**
   * "Vehicle MSRP.
   */
  VehicleMSRP: number;
};

export type MSAPIMultiAnnualMileageScanResultLease = {
  Lease: MSAPIAnnualMileageScanResultLease[];
};

export type MSAPIAnnualMileageScanResultLease = {
  AnnualMileage: number;
  Lease: MSAPIScanResultLease;
};

export type MSAPIScanResult = {
  /**
   * Returns the generic pass description
   */
  CustomKey: string;
  /**
   * mScan engine name.
   */
  Engine: string;
  /**
   * mScan engine version.
   */
  EngineVersion: string;
  /**
   * Lease data/lender programs based on the requested Terms
   *
   * [Lease](http://help.mscanapi.com/html/53635620-6363-2eb4-b537-df107d6c7215.htm)
   */
  Lease: MSAPIScanResultLease;
  /**
   * Cash purchase option.
   *
   * [mPencil](http://help.mscanapi.com/html/fd27746c-a4b2-161f-6d24-eb34af53698d.htm)
   */
  mPencil: MSAPIScanResultmPencil;
  /**
   * Selling Price value from quoting policy rules.
   */
  QuotedDesiredValue: number;
  /**
   * Quote scan type from quoting policy rules.
   */
  QuotedScanType: MSAPIScanType;
  /**
   * Quoting policy rule description that was applied to the request.
   */
  QuotingPolicyUsed: string;
  /**
   * Quote policy ID from quoting policy rules.
   */
  QuotingPolicyUsedID: number;
  /**
   * Retail data/lender programs based on the requested Terms.
   */
  Retail: MSAPIScanResultRetail;
  /**
   * If included and bool set to false you will not return UI results.
   */
  UIJson: string;
};

/**
 * Tax rates by zip code and region.
 * Some locations might require a TaxRegionID and TaxInCity properties to be defiend to return the proper tax rate.
 * If TaxRegionID and TaxInCity are not defined then the default rate is used for the ZIP
 *
 * [MSAPIZIPTaxRate](http://help.mscanapi.com/html/4c71c13e-8437-44d8-cf64-ab61489d4952.htm)
 */
export type MSAPIZIPTaxRate = {
  InCity: boolean;
  RegionID: number;
  RegionName: string;
  StateFeeTax: MSAPIStateFeeTax;
  SubregionID: number;
  ZIP: string;
};

/**
 * Rebate Data.
 *
 * [MSISRebatesData](http://help.mscanapi.com/html/1f579aec-e0d3-03b3-b5c7-66bc36ee786e.htm)
 */
export type MSISRebatesData = {
  /**
   * Rebate customer type identifier.
   */
  CustomerTypeID: number;
  /**
   * Array of rebate customer types.
   * Rebate Customer Type - Ford and GMAC rebates contain the most rebate customer types.
   * By Default we return \"Individual\".
   */
  CustomerTypes: MSISRebateCustomerType[];
  /**
   * Dealership zip code. Some manufacturers apply dealer incentives based on their ZIP.
   */
  DealerZIP?: number;
  /**
   * Array of rebate compatibilities.
   */
  GeneralCompatibilities: MSISCompatibilityMatrix[];
  /**
   *
   */
  InMem: boolean;
  /**
   * Array of rebates.
   */
  Rebates: MSISRebate[];
  /**
   * Rebate region identifier, currently only used for Nissan and is Zip code dependent.
   */
  RegionID: number;
  /**
   * Array of Rebate regions, currently only used for Nissan and is Zip code dependent.
   */
  Regions: MSISRebateRegion[];
  /**
   * mScanAPI Vehicle Identifier.
   */
  VehicleID: number;
  /**
   * Zip code.
   */
  ZIP: number;
};
/**
 * [MSAPIVSAccountInfo](https://help.mscanapi.com/html/7bc20e88-eaa4-5af1-02ae-274b4815e3bd.htm)
 */
export type MSAPIMVSAccountInfo = {
  AccountNumber: string;
};

/**
 * Filter out lender programs.
 *
 * [MSAPILenderFilter](https://help.mscanapi.com/html/10591e0c-44d1-a437-1790-af3a3feccc3b.htm)
 */
export type MSAPILenderFilter = {
  /**
   * If bool is true, it will return only the captive lender.
   */
  UseOnlyCaptiveLender: boolean;
  /**
   * An array of Lenders that are defined by the Lender code and Lender tier.
   */
  UseOnlyLender: MSAPILender[];
  /**
   * An array of Lenders that are defined by the Lender code.
   */
  UseOnlyLenderCode: string[];
  /**
   * Setting Bool to true will filter programs based on the LenderCode.
   */
  UseOnlyLenderCodeSpecified: boolean;
  /**
   * Setting Bool to true will filter programs based on the Lender.
   */
  UseOnlyLenderSpecified: boolean;
};

/**
 * Market for state calculations. Seperate market for interest rates/money factors.
 *
 * [MSAPIMarketData](https://help.mscanapi.com/html/e147cf0a-4aae-b4d8-d697-8d262c2b3ae4.htm)
 */
export type MSAPIMarketData = {
  /**
   * Market/State for tax purposes.
   */
  Market: MSAPIMarket;
  /**
   * Rate taxes and fees based on the desired ratemarket/state.
   */
  RateMarket: MSAPIMarket;
  /**
   * State taxes and fees.
   */
  StateFeeTax: MSAPIStateFeeTax;
};

/**
 * Define Account Number, Lender Filters, Market, Rebates, and Vehicle Parameters.
 *
 * [MSAPIMVSVehicleItem](https://help.mscanapi.com/html/3dff33b7-cd27-451c-84fa-b76dd5445341.htm)
 */
export type MSAPIMVSVehicleItem = {
  /**
   * Account Number.
   */
  AccountInfo: MSAPIMVSAccountInfo;
  /**
   * Apply rebates by ZIP Code.                          Applies to all rebates that are flagged \"Available to All\", applicable for the specified ZIP and RegionID.                        Can auto apply rebates by Category, VIN, Trade-In, and Vehicle Option Code.                        Option to exclude Dealer Cash rebates that are flagged \"Available to All\"
   */
  AutoRebateParams: MSAPIAutoRebateParams;
  /**
   * Setting bool to true will apply AutoRebateParams defined property values in the request.
   */
  AutoRebateParamsSpecified: true;
  /**
   * Defining a credit score will limit the number of lender programs returned.                         Use \"0\" if you want to return all lender programs.
   */
  CreditScore?: number;
  /**
   "
   */
  CustomerCash?: number;
  /**
   * Desired profit value when running a Profit scan (MSISAPIScanRequest.ScanType = 0).                         Desired Selling Price value when running a Selling Price scan(MSISAPIScanRequest.ScanType = 1).                         Desired Payment value when running a Payment scan (MSISAPIScanRequest.ScanType = 2).
   */
  DesiredValue: number;
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Lease custom fees.
   */
  LeaseCustomFee: MSAPICustomFeeLease[];
  /**
   * Setting bool to true will pass account custom fees.
   */
  LeaseCustomFeeSpecified: boolean;
  /**
   * Lease Insurance.
   */
  LeaseInsurance: MSAPIInsurance[];
  /**
   * Setting bool to true will pass Lease Insurance information.
   */
  LeaseInsuranceSpecified: boolean;
  /**
   * Filter results by lender.
   */
  LeaseLenderFilter: MSAPILenderFilter;
  /**
   * Lease Service Contract.
   */
  LeaseServiceContract: MSAPIServiceContract[];
  /**
   * Setting bool to true will pass Service Contract information.
   */
  LeaseServiceContractSpecified: boolean;
  /**
   * Define a market for state calculations or to define a seperate market for interest rates/money factors.
   */
  Market: MSAPIMarketData;
  /**
   * Rebates for the specified vehicle.
   */
  Rebates: MSAPIScanRequestRebateContainer;
  /**
   * Setting bool to true will apply rebates to the transaction.
   */
  RebatesSpecified: boolean;
  /**
   * Retail Custom Fees.
   */
  RetailCustomFee: MSAPICustomFeeRetail[];
  /**
   * Setting bool to true will pass account custom fees.
   */
  RetailCustomFeeSpecified: boolean;
  /**
   * Retail Insurance.
   */
  RetailInsurance: MSAPIInsurance[];
  /**
   * Setting bool to true will pass Retail Insurance information.
   */
  RetailInsuranceSpecified: boolean;
  /**
   * Filter results by lender.
   */
  RetailLenderFilter: MSAPILenderFilter;
  /**
   * Retail Service Contract.
   */
  RetailServiceContract: MSAPIServiceContract[];
  /**
   * Setting bool to true will pass Retail Service Contract information.
   */
  RetailServiceContractSpecified: boolean;
  /**
   * Vehicle of interest values.
   */
  Vehicle: MSAPIVehicle;
  /**
   * Vehicle equipment for the vehicle of interest.
   */
  VehicleEquipment: MSAPIVehicleEquipmentResidualizable[];
  /**
   * Setting bool to true will apply the defined vehicle equipment values to the transaction.
   */
  VehicleEquipmentSpecified: boolean;
  /**
   * Warranty values.
   */
  Warranty: MSAPIWarranty;
  /**
   * Setting bool to true will apply warranty values to the transaction.
   */
  WarrantySpecified: boolean;
};

/**
 * Multiple vehicle lease and retail transactions. Can define multiple vehicles.
 *
 * [MSAPIMultiVehicleScanRequest](https://help.mscanapi.com/html/26359e26-3b03-1fb4-1de0-53693f4f1e1c.htm)
 */
export type MSAPIMultiVehicleScanRequest = {
  /**
   *  Vehicle parameters for lease and retail.
   */
  Item: MSAPIMVSVehicleItem[];
  /**
   *  Scan parameters.
   */
  Request: MSISAPIScanRequest;
};

/**
 * Multiple Vehicle Results.
 *
 * [MSAPIMVVehicleResult](https://help.mscanapi.com/html/07b7fa27-fc70-0f73-c791-6d433ae62071.htm)
 */
export type MSAPIMVVehicleResult = {
  ID: number;
  Result: MSAPIScanResult;
};

/**
 * Multiple vehicle scan results.
 *
 * [MSAPIMultiVehicleScanResult](https://help.mscanapi.com/html/ca20281a-d714-6fe8-3052-10bb0969020f.htm)
 */
export type MSAPIMultiVehicleScanResult = {
  /**
   * Array of lease and retail transactions based on the VehicleID.
   */
  Item: MSAPIMVVehicleResult[];
};

/**
 * Makes/Manufacturer information.
 *
 * [MSAPIMake](https://help.mscanapi.com/html/d5034721-2d39-7c00-9bd4-6bca4685d2a3.htm)
 */
export type MSAPIMake = {
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Vehicle Make.
   */
  Name: string;
  /**
   * Captive lender name.
   */
  Captive: Array<string>;
  /**
   * Bool is set to true for domestic vehicle makes.
   */
  IsDomestic: boolean;
};

export type MSAPIModel = {
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Make Identifier.
   */
  MakeID: number;
  /**
   * Model Name.
   */
  Name: string;
};

export type MSAPIYear = {
  /**
   * Numeric format of year
   */
  Year: number;
  /**
   * String year representation
   */
  YearDisplay: string;
};

export type MSISRebateCategory = {
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Rebate Category name to be displayed.
   */
  Name: string;
  /**
   * Contains an array of Subcategories. Only used if a Category contains a Subcategory.
   */
  Subcategory: MSISRebateSubcategory[];
};

export type MSISRebateSubcategory = {
  /**
   * Identifier.
   */
  ID: number;
  /**
   * Name of Subcategory rebate.
   */
  Name: string;
};

export type MSAPIInventoryVehicle = {
  BaseMSRPAmount: number;
  Bore: number;
  CategoryID: number;
  ClassID: number;
  CurrentMileage: number;
  Cylinders: number;
  Displacement: number;
  ElectricRange: number;
  engineType: MSAPIVehicleEngineTypeEnum;
  EPAMPGAvg: number;
  EPAMPGCity: number;
  EPAMPGHwy: number;
  EquipmentResidualizable: MSAPIVehicleEquipmentResidualizable;
  ExteriorColor: string;
  GasGuzzlerValue: number;
  GrossVehicleWeightRating: number;
  ID: number;
  InteriorColor: string;
  Invoice: number;
  Is2WheelDrive: boolean;
  IsCar: boolean;
  IsDomestic: boolean;
  IsMiniVAN: boolean;
  IsNew: boolean;
  IsSUV: boolean;
  IsTruck: boolean;
  IsVAN: boolean;
  LotAge: number;
  MakeID: number;
  MaxHP: number;
  ModelID: number;
  ModelNumber: string;
  MsCode: string;
  MSRP: number;
  MSRPDiscount: number;
  Option: MSAPIVehicleOption[];
  PreferredPrice: number;
  Price: number;
  Price3: number;
  Shipping: number;
  ShortDescription: string;
  StockNo: string;
  Stroke: number;
  SubcategoryID: number;
  UsedData: MSAPIUsedVehicleData;
  VIN: string;
  Weight: number;
  Year: number;
  YearDisplay: string;
};

export type MSAPIDCDefaultCustomFee = {
  Lease: MSAPIDCCustomFeeLeaseArray;
  Retail: MSAPIDCCustomFeeRetailArray;
};

export enum MSAPIDefaultNewUsedTypeEnum {
  New = 0,
  Used = 1,
}

export type MSAPIDCCustomFeeLeaseArray = Array<{
  CustomFee: MSAPICustomFeeLease[];
  Type: MSAPIDefaultNewUsedTypeEnum;
}>;

/**
 * Retail custom fee properties array.
 *
 * [MSAPIDCCustomFeeRetailArray](https://help.mscanapi.com/html/023addd4-4143-039c-fd5c-0534b800c4f3.htm)
 */
export type MSAPIDCCustomFeeRetailArray = Array<{
  CustomFee: MSAPICustomFeeRetail[];
  Type: MSAPIDefaultNewUsedTypeEnum;
}>;

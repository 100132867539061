import React from 'react';
import { styled } from '@mui/material/styles';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import compressImage from 'utils/compressImage';

const DefaultAvatar = styled(StyledFontAwesomeIconMui5)(() => ({
  width: '100% !important',
  height: '100%',
  '& .svg-inline--fa': {
    height: '100% !important',
  },
}));

export const StyledDiv = styled('div')(() => ({
  width: 100,
  height: 100,
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '100%',
}));

export const StyledAvatar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'src',
})<{ src: string }>(({ src }) => ({
  backgroundImage: `url("${src}")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
}));

export const FileInput = styled('input')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  opacity: 0,
  width: 100,
  height: 100,
}));

type Props = {
  src: string;
  onChange?: (src: string) => void;
  name?: string;
  className?: string;
};

const Avatar = ({ name, src, onChange, className }: Props): JSX.Element => {
  return (
    <StyledDiv className={className}>
      {!src ? (
        <DefaultAvatar
          icon={faUserCircle}
          color='imherit'
          sx={{ color: 'inherit' }}
        />
      ) : (
        <StyledAvatar src={src} />
      )}
      {onChange && (
        <FileInput
          accept='image/*'
          name={name}
          onChange={(event) => {
            const selectedFile = event.target.files[0];

            if (!selectedFile) {
              return;
            }

            const reader = new FileReader();

            reader.onload = async function (event) {
              const src = await compressImage(
                event.target.result as string,
                200,
                200,
              );
              onChange(src as string);
            };

            reader.readAsDataURL(selectedFile);
          }}
          type='file'
        />
      )}
    </StyledDiv>
  );
};

export default Avatar;

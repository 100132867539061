export enum Makes {
  'Acura' = 'Acura',
  'Alfa Romeo' = 'Alfa Romeo',
  'Aston Martin' = 'Aston Martin',
  'Audi' = 'Audi',
  'BMW' = 'BMW',
  'Bentley' = 'Bentley',
  'Buick' = 'Buick',
  'Cadillac' = 'Cadillac',
  'Chevrolet' = 'Chevrolet',
  'Chrysler' = 'Chrysler',
  'Dodge' = 'Dodge',
  'FIAT' = 'FIAT',
  'Ferrari' = 'Ferrari',
  'Ford' = 'Ford',
  'Freightliner' = 'Freightliner',
  'GMC' = 'GMC',
  'Genesis' = 'Genesis',
  'Honda' = 'Honda',
  'Hyundai' = 'Hyundai',
  'INFINITI' = 'INFINITI',
  'Jaguar' = 'Jaguar',
  'Jeep' = 'Jeep',
  'Karma' = 'Karma',
  'Kia' = 'Kia',
  'Lamborghini' = 'Lamborghini',
  'Land Rover' = 'Land Rover',
  'Lexus' = 'Lexus',
  'Lincoln' = 'Lincoln',
  'Lotus' = 'Lotus',
  'Lucid' = 'Lucid',
  'MINI' = 'MINI',
  'Maserati' = 'Maserati',
  'Mazda' = 'Mazda',
  'McLaren' = 'McLaren',
  'Mercedes-Benz' = 'Mercedes-Benz',
  'Mitsubishi' = 'Mitsubishi',
  'Nissan' = 'Nissan',
  'Polestar' = 'Polestar',
  'Porsche' = 'Porsche',
  'Ram' = 'Ram',
  'Rolls-Royce' = 'Rolls-Royce',
  'Rivian' = 'Rivian',
  'Subaru' = 'Subaru',
  'Tesla' = 'Tesla',
  'Toyota' = 'Toyota',
  'Volkswagen' = 'Volkswagen',
  'Volvo' = 'Volvo',
}

import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const SelectLabel = styled(InputLabel, { skipSx: false })(
  ({ theme }) => `
  &.MuiInputLabel-root {
    color: ${theme.palette.primary.main};
    transform: translate(${theme.spacing(0)}, ${theme.spacing(-3)}) !important;
    text-transform: uppercase;
  }`,
);

export default SelectLabel;
